import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { isNullOrEmpty } from '@liveconnect/communities-ui'
import {
  FormRichText,
  FormSelect,
  FormTextInput,
  FormProvider,
  Loader,
  Tooltip,
  UploadInput,
  MimeType,
} from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import useBrandsInfo from '../../core/brandSpace/useBrandSpace'
import FormActions from '../../components/forms/FormActions'
import { buildValidationSchema } from './validations'
import useLocalizations from '../../core/localizations/useLocalizations'
import { DEFAULT_LANG } from '../../i18n/config'
import useNotifications from '../../utils/notifications/useNotifications'
import TranslationCard from '../../components/TranslationCard'
import { characterCounterText } from '../../utils/characterCounterText'

import {
  AvailableBrand,
  BrandBasicInfo,
  BrandTranslation,
} from '../../core/brandSpace/types'
import useUi from '../../core/ui/useUi'
import { useBlockRouteChangeWithDialog } from '../../utils/routing/useBlockRouteChange'
import useUploadInput from '../../utils/uploadInput/useUploadInput'

interface BrandBasicInfoProps {
  brandsWithoutSpace?: AvailableBrand[]
  isEdit: boolean
  brandId: string | undefined
  onBasicInfoSubmit: () => void
  onCancel: () => void
}

const BrandBasicInfoComponent: FC<BrandBasicInfoProps> = ({
  brandsWithoutSpace,
  isEdit,
  onBasicInfoSubmit,
  onCancel,
  brandId,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    brand,
    uploadBrandHeaderImage,
    uploadBrandLogoImage,
    uploadBrandProfileImage,
    createBrand,
    updateBrand,
    isLoaded,
  } = useBrandsInfo()
  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })
  const { localizations } = useLocalizations()
  const { showConfirmation } = useUi()
  const notify = useNotifications()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const methods = useForm<BrandBasicInfo>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      language:
        (localizations || []).find((item) => item.isDefault)?.isoCode ||
        DEFAULT_LANG,
    },
  })
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState,
    setError,
    setValue,
    formState: { isValid, dirtyFields },
  } = methods

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  useBlockRouteChangeWithDialog(isDirtyWithoutLanguage && !isSubmitting)

  const watchCode = watch('code')
  const watchLang = watch('language')

  const eventCodes = useMemo(() => {
    if (!brandsWithoutSpace) return []
    const options = brandsWithoutSpace.map((brand: AvailableBrand) => {
      return {
        value: brand.code,
        label: brand.code,
      }
    })
    if (brand && !options.some((opt) => opt.value === brand.code))
      options.push({ value: brand.code, label: brand.code })
    return options.sort((o1, o2) => o1.label.localeCompare(o2.label))
  }, [brand, brandsWithoutSpace])

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          iconName: 'report_problem',
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const onSubmit = async (brand: BrandBasicInfo) => {
    setIsSubmitting(true)
    try {
      const imagePromises = []
      if (brand.headerImageUrl.includes('blob:'))
        imagePromises.push(uploadBrandHeaderImage(brand.headerImageUrl))
      if (brand.logoImageUrl.includes('blob:'))
        imagePromises.push(uploadBrandLogoImage(brand.logoImageUrl))
      if (brand.profileImageUrl.includes('blob:'))
        imagePromises.push(uploadBrandProfileImage(brand.profileImageUrl))
      const imageUrls = await Promise.all(imagePromises)
      const headerNewValue = imageUrls.find(
        (image) => image && image[0] === 'headerImageUrl'
      )
      const logoNewValue = imageUrls.find(
        (image) => image && image[0] === 'logoImageUrl'
      )
      const profileNewValue = imageUrls.find(
        (image) => image && image[0] === 'profileImageUrl'
      )
      const _brand = {
        ...brand,
        headerImageUrl: headerNewValue
          ? headerNewValue[1]
          : brand.headerImageUrl,
        logoImageUrl: logoNewValue ? logoNewValue[1] : brand.logoImageUrl,
        profileImageUrl: profileNewValue
          ? profileNewValue[1]
          : brand.profileImageUrl,
      }
      if (isEdit) {
        delete brand.code
        brandId && (await updateBrand(brandId, _brand))
      } else {
        const response = await createBrand(_brand)
        if (response) {
          onBasicInfoSubmit()
          navigate(`../${response.id}/edit`)
        }
      }
      setIsSubmitting(false)

      const toast = isEdit
        ? 'brand.edit.editSuccess'
        : 'brand.edit.createSuccess'
      notify.success(t(toast))
    } catch {
      notify.error(t('brand.edit.saveError'))
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    const trans = (brand?.translations || []).find(
      (item: BrandTranslation) => item.language === watchLang
    )
    reset({
      ...brand,
      description: '',
      headerImageUrl: '',
      name: '',
      shortDescription: '',
      title: '',
      ...trans,
      language: watchLang,
    })
  }, [watchLang])

  useEffect(() => {
    if (!isEdit || !brand) return
    const trans = (brand?.translations || []).find(
      (item) => item.language === DEFAULT_LANG
    )
    reset({
      ...brand,
      description: '',
      headerImageUrl: '',
      language: '',
      name: '',
      shortDescription: '',
      title: '',
      ...trans,
    })
  }, [brand, isEdit, reset])

  const renderLanguageSelector = () => {
    return (
      <>
        <FormSelect
          control={control}
          name="language"
          label=""
          options={localizations.map((item) => ({
            label: t(`localizations.${item.isoCode}`),
            value: item.isoCode,
          }))}
          disabled={!isEdit || !isLoaded}
          noOptionsMessage={t('select.empty')}
          menuPlacement="top"
          isClearable={false}
          onBeforeChange={handleBeforeChangeLang}
        />
        <Tooltip content={t('brand.changeLanguage.tooltip')} className="ms-2">
          <Icon name="info_outline" tabIndex={0} />
        </Tooltip>
      </>
    )
  }

  return (
    <FormProvider methods={methods}>
      {brandId && brand && (
        <TranslationCard
          translations={brand.translations}
          lang={watchLang || DEFAULT_LANG}
          entity={t('brand.edit.create.title')}
        />
      )}
      <div className="BrandEdit__section">
        <div className="eventCode col-9">
          <FormSelect
            control={control}
            name="code"
            label={t('brand.edit.eventCode')}
            placeholder={t('brand.edit.eventCode.placeholder')}
            tooltip={
              <div className="eventCode__tooltip">
                <strong>{t('brand.edit.eventCode')}</strong>
                {t('brand.edit.eventCode.tooltip')}
              </div>
            }
            options={eventCodes}
            required
            loading={typeof brandsWithoutSpace === 'undefined'}
            disabled={!isLoaded}
            noOptionsMessage={t('select.empty')}
          />
        </div>
      </div>
      <div className="BrandEdit__section">
        <h4>{t('brand.edit.brandDetail')}</h4>
        <div className="brandName col-9">
          <FormTextInput
            control={control}
            name="name"
            label={t('brand.edit.brandName')}
            type="text"
            required
            disabled={isNullOrEmpty(watchCode) || !isLoaded}
          />
        </div>
        <div className="brandShortDescription col-9">
          <FormTextInput
            control={control}
            name="shortDescription"
            label={t('brand.edit.brandShortDescription')}
            placeholder={t('brand.edit.brandShortDescription.placeholder')}
            type="text"
            required
            disabled={!isLoaded}
          />
          <span className="max">{t('brand.edit.brandTitle.max')}</span>
        </div>
        <div className="imagesContainer">
          <div className="brandIcon">
            <UploadInput
              control={control}
              title={t('brand.edit.profileImageUrl')}
              name="profileImageUrl"
              accept={accept}
              onRemoveFile={() => setValue('profileImageUrl', '')}
              selectFilesError={(error) =>
                setError('profileImageUrl', {
                  message: error.message,
                })
              }
              required
              rules={{
                image: {
                  maxWidth: 144,
                  maxHeight: 144,
                  maxFileSize: 1,
                },
              }}
              slotLabel={
                <Tooltip
                  content={t('brand.edit.profileImageUrl.tooltip')}
                  className="ms-2"
                >
                  <Icon name="info_outline" tabIndex={0} />
                </Tooltip>
              }
              labels={labels}
            />
          </div>
          <div className="brandLogo">
            <UploadInput
              control={control}
              title={t('brand.edit.logoImageUrl')}
              name="logoImageUrl"
              accept={accept}
              onRemoveFile={() => setValue('logoImageUrl', '')}
              selectFilesError={(error) =>
                setError('logoImageUrl', {
                  message: error.message,
                })
              }
              required
              rules={{
                image: {
                  maxWidth: 144,
                  maxHeight: 48,
                  maxFileSize: 1,
                },
              }}
              slotLabel={
                <Tooltip
                  content={t('brand.edit.logoImageUrl.tooltip')}
                  className="ms-2"
                >
                  <Icon name="info_outline" tabIndex={0} />
                </Tooltip>
              }
              labels={labels}
            />
          </div>
        </div>
      </div>
      <div className="BrandEdit__section">
        <h4>{t('brand.edit.brandInfo')}</h4>
        <div className="brandTitle col-9">
          <FormTextInput
            control={control}
            name="title"
            label={t('brand.edit.brandTitle')}
            placeholder={t('brand.edit.brandTitle.placeholder')}
            type="text"
            required
            disabled={!isLoaded}
          />
          <span className="max">{t('brand.edit.brandTitle.max')}</span>
        </div>
        <div className="brandDescription">
          <FormRichText
            control={control}
            name="description"
            label={t('brand.edit.brandDescription')}
            placeholder={t('brand.edit.brandDescription.placeholder')}
            key={watchLang}
            required
            helperText={characterCounterText(
              watch('description') ?? '',
              1400,
              t
            )}
          />
        </div>
        <div className="brandImage">
          <UploadInput
            control={control}
            title={t('brand.edit.headerImageUrl')}
            name="headerImageUrl"
            accept={accept}
            onRemoveFile={() => setValue('headerImageUrl', '')}
            selectFilesError={(error) =>
              setError('headerImageUrl', {
                message: error.message,
              })
            }
            required
            rules={{
              image: {
                maxWidth: 672,
                maxHeight: 284,
                maxFileSize: 1,
              },
            }}
            slotLabel={
              <Tooltip
                content={t('brand.edit.headerImageUrl.tooltip')}
                className="ms-2"
              >
                <Icon name="info_outline" tabIndex={0} />
              </Tooltip>
            }
            labels={labels}
          />
        </div>
      </div>
      <div className="BrandEdit__actions">
        <FormActions languageSelector={renderLanguageSelector()}>
          <button
            className="btn btn-outline-primary"
            disabled={isSubmitting}
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {isSubmitting ? <Loader /> : t('common.save')}
          </button>
        </FormActions>
      </div>
    </FormProvider>
  )
}

export { BrandBasicInfoComponent }
