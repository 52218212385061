// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader--full-screen{position:fixed;top:0;left:0;width:100vw;height:100vh}`, "",{"version":3,"sources":["webpack://./src/app/components/Loader/index.scss"],"names":[],"mappings":"AAUE,qBACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,WAAA,CACA,YAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.Loader {\n\n  &--full-screen {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Loader--full-screen": `Loader--full-screen`
};
export default ___CSS_LOADER_EXPORT___;
