import { FC, useState, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'

import { Tooltip } from '@liveconnect/communities-ui'
import {
  FormSelect,
  FormTextInput,
  FormProvider,
  TextControl,
  Loader,
  MimeType,
  UploadInput,
} from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import FormActions from '../../../../components/forms/FormActions'
import { Main } from '../../../../components/Main'
import TranslationCard from '../../../../components/TranslationCard'
import {
  Badge,
  BrandBadgeInfo,
  PostBadgePayload,
  PutBadgePayload,
} from '../../../../core/badges/types'
import useLocalizations from '../../../../core/localizations/useLocalizations'
import { DEFAULT_LANG, ENGLISH_LANG } from '../../../../i18n/config'
import { useCustomRouter } from '../../../../utils/extractParams'
import { BrandTabs } from '../../types'
import { buildValidationSchema } from './validations'
import useBadges from '../../../../core/badges/useBadges'
import useUi from '../../../../core/ui/useUi'
import useNotifications from '../../../../utils/notifications/useNotifications'
import { useBlockRouteChangeWithDialog } from '../../../../utils/routing/useBlockRouteChange'
import { FetchError } from '../../../../utils/fetch/types'
import useUploadInput from '../../../../utils/uploadInput/useUploadInput'

import './styles.scss'

const EditBadge: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { localizations } = useLocalizations()
  const { basePath } = useCustomRouter()
  const { brandId, badgeId } = useParams()
  const notify = useNotifications()
  const {
    uploadImageBadge,
    createBadge,
    updateBadge,
    fetchBadgeDetail,
    badgeDetail,
    isLoaded,
  } = useBadges()
  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })
  const { showConfirmation } = useUi()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isTranslating, setIsTranslating] = useState<boolean>(false)
  const methods = useForm<BrandBadgeInfo>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      name: '',
      imageUrl: '',
      language:
        (localizations || []).find((item) => item.isDefault)?.isoCode ||
        DEFAULT_LANG,
    },
  })
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setError,
    setValue,
    formState,
    formState: { isValid, dirtyFields },
  } = methods

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  useBlockRouteChangeWithDialog(isDirtyWithoutLanguage && !isSubmitting)

  const watchName = watch('name')
  const watchPicture = watch('imageUrl')
  const watchLanguage = watch('language')

  const defaultBadgeTrans = useMemo(() => {
    return (badgeDetail?.translations || []).find(
      (item) => item.language === DEFAULT_LANG
    )
  }, [badgeDetail])

  const englishBadgeTrans = useMemo(() => {
    return (badgeDetail?.translations || []).find(
      (item) => item.language === ENGLISH_LANG
    )
  }, [badgeDetail])

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          iconName: 'report_problem',
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const renderLanguageSelector = () => {
    return (
      <>
        <FormSelect
          control={control}
          name="language"
          label=""
          options={localizations.map((item) => ({
            label: t(`localizations.${item.isoCode}`),
            value: item.isoCode,
          }))}
          disabled={!badgeId}
          noOptionsMessage={t('select.empty')}
          menuPlacement="top"
          isClearable={false}
          onBeforeChange={handleBeforeChangeLang}
        />
        <Tooltip content={t('brand.changeLanguage.tooltip')} className="ms-2">
          <Icon name="info_outline" tabIndex={0} />
        </Tooltip>
      </>
    )
  }

  const handleCancel = () => {
    navigate(`${basePath}/brands/${brandId}/edit`, {
      state: { brandCurrentTab: BrandTabs.BADGES },
    })
  }

  const submitCreate = async (badge: BrandBadgeInfo, idBrand: string) => {
    try {
      let data: PostBadgePayload = {
        imageUrl: badge.imageUrl,
        brandSpaceId: idBrand,
        name: badge.name,
        language: badge.language,
      }
      if (data?.imageUrl?.includes('blob:')) {
        const imageUrl = await uploadImageBadge(data.imageUrl as string)
        data = { ...data, imageUrl }
      }
      const response: Badge | undefined = await createBadge(data)
      if (response) {
        setIsSubmitting(false)
        navigate(`${basePath}/brands/${brandId}/edit`, {
          state: { brandCurrentTab: BrandTabs.BADGES },
        })
        notify.success(t('badge.form.edit.succeessFeedback'))
      } else setIsSubmitting(false)
    } catch (error: unknown) {
      const fetchError = error as FetchError
      if (fetchError.status === 409) {
        notify.error(t('topic.channel.modal.formError'))
        setError('name', {
          type: 'custom',
          message: t(`validations.ENTITY_CONFLICT`, {
            label: t(`badge.form.error.name`).toLowerCase(),
          }),
        })
      } else {
        notify.error(t('badge.form.unknownError'))
      }

      setIsSubmitting(false)
    }
  }

  const submitEdit = async (
    badge: BrandBadgeInfo,
    idBrand: string,
    idBadge: string
  ) => {
    try {
      let data: PutBadgePayload = {
        language: badge.language,
        brandSpaceId: idBrand,
        translation: {
          badgeId: idBadge,
          name: badge.name,
          imageUrl: badge.imageUrl,
        },
      }
      if (badge.imageUrl?.includes('blob:')) {
        const imageUrlTrans = await uploadImageBadge(badge.imageUrl as string)
        data = {
          language: badge.language,
          brandSpaceId: idBrand,
          translation: {
            badgeId: idBadge,
            name: badge.name,
            imageUrl: imageUrlTrans,
          },
        }
      }
      const response: Badge | undefined = await updateBadge(idBadge, data)
      if (response) {
        navigate(`${basePath}/brands/${brandId}/edit`, {
          state: { brandCurrentTab: BrandTabs.BADGES },
        })
        notify.success(t('badge.form.edit.succeessFeedback'))
        setIsSubmitting(false)
      }
    } catch (error: unknown) {
      notify.error(t('badge.form.edit.unknownError'))
      setIsSubmitting(false)
    }
  }

  const onSubmit = async (badge: BrandBadgeInfo) => {
    setIsSubmitting(true)
    if (!badgeId && brandId) {
      submitCreate(badge, brandId)
    }
    if (badgeId && brandId && badgeId) {
      submitEdit(badge, brandId, badgeId)
    }
  }

  const youShouldNotChange = () => {
    throw new Error('Cannot change value to disabled input')
  }

  useEffect(() => {
    if (badgeId) {
      try {
        fetchBadgeDetail(badgeId)
      } catch (error: unknown) {
        notify.error(t('badge.form.unknownError'))
      }
    }
  }, [badgeId])

  useEffect(() => {
    if (badgeId && badgeDetail) {
      if (watchLanguage === ENGLISH_LANG && englishBadgeTrans) {
        reset({
          name: englishBadgeTrans?.name,
          imageUrl: englishBadgeTrans?.imageUrl,
          language:
            (localizations || []).find((item) => item.isDefault)?.isoCode ||
            DEFAULT_LANG,
        })
      } else {
        reset({
          name: defaultBadgeTrans?.name,
          imageUrl: defaultBadgeTrans?.imageUrl,
          language:
            (localizations || []).find((item) => item.isDefault)?.isoCode ||
            DEFAULT_LANG,
        })
      }
    }
  }, [badgeDetail])

  useEffect(() => {
    if (watchLanguage !== DEFAULT_LANG) {
      return setIsTranslating(true)
    }
    return setIsTranslating(false)
  }, [watchLanguage])

  useEffect(() => {
    if (badgeId) {
      const translation = (badgeDetail?.translations || []).find(
        (item) => item.language === watchLanguage
      )
      if (translation) {
        reset({ ...translation })
      }
    }
  }, [watchLanguage, badgeDetail])

  return (
    <Main ariaLabelledby="edit-badge" className="EditBadge">
      <div>
        <h1 id="edit-badge-title" className="h3">
          {badgeId
            ? t('badge.title.edit', {
                badgeName: badgeDetail?.translations[0].name,
              })
            : t('badge.title.create')}
        </h1>
        {!badgeId && <p>{t('badge.text.create')}</p>}

        {badgeId && badgeDetail && (
          <TranslationCard
            translations={badgeDetail.translations}
            lang={watchLanguage}
            entity={t('badges.entity')}
          />
        )}
      </div>
      {badgeId && !isLoaded ? (
        <Loader />
      ) : (
        <FormProvider methods={methods}>
          <div className="EditBadge__form">
            <div className="EditBadge__form__name col-9">
              {isTranslating && (
                <TextControl
                  label={t('badge.form.name')}
                  type="text"
                  name="name"
                  value={defaultBadgeTrans?.name as string}
                  disabled={true}
                  onChange={youShouldNotChange}
                  required={true}
                />
              )}
              <FormTextInput
                control={control}
                name="name"
                label={isTranslating ? undefined : t('badge.form.name')}
                type="text"
                placeholder={t('badge.form.placeholder')}
                required={!isTranslating}
              />
              <span className="max">{t('brand.edit.badgeTitle.max')}</span>
            </div>
            <div className="EditBadge__form__picture col-12">
              <UploadInput
                control={control}
                title={t('badge.form.picture')}
                name="imageUrl"
                accept={accept}
                onRemoveFile={() => setValue('imageUrl', '')}
                selectFilesError={(error) =>
                  setError('imageUrl', {
                    message: error.message,
                  })
                }
                required
                rules={{
                  image: {
                    maxWidth: 144,
                    maxHeight: 144,
                    maxFileSize: 1,
                  },
                }}
                labels={labels}
              />
            </div>
          </div>

          <div className="EditBadge__actions">
            <FormActions languageSelector={renderLanguageSelector()}>
              <button
                className="btn btn-outline-primary"
                disabled={isSubmitting}
                onClick={handleCancel}
              >
                {t('common.cancel')}
              </button>
              <button
                className="btn btn-primary"
                disabled={
                  !isValid || isSubmitting || !watchName || !watchPicture
                }
                onClick={handleSubmit(onSubmit)}
              >
                {isSubmitting ? <Loader /> : t('common.save')}
              </button>
            </FormActions>
          </div>
        </FormProvider>
      )}
    </Main>
  )
}

export default EditBadge
