import { FC } from 'react'
import classNames from 'classnames'

import { Icon } from '@liveconnect/icons'

import './styles.scss'

interface InfoBlockProps {
  label?: string
  className?: string
}

const InfoBlock: FC<InfoBlockProps> = ({ label, children, className }) => {
  return (
    <div className={classNames('info-block', className)}>
      <Icon className="info-block__icon" name="info_outline" />
      {label && <span dangerouslySetInnerHTML={{ __html: label }}></span>}
      {children && children}
    </div>
  )
}

export default InfoBlock
