import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { MultimediaType } from '../../../../core/shared/types'
import { useHtmlToString } from '../../../../utils/useHtmlToString'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    isHighLight: yup
      .boolean()
      .label(t('directories.formElement.infoTab.isHighlighted')),
    name: yup
      .string()
      .required(({ label }) => t('validations.required', { label }))
      .label(t('directories.formElement.infoTab.name')),
    sectorIds: yup
      .array()
      .min(1, ({ label }) =>
        t('validations.minOptionsLength', { label, count: 1 })
      )
      .of(yup.string())
      .required(({ label }) => t('validations.required', { label }))
      .label(t('directories.formElement.infoTab.sectorIds')),
    channelIds: yup
      .array()
      .min(1, ({ label }) =>
        t('validations.minOptionsLength', { label, count: 1 })
      )
      .of(yup.string())
      .required(({ label }) => t('validations.required', { label }))
      .label(t('directories.formElement.infoTab.channelsIds')),
    thematic: yup
      .string()
      .required(({ label }) => t('validations.required', { label }))
      .max(60, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 60 })
      )
      .label(t('directories.formElement.infoTab.thematic')),
    description: yup
      .string()
      .label(t('directories.formElement.infoTab.elementDescription'))
      .required(({ label }) => t('validations.required', { label }))
      .max(300, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 300 })
      ),
    imageUrl: yup
      .string()
      .required(({ label }) => t('validations.required', { label }))
      .label(t('directories.formElement.infoTab.elementImageUrl')),
    infoSection: yup
      .object()
      .shape({
        title: yup
          .string()
          .required(({ label }) => t('validations.required', { label }))
          .label(t('directories.formElement.infoTab.sectionTitle')),
        description: yup
          .string()
          .required(({ label }) => t('validations.required', { label }))
          .test(
            'directories.formElement.infoTab.sectionDescription',
            ({ label }) =>
              t('validations.maxLength', { label, maxLength: 2000 }),
            (val) => useHtmlToString(val ?? '').length <= 2000
          )
          .label(t('directories.formElement.infoTab.sectionDescription')),
        type: yup
          .string()
          .oneOf(Object.values(MultimediaType))
          .required(({ label }) => t('validations.required', { label }))
          .label(t('directories.formElement.infoTab.sectionAttachmentType')),
        multimediaUrl: yup.string().when('infoSection.type', {
          is: MultimediaType.Video,
          then: yup
            .string()
            .url(t('common.link.patternValidation'))
            .label(t('directories.formElement.infoTab.sectionVideoUrl')),
          otherwise: yup
            .string()
            .label(t('directories.formElement.infoTab.sectionImageUrl')),
        }),
      })
      .notRequired(),
  })
}
