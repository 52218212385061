import { useEffect } from 'react'

import './i18n/config'
import AppRouter from './routers/AppRouter'
import useSettings from './core/settings/useSettings'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import '../styles/main.scss'

export const App = () => {
  const { fetchSettings, settings } = useSettings()

  useEffect(() => {
    fetchSettings()
  }, [])

  return settings && <AppRouter />
}
