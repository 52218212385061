import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs } from 'react-bootstrap'

import { DataTab, DataTabNames } from '../../../core/configuration/footer/types'

import { Main } from '../../../components/Main'
import FooterGeneralInfo from './GeneralInfo'
import FooterContactInfo from './ContactInfo'
import FooterSocialMedia from './SocialMedia'
import FooterLegalInfo from './LegalInfo'

const ConfigurationFooterPage = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState<DataTabNames>(
    DataTabNames.GeneralInfo
  )

  const changeState = (param: DataTabNames) => {
    setCurrentTab(param)
    navigate('./', {
      state: { activeTabParam: param },
    })
  }

  useEffect(() => {
    if (state && (state as DataTab)) {
      return setCurrentTab((state as DataTab).activeTabParam)
    }
    return setCurrentTab(DataTabNames.GeneralInfo)
  }, [state])

  return (
    <Main
      ariaLabelledby="config-footer-info"
      title={t('footerInfo.title')}
      subtitle={t('footerInfo.subtitle')}
      isVisibleRequiredFieldsLeyend
    >
      <Tabs
        className="mb-5"
        mountOnEnter
        unmountOnExit
        transition={false}
        activeKey={currentTab}
        onSelect={(key) => changeState(key as DataTabNames)}
      >
        <Tab
          eventKey={DataTabNames.GeneralInfo}
          title={t('footerInfo.tabs.generalInfo')}
        >
          <FooterGeneralInfo />
        </Tab>
        <Tab
          eventKey={DataTabNames.ContactInfo}
          title={t('footerInfo.tabs.contactInfo')}
        >
          <FooterContactInfo />
        </Tab>
        <Tab
          eventKey={DataTabNames.SocialMediaInfo}
          title={t('footerInfo.tabs.socialMediaInfo')}
        >
          <FooterSocialMedia />
        </Tab>
        <Tab
          eventKey={DataTabNames.LegalInfo}
          title={t('footerInfo.tabs.legalInfo')}
        >
          <FooterLegalInfo />
        </Tab>
      </Tabs>
    </Main>
  )
}

export default ConfigurationFooterPage
