import { FC, useMemo, useRef, useState } from 'react'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
// eslint-disable-next-line import/no-unresolved
import { NavigationOptions } from 'swiper/types'
import { Navigation, Pagination, Swiper as SwiperType } from 'swiper'
import { Icon } from '@liveconnect/icons'
import ActivityCard from './ActivityCard'
import { CarouselProps, TypeCard } from './types'
import { Activity, ActivityType } from '../../core/activities/types'
import BadgeCard from './BadgeCard'
import { useSwiperRef } from '../../utils/useSwiperRef'
import { MemberBadge } from '../../core/members/types'
import ChannelCard from './ChannelCard'

import './styles.scss'

const Carousel: FC<CarouselProps> = ({ elements, type, onClick }) => {
  const [nextEl, nextElRef] = useSwiperRef<HTMLElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLElement>()
  const swiperRef = useRef<SwiperType | null>(null)
  const [isHovering, setIsHovering] = useState<boolean>(false)

  const initialSlide = useMemo(() => {
    if (elements.length === 0) return 0
  }, [elements])

  const settings: SwiperProps = {
    slidesPerView: 'auto' as const,
    modules: [Navigation],
    navigation: {
      nextEl,
      prevEl,
    } as NavigationOptions,
    direction: 'horizontal' as const,
    onSwiper: (swiper: SwiperType) => (swiperRef.current = swiper),
    initialSlide,
  }
  const settingsMobile: SwiperProps = {
    slidesPerView: 'auto' as const,
    modules: [Pagination],
    pagination: true,
    direction: 'horizontal' as const,
    onSwiper: (swiper: SwiperType) => (swiperRef.current = swiper),
    initialSlide,
  }

  return (
    <>
      <div
        className="contentAll d-none d-md-block"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className="swiperContent d-none d-md-flex">
          <Swiper {...settings}>
            {elements.map((element: Activity | MemberBadge | any, i) => (
              <SwiperSlide key={i}>
                {type === TypeCard.Activity && (
                  <ActivityCard
                    title={element.title}
                    type={element.type}
                    onClick={(id) => onClick && onClick(id)}
                    id={element.id}
                    start={element.showDate}
                    imageUrl={element.imageUrl}
                    logoImageUrl={element.brandLogoImageUrl}
                    isSponsored={element.isSponsored}
                  />
                )}
                {type === TypeCard.Badges && (
                  <BadgeCard
                    title={element.title}
                    id={element.id}
                    image={element.imageUrl}
                  />
                )}
                {type === TypeCard.Channels && (
                  <ChannelCard title={element.name} id={element.id} />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="contentarrow d-none d-md-flex">
          <span ref={prevElRef}>
            <span
              className={
                !isHovering
                  ? 'swiper-left-arrow'
                  : 'swiper-left-arrow showArrow'
              }
            >
              <Icon name="chevron_left" />
            </span>
          </span>
          <span ref={nextElRef}>
            <span
              className={
                !isHovering
                  ? 'swiper-right-arrow'
                  : 'swiper-right-arrow showArrow'
              }
            >
              <Icon name="chevron_right" />
            </span>
          </span>
        </div>
      </div>

      <div className="swiperContent d-flex d-md-none">
        <Swiper {...settingsMobile}>
          {elements.map((element: Activity | MemberBadge | any, i) => (
            <SwiperSlide key={i}>
              {type === TypeCard.Activity && (
                <ActivityCard
                  title={element.title}
                  type={ActivityType.Video}
                  onClick={(id) => onClick && onClick(id)}
                  id={element.id}
                  start={element.startDate}
                  imageUrl={element.imageUrl}
                  logoImageUrl={element.brandLogoImageUrl}
                  isSponsored={element.isSponsored}
                />
              )}
              {type === TypeCard.Badges && (
                <BadgeCard
                  title={element.title}
                  id={element.id}
                  image={element.imageUrl}
                />
              )}
              {type === TypeCard.Channels && (
                <ChannelCard title={element.name} id={element.id} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}

export default Carousel
