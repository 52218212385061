// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChannelsArrayItem{display:flex;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/app/pages/Wall/PostCreate/ChannelsArrayItem/styles.scss"],"names":[],"mappings":"AAQA,mBACE,YAAA,CACA,6BAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.ChannelsArrayItem {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChannelsArrayItem": `ChannelsArrayItem`
};
export default ___CSS_LOADER_EXPORT___;
