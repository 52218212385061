import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { ContextMenu } from '@liveconnect/communities-ui'
import { ToggleControl, Tooltip, Pagination } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import EmptyState from '../../../../../components/EmptyState'
import SearchTable from '../../../../../components/Table/SearchTable'
import TranslationChips from '../../../../../components/TranslationsChips'
import {
  ElementListItem,
  ElementsHighParams,
  ElementsListResponse,
  ElementsPublishParams,
  ElementsRequestParams,
} from '../../../../../core/elements/types'
import useUi from '../../../../../core/ui/useUi'
import useCopy from '../../../../../utils/copy/useCopy'
import { useCustomRouter } from '../../../../../utils/extractParams'

import './styles.scss'

type TableElementsProps = {
  listElements: ElementsListResponse
  confirmDelete: (idElement: string) => void
  confirmPublish: (params: ElementsPublishParams) => void
  confirmHighlighted: (params: ElementsHighParams) => void
  confirmPaginator: (params: ElementsRequestParams) => void
  confirmSearch: (param: string) => void
}

const TableElements: FC<TableElementsProps> = ({
  listElements,
  confirmDelete,
  confirmPublish,
  confirmHighlighted,
  confirmPaginator,
  confirmSearch,
}) => {
  const { t } = useTranslation()
  const { basePath } = useCustomRouter()
  const { directoryId } = useParams()
  const { showConfirmation } = useUi()
  const navigate = useNavigate()
  const { handleCopy } = useCopy()

  const searchMultimedia = (param: string) => {
    confirmSearch(param)
  }

  const handleConfirmDelete = (param: string) => {
    showConfirmation({
      title: t('directories.elementsList.delete.title'),
      subtitle: t('directories.elementsList.delete.subtitle'),
      text: t('directories.elementsList.delete.text'),
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: async () => {
        await confirmDelete(param)
      },
    })
  }

  const handlePublish = (idElement: string, stateToggle: boolean) => {
    const paramsRequest = {
      id: idElement,
      isPublished: stateToggle,
    }
    showConfirmation({
      title: stateToggle
        ? t('directories.elementsList.publish.title')
        : t('directories.elementsList.unpublish.title'),
      subtitle: stateToggle
        ? t('directories.elementsList.publish.subtitle')
        : t('directories.elementsList.unpublish.subtitle'),
      confirmText: stateToggle ? t('common.publish') : t('common.unpublish'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: async () => {
        confirmPublish(paramsRequest)
      },
    })
  }

  const handleHighlighted = (idElement: string, stateToggle: boolean) => {
    const paramsRequest = {
      id: idElement,
      isHighlight: stateToggle,
    }
    showConfirmation({
      title: t('directories.elementsList.highlight.title'),
      subtitle: t('directories.elementsList.highlight.subtitle'),
      confirmText: t('common.highlight'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: () => {
        confirmHighlighted(paramsRequest)
      },
    })
  }

  const handlePaginator = (paramsPaginator: ElementsRequestParams) => {
    confirmPaginator(paramsPaginator)
  }

  const rowActions = (element: ElementListItem) => {
    const actions = []
    actions.push(
      {
        label: t('directories.editElement'),
        onClick: () => {
          navigate(
            `${basePath}/directories/${directoryId}/elements/${element.elementId}`
          )
        },
      },
      {
        label: t('directories.elementsList.copy'),
        onClick: () => {
          handleCopy(element.elementId, t('directories.formElement.entity'))
        },
      },
      {
        label: t('directories.elementsList.delete.title'),
        onClick: () => {
          handleConfirmDelete(element.elementId)
        },
      }
    )
    return actions
  }

  return (
    <div className="col-12 TableElements">
      <SearchTable
        title={t('directories.manage.elementsList')}
        titleLink={t('directories.createElement')}
        placeHolderText={t('common.search')}
        handleSearch={(s: string) => searchMultimedia(s)}
        urlLink={`../${directoryId}/elements/create`}
      />
      <div>
        <div className="header row">
          <div className="col cell">
            <p>{t('sections.list.table.columns.name')}</p>
          </div>
          <div className="col-3 cell">
            <p>{t('sections.list.table.columns.website')}</p>
          </div>
          <div className="col-2 cell">
            <p>{t('sections.validations.language')}</p>
          </div>
          <div className="col-1 cell">
            <p>{t('topic.list.headers.isPublished')}</p>
          </div>
          <div className="col-1 cell">
            <p>{t('directories.elementsList.isHighlighted')}</p>
          </div>
          <div className="col-1 cell">{''}</div>
        </div>
        {listElements.items.length > 0 ? (
          <>
            {listElements.items.map((element) => (
              <div className="body row" key={`${element.elementId}`}>
                <div className="col-4 cell">
                  <span className="element-name">{element.name}</span>
                  <div className="contentTooltip ms-2">
                    <span>ID</span>
                    <Tooltip
                      content={t('topic.channel.id', {
                        idChannel: element.elementId,
                      })}
                    >
                      <Icon name="info_outline" tabIndex={0} />
                    </Tooltip>
                  </div>
                </div>
                <div className="col-3 cell">
                  <a
                    href={element.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {element.url}
                  </a>
                </div>
                <div className="col-2 cell">
                  <TranslationChips
                    translations={element.translations}
                    isArrayOfStrings
                  />
                </div>
                <div className="col-1 cell toggleCell">
                  <ToggleControl
                    className=""
                    label=""
                    hiddenLabel
                    name="isPublished"
                    value={element.isPublished}
                    onChange={() =>
                      handlePublish(element.elementId, !element.isPublished)
                    }
                  />
                </div>
                <div className="col-1 cell toggleCell">
                  <ToggleControl
                    className=""
                    label=""
                    hiddenLabel
                    name="isHighlighted"
                    value={element.isHighlight}
                    onChange={() =>
                      handleHighlighted(element.elementId, !element.isHighlight)
                    }
                  />
                </div>
                <div className="col-1 cell actions">
                  <ContextMenu items={rowActions(element)} />
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="row empty">
            <EmptyState text={t('directories.elementsList.empty')} />
          </div>
        )}
      </div>
      {listElements && (
        <Pagination
          currentPage={listElements.page - 1}
          totalPages={listElements.availablePages}
          totalItems={listElements.availableItems}
          pageSize={listElements.pageSize}
          singlePageText={(count) => t('common.pager.singlePage', { count })}
          multiplePageText={(start, end, total) =>
            t('common.pager.multiplePage', { start, end, total })
          }
          onChange={(pageIndex) => {
            if (directoryId) {
              handlePaginator({
                page: pageIndex + 1,
                page_size: 20,
                directoryId: directoryId,
              })
            }
          }}
        />
      )}
    </div>
  )
}

export default TableElements
