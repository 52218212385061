import { useTranslation } from 'react-i18next'
import { MimeType, UploadControlLabels } from '@liveconnect/components'

interface useUploadInputProps {
  allowedExtensions: MimeType[]
  fileUploaded?: string
}

const useUploadInput = ({
  allowedExtensions,
  fileUploaded,
}: useUploadInputProps) => {
  const { t } = useTranslation()

  const labels: UploadControlLabels = {
    image: {
      cropTitle: t('upload.cropImage.title'),
      cropSave: t('common.save'),
      cropCancel: t('common.cancel'),
    },
    application: {
      restrictions: (extensions: string, maxWeight: number) =>
        `${extensions} Peso máximo ${maxWeight} MB`,
    },
    description: t('file.control.description'),
    button: t('common.add'),
    fileUploaded: fileUploaded ?? t('file.control.fileUploaded'),
    validations: {
      invalidFileExtension: t('file.validations.invalidFileExtension', {
        extensions: allowedExtensions.join(', '),
      }),
      invalidFileSize: () => t('file.validations.invalidFileSize'),
      invalidImageAspectRatio: () =>
        t('file.validations.invalidImageAspectRatio'),
    },
  }

  return {
    accept: allowedExtensions,
    fileUploaded,
    labels,
  }
}

export default useUploadInput
