import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap'
import { Icon } from '@liveconnect/icons'

import { Main } from '../../../components/Main'
import BackButton from '../../../components/Buttons/BackButton'
import FormElementInfoTab from './InfoTab'
import FormElementInfoSectionTab from './InfoSectionTab'
import MultimediaTab from './MultimediaTab'
import useElements from '../../../core/elements/useElements'

import './styles.scss'

export enum FormElementTabs {
  INFO_ELEMENT = 'INFO_ELEMENT',
  INFO_SECTION = 'INFO_SECTION',
  MULTIMEDIA_SECTION = 'MULTIMEDIA_SECTION',
}

export interface dataTab {
  activeTabParam: FormElementTabs
}

const FormElement = () => {
  const { t } = useTranslation()
  const { directoryId, elementId } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [currentTab, setCurrentTab] = useState<any>(
    FormElementTabs.INFO_ELEMENT
  )

  const { fetchDirectoryName, directoryName, element } = useElements()

  const eyes = useMemo(() => {
    if (element?.infoElement && elementId) {
      return {
        infoSection: element.infoElement.informativeSectionVisible,
        multimediaSection: element.infoElement.multimediaSectionVisible,
      }
    }
    return {
      infoSection: false,
      multimediaSection: false,
    }
  }, [element?.infoElement])

  const changeState = (param: FormElementTabs | any) => {
    setCurrentTab(param)
    navigate('./', {
      state: { activeTabParam: param },
    })
  }

  useEffect(() => {
    directoryId && fetchDirectoryName(directoryId)
  }, [directoryId])

  useEffect(() => {
    if (state && (state as dataTab)) {
      return setCurrentTab((state as dataTab).activeTabParam)
    }
    return setCurrentTab(FormElementTabs.INFO_ELEMENT)
  }, [state])

  return (
    <Main
      ariaLabelledby="directories-form-element"
      title={t(`directories.formElement.title`, {
        name: directoryName ? directoryName : '',
      })}
      subtitle={t('directories.formElement.subtitle')}
      rightColumn={
        <BackButton
          label={t('directories.formElement.back')}
          onClick={() => navigate(`../${directoryId}/manage`)}
        />
      }
    >
      <Tabs
        id="justify-tab-example"
        className="mb-5"
        mountOnEnter
        unmountOnExit
        transition={false}
        activeKey={currentTab}
        onSelect={(key) => changeState(key)}
      >
        <Tab
          eventKey={FormElementTabs.INFO_ELEMENT}
          title={t('directories.formElement.infoElement')}
        >
          <FormElementInfoTab />
        </Tab>
        <Tab
          eventKey={FormElementTabs.INFO_SECTION}
          title={
            <div className="d-flex align-items-center">
              <Icon
                className="me-2"
                name={eyes.infoSection ? 'visibility' : 'visibility_off'}
              />
              <span>{t('directories.formElement.infoSection')}</span>
            </div>
          }
          disabled={!elementId}
        >
          <FormElementInfoSectionTab />
        </Tab>
        <Tab
          eventKey={FormElementTabs.MULTIMEDIA_SECTION}
          title={
            <div className="d-flex align-items-center">
              <Icon
                className="me-2"
                name={eyes.multimediaSection ? 'visibility' : 'visibility_off'}
              />
              <span>{t('directories.formElement.multimediaSection')}</span>
            </div>
          }
          disabled={!elementId}
        >
          <MultimediaTab />
        </Tab>
      </Tabs>
    </Main>
  )
}

export default FormElement
