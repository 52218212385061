// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-activities-message{background-color:#f8f9fd;font-weight:600;font-size:1.25rem;text-align:center;min-height:510px;padding:30px 20px 30px}`, "",{"version":3,"sources":["webpack://./src/app/pages/Activities/styles.scss"],"names":[],"mappings":"AAQA,uBACE,wBAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,gBAAA,CACA,sBAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.no-activities-message {\n  background-color: #F8F9FD;\n  font-weight: 600;\n  font-size: 1.25rem;\n  text-align: center;\n  min-height: 510px;\n  padding: 30px 20px 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-activities-message": `no-activities-message`
};
export default ___CSS_LOADER_EXPORT___;
