import { ReactNode, useState } from 'react'
import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  flexRender,
  Row,
  ColumnDef,
  Cell,
  Header,
  HeaderGroup,
  SortingState,
  getSortedRowModel,
  Table,
} from '@tanstack/react-table'
import classnames from 'classnames'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'

import { Icon, IconName } from '@liveconnect/icons'
import { ContextMenu } from '@liveconnect/communities-ui'
import { Loader, SearchInput } from '@liveconnect/components'

import EmptyState from '../../EmptyState'

import './styles.scss'
export interface TableRowAction {
  onClick: () => void
  label: string
  icon: string
  isHidden?: boolean
  disabled?: boolean
}

export interface OnDragEndProps {
  oldIndex: number
  newIndex: number
}

interface ReactTableProps<T> {
  title: string
  isLoaded?: boolean
  emptyText?: string
  data: T[]
  columns: ColumnDef<T>[]
  isExpandable?: boolean
  searchPlaceholder?: string
  filterLabel?: string
  isFilterActive?: boolean
  headerChild?: ReactNode
  onFilterClick?: () => void
  onSearch?: (search: string) => void
  actions?: (row: T) => TableRowAction[]
  onDragEnd?: (data: OnDragEndProps) => void
}

type WithChildren<T> = T & { children?: T[] }

type ReactTableWithChildrenProps<T> = ReactTableProps<WithChildren<T>>

const ContentTable = <T,>({
  title,
  isLoaded = true,
  emptyText,
  data,
  columns,
  isExpandable = false,
  searchPlaceholder,
  headerChild,
  onSearch,
  onDragEnd,
  actions,
}: ReactTableWithChildrenProps<T>) => {
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable<T>({
    columns,
    data,
    state: {
      expanded,
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row: WithChildren<T>) => row.children ?? [],
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  })

  const renderActions = (row: T) => {
    if (!actions) return
    const rowActions = actions(row)
    const visibleActions = rowActions.filter((item) => !item.isHidden)
    if (rowActions.length > 2) {
      return (
        <div className="custom-table-body__actions custom-table-body__actions--dropdown">
          <ContextMenu items={visibleActions} />
        </div>
      )
    }
    return (
      <div className="custom-table-body__actions custom-table-body__actions--inline">
        {visibleActions.map((item: TableRowAction, index: number) => (
          <button
            title={item.label}
            onClick={item.onClick}
            key={index}
            disabled={item.disabled}
          >
            <Icon name={item.icon as IconName} />
          </button>
        ))}
      </div>
    )
  }

  const handleDragEnd = (event: any) => {
    const oldIndex = event.source.index
    const newIndex = event.destination.index
    onDragEnd &&
      oldIndex !== newIndex &&
      onDragEnd({
        oldIndex,
        newIndex,
      })
  }

  return (
    <div className="custom-table">
      <div className="custom-table-header">
        <div className="d-flex align-items-center w-100">
          <div className="custom-table-header__title col-6">{title}</div>
          {headerChild && (
            <div className="custom-table-header__header-child col-6 d-flex justify-content-end">
              {headerChild}
            </div>
          )}
          <div className="custom-table-header__filters col-6">
            {onSearch && (
              <SearchInput
                label="search"
                onSearch={onSearch}
                placeholder={searchPlaceholder}
              />
            )}
          </div>
        </div>
      </div>
      {!isLoaded ? (
        <Loader />
      ) : data.length === 0 ? (
        <EmptyState text={emptyText} />
      ) : (
        <table className="table table-bordered">
          <thead className="custom-table-head">
            {table
              .getHeaderGroups()
              .map((headerGroup: HeaderGroup<T>, i: number) => (
                <tr key={`${i}headerTr`}>
                  {onDragEnd && (
                    <th className="custom-table-head__expandable-header"></th>
                  )}
                  {isExpandable && (
                    <th className="custom-table-head__expandable-header">
                      {''}
                    </th>
                  )}
                  {headerGroup.headers.map((header: Header<T, unknown>) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width:
                            header.getSize() !== 150
                              ? header.getSize()
                              : undefined,
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    )
                  })}
                  {actions && <th>{''}</th>}
                </tr>
              ))}
          </thead>
          {onDragEnd ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="sections">
                {(provided: DroppableProvided) => (
                  <ContentTableTBody
                    table={table}
                    provided={provided}
                    onDragEnd={onDragEnd}
                    isExpandable={isExpandable}
                    actions={actions}
                    renderActions={renderActions}
                  />
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <ContentTableTBody
              table={table}
              onDragEnd={onDragEnd}
              isExpandable={isExpandable}
              actions={actions}
              renderActions={renderActions}
            />
          )}
        </table>
      )}
    </div>
  )
}

interface ContentTableTBodyProps<T> {
  provided?: DroppableProvided
  table: Table<T>
  onDragEnd?: (data: OnDragEndProps) => void
  isExpandable: boolean
  actions?: (row: T) => TableRowAction[]
  renderActions: (row: T) => JSX.Element | undefined
}

const ContentTableTBody = <T,>({
  provided,
  table,
  onDragEnd,
  isExpandable,
  actions,
  renderActions,
}: ContentTableTBodyProps<T>) => {
  return (
    <tbody
      className="custom-table-body"
      {...provided?.droppableProps}
      ref={provided?.innerRef}
    >
      {table.getRowModel().rows.map((row: Row<T>, index: number) =>
        onDragEnd ? (
          <Draggable key={row.id} draggableId={row.id} index={index}>
            {(provided: DraggableProvided) => (
              <ContentTableTr
                row={row}
                provided={provided}
                onDragEnd={onDragEnd}
                isExpandable={isExpandable}
                actions={actions}
                renderActions={renderActions}
              />
            )}
          </Draggable>
        ) : (
          <ContentTableTr
            key={row.id}
            row={row}
            onDragEnd={onDragEnd}
            isExpandable={isExpandable}
            actions={actions}
            renderActions={renderActions}
          />
        )
      )}
      {onDragEnd && provided ? provided.placeholder : <></>}
    </tbody>
  )
}

interface ContentTableTrProps<T> {
  row: Row<T>
  provided?: DraggableProvided
  onDragEnd?: (data: OnDragEndProps) => void
  isExpandable: boolean
  actions?: (row: T) => TableRowAction[]
  renderActions: (row: T) => JSX.Element | undefined
}

const ContentTableTr = <T,>({
  row,
  provided,
  onDragEnd,
  isExpandable,
  actions,
  renderActions,
}: ContentTableTrProps<T>) => {
  return (
    <tr
      className={classnames({
        'custom-table-body__subrow': row.depth > 0,
      })}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
    >
      {onDragEnd && provided && (
        <td
          className="custom-table-body__expandable-col"
          {...provided.dragHandleProps}
        >
          <Icon name="drag_and_drop" />
        </td>
      )}
      {isExpandable && (
        <td className="custom-table-body__expandable-col">
          {row.getCanExpand() && (
            <div
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: { cursor: 'pointer' },
              }}
            >
              {row.getIsExpanded() ? (
                <Icon name="keyboard_arrow_up" />
              ) : (
                <Icon name="keyboard_arrow_down" />
              )}
            </div>
          )}
        </td>
      )}
      {row.getVisibleCells().map((cell: Cell<T, T>, i: number) => {
        return (
          <td key={`${i}bodyTd`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        )
      })}
      {actions && <td>{renderActions(row.original)}</td>}
    </tr>
  )
}

export default ContentTable
