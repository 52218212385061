import { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormRadio,
  FormTextInput,
  FormProvider,
  UploadInput,
  MimeType,
} from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import { FormElementTabs } from '../..'
import BackButton from '../../../../../components/Buttons/BackButton'
import useLocalizations from '../../../../../core/localizations/useLocalizations'
import { DEFAULT_LANG } from '../../../../../i18n/config'
import { buildValidationSchema } from './validation'
import {
  FormMultimediaFields,
  MultiMediaDetail,
  OnlyTransMultimedia,
  PayloadEditMultimedia,
  PayloadMultimedia,
} from '../../../../../core/multimedia/types'
import { FormActionsLang } from '../../../../../components/forms/FormActionsLang'
import useUi from '../../../../../core/ui/useUi'
import { useBlockRouteChangeWithDialog } from '../../../../../utils/routing/useBlockRouteChange'
import { MultimediaType } from '../../../../../core/shared/types'
import useNotifications from '../../../../../utils/notifications/useNotifications'
import useUploadInput from '../../../../../utils/uploadInput/useUploadInput'
import useMultimedia from '../../../../../core/multimedia/useMultimedia'
import { useCustomRouter } from '../../../../../utils/extractParams'
import TranslationCard from '../../../../../components/TranslationCard'

import './styles.scss'

const FormMultimedia: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { directoryId, elementId, multimediaId } = useParams()
  const { basePath } = useCustomRouter()
  const { localizations } = useLocalizations()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    section,
    addMultimedia,
    uploadImageMultimedia,
    fetchMultimediaDetail,
    fetchMultimediaSection,
    multimediaDetail,
    editMultimedia,
    editMultimediaTranslation,
  } = useMultimedia()
  const methods = useForm<FormMultimediaFields>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      typeMultimedia: MultimediaType.Image,
      language:
        (localizations || []).find((item) => item.isDefault)?.isoCode ||
        DEFAULT_LANG,
    },
  })

  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })

  const {
    control,
    formState,
    watch,
    reset,
    setError,
    setValue,
    formState: { isDirty, dirtyFields },
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const watchMediaType = watch('typeMultimedia')
  const watchLang = watch('language')

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const handleCancel = () => {
    navigate(`../${directoryId}/elements/${elementId}`, {
      state: { activeTabParam: FormElementTabs.MULTIMEDIA_SECTION },
    })
  }

  const transformData = (val: FormMultimediaFields) => {
    let data
    if (watchLang === DEFAULT_LANG) {
      switch (val.typeMultimedia) {
        case MultimediaType.Image:
          data = {
            elementMultimediaSectionId: section?.multimediaSectionId,
            elementId: elementId,
            name: val.nameMultimedia,
            type: val.typeMultimedia,
            multimediaUrl: val.imageUrl,
            language: val.language,
          }
          return data
        case MultimediaType.Video:
          data = {
            elementMultimediaSectionId: section?.multimediaSectionId,
            elementId: elementId,
            name: val.nameMultimedia,
            type: val.typeMultimedia,
            multimediaUrl: val.videoUrl,
            language: val.language,
          }
          return data
      }
    }
    data = {
      name: val.nameMultimedia,
      language: val.language,
    }
    return data
  }

  const successPost = (data: MultiMediaDetail) => {
    const i18nData = data.translations.find(
      (item) => item.language === watchLang
    )
    if (i18nData) {
      return reset({
        nameMultimedia: i18nData.name,
        language: i18nData.language,
        typeMultimedia: data.type,
        imageUrl: data.type === MultimediaType.Image ? data.multimediaUrl : '',
        videoUrl: data.type === MultimediaType.Video ? data.multimediaUrl : '',
      })
    }
  }

  const onSubmit = async (values: FormMultimediaFields) => {
    setIsSubmitting(true)
    try {
      const dataForm: PayloadMultimedia | OnlyTransMultimedia | any =
        transformData(values)
      if (
        watchLang === DEFAULT_LANG &&
        watchMediaType === MultimediaType.Image &&
        dataForm.multimediaUrl
      ) {
        const image: string = dataForm.multimediaUrl
        if (image.includes('blob:')) {
          if (elementId) {
            const responseImage = await uploadImageMultimedia(image)
            responseImage && (dataForm.multimediaUrl = responseImage)
          }
        }
      }
      if (elementId) {
        if (!multimediaId) {
          const response = await addMultimedia(dataForm)
          if (response) {
            setIsSubmitting(false)
            navigate(
              `${basePath}/directories/${directoryId}/elements/${elementId}/multimedia/${response}`
            )
            notify.success(
              t('directories.formElement.multimedia.form.create.toast.ok')
            )
          }
        } else {
          if (watchLang === DEFAULT_LANG) {
            const params: PayloadMultimedia = dataForm
            const paramsEdit: PayloadEditMultimedia = {
              name: params.name,
              type: params.type,
              multimediaUrl: params.multimediaUrl,
              language: params.language,
              elementId: elementId,
            }
            const response = await editMultimedia(paramsEdit, multimediaId)
            response && successPost(response)
          } else {
            const response = await editMultimediaTranslation(
              dataForm,
              multimediaId,
              elementId
            )
            response && successPost(response)
          }
          setIsSubmitting(false)
          notify.success(
            t('directories.formElement.multimedia.form.edit.toast.ok')
          )
        }
      }
    } catch (error: unknown) {
      setIsSubmitting(false)
      notify.error(t('directories.formElement.multimedia.form.create.toast.ko'))
    }
  }

  useEffect(() => {
    if (multimediaDetail) {
      const i18nData = multimediaDetail.translations.find(
        (item) => item.language === watchLang
      )
      if (i18nData) {
        return reset({
          nameMultimedia: i18nData?.name,
          language: i18nData?.language,
          typeMultimedia: multimediaDetail.type,
          imageUrl:
            multimediaDetail.type === MultimediaType.Image
              ? multimediaDetail.multimediaUrl
              : '',
          videoUrl:
            multimediaDetail.type === MultimediaType.Video
              ? multimediaDetail.multimediaUrl
              : '',
        })
      }
      return reset({
        nameMultimedia: '',
        language: watchLang,
        typeMultimedia: multimediaDetail.type,
        imageUrl:
          multimediaDetail.type === MultimediaType.Image
            ? multimediaDetail.multimediaUrl
            : '',
        videoUrl:
          multimediaDetail.type === MultimediaType.Video
            ? multimediaDetail.multimediaUrl
            : '',
      })
    }
  }, [watchLang])

  useEffect(() => {
    if (multimediaDetail && multimediaId) {
      const i18nData = multimediaDetail.translations.find(
        (item) => item.language === watchLang
      )
      reset({
        nameMultimedia: i18nData?.name,
        language: watchLang,
        typeMultimedia: multimediaDetail.type,
        imageUrl:
          multimediaDetail.type === MultimediaType.Image
            ? multimediaDetail.multimediaUrl
            : '',
        videoUrl:
          multimediaDetail.type === MultimediaType.Video
            ? multimediaDetail.multimediaUrl
            : '',
      })
    }
  }, [multimediaDetail])

  useEffect(() => {
    if (!multimediaId) {
      return reset({
        nameMultimedia: '',
        language: watchLang,
        typeMultimedia: MultimediaType.Image,
        imageUrl: '',
      })
    }
    if (elementId && multimediaId) {
      fetchMultimediaDetail(multimediaId, elementId)
    }
  }, [multimediaId])

  useEffect(() => {
    elementId && fetchMultimediaSection(elementId)
  }, [elementId])

  return (
    <div className="FormMultimedia">
      <div className="FormMultimedia__title">
        <h1
          id="multimedia-title"
          className="h3"
          data-testid="title-multimediaForm"
        >
          {!multimediaId
            ? t('directories.formElement.multimedia.form.title')
            : t('directories.formElement.multimedia.form.title.edit')}
        </h1>
        <BackButton
          label={t('directories.formElement.multimedia.form.back')}
          onClick={handleCancel}
          data-testid="backButton-multimediaForm"
        />
      </div>
      <div className="FormMultimedia__text">
        <p>{t('directories.formElement.multimedia.form.text')}</p>
        <span className="lc-main__required">{t('common.required')}</span>
      </div>
      {multimediaDetail && multimediaId && (
        <TranslationCard
          translations={multimediaDetail.translations}
          lang={watchLang}
          entity={t('directories.formElement.multimedia.form.entity')}
          data-testid="translationCard-multimediaForm"
        />
      )}
      <div className="FormMultimedia__form">
        <FormProvider methods={methods}>
          <div>
            <div className="row">
              <div className="col-lg-10">
                <FormTextInput
                  control={control}
                  name="nameMultimedia"
                  label={t('directories.formElement.multimedia.form.label')}
                  type="text"
                  placeholder={t(
                    'directories.formElement.multimedia.form.placeholder'
                  )}
                  required={true}
                  data-testid="nameMultimedia-multimediaForm"
                />
              </div>
            </div>
            {watchLang === DEFAULT_LANG && (
              <>
                <div className="row">
                  <div className="col-lg-10">
                    <div
                      className="cardRemember"
                      data-testid="cardRemember-multimediaForm"
                    >
                      <Icon
                        className="cardRemember__icon"
                        name="info_outline"
                      />
                      <p>
                        {t('directories.formElement.multimedia.form.remember')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row contentType">
                  <div className="col-lg-10">
                    <FormRadio
                      control={control as any}
                      name="typeMultimedia"
                      options={[
                        { value: MultimediaType.Image, label: 'Imagen' },
                        { value: MultimediaType.Video, label: 'Video' },
                      ]}
                      disabled={!!multimediaId}
                      data-testid="typeMultimedia-multimediaForm"
                    />
                  </div>
                </div>
                {watchMediaType === MultimediaType.Image && (
                  <div className="row">
                    <div className="col-lg-10">
                      <UploadInput
                        control={control}
                        title={t(
                          'directories.formElement.infoTab.sectionImageUrl'
                        )}
                        name="imageUrl"
                        accept={accept}
                        onRemoveFile={() => setValue('imageUrl', '')}
                        selectFilesError={(error) =>
                          setError('imageUrl', {
                            message: error.message,
                          })
                        }
                        labels={labels}
                        required
                        rules={{
                          image: {
                            maxWidth: 640,
                            maxHeight: 360,
                          },
                        }}
                        data-testid="upload-multimediaForm"
                      />
                    </div>
                  </div>
                )}
                {watchMediaType === MultimediaType.Video && (
                  <div className="row">
                    <div className="col-lg-10">
                      <FormTextInput
                        control={control}
                        name="videoUrl"
                        label={t(
                          'directories.formElement.infoTab.sectionVideoUrl'
                        )}
                        placeholder={t(
                          'directories.formElement.infoTab.sectionVideoUrl.placeholder'
                        )}
                        data-testid="videoUrl-multimediaForm"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <FormActionsLang
            selectName="language"
            selectDisabled={!multimediaId}
            isLoaded={true}
            methods={methods}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            onCancel={handleCancel}
            onBeforeChange={handleBeforeChangeLang}
            data-testid="actionsLang-multimediaForm"
          />
        </FormProvider>
      </div>
    </div>
  )
}

export default FormMultimedia
