import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'

import {
  CheckboxControl,
  FormDate,
  FormMultiSelect,
  FormSelect,
  FormTextInput,
  FormProvider,
} from '@liveconnect/components'

import { FiltersModal } from '@liveconnect/communities-ui'

import {
  ActivityBrandSpace,
  ActivityChannel,
  ActivityListParams,
  ActivityType,
} from '../../../../core/activities/types'
import useActivities from '../../../../core/activities/useActivities'
import { buildValidationSchema } from './validations'

import { FilterButton } from '../../../../components/Buttons/FilterButton'

import './styles.scss'

interface ActivitiesListFiltersProps {
  handler: (params: ActivityListParams) => void
  isHistorical: boolean
}

export const ActivitiesListFilters: FC<ActivitiesListFiltersProps> = ({
  handler,
  isHistorical,
}) => {
  const { getBrandSpaces, getChannelsByBrandSpaces } = useActivities()
  const [visibleSlidePanel, setVisibleSlidePanel] = useState<boolean>(false)
  const [activeFilters, setActiveFilters] = useState<boolean>(false)
  const [brandSpaces, setBrandSpaces] = useState<ActivityBrandSpace[]>([])
  const [channels, setChannels] = useState<ActivityChannel[]>([])
  const [types, setTypes] = useState({
    VideoConference: false,
    FaceToFace: false,
    RoundTable: false,
    AskAuthor: false,
  })

  const { t } = useTranslation()

  const DEFAULT_VALUES: ActivityListParams = {
    page: 1,
    page_size: 5,
    channels_ids: [],
    activityTypes: [],
    brandspace_id: '',
    is_historical: isHistorical,
    search: '',
    startDate: null,
    endDate: null,
  }

  const methods = useForm<ActivityListParams>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: DEFAULT_VALUES,
  })

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    trigger,
    formState: {},
  } = methods

  const watchBrandSpaces = watch('brandspace_id')
  const watchStartDate = watch('startDate')
  const watchEndDate = watch('endDate')

  const eventCodesOptions = useMemo(() => {
    if (!brandSpaces) return []
    const options = brandSpaces.map((brand: ActivityBrandSpace) => {
      return {
        value: brand.id,
        label: brand.name,
      }
    })
    return options
  }, [brandSpaces])

  const channelsOptions = useMemo(() => {
    if (!channels) return []
    const options = channels.map((channel: ActivityChannel) => {
      return {
        value: channel.id,
        label: channel.name,
      }
    })
    return options
  }, [channels])

  const activityTypesOptions = useMemo(() => {
    return Object.values(ActivityType)
  }, [])

  const initBrands = async () => {
    if (brandSpaces.length == 0) {
      const _brandSpaces = await getBrandSpaces()
      setBrandSpaces(_brandSpaces)
    }
  }

  const initChannelsWithoutSpace = async () => {
    const _channels = await getChannelsByBrandSpaces()
    setChannels(_channels)
  }

  const initChannelsWithSpace = async () => {
    if (watchBrandSpaces) {
      const _channels = await getChannelsByBrandSpaces([watchBrandSpaces])
      setChannels(_channels)
    }
  }

  const handleChangeType = (newValue: boolean, name: ActivityType) => {
    setTypes({
      ...types,
      [name]: newValue,
    })
  }

  const handleReset = () => {
    setTypes({
      VideoConference: false,
      FaceToFace: false,
      RoundTable: false,
      AskAuthor: false,
    })
    reset(DEFAULT_VALUES)
  }

  const watchAllFields = watch()

  const handleActivateFilters = () => {
    const fieldsForm = watchAllFields
    if (
      !fieldsForm.brandspace_id &&
      fieldsForm.channels_ids &&
      fieldsForm.channels_ids.length === 0 &&
      fieldsForm.activityTypes &&
      fieldsForm.activityTypes.length === 0 &&
      !fieldsForm.endDate &&
      !fieldsForm.startDate
    ) {
      return setActiveFilters(false)
    }
    return setActiveFilters(true)
  }

  const onSubmit = async (values: ActivityListParams) => {
    handler(formatData(values))
    await handleActivateFilters()
    setVisibleSlidePanel(false)
  }

  const debounce = (func: any) => {
    let timer: NodeJS.Timeout | null
    return function (...args: any[]) {
      let context: any
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, 500)
    }
  }

  const handleSubmitFilters = async () => {
    const values = getValues()
    handler(formatData(values))
    await handleActivateFilters()
  }

  const optimizedHandleChangeSearch = useCallback(
    debounce(handleSubmitFilters),
    []
  )

  const formatData = (values: ActivityListParams) => {
    const _params: ActivityListParams = {
      ...values,
      brandspace_id:
        values.brandspace_id != '' ? values.brandspace_id : undefined,
      startDate: values.startDate
        ? new Date(values.startDate).toISOString()
        : undefined,
      endDate: values.endDate
        ? new Date(values.endDate).toISOString()
        : undefined,
    }
    return _params
  }

  useEffect(() => {
    initBrands()
    initChannelsWithoutSpace()
  }, [])

  useEffect(() => {
    trigger('startDate')
    trigger('endDate')
  }, [watchStartDate, watchEndDate])

  useEffect(() => {
    if (watchBrandSpaces && watchBrandSpaces !== '') {
      initChannelsWithSpace()
      setValue('channels_ids', [])
    }
    if (!watchBrandSpaces) {
      initChannelsWithoutSpace()
      setValue('brandspace_id', '')
    }
  }, [watchBrandSpaces])

  useEffect(() => {
    let _types: ActivityType[] = []
    Object.keys(types).map((key) => {
      if (types[key as ActivityType]) _types = [..._types, key as ActivityType]
    })
    setValue('activityTypes', _types, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [types])

  useEffect(() => {
    setValue('is_historical', isHistorical)
    handleSubmitFilters()
  }, [isHistorical])

  return (
    <div className="activities-filters my-4">
      <FormProvider methods={methods}>
        <div className="row align-items-baseline">
          <div className="col-lg">
            <div className="activities-filters__search">
              <FormTextInput
                control={control}
                name="search"
                placeholder={t('common.search')}
                onChange={optimizedHandleChangeSearch}
              />
            </div>
          </div>
          <div className="col-lg-auto">
            <FilterButton
              isActive={activeFilters}
              onClick={() => setVisibleSlidePanel(!visibleSlidePanel)}
            />
          </div>
        </div>

        <FiltersModal
          isOpened={visibleSlidePanel}
          onClose={() => setVisibleSlidePanel(false)}
          onFilter={handleSubmit(onSubmit)}
          onReset={handleReset}
          texts={{
            title: t('filtersModal.filters'),
            reset: t('filtersModal.clear'),
            viewResults: t('filtersModal.viewResults'),
          }}
        >
          <FiltersModal.Section title={t('activities.filters.organizer')}>
            <FormSelect
              control={control}
              name="brandspace_id"
              label={t('activities.filters.fields.spaces.label')}
              placeholder={t('activities.filters.fields.spaces.placeholder')}
              options={eventCodesOptions}
            />
            <FormMultiSelect
              control={control}
              name="channels_ids"
              label={t('activities.filters.fields.channels.label')}
              placeholder={t('activities.filters.fields.channels.placeholder')}
              options={channelsOptions}
            />
          </FiltersModal.Section>

          <FiltersModal.Section title={t('activities.filters.type')}>
            {activityTypesOptions.map((type) => (
              <CheckboxControl
                key={type}
                name={type}
                value={types[type]}
                onChange={(value) => handleChangeType(value, type)}
                label={t(`activity.form.config.type.options.${type}`)}
              />
            ))}
          </FiltersModal.Section>

          <FiltersModal.Section title={t('activities.filters.date')}>
            <FormDate
              label={t('activities.filters.startDate.label')}
              name="startDate"
              control={control}
            />
            <FormDate
              label={t('activities.filters.endDate.label')}
              name="endDate"
              control={control}
            />
          </FiltersModal.Section>
        </FiltersModal>
      </FormProvider>
    </div>
  )
}
