import { FC } from 'react'

export interface SeparatorProps {
  spacing?: number
}

import './styles.scss'

export const Separator: FC<SeparatorProps> = ({ spacing = 5 }) => {
  return <div className={`separator mb-${spacing}`}></div>
}
