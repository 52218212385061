import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormTextInput, Loader } from '@liveconnect/components'

import { useCustomRouter } from '../../../../../../utils/extractParams'
import useConfigurationFooter from '../../../../../../core/configuration/footer/useFooter'
import useNotifications from '../../../../../../utils/notifications/useNotifications'

import { Main } from '../../../../../../components/Main'
import BackButton from '../../../../../../components/Buttons/BackButton'
import Heading from '../../../../../../components/Heading'
import CustomForm from '../../../../../../components/CustomForm'
import TranslationCard from '../../../../../../components/TranslationCard'

import {
  ConfigurationFooterPageForm,
  DataTabNames,
  UrlTypeFooterPage,
} from '../../../../../../core/configuration/footer/types'
import { buildValidationSchema } from './validations'
import { DEFAULT_LANG } from '../../../../../../i18n/config'

const ConfigurationFooterPagesForm = () => {
  const { t } = useTranslation()
  const { id, tabId, type } = useParams()
  const { basePath } = useCustomRouter()
  const notify = useNotifications()
  const navigate = useNavigate()
  const {
    isLoaded,
    fetchPageSectionDetail,
    createPageSection,
    updatePageSection,
    pageSection,
  } = useConfigurationFooter()

  const returnTab = useMemo(() => {
    switch (type) {
      case UrlTypeFooterPage.GeneralInfo:
        return DataTabNames.GeneralInfo
      case UrlTypeFooterPage.ContactInfo:
        return DataTabNames.ContactInfo
      case UrlTypeFooterPage.LegalInfo:
        return DataTabNames.LegalInfo
      default:
        return DataTabNames.GeneralInfo
    }
  }, [type])

  const defaultValues = useMemo(() => {
    return {
      language: DEFAULT_LANG,
      footerGeneralInfoId:
        type === UrlTypeFooterPage.GeneralInfo ? tabId : undefined,
      footerContactInfoId:
        type === UrlTypeFooterPage.ContactInfo ? tabId : undefined,
      footerLegalInfoId:
        type === UrlTypeFooterPage.LegalInfo ? tabId : undefined,
    }
  }, [DEFAULT_LANG, tabId, type])

  const methods = useForm<ConfigurationFooterPageForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues,
  })

  const { control, watch, reset } = methods

  const formValues = watch()

  const onSubmit = async (values: ConfigurationFooterPageForm) => {
    const isTranslating = formValues.language !== DEFAULT_LANG
    let action = 'create'
    if (id) action = 'edit'
    if (isTranslating) action = 'translate'
    try {
      reset(formValues)
      if (!id) {
        const response = await createPageSection(values)
        if (response)
          navigate(
            `${basePath}/configuration/footer/${type}/${tabId}/page/${response}`
          )
      } else {
        await updatePageSection(id, values)
        navigate(`${basePath}/configuration/footer`, {
          state: { activeTabParam: returnTab },
        })
      }
      notify.success(t(`footerPage.form.feedback.${action}.ok`))
    } catch (error) {
      notify.error(t(`footerPage.form.feedback.${action}.ko`))
    }
  }

  useEffect(() => {
    if (id) fetchPageSectionDetail(id)
  }, [id])

  useEffect(() => {
    if (pageSection && id) {
      const translation = pageSection.translations.find(
        (ct) => ct.language === formValues.language
      )
      reset({
        ...formValues,
        title: translation ? translation.title : '',
        url: translation ? translation.url : '',
      })
    } else {
      reset(defaultValues)
    }
  }, [pageSection, formValues.language])

  if (!isLoaded && id) {
    return <Loader />
  }

  return (
    <Main
      ariaLabelledby="config-footer-page-form"
      title={t('footerPage.form.title')}
      subtitle={t('footerPage.form.subtitle')}
      isVisibleRequiredFieldsLeyend
      rightColumn={
        <BackButton
          label={t('footerPage.form.goBack')}
          onClick={() =>
            navigate(`${basePath}/configuration/footer`, {
              state: { activeTabParam: returnTab },
            })
          }
        />
      }
    >
      <CustomForm
        methods={methods}
        onSubmit={onSubmit}
        onCancel={() =>
          navigate(`${basePath}/configuration/footer`, {
            state: { activeTabParam: returnTab },
          })
        }
        isSelectDisabled={!id}
      >
        {id && pageSection && (
          <TranslationCard
            translations={pageSection.translations.map(
              ({ language, title }) =>
                ({
                  language,
                  name: title,
                } as any)
            )}
            lang={formValues.language}
            entity={t('footerPage.form.entity')}
          />
        )}
        <Heading as="h2">{t('footerPage.form.data.title')}</Heading>
        <p>{t('footerPage.form.data.subtitle')}</p>

        <div className="row">
          <div className="col-9">
            <FormTextInput
              label={t('footerPage.form.title.label')}
              placeholder={t('footerPage.form.title.placeholder')}
              control={control}
              name="title"
              required
              data-testid="footerPage-form-title"
            />
          </div>
        </div>

        <FormTextInput
          label={t('footerPage.form.url.label')}
          placeholder={t('footerPage.form.url.placeholder')}
          control={control}
          name="url"
          required
          data-testid="footerPage-form-url"
        />
      </CustomForm>
    </Main>
  )
}

export default ConfigurationFooterPagesForm
