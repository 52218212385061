import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import {
  MultiMediaDetail,
  MultimediaListResponse,
  MultimediaSection,
  MultimediaState,
} from './types'

const initialState: MultimediaState = {
  detail: null,
  list: null,
  section: null,
  isLoaded: false,
}

export const multimediaSlice = createSlice({
  name: 'multimedia',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setMultimediaList: (
      state,
      action: PayloadAction<MultimediaListResponse>
    ) => {
      state.list = action.payload
    },
    setDetailMultimedia: (state, action: PayloadAction<MultiMediaDetail>) => {
      state.detail = action.payload
    },
    setSectionmultimedia: (state, action: PayloadAction<MultimediaSection>) => {
      state.section = action.payload
    },
    setVisibility: (state, action: PayloadAction<boolean>) => {
      if (state.section) {
        state.section.isVisible = action.payload
      }
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setMultimediaList,
  setDetailMultimedia,
  setSectionmultimedia,
  setVisibility,
} = multimediaSlice.actions

export const selectBadge = (state: RootState) => state.badges

export default multimediaSlice.reducer
