import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Main } from '../../components/Main'

import './styles.scss'

const WelcomePage: FC = () => {
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [])

  return (
    <Main ariaLabelledby="welcome-page" className="WelcomePage">
      <div className="WelcomePage__content" data-testid="WelcomePage">
        <h3>{t('welcome.title')}</h3>
        <p>{t('welcome.text')}</p>
      </div>
    </Main>
  )
}

export default WelcomePage
