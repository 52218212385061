import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { TabsComponent } from '@liveconnect/communities-ui'

import { Main } from '../../components/Main'
import { AvailableBrand } from '../../core/brandSpace/types'
import useBrandsInfo from '../../core/brandSpace/useBrandSpace'
import SponsorsList from './Sponsors'
import { BrandBasicInfoComponent } from './BrandBasicInfo'
import { TabComponent } from '../../components/Tabs/types'
import { BrandTabs } from './types'
import { NavigationState, useCustomRouter } from '../../utils/extractParams'
import { BadgesList } from './Badges'
import usePermissions from '../../core/permissions/usePermissions'

import './styles.scss'

interface BrandEditProps {
  isNew?: boolean
}

const BrandEditScreen: FC<BrandEditProps> = ({ isNew }) => {
  const { t } = useTranslation()
  const { verifyEditorSpaces, verifySomeBrands } = usePermissions()
  const {
    fetchBrandDetail,
    clearCurrentBrand,
    isLoaded,
    getAvailableBrands,
    brand,
  } = useBrandsInfo()
  const { brandId } = useParams()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const { state } = useLocation()
  const isEdit = isNew !== true
  const [tabs, setTabs] = useState<TabComponent[]>([])
  const [brandsWithoutSpace, setBrandsWithoutSpace] = useState<
    AvailableBrand[]
  >([])

  const handleCancel = () => {
    navigate(`${basePath}/brands`)
  }

  const onSubmit = () => {
    setTabs(tabs.map((tab) => ({ ...tab, disabled: false })))
  }

  const initBrandsWithoutSpace = async () => {
    const _brands = await getAvailableBrands()
    setBrandsWithoutSpace(_brands ?? [])
  }

  const getActiveTab = (): string | undefined => {
    if (state) {
      const brandCurrentTab = (state as NavigationState).brandCurrentTab
      return brandCurrentTab
    }
  }

  useEffect(() => {
    if (isNew && !verifyEditorSpaces()) {
      navigate(`${basePath}/access-denied`)
    }
    if (brandId) {
      if (!verifySomeBrands(brandId)) {
        navigate(`${basePath}/access-denied`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    initBrandsWithoutSpace()
    if (isEdit && brandId) fetchBrandDetail(brandId)
    else clearCurrentBrand()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTabs([
      {
        id: BrandTabs.BASIC_INFO,
        label: t('brand.edit.basicInfo'),
        component: (
          <BrandBasicInfoComponent
            brandsWithoutSpace={brandsWithoutSpace}
            onBasicInfoSubmit={onSubmit}
            onCancel={handleCancel}
            isEdit={isEdit}
            brandId={brandId}
          />
        ),
        disabled: !isLoaded,
      },
      {
        id: BrandTabs.SPONSORS,
        label: t('brand.edit.sponsors'),
        component: <SponsorsList brandId={brandId} />,
        tooltip: !isEdit ? t('brand.edit.sponsors.tooltip') : undefined,
        disabled: !isEdit || !isLoaded,
      },
      {
        id: BrandTabs.BADGES,
        label: t('brand.edit.badges'),
        component: <BadgesList />,
        disabled: !isEdit || !isLoaded,
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsWithoutSpace, brand, isNew, isLoaded])

  return (
    <Main ariaLabelledby="brand" className="BrandEdit">
      <div className="BrandEdit__breadcrumb">&nbsp;</div>
      <div className="BrandEdit__header">
        <h3 className="BrandEdit__header__title">
          {!brandId && t('brand.edit.create.title')}
          {brandId &&
            t('brand.edit.title', { brandName: brand?.translations[0].name })}
          <span className="required">{t('common.required')}</span>
        </h3>
        <p className="BrandEdit__header__subtitle">
          {!brandId && t('brand.edit.subtitle')}
        </p>
      </div>
      <TabsComponent tabs={tabs} getActiveTab={getActiveTab} />
    </Main>
  )
}

export default BrandEditScreen
