import { FC, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Permission } from '../core/permissions/types'
import usePermissions from '../core/permissions/usePermissions'
import PermissionsDetail from '../pages/Permissions/Detail'
import PermissionsList from '../pages/Permissions/List'
import { useCustomRouter } from '../utils/extractParams'

const PermissionsRoutes: FC = () => {
  const { list: permissionsUser } = usePermissions()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const initPermissionsUser = () => {
    if (!permissionsUser.includes(Permission.FullAccess)) {
      navigate(`${basePath}/access-denied`)
    }
  }
  useEffect(() => {
    initPermissionsUser()
  }, [])
  return (
    <Routes>
      <Route path="/" element={<PermissionsList />} />
      <Route path="/:userId" element={<PermissionsDetail />} />
    </Routes>
  )
}

export default PermissionsRoutes
