import { FC, useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { HtmlSanitizer } from '@liveconnect/communities-ui'
import { ToggleControl, Loader, Avatar } from '@liveconnect/components'

import { Main } from '../../../components/Main'
import useMembers from '../../../core/members/useMembers'
import { JobsMember, MemberDetail } from '../../../core/members/types'
import Carousel from '../../../components/Carousel'
import { TypeCard } from '../../../components/Carousel/types'
import useUi from '../../../core/ui/useUi'
import useNotifications from '../../../utils/notifications/useNotifications'
import usePermissions from '../../../core/permissions/usePermissions'
import { returnHypphen } from '../../../utils/showHypper/showHyppen'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation'

import './styles.scss'

const ProfileScreen: FC = () => {
  const { profileId } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [member, setMember] = useState<MemberDetail | undefined>()
  const { getMemberDetail, toggleEnableProfile, deleteMember } = useMembers()
  const { t } = useTranslation()
  const { showConfirmation } = useUi()
  const { verifyUsersModerator, verifyPermissionsMaster } = usePermissions()
  const notify = useNotifications()
  const navigate = useNavigate()

  const fetchMember = async (memberId: string) => {
    setIsLoading(true)
    try {
      const result = await getMemberDetail(memberId)
      setMember(result)
    } finally {
      setIsLoading(false)
    }
  }

  const renderPositions = (list: JobsMember[]) => {
    const jobs = [...list]
    jobs.shift()
    return jobs.map((job: JobsMember) => (
      <p key={job.id}>
        {job.jobTitle} - {job.company}
      </p>
    ))
  }

  const memberChange = async (detailProfile: MemberDetail) => {
    try {
      const result = await toggleEnableProfile(
        detailProfile.id,
        !detailProfile.isEnabled
      )
      result && (await setMember(result))
      {
        member?.isEnabled
          ? notify.success(t('user.modal.disabled.notif.success'))
          : notify.success(t('user.modal.enabled.notif.success'))
      }
    } catch (e) {
      {
        member?.isEnabled
          ? notify.error(t('user.modal.disabled.notif.error'))
          : notify.error(t('user.modal.enabled.notif.error'))
      }
    }
  }

  const handleChange = () => {
    if (member) {
      showConfirmation({
        title: member.isEnabled
          ? t('user.modal.disabled')
          : t('user.modal.enabled'),
        subtitle: member.isEnabled
          ? t('user.modal.disabled.question')
          : t('user.modal.enabled.question'),
        text: member.isEnabled
          ? `${t('user.modal.disabled.text')} ${t('user.modal.disabled.text2')}`
          : t('user.modal.enabled.text'),
        confirmText: member.isEnabled
          ? t('user.modal.disabled.button')
          : t('user.modal.enabled.button'),
        cancelText: t('common.cancel'),
        iconName: 'report_problem',
        onConfirm: async () => {
          await memberChange(member)
        },
      })
    }
  }

  const handleDelete = () => {
    if (member) {
      showConfirmation({
        title: t('modalDeleteUser.title'),
        subtitle: t('modalDeleteUser.subtitle', {
          name: `${member.name} ${member.surname}`,
        }),
        text: t('modalDeleteUser.text'),
        confirmText: t('common.remove'),
        cancelText: t('common.cancel'),
        iconName: 'report_problem',
        confirmActionOptions: {
          confirmText: t('modalDeleteUser.confirmText'),
          word: t('modalDeleteUser.word'),
          checkboxes: [
            {
              text: t('modalDeteteUser.checkbox'),
              required: false,
            },
          ],
        },
        onConfirm: async (checkboxes) => {
          const [deleteContent] = checkboxes
          try {
            await deleteMember(member.id, deleteContent)
            navigate(-1)
            notify.success(t('modalDeteteUser.feedback.success'))
          } catch (error) {
            notify.error(t('modalDeteteUser.feedback.error'))
          }
        },
      })
    }
  }

  useEffect(() => {
    if (!profileId) return
    fetchMember(profileId)
  }, [profileId])

  const idArrayChannels = useMemo(() => {
    if (member) {
      return (member.channels || []).map((obj) => obj.id)
    }
    return []
  }, [member])

  return isLoading ? (
    <Loader />
  ) : (
    <Main ariaLabelledby="profile" className="Profile">
      {member && (
        <div className="Profile__box">
          <div className="Profile__box__imageContent">
            <div className="Profile__box__imageContent__image">
              <Avatar
                size="xl"
                name={member.name}
                surname={member.surname}
                imageUrl={member.imageUrl}
                alt={`${member.name} ${member.surname}`}
              />
            </div>
            <div className="Profile__box__imageContent__name">
              <h4>
                {member.name} {member.surname}
              </h4>
              <div className="align-items-center d-flex gap-4">
                <ToggleControl
                  className="TopicRow__header__actions__publish"
                  label={t('user.modal.enabled')}
                  name="isPublished"
                  value={member.isEnabled}
                  onChange={handleChange}
                  disabled={!verifyUsersModerator(idArrayChannels)}
                />
                {verifyPermissionsMaster() && (
                  <button
                    onClick={handleDelete}
                    type="button"
                    className="btn btn-outline-primary"
                  >
                    Eliminar
                  </button>
                )}
              </div>
            </div>

            <div className="Profile__box__imageContent__job">
              {member?.positions &&
                member?.positions.length > 0 &&
                `${member?.positions[0].jobTitle} ${returnHypphen(
                  member?.positions[0].jobTitle,
                  member?.positions[0].company
                )} ${member?.positions[0].company}`}
            </div>
            <div className="Profile__box__imageContent__email">
              <p>{member.email || 'emailMock'}</p>
            </div>
          </div>
          {member && member.badges && member.badges.length > 0 && (
            <div className="Profile__box__badges">
              <div className="row contentCarousel">
                <Carousel elements={member.badges} type={TypeCard.Badges} />
              </div>
            </div>
          )}
          {member?.positions && member?.positions.length > 1 && (
            <div className="Profile__box__jobs">
              <p className="Profile__box__jobs__title">
                {t('user.list.jobs.others')}
              </p>
              <div className="Profile__box__jobs__list">
                {renderPositions(member.positions)}
              </div>
            </div>
          )}
          <div className="Profile__box__text">
            <p className="Profile__box__text__title">{t('user.about')}</p>
            <HtmlSanitizer
              className="Profile__box__text__description"
              html={member.description ?? ''}
            />
          </div>
          <hr />
          {member && member.channels && (
            <div className="Profile__box__channels">
              <p className="title">{t('user.info')}</p>
              <p className="channelsTit">{t('topic.channels')}</p>
              <div className="contentCarousel">
                <div className="row carousel">
                  <Carousel
                    elements={member.channels}
                    type={TypeCard.Channels}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Main>
  )
}

export default ProfileScreen
