import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormTextInput, Loader } from '@liveconnect/components'

import useConfigurationFooter from '../../../../core/configuration/footer/useFooter'
import useNotifications from '../../../../utils/notifications/useNotifications'
import { DEFAULT_LANG } from '../../../../i18n/config'

import CustomForm from '../../../../components/CustomForm'
import TranslationCard from '../../../../components/TranslationCard'
import { ConfigurationFooterPages } from '../Shared/Pages'

import {
  ConfigurationFooterGeneralInfoForm,
  UrlTypeFooterPage,
} from '../../../../core/configuration/footer/types'

import { buildValidationSchema } from './validations'

const FooterGeneralInfo = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const {
    isLoaded,
    generalInfo: { list },
    fetchGeneralInfoList,
    createGeneralInfo,
    updateGeneralInfo,
    translateGeneralInfo,
  } = useConfigurationFooter()

  const defaultValues = {
    language: DEFAULT_LANG,
  }

  const methods = useForm<ConfigurationFooterGeneralInfoForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues,
  })

  const { control, watch, reset } = methods

  const formValues = watch()

  const isGeneralInfoSetted = useMemo(() => {
    if (!list) return false
    return list.id !== '00000000-0000-0000-0000-000000000000'
  }, [isLoaded, list])

  const onSubmit = async (values: ConfigurationFooterGeneralInfoForm) => {
    const isTranslating = formValues.language !== DEFAULT_LANG
    let action = 'create'
    if (isGeneralInfoSetted) action = 'edit'
    if (isTranslating) action = 'translate'
    try {
      if (!isGeneralInfoSetted) {
        await createGeneralInfo(values)
        fetchGeneralInfoList()
      } else {
        if (isTranslating) {
          await translateGeneralInfo(list!.id, values)
        } else {
          await updateGeneralInfo(list!.id, values)
        }
      }
      reset(formValues)
      notify.success(t(`footerInfo.generalInfo.feedback.${action}.ok`))
    } catch (error) {
      notify.error(t(`footerInfo.generalInfo.feedback.${action}.ko`))
    }
  }

  useEffect(() => {
    fetchGeneralInfoList()
  }, [])

  useEffect(() => {
    if (list && isGeneralInfoSetted) {
      const translation = list.generalInfoTranslations.find(
        (ct) => ct.language === formValues.language
      )
      reset({
        ...formValues,
        sectionTitle: translation ? translation.sectionTitle : '',
      })
    } else {
      reset(defaultValues)
    }
  }, [list, formValues.language])

  if (!isLoaded) {
    return <Loader />
  }

  return (
    <CustomForm
      methods={methods}
      onSubmit={onSubmit}
      onCancel={() => reset(defaultValues)}
      isSelectDisabled={!isGeneralInfoSetted}
    >
      {isGeneralInfoSetted && list && (
        <TranslationCard
          translations={list.translations.map(
            (t) =>
              ({
                language: t,
                name: t,
              } as any)
          )}
          lang={formValues.language}
          entity={t('footerInfo.generalInfo.entity')}
        />
      )}
      <div className="row mb-5">
        <div className="col-9">
          <FormTextInput
            label={t('footerInfo.generalInfo.form.sectionTitle.label')}
            placeholder={t(
              'footerInfo.generalInfo.form.sectionTitle.placeholder'
            )}
            control={control}
            name="sectionTitle"
            required
            data-testid="footerInfo-generalInfo-sectionTitle"
            helperText={t('validations.leyendField.max', { number: 50 })}
          />
        </div>
      </div>
      {isGeneralInfoSetted && list && (
        <ConfigurationFooterPages
          title={t('footerInfo.generalInfo.table.title')}
          type={UrlTypeFooterPage.GeneralInfo}
          tabId={list.id}
          data={list.pages}
        />
      )}
    </CustomForm>
  )
}

export default FooterGeneralInfo
