// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditBadge__form{margin-bottom:56px}.EditBadge__form__name__translation .required::after{display:none}.EditBadge__form__name .max{font-size:12px;margin-top:-8px;display:block}.EditBadge__form__picture{margin-top:30px}`, "",{"version":3,"sources":["webpack://./src/app/pages/Brand/Badges/EditBadge/styles.scss"],"names":[],"mappings":"AASI,iBACI,kBAAA,CAGQ,qDACI,YAAA,CAGR,4BACI,cAAA,CACA,eAAA,CACA,aAAA,CAIR,0BACI,eAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.EditBadge{\n    &__form {\n        margin-bottom: 56px;\n        &__name {\n            &__translation {\n                .required::after {\n                    display: none;\n                }\n            }\n            .max{\n                font-size: 12px;\n                margin-top: -8px;\n                display: block;\n            }\n            \n        }\n        &__picture {\n            margin-top: 30px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EditBadge__form": `EditBadge__form`,
	"EditBadge__form__name__translation": `EditBadge__form__name__translation`,
	"required": `required`,
	"EditBadge__form__name": `EditBadge__form__name`,
	"max": `max`,
	"EditBadge__form__picture": `EditBadge__form__picture`
};
export default ___CSS_LOADER_EXPORT___;
