import { Sponsor } from '../brandSpace/types'
import { Translation } from '../shared/types'

export interface Section {
  id: string
  translations: Translation[]
  index: number
  isFeatured: boolean
  subSections?: Subsection[]
  sponsors?: Sponsor[]
}
export interface Subsection {
  id: string
  index: number
  translations: Translation[]
  sponsors?: Sponsor[]
}

export enum SupportedLanguages {
  ES_ES = 'es-ES',
  EN_GB = 'en-GB',
}

export interface SectionsState {
  list: Section[]
  isLoaded: boolean
}

export interface UseSectionsResult {
  sections: Section[]
  isLoaded: boolean
  fetchSections: (brandId: string) => Promise<void>
  createUpdateSections: (
    params: CreateSectionsModel
  ) => Promise<Section[] | undefined>
  updateIsFeatured: (params: UpdateIsHighlightedParams) => Promise<void>
  updateSectionOrder: (params: UpdateOrderParams) => Promise<void>
  uploadSponsorImage: (
    params: UploadSponsorImageModel
  ) => Promise<[string, string] | undefined>
  createBatchSponsors: (
    params: CreateBatchSponsorsModel
  ) => Promise<CreateUpdateSponsorModel[] | undefined>
  updateSponsor: (params: UpdateSponsorModel) => Promise<Sponsor | undefined>
  deleteSponsor: (sponsorId: string) => Promise<void>
  updateOrderSponsor: (params: UpdateOrderSponsorParams) => Promise<void>
  updateSubsectionOrder: (params: UpdateSubsectionOrderParams) => Promise<void>
}

export interface UpdateOrderParams {
  brandId: string
  sectionId: string
  index: number
}

export interface UpdateIsHighlightedParams {
  brandId: string
  sectionId: string
  isHighlighted: boolean
}

export interface SetIsFeaturedPayload {
  isFeatured: boolean
  sectionId: string
}

export interface CreateSectionsModel {
  brandId: string
  language: string
  sections: CreateSectionsSectionModel[]
}
export interface CreateSectionsSectionModel {
  id?: string
  name: string
  index: number
  subsections?: CreateSubsectionModel[]
}

export interface CreateSubsectionModel {
  id?: string
  name: string
  index: number
}

export interface UploadSponsorImageModel {
  internalId: string
  logoUrl: string
}

export interface ImageResult {
  uri: string
}

export interface CreateUpdateSponsorModel {
  id?: string
  name: string
  websiteUrl: string
  logoUrl: string
  index: number
}

export interface UpdateSponsorModel {
  id: string
  name: string
  websiteUrl: string
  logoUrl: string
  brandId: string
}
export interface CreateBatchSponsorsModel {
  sectionId: string
  brandId: string
  sponsors: CreateUpdateSponsorModel[]
}

export interface UpdateOrderSponsorParams {
  sponsorId: string
  index: number
  brandId: string
}

export interface UpdateSubsectionOrderParams {
  brandId: string
  subsectionId: string
  sectionId: string
  index: number
}
