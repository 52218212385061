// Function to download text as CSV file
export function downloadCSV(csv: string, filename: string) {
  const csvFile = new Blob([csv], { type: 'text/csv' })
  const downloadLink = document.createElement('a')
  downloadLink.download = filename
  downloadLink.href = window.URL.createObjectURL(csvFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  downloadLink.remove()
}
