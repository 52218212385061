import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Loader } from '@liveconnect/components'

import useNotifications from '../../../../utils/notifications/useNotifications'
import { CommentActivity } from '../../../../core/activities/types'
import useActivities from '../../../../core/activities/useActivities'
import { MakeCommentComponent } from '../../../Wall/Post/MakeComment'
import {
  PublishersResponse,
  PublisherTypeEnum,
} from '../../../../core/posts/types'
import { CommentComponent } from '../../../Wall/Post/Comment'

import './styles.scss'
import EmptyState from '../../../../components/EmptyState'

interface CommentsActivityProps {
  isDetail?: boolean
}

const CommentsActivity: FC<CommentsActivityProps> = ({ isDetail }) => {
  const { t } = useTranslation()
  const { activity, getCommentsActivity, comments } = useActivities()
  const { activityId } = useParams()
  const notify = useNotifications()
  const [loading, setLoading] = useState<boolean>(false)

  const getPublisher: PublishersResponse = {
    id: activity ? activity.brandSpace.id : '',
    name: activity ? activity.brandSpace.name : '',
    type: PublisherTypeEnum.BRAND,
    channels: [],
    profileImageUrl: activity ? activity.brandSpace.profileImageUrl : '',
    shortDescription: '',
  }

  const getData = async () => {
    if (activityId) {
    }
    setLoading(true)
    try {
      activityId &&
        activity &&
        (await getCommentsActivity(activityId, activity.brandSpace.id))
    } catch (e) {
      notify.error(t('activity.form.comments.load.error'))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [activityId])

  return (
    <div className="Comments">
      {activity && !isDetail && (
        <div className="Comments__action">
          <MakeCommentComponent
            takenId={activity.id}
            publisherId={activity.brandSpace.id}
            publisherType={PublisherTypeEnum.BRAND}
            selectedPublisher={getPublisher}
            inActivity={true}
          />
        </div>
      )}

      {(loading || !comments) && <Loader />}
      {comments && comments.length > 0 && (
        <div className="Comments__content">
          <div className="row">
            {comments.map((comment: CommentActivity) => (
              <div className="col-12" key={comment.id}>
                <CommentComponent
                  publisherId={comment.publisher.id}
                  publisherType={comment.publisher.type}
                  key={comment.id}
                  {...comment}
                  takenId={activity ? activity.id : ''}
                  originalLangComment={comment.originalLanguage}
                  inActivity={true}
                  isReactionable={!isDetail}
                  isEditable={isDetail ? false : comment.isEditable}
                  isDeletable={isDetail ? false : comment.isDeletable}
                  channelsIds={activity?.channels || []}
                  sponsor={null}
                  isModerate={comment.isModerate}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {(loading && !comments) ||
        (comments && comments.length === 0 && (
          <EmptyState text={t('activities.noComments')} />
        ))}
    </div>
  )
}

export default CommentsActivity
