import {
  AsyncSelectControl,
  Option,
  PaginatedResult,
} from '@liveconnect/components'
import { FC, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useBadges from '../../../../../core/badges/useBadges'
import { Member } from '../../../../../core/brandSpace/types'
import useMembers from '../../../../../core/members/useMembers'
import useUi from '../../../../../core/ui/useUi'
import useNotifications from '../../../../../utils/notifications/useNotifications'

interface ModalAssignProps {
  isModalVisible: boolean
  handleChangeStateModal: () => void
  onAssign: () => void
  badgeName: string
}

const ModalAssign: FC<ModalAssignProps> = ({
  isModalVisible,
  handleChangeStateModal,
  onAssign,
  badgeName,
}) => {
  const { t } = useTranslation()
  const { showConfirmation } = useUi()
  const notify = useNotifications()
  const { badgeId, brandId } = useParams()
  const { assignMember } = useBadges()
  const { getMembersBadge } = useMembers()
  const { members } = useBadges()
  const [selectedMembers, setSelectedMembers] = useState<string[]>([])

  const actualMembersIds = useMemo(() => {
    return (members?.items ?? []).map((member) => member.memberId)
  }, [members])

  const handleAssignMembers = () => {
    handleChangeStateModal()
    showConfirmation({
      title: t('badge.manage.assign'),
      subtitle: t('badge.manage.assign.modal.title', {
        number: selectedMembers.length,
        badgeName,
      }),
      confirmText: t('common.assign'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: assignMembers,
    })
  }

  const assignMembers = async () => {
    if (!badgeId) return
    try {
      await assignMember(badgeId, selectedMembers)
      onAssign()
      notify.success(t('badge.manage.assign.ok'))
    } catch (error) {
      notify.error(t('badge.manage.assign.ko'))
    }
  }

  const handleSearch = async (
    query: string,
    page: number
  ): Promise<PaginatedResult<Member>> => {
    const params = {
      search: query,
      page: 1,
      page_size: 100,
      brandSpaceId: brandId || '',
    }
    const response = await getMembersBadge(params)
    const data = response
      ? response.items.filter((item) => !actualMembersIds.includes(item.id))
      : []

    return new Promise((resolve) => {
      resolve({
        page,
        pageSize: 100,
        hasMoreElements: false,
        data,
      })
    })
  }

  const mapMembers = (member: Member): Option<string> => {
    return {
      label: `${member.name} ${member.surname}  (${member.email})`,
      value: member.id,
    }
  }

  const handleChangeSearch = (selected: string[]) => {
    setSelectedMembers(selected)
  }

  return (
    <Modal
      show={isModalVisible}
      size="lg"
      className="badge-manage-assign-modal"
      scrollable={false}
    >
      <Modal.Header>
        <Modal.Title>
          {t('badge.manage.assign')} {badgeName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AsyncSelectControl
          name="members"
          value={selectedMembers}
          onChange={handleChangeSearch}
          label={''}
          isMulti={true}
          loadOptions={handleSearch}
          mapResponseToOptions={mapMembers}
          loadingMessage={t('badge.manage.selector.loadingMessage')}
          noOptionsMessage={t('select.empty')}
          placeholder={t('badge.manage.selector.placeholder')}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={handleChangeStateModal}
        >
          {t('common.cancel')}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAssignMembers}
          disabled={selectedMembers.length === 0}
        >
          {t('common.assign')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAssign
