import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  showLoader,
  hideLoader,
  setMultimediaList,
  setDetailMultimedia,
  setSectionmultimedia,
  setVisibility,
} from './store'

import useFetch from '../../utils/fetch/useFetch'
import {
  MultiMediaDetail,
  MultimediaListResponse,
  MultimediaRequestParams,
  MultimediaSection,
  MultimediaVisibleParams,
  OnlyTransMultimedia,
  PayloadEditMultimedia,
  PayloadMultimedia,
  UseMultimediaResult,
} from './types'
import { setElementMultimediaSectionVisible } from '../elements/store'

const useMultimedia = (): UseMultimediaResult => {
  const dispatch = useAppDispatch()
  const { detail, list, isLoaded, section } = useAppSelector(
    (state) => state.multimedia
  )
  const { get, del, patch, post } = useFetch()
  const endpoint = 'element-multimedia'

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const fetchMultimediaSection = async (idElement: string) => {
    dispatch(showLoader())
    const response: MultimediaSection | undefined = await get({
      endpoint: `${endpoint}/by-element/${idElement}`,
    })
    if (response) {
      dispatch(setSectionmultimedia(response))
    }
    dispatch(hideLoader())
    if (response) return response
  }

  const getMultimediaList = async (
    params: MultimediaRequestParams
  ): Promise<MultimediaListResponse | undefined> => {
    const response: MultimediaListResponse | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const fetchMultimediaList = async (
    params: MultimediaRequestParams
  ): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: MultimediaListResponse | undefined =
        await getMultimediaList(params)
      if (response) {
        dispatch(setMultimediaList(response))
      }
    })
  }

  const clearMultimediaList = async () => {
    dispatch(showLoader())
    const empty = {
      items: [],
      page: 1,
      pageSize: 20,
      availableItems: 1,
      availablePages: 1,
    }
    dispatch(setMultimediaList(empty))
    dispatch(hideLoader())
  }

  const changeVisibilityMultimedia = async (data: MultimediaVisibleParams) => {
    const response: boolean | undefined = await patch({
      endpoint: endpoint,
      body: data,
      id: 'visible',
    })
    if (response) {
      dispatch(setVisibility(data.visible))
      dispatch(setElementMultimediaSectionVisible(data.visible))
    }
  }

  const addMultimedia = async (data: PayloadMultimedia) => {
    dispatch(showLoader())
    const response: string | undefined = await post({
      endpoint: `${endpoint}/content`,
      body: data,
    })
    if (response) {
      dispatch(hideLoader())
    }

    return response
  }

  const editMultimedia = async (
    data: PayloadEditMultimedia,
    idMultimedia: string
  ) => {
    const response: MultiMediaDetail | undefined = await patch({
      endpoint: `${endpoint}/content`,
      body: data,
      id: idMultimedia,
    })
    if (response) {
      dispatch(setDetailMultimedia(response))
    }
    return response
  }

  const editMultimediaTranslation = async (
    data: OnlyTransMultimedia,
    idMultimedia: string,
    elementId: string
  ) => {
    const response: MultiMediaDetail | undefined = await patch({
      endpoint: `${endpoint}/content/${idMultimedia}/translations/element`,
      body: {
        name: data.name,
        language: data.language,
      },
      id: elementId,
    })
    if (response) {
      dispatch(setDetailMultimedia(response))
    }
    return response
  }

  const uploadImageMultimedia = async (
    image: string
  ): Promise<string | undefined> => {
    const form = new FormData()
    const blob = await fetch(image).then((r) => r.blob())
    form.set('imageFile', blob)
    const result: { uri: string } | undefined = await post({
      endpoint: `${endpoint}/image`,
      body: form,
      isMultipart: true,
    })
    if (result) return result.uri
  }

  const deleteMultimedia = async (
    id: string,
    elementId: string
  ): Promise<void> => {
    await del({
      endpoint: `${endpoint}/content/${id}/element`,
      id: elementId,
    })
  }

  const fetchMultimediaDetail = async (id: string, elementId: string) => {
    dispatch(showLoader())
    const response: MultiMediaDetail | undefined = await get({
      endpoint: `${endpoint}/content/${id}`,
      params: { elementId },
    })
    if (response) {
      dispatch(setDetailMultimedia(response))
    }
    dispatch(hideLoader())
  }

  return {
    multimediaDetail: detail,
    multimediaList: list,
    section,
    isLoaded,
    fetchMultimediaSection,
    fetchMultimediaList,
    clearMultimediaList,
    changeVisibilityMultimedia,
    addMultimedia,
    editMultimedia,
    editMultimediaTranslation,
    uploadImageMultimedia,
    deleteMultimedia,
    fetchMultimediaDetail,
  }
}

export default useMultimedia
