import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import {
  ActivityConfigForm,
  ActivityType,
} from '../../../../core/activities/types'
import { addDays } from 'date-fns'
import { useHtmlToString } from '../../../../utils/useHtmlToString'

let endDatePart = ''
interface TestContextExtended {
  from: {
    value: ActivityConfigForm
  }[]
}

export function buildValidationSchema(
  t: TFunction,
  endActivity: boolean
): yup.AnyObjectSchema {
  return yup.object().shape({
    translations: yup
      .array()
      .of(
        yup.object().shape({
          title: yup
            .string()
            .label(t('activity.form.config.title.label'))
            .max(120, ({ label }) =>
              t('validations.maxLength', { label, maxLength: 120 })
            )
            .required(({ label }) => t('validations.required', { label })),
          description: yup
            .string()
            .label(t('activity.form.config.description.label'))
            .test(
              'activity.form.config.description.label',
              ({ label }) =>
                t('validations.maxLength', { label, maxLength: 1400 }),
              (val) => useHtmlToString(val ?? '').length <= 1400
            )
            .required(({ label }) => t('validations.required', { label })),
          imageURL: yup
            .string()
            .label(t('activity.form.config.image.label'))
            .required(({ label }) => t('validations.required', { label })),
          resultTitle: yup
            .string()
            .label(t('activity.form.config.challengeTitle.label'))
            .max(120, ({ label }) =>
              t('validations.maxLength', { label, maxLength: 120 })
            )
            .nullable(),
          resultDescription: yup
            .string()
            .label(t('activity.form.config.challengeDescription.label'))
            .test(
              'activity.form.config.challengeDescription.label',
              ({ label }) =>
                t('validations.maxLength', { label, maxLength: 1500 }),
              (val) => useHtmlToString(val ?? '').length <= 1500
            )
            .nullable(),
        })
      )
      .nullable()
      .notRequired(),
    brandSpaceId: yup
      .string()
      .label(t('activity.form.config.brandSpaceId.label'))
      .required(({ label }) => t('validations.required', { label })),
    channels: yup
      .array()
      .label(t('activities.filters.fields.channels.connect'))
      .min(1, ({ label }) => t('validations.required', { label }))
      .required(({ label }) => t('validations.required', { label })),
    type: yup
      .string()
      .label(t('activity.form.config.type.label'))
      .required(({ label }) => t('validations.required', { label })),
    maxActivityAttendees: yup
      .number()
      .typeError(({ label }) => t('validations.required', { label }))
      .label(t('activity.form.config.limit.label'))
      .when('hasMaxAttendees', {
        is: (hasMaxAttendees: boolean) => hasMaxAttendees,
        then: yup
          .number()
          .typeError(({ label }) => t('validations.required', { label }))
          .required(({ label }) => t('validations.required', { label }))
          .positive(({ label }) => t('validations.numberPositive', { label })),
      }),
    startDateParticipation: yup
      .string()
      .label(t('activity.form.config.startDateParticipation.label'))
      .required(({ label }) => t('validations.required', { label })),
    endDateParticipation: yup
      .string()
      .label(t('activity.form.config.endDateParticipation.label'))
      .test('captur_endDateParticipation', '', (value) => {
        if (value) {
          endDatePart = value
        }
        return true
      })
      .test(
        'startDateParticipation',
        t('validations.greaterThan', {
          label: t('activity.form.config.endDateParticipation.label'),
          other: t('activity.form.config.startDateParticipation.label'),
        }),
        (value, context) => {
          const { startDateParticipation } = context.parent
          if (value && startDateParticipation) {
            return new Date(startDateParticipation) < new Date(value)
          }
          return true
        }
      )
      .required(({ label }) => t('validations.required', { label })),
    expirationDate: yup
      .string()
      .label(t('activity.form.config.expirationDate.label'))
      .test(
        'expirationDate participation',
        t('validations.greaterThan', {
          label: t('activity.form.config.expirationDate.label'),
          other: t('activity.form.config.endDateParticipation.label'),
        }),
        (value, context) => {
          const { endDateParticipation } = context.parent
          if (value && endDateParticipation) {
            return new Date(endDateParticipation) < new Date(value)
          }
          return true
        }
      )
      .required(({ label }) => t('validations.required', { label }))
      .test(
        'expirationDateValidation',
        t('activity.form.config.expirationDate.validation'),
        (value, context) => {
          const {
            videoConferenceType,
            faceToFaceType,
            askAuthorType,
            roundTableType,
            type,
          } = context.parent
          if (type == ActivityType.Video) {
            if (value && videoConferenceType && videoConferenceType.endDate) {
              return new Date(videoConferenceType.endDate) < new Date(value)
            }
          }
          if (type == ActivityType.AskAuthor) {
            if (value && askAuthorType && askAuthorType.endDate) {
              return new Date(askAuthorType.endDate) < new Date(value)
            }
          }
          if (type == ActivityType.RoundTable) {
            if (value && roundTableType && roundTableType.endDate) {
              return new Date(roundTableType.endDate) < new Date(value)
            }
          }
          if (type == ActivityType.FaceToFace) {
            if (value && faceToFaceType && faceToFaceType.endDate) {
              return new Date(faceToFaceType.endDate) < new Date(value)
            }
          }
          return true
        }
      ),
    language: yup
      .string()
      .label(t('activity.form.config.activityLanguage.label'))
      .required(({ label }) => t('validations.required', { label })),
    videoConferenceType: getDynamicTypeValidations(
      ActivityType.Video,
      t,
      endActivity
    ),
    roundTableType: getDynamicTypeValidations(
      ActivityType.RoundTable,
      t,
      endActivity
    ),
    askAuthorType: getDynamicTypeValidations(
      ActivityType.AskAuthor,
      t,
      endActivity
    ),
    faceToFaceType: yup
      .object()
      .when('type', {
        is: (type: string) => type == ActivityType.FaceToFace,
        then: yup.object().shape({
          ...getDateValidations(t, endActivity),
          location: yup.object().shape({
            fullAddress: yup
              .string()
              .label(t('activity.form.config.location.label'))
              .required(({ label }) => t('validations.required', { label })),
          }),
        }),
      })
      .nullable()
      .notRequired(),
    challengeType: yup
      .object()
      .when('type', {
        is: (type: string) => type == ActivityType.Challenge,
        then: yup.object().shape({
          ...getDateValidations(t, endActivity),
          challengeUrl: yup
            .string()
            .url(t('common.link.patternValidation'))
            .label(t('activity.form.config.challengeUrl.label'))
            .required(({ label }) => t('validations.required', { label })),
        }),
      })
      .nullable()
      .notRequired(),
  })
}

const getDateValidations = (t: TFunction, endActivity: boolean) => {
  return {
    startDate: yup
      .string()
      .label(t('activity.form.config.startDate.label'))
      .required(({ label }) => t('validations.required', { label }))
      .test(
        'validation startDate with endDateParticipation',
        t('activity.form.config.endDate.validation.startDateParticipation'),
        (value) => {
          if (value && endDatePart) {
            return new Date(endDatePart) > new Date(value)
          }
          return true
        }
      )
      .test(
        'validation startDate with startDateParticipation',
        t('validations.greaterThan', {
          label: t('activity.form.config.startDate.label'),
          other: t('activity.form.config.startDateParticipation.label'),
        }),
        (value, context) => {
          const { from } = context as yup.TestContext & TestContextExtended
          if (value && from[1].value.startDateParticipation) {
            return (
              new Date(from[1].value.startDateParticipation) < new Date(value)
            )
          }
          return true
        }
      ),
    endDate: yup
      .string()
      .label(t('activity.form.config.endDate.label'))
      .required(({ label }) => t('validations.required', { label }))
      .test(
        'validate that end date is greater than today',
        t('activity.form.config.endDate.validation.today'),
        (value) => {
          if (!endActivity && value) {
            return new Date() < new Date(value)
          }
          return true
        }
      )
      .test(
        'startDate',
        t('activity.form.config.endDate.validation.day'),
        (value, context) => {
          const { startDate }: { startDate: string } = context.parent
          const { from } = context as yup.TestContext & TestContextExtended
          const activityType = from[1].value.type

          if (value && startDate && activityType !== ActivityType.Challenge) {
            return addDays(new Date(startDate), 1) > new Date(value)
          }
          return true
        }
      )
      .test(
        'validation startDate with endDateParticipation',
        t('validations.greaterThan', {
          label: t('activity.form.config.endDateParticipation.label'),
          other: t('activity.form.config.endDate.label'),
        }),
        (value, context) => {
          const { from } = context as yup.TestContext & TestContextExtended
          if (value && from[1].value.endDateParticipation) {
            return (
              new Date(from[1].value.endDateParticipation) >= new Date(value)
            )
          }
          return true
        }
      )
      .test(
        'endDateValidation',
        t('activity.form.config.endDate.validation.startDate'),
        (value, context) => {
          const { startDate }: { startDate: string } = context.parent
          if (value && startDate) {
            return new Date(startDate) < new Date(value)
          }
          return true
        }
      ),
  }
}

const getDynamicTypeValidations = (
  activityType: ActivityType,
  t: TFunction,
  endActivity: boolean
) => {
  return yup
    .object()
    .when('type', {
      is: (type: string) => type == activityType,
      then: yup.object().shape({
        ...getDateValidations(t, endActivity),
        broadcastURL: yup
          .string()
          .label(
            t('activity.form.config.linksVideoConference.broadcastURL.label')
          )
          .required(({ label }) => t('validations.required', { label })),
        postBroadcastURL: yup
          .string()
          .label(
            t(
              'activity.form.config.linksVideoConference.postBroadcastURL.label'
            )
          )
          .url(t('common.link.patternValidation'))
          .nullable(),
      }),
    })
    .nullable()
    .notRequired()
}
