import { useEffect, FC } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import useAuth, { postLogoutRedirectPathKey } from '../core/auth/useAuth'
import useLocalizations from '../core/localizations/useLocalizations'
import usePermissions from '../core/permissions/usePermissions'
import Layout from '../containers/Layout'
import useTenantConfig from '../core/tenantConfig/useTenantConfig'
import { useCustomRouter } from '../utils/extractParams'
import useMe from '../core/me/useMe'
import { useParams } from 'react-router'

const PrivateRoute: FC = () => {
  const navigate = useNavigate()
  const { user, login, logout, loaded, isLoggedIn } = useAuth()
  const { fetchLocalizations } = useLocalizations()
  const { basePath } = useCustomRouter()
  const { fetchTenantConfig } = useTenantConfig()
  const { fetchMe } = useMe()
  const { tenantId } = useParams()
  const {
    isLoaded: hasPermissionsLoaded,
    communityPermissions,
    fetchList: fetchPermissions,
    fetchCommunityPermissions,
  } = usePermissions()

  const initPermissions = async () => {
    try {
      await fetchPermissions()
      await fetchCommunityPermissions()
      await fetchMe()
      await fetchLocalizations()
      await fetchTenantConfig()
    } catch (e) {
      navigate(`${basePath}/access-denied`)
    }
  }

  useEffect(() => {
    if (!isLoggedIn) {
      login()
    }
    if (user && !hasPermissionsLoaded) {
      initPermissions()
    }
  }, [loaded, isLoggedIn, login, hasPermissionsLoaded, user])

  if (user && tenantId && user.profile.tenant_id !== tenantId) {
    sessionStorage.setItem(postLogoutRedirectPathKey, `/t/${tenantId}`)
    logout()
  }

  return isLoggedIn && hasPermissionsLoaded && communityPermissions ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : null
}
export default PrivateRoute
