import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  setDirectoriesList,
  showLoader,
  hideLoader,
  setDetailDirectory,
} from './store'

import useFetch from '../../utils/fetch/useFetch'
import {
  UseDirectoriesResult,
  DirectoriesRequestParams,
  SectorDirectory,
  DirectoryDetail,
  PutOnlyTrans,
  PayloadDirectory,
  BrandDirectory,
  DirectoriesListResponse,
  ResponseSectorsByDirectory,
} from './types'

const useDirectories = (): UseDirectoriesResult => {
  const dispatch = useAppDispatch()
  const { detail, list, isLoaded } = useAppSelector(
    (state) => state.directories
  )
  const { get, del, patch, post } = useFetch()

  const endpoint = 'directories'

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }
  const getDirectories = async (
    params: DirectoriesRequestParams
  ): Promise<DirectoriesListResponse | undefined> => {
    const response: DirectoriesListResponse | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const fetchDirectories = async (
    params: DirectoriesRequestParams
  ): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: DirectoriesListResponse | undefined =
        await getDirectories(params)
      if (response) {
        dispatch(setDirectoriesList(response))
      }
    })
  }

  const getSectorsDirectory = async (): Promise<
    SectorDirectory[] | undefined
  > => {
    const response: SectorDirectory[] | undefined = await get({
      endpoint: `topic-families/directory-sectors`,
    })
    return response
  }

  const getSectorsByDirectory = async (
    directoryId: string
  ): Promise<ResponseSectorsByDirectory | undefined> => {
    const response: ResponseSectorsByDirectory | undefined = await get({
      endpoint: `${endpoint}/sectors/by-directory/${directoryId}`,
    })
    return response
  }

  const getBrandsDirectory = async (): Promise<
    BrandDirectory[] | undefined
  > => {
    const response: BrandDirectory[] | undefined = await get({
      endpoint: `${endpoint}/brand-spaces`,
    })
    return response
  }

  const publishDirectory = async (
    id: string,
    publish: boolean
  ): Promise<void> => {
    const response = await patch({
      endpoint: `${endpoint}/publish`,
      body: {
        id,
        publish,
      },
      id: '',
    })
    if (response && list) {
      dispatch(
        setDirectoriesList({
          ...list,
          items: list.items.map((directory) => {
            if (directory.id == id) {
              return { ...directory, isPublished: !directory.isPublished }
            }
            return directory
          }),
        })
      )
    }
  }

  const deleteDirectory = async (id: string): Promise<void> => {
    await del({
      endpoint,
      id,
    })
  }

  const addDirectory = async (data: PayloadDirectory) => {
    dispatch(showLoader())
    const response: string | undefined = await post({
      endpoint: `${endpoint}`,
      body: data,
    })
    if (response) {
      dispatch(hideLoader())
    }

    return response
  }

  const editDirectory = async (directory: PayloadDirectory, id: string) => {
    const response: DirectoryDetail | undefined = await patch({
      endpoint: `${endpoint}`,
      body: directory,
      id: id,
    })
    if (response) {
      dispatch(setDetailDirectory(response))
    }
    return response
  }

  const editDirectoryTranslation = async (
    directory: PutOnlyTrans,
    idDirectory: string
  ) => {
    const response: DirectoryDetail | undefined = await patch({
      endpoint: `${endpoint}/${idDirectory}`,
      body: {
        name: directory.name,
        description: directory.description,
        language: directory.language,
      },
      id: 'translations',
    })
    if (response) {
      dispatch(setDetailDirectory(response))
    }
    return response
  }

  const fetchDirectoryDetail = async (id: string) => {
    dispatch(showLoader())
    const response: DirectoryDetail | undefined = await get({
      endpoint: `${endpoint}/${id}`,
    })
    if (response) {
      dispatch(setDetailDirectory(response))
    }
    dispatch(hideLoader())
  }

  const uploadImageDirectory = async (
    image: string
  ): Promise<string | undefined> => {
    const form = new FormData()
    const blob = await fetch(image).then((r) => r.blob())
    form.set('imageFile', blob)
    const result: { uri: string } | undefined = await post({
      endpoint: `${endpoint}/image`,
      body: form,
      isMultipart: true,
    })
    if (result) return result.uri
  }

  return {
    directory: detail,
    directoriesList: list,
    isLoaded,
    fetchDirectories,
    fetchDirectoryDetail,
    publishDirectory,
    deleteDirectory,
    getSectorsDirectory,
    getSectorsByDirectory,
    getBrandsDirectory,
    addDirectory,
    editDirectory,
    editDirectoryTranslation,
    uploadImageDirectory,
  }
}

export default useDirectories
