import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const NotFound: FC = () => {
  const { t } = useTranslation()

  return (
    <div>
      <h1 id="not-found-title" className="h3">
        {t('notFound.title')} <br />
      </h1>
    </div>
  )
}

export default NotFound
