import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  FormTextInput,
  Loader,
  MimeType,
  UploadInput,
} from '@liveconnect/components'

import useConfigurationCorporate from '../../../core/configuration/corporate/useCorporate'
import { ConfigurationCorporateForm } from '../../../core/configuration/corporate/types'
import { Translation } from '../../../core/shared/types'

import { DEFAULT_LANG } from '../../../i18n/config'

import useUploadInput from '../../../utils/uploadInput/useUploadInput'
import useNotifications from '../../../utils/notifications/useNotifications'
import { uploadFile } from '../../../utils/fetch/uploadFile'

import { Main } from '../../../components/Main'
import CustomForm from '../../../components/CustomForm'
import TranslationCard from '../../../components/TranslationCard'
import Heading from '../../../components/Heading'

import { buildValidationSchema } from './validations'

const CorporateInfoPage = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { isLoaded, detail, fetchDetail, uploadImage, update, translate } =
    useConfigurationCorporate()
  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })

  const defaultValues = {
    language: DEFAULT_LANG,
  }

  const methods = useForm<ConfigurationCorporateForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues,
  })

  const { watch, control, setValue, setError, reset } = methods

  const formValues = watch()

  const isTranslating = useMemo(() => {
    return formValues.language !== DEFAULT_LANG
  }, [formValues.language])

  const onSubmit = async (values: ConfigurationCorporateForm) => {
    const _values = { ...values }
    const action = isTranslating ? 'translate' : 'update'
    try {
      _values.imageUrl = await uploadFile(_values.imageUrl, uploadImage)
      if (!isTranslating) {
        update(_values)
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { imageUrl, ..._translateValues } = _values
        translate(_translateValues)
      }
      reset(formValues)
      notify.success(t(`corporateInfo.form.feedback.${action}.ok`))
    } catch (error) {
      notify.error(t(`corporateInfo.form.feedback.${action}.ko`))
    }
  }

  useEffect(() => {
    fetchDetail()
  }, [])

  const resetForm = () => {
    if (detail && detail.id) {
      const translation = detail.translations.find(
        (t) => t?.language === formValues.language
      ) ?? {
        accessDenyText: '',
        accessDenyUrl: '',
        name: '',
        slogan: '',
        eventsUrl: '',
        language: formValues.language,
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { translations, ...restDetail } = detail
      reset({
        ...formValues,
        ...restDetail,
        ...translation,
      })
    } else {
      reset(defaultValues)
    }
  }

  useEffect(() => {
    resetForm()
  }, [detail, formValues.language])

  if (!isLoaded) return <Loader />

  return (
    <Main
      ariaLabelledby="config-corporate-info"
      title={t('corporateInfo.title')}
      subtitle={t('corporateInfo.subtitle')}
      isVisibleRequiredFieldsLeyend
    >
      <CustomForm
        methods={methods}
        onSubmit={onSubmit}
        onCancel={resetForm}
        isSelectDisabled={!detail?.id}
      >
        {detail && (
          <TranslationCard
            translations={detail.translations as Translation[]}
            lang={formValues.language}
            entity={t('corporateInfo.entity')}
          />
        )}

        <div className="row">
          <div className="col-9">
            <FormTextInput
              label={t('corporateInfo.form.name.label')}
              placeholder={t('corporateInfo.form.name.placeholder')}
              control={control}
              name="name"
              required
              data-testid="corporateInfo-form-name"
            />
            <FormTextInput
              label={t('corporateInfo.form.slogan.label')}
              placeholder={t('corporateInfo.form.slogan.placeholder')}
              control={control}
              name="slogan"
              required
              data-testid="corporateInfo-form-slogan"
              helperText={t('validations.leyendField.max', { number: 60 })}
            />
            <FormTextInput
              label={t('corporateInfo.form.eventsUrl.label')}
              placeholder={t('corporateInfo.form.eventsUrl.placeholder')}
              control={control}
              name="eventsUrl"
              required
              data-testid="corporateInfo-form-eventsUrl"
            />
          </div>
        </div>

        {!isTranslating && (
          <UploadInput
            control={control}
            title={t('corporateInfo.form.imageUrl.label')}
            name="imageUrl"
            accept={accept}
            onRemoveFile={() => setValue('imageUrl', '')}
            selectFilesError={(error) =>
              setError('imageUrl', {
                message: error.message,
              })
            }
            required
            rules={{
              image: {
                maxFileSize: 1,
                maxWidth: 128,
                maxHeight: 128,
              },
            }}
            data-testid="corporateInfo-form-imageUrl"
            labels={labels}
          />
        )}

        <Heading as="h2">{t('corporateInfo.deniedPage.title')}</Heading>
        <p>{t('corporateInfo.deniedPage.subtitle')}</p>

        <div className="row">
          <div className="col-4">
            <FormTextInput
              label={t('corporateInfo.form.accessDenyText.label')}
              placeholder={t('corporateInfo.form.accessDenyText.placeholder')}
              control={control}
              name="accessDenyText"
              required
              data-testid="corporateInfo-form-accessDenyText"
            />
          </div>
        </div>

        <FormTextInput
          label={t('corporateInfo.form.accessDenyUrl.label')}
          placeholder={t('corporateInfo.form.accessDenyUrl.placeholder')}
          control={control}
          name="accessDenyUrl"
          required
          data-testid="corporateInfo-form-accessDenyUrl"
        />
      </CustomForm>
    </Main>
  )
}

export default CorporateInfoPage
