import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import {
  FormMultiSelect,
  FormRadio,
  FormRichText,
  FormTextarea,
  FormTextInput,
  FormToggle,
  FormProvider,
  UploadInput,
  MimeType,
} from '@liveconnect/components'

import { ElementInfoTabForm } from '../../../../core/elements/types'
import { MultimediaType } from '../../../../core/shared/types'
import { SectorDirectory } from '../../../../core/directories/types'
import { buildValidationSchema } from './validations'

import useElements from '../../../../core/elements/useElements'
import useDirectories from '../../../../core/directories/useDirectories'
import useLocalizations from '../../../../core/localizations/useLocalizations'
import { useCustomRouter } from '../../../../utils/extractParams'
import useNotifications from '../../../../utils/notifications/useNotifications'
import { useBlockRouteChangeWithDialog } from '../../../../utils/routing/useBlockRouteChange'
import useUi from '../../../../core/ui/useUi'

import { FormActionsLang } from '../../../../components/forms/FormActionsLang'
import InfoBlock from '../../../../components/InfoBlock'
import TranslationCard from '../../../../components/TranslationCard'

import { DEFAULT_LANG } from '../../../../i18n/config'
import useUploadInput from '../../../../utils/uploadInput/useUploadInput'
import { characterCounterText } from '../../../../utils/characterCounterText'

const FormElementInfoTab = () => {
  const { t } = useTranslation()
  const { localizations } = useLocalizations()
  const { basePath } = useCustomRouter()
  const { elementId, directoryId } = useParams()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const navigate = useNavigate()
  const { getSectorsByDirectory } = useDirectories()
  const {
    isLoaded,
    element,
    addElementInfoTab,
    editElementInfoTab,
    editElementTranslationInfoTab,
    uploadImageElement,
    fetchElementInfoTab,
  } = useElements()

  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [sectors, setSectors] = useState<SectorDirectory[]>([])

  const INITIAL_VALUES = useMemo(() => {
    return {
      directoryId,
      isHighLight: false,
      infoSection: {
        type: MultimediaType.Image,
      },
      language:
        (localizations || []).find((item: any) => item.isDefault)?.isoCode ||
        DEFAULT_LANG,
    }
  }, [localizations])

  const methods = useForm<ElementInfoTabForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: INITIAL_VALUES,
  })
  const {
    control,
    formState,
    watch,
    reset,
    setValue,
    setError,
    formState: { dirtyFields },
  } = methods

  const watchSectionMediaType = watch('infoSection.type')
  const watchSectors = watch('sectorIds')
  const watchLang = watch('language')
  const watchType = watch('infoSection.type')

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  useBlockRouteChangeWithDialog(isDirtyWithoutLanguage && !isSubmitting)

  const sectorsOptions = useMemo(() => {
    if (!sectors) return []
    const options = sectors.map((sector) => {
      return {
        value: sector.id,
        label: sector.name,
      }
    })
    return options
  }, [sectors])

  const channelsOptions = useMemo(() => {
    let channels: { value: string; label: string }[] = []
    if (watchSectors && sectors) {
      sectors
        .filter((sector) => watchSectors.includes(sector.id))
        .map((sector) => {
          const options = sector.channels.map((channel) => {
            return {
              value: channel.id,
              label: channel.name,
            }
          })
          channels = [...channels, ...options]
        })
    }
    return channels
  }, [watchSectors])

  const isTranslating = useMemo(() => {
    if (watchLang) {
      return (
        watchLang !==
        ((localizations || []).find((item) => item.isDefault)?.isoCode ||
          DEFAULT_LANG)
      )
    }
    return false
  }, [watchLang, localizations])

  const handleCancel = () => {
    navigate(`../${directoryId}/manage`)
  }

  const onSubmit = async (values: ElementInfoTabForm) => {
    setIsSubmitting(true)

    const action = !elementId
      ? 'create'
      : isTranslating
      ? 'translation'
      : 'edit'

    try {
      const dataForm = { ...values }
      if (dataForm.imageUrl.includes('blob:')) {
        const responseImage = await uploadImageElement(dataForm.imageUrl)
        responseImage && (dataForm.imageUrl = responseImage)
      }
      if (
        dataForm.infoSection.multimediaUrl &&
        dataForm.infoSection.multimediaUrl.includes('blob:')
      ) {
        const responseImage = await uploadImageElement(
          dataForm.infoSection.multimediaUrl
        )
        responseImage && (dataForm.infoSection.multimediaUrl = responseImage)
      }
      if (!elementId) {
        const response = await addElementInfoTab(dataForm)
        setIsSubmitting(false)
        navigate(`${basePath}/directories/${directoryId}/elements/${response}`)
      } else {
        if (isTranslating) {
          const {
            name,
            thematic,
            description,
            infoSection: { title, description: infoSectionDescription },
            language,
          } = dataForm
          await editElementTranslationInfoTab(
            {
              name,
              thematic,
              description,
              infoSection: {
                title,
                description: infoSectionDescription,
              },
              language,
            },
            elementId
          )
        } else {
          await editElementInfoTab(dataForm, elementId)
        }
        setIsSubmitting(false)
      }
      notify.success(t(`directories.formElement.infoTab.feedback.${action}.ok`))
    } catch (error: unknown) {
      setIsSubmitting(false)
      notify.error(t(`directories.formElement.infoTab.feedback.${action}.ko`))
    }
  }

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const fetchSectorsWithChannels = async () => {
    if (directoryId) {
      try {
        const response = await getSectorsByDirectory(directoryId)
        response && setSectors(response.sectors)
      } catch (error) {}
    }
  }

  useEffect(() => {
    fetchSectorsWithChannels()
  }, [directoryId])

  useEffect(() => {
    if (elementId) {
      fetchElementInfoTab(elementId)
    }
  }, [elementId])

  useEffect(() => {
    if (element?.infoElement) {
      const i18nData = element?.infoElement.translations.find(
        (language) => language.language === watchLang
      ) ?? {
        description: '',
        name: '',
        thematic: '',
        informativeSectionDescription: '',
        informativeSectionTitle: '',
      }

      const {
        directoryId,
        isHighLight,
        sectorIds,
        channelIds,
        imageUrl,
        url,
        informativeSectionType,
        informativeSectionMultimediaUrl,
      } = element.infoElement

      reset({
        language: watchLang,
        directoryId,
        isHighLight,
        name: i18nData.name,
        sectorIds,
        channelIds,
        thematic: i18nData.thematic,
        description: i18nData.description === '' ? '' : i18nData.description,
        imageUrl,
        url,
        infoSection: {
          title: i18nData.informativeSectionTitle,
          description: i18nData.informativeSectionDescription,
          type: informativeSectionType,
          multimediaUrl: informativeSectionMultimediaUrl,
        },
      })
    }
  }, [element?.infoElement, watchLang])

  useEffect(() => {
    if (dirtyFields.infoSection?.type) {
      setValue('infoSection.multimediaUrl', '', { shouldDirty: true })
    }
  }, [watchType])

  useEffect(() => {
    if (!elementId) {
      reset(INITIAL_VALUES)
    }
  }, [])

  return (
    <>
      <FormProvider methods={methods}>
        {element && element.infoElement && elementId && (
          <TranslationCard
            translations={element?.infoElement.translations}
            lang={watchLang}
            entity={t('directories.formElement.entity')}
          />
        )}
        <div className="row">
          <div className="col">
            <p className="h4">
              {t('directories.formElement.infoTab.data.title')}
            </p>
            <p>{t('directories.formElement.infoTab.data.subtitle')}</p>
          </div>
          <div className="col-auto">
            <span className="lc-main__required">{t('common.required')}</span>
          </div>
        </div>
        {!isTranslating && (
          <FormToggle
            label={t('directories.formElement.infoTab.isHighlighted')}
            control={control}
            name="isHighLight"
          />
        )}
        <FormTextInput
          control={control}
          name="name"
          label={t('directories.formElement.infoTab.name')}
          placeholder={t('directories.formElement.infoTab.name.placeholder')}
          required
        />
        {!isTranslating && (
          <div className="row">
            <div className="col-6">
              <FormMultiSelect
                control={control}
                label={t('directories.formElement.infoTab.sectorIds')}
                placeholder={t(
                  'directories.formElement.infoTab.sectorIds.placeholder'
                )}
                noOptionsMessage={t('select.empty')}
                name="sectorIds"
                options={sectorsOptions}
                required
              />
            </div>
            <div className="col-6">
              <FormMultiSelect
                control={control}
                label={t('directories.formElement.infoTab.channelsIds')}
                placeholder={t(
                  'directories.formElement.infoTab.channelsIds.placeholder'
                )}
                name="channelIds"
                noOptionsMessage={t('select.empty')}
                options={channelsOptions}
                required
              />
            </div>
          </div>
        )}
        <FormTextInput
          control={control}
          name="thematic"
          label={t('directories.formElement.infoTab.thematic')}
          placeholder={t(
            'directories.formElement.infoTab.thematic.placeholder'
          )}
          required
        />
        <span className="leyend-field">
          {t('validations.leyendField.max', { number: 60 })}
        </span>
        <p className="h4">
          {t('directories.formElement.infoTab.specificInfo.title')}
        </p>

        <FormTextarea
          control={control}
          name="description"
          label={t('directories.formElement.infoTab.elementDescription')}
          placeholder={t(
            'directories.formElement.infoTab.elementDescription.placeholder'
          )}
          required
          rows={3}
        />

        <span className="leyend-field">
          {t('validations.leyendField.max', { number: 300 })}
        </span>
        {!isTranslating && (
          <UploadInput
            control={control}
            title={t('directories.formElement.infoTab.elementImageUrl')}
            name="imageUrl"
            accept={accept}
            onRemoveFile={() => setValue('imageUrl', '')}
            selectFilesError={(error) =>
              setError('imageUrl', {
                message: error.message,
              })
            }
            labels={labels}
            rules={{
              image: {
                maxWidth: 640,
                maxHeight: 360,
              },
            }}
            required
          />
        )}
        {!isTranslating && (
          <FormTextInput
            control={control}
            name="url"
            label={t('directories.formElement.infoTab.elementUrl')}
            placeholder={t(
              'directories.formElement.infoTab.elementUrl.placeholder'
            )}
          />
        )}
        <p className="h4">
          {t('directories.formElement.infoTab.directorySectionInfo.title')}
        </p>
        <p>
          {t('directories.formElement.infoTab.directorySectionInfo.subtitle')}
        </p>
        <FormTextInput
          control={control}
          name="infoSection.title"
          label={t('directories.formElement.infoTab.sectionTitle')}
          placeholder={t(
            'directories.formElement.infoTab.sectionTitle.placeholder'
          )}
          required
        />
        <FormRichText
          control={control}
          name="infoSection.description"
          label={t('directories.formElement.infoTab.sectionDescription')}
          placeholder={t(
            'directories.formElement.infoTab.sectionDescription.placeholder'
          )}
          key={`infoSection-description-${watchLang}`}
          required
          helperText={characterCounterText(
            watch('infoSection.description') ?? '',
            2000,
            t
          )}
        />
        {!elementId && (
          <InfoBlock
            label={t(
              'directories.formElement.infoTab.sectionAttachmentType.info'
            )}
          />
        )}
        {!isTranslating && (
          <>
            <div className="form-radio-container">
              <FormRadio
                control={control as any}
                name="infoSection.type"
                options={[
                  {
                    value: MultimediaType.Image,
                    label: t(`multimediaType.${MultimediaType.Image}`),
                  },
                  {
                    value: MultimediaType.Video,
                    label: t(`multimediaType.${MultimediaType.Video}`),
                  },
                ]}
              />
            </div>
            {watchSectionMediaType === MultimediaType.Image && (
              <UploadInput
                key={watchSectionMediaType}
                control={control}
                title={t('directories.formElement.infoTab.sectionImageUrl')}
                name="infoSection.multimediaUrl"
                accept={accept}
                onRemoveFile={() => setValue('infoSection.multimediaUrl', '')}
                selectFilesError={(error) =>
                  setError('infoSection.multimediaUrl', {
                    message: error.message,
                  })
                }
                labels={labels}
                rules={{
                  image: {
                    maxWidth: 640,
                    maxHeight: 360,
                  },
                }}
              />
            )}
            {watchSectionMediaType === MultimediaType.Video && (
              <FormTextInput
                key={watchSectionMediaType}
                control={control}
                name="infoSection.multimediaUrl"
                label={t('directories.formElement.infoTab.sectionVideoUrl')}
                placeholder={t(
                  'directories.formElement.infoTab.sectionVideoUrl.placeholder'
                )}
              />
            )}
          </>
        )}

        <FormActionsLang
          selectName="language"
          selectDisabled={!elementId || !element}
          isLoaded={isLoaded}
          methods={methods}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          onCancel={handleCancel}
          onBeforeChange={handleBeforeChangeLang}
        />
      </FormProvider>
    </>
  )
}

export default FormElementInfoTab
