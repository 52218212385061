import { FC } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import BrandListScreen from '../pages/Brand/BrandList'
import BrandEditScreen from '../pages/Brand'
import SectionsRouter from './SectionsRouter'
import EditBadge from '../pages/Brand/Badges/EditBadge'
import ManageBadge from '../pages/Brand/Badges/Manage'

const BrandsRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<BrandListScreen />} />
      <Route path="new" element={<BrandEditScreen isNew />} />
      <Route path=":brandId" element={<Navigate to="edit" />} />
      <Route path=":brandId/edit" element={<BrandEditScreen />} />
      <Route path=":brandId/edit/sections/*" element={<SectionsRouter />} />
      <Route path="/:brandId/edit/badges" element={<EditBadge />} />
      <Route path="/:brandId/edit/badges/:badgeId" element={<EditBadge />} />
      <Route
        path="/:brandId/edit/badges/:badgeId/manage"
        element={<ManageBadge />}
      />
    </Routes>
  )
}

export default BrandsRoutes
