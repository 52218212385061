import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import {
  DirectoriesState,
  DirectoriesListResponse,
  DirectoryDetail,
} from './types'

const initialState: DirectoriesState = {
  detail: null,
  list: null,
  isLoaded: false,
}

export const directoriesSlice = createSlice({
  name: 'directories',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setDirectoriesList: (
      state,
      action: PayloadAction<DirectoriesListResponse>
    ) => {
      state.list = action.payload
    },
    setDetailDirectory: (state, action: PayloadAction<DirectoryDetail>) => {
      state.detail = action.payload
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setDirectoriesList,
  setDetailDirectory,
} = directoriesSlice.actions

export const selectBadge = (state: RootState) => state.badges

export default directoriesSlice.reducer
