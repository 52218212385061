import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldValues, UseFormReturn } from 'react-hook-form'

import { Icon } from '@liveconnect/icons'
import { FormSelect, Loader, Tooltip } from '@liveconnect/components'

import useLocalizations from '../../../core/localizations/useLocalizations'
import FormActions from '../FormActions'
import { DEFAULT_LANG } from '../../../i18n/config'

import './styles.scss'

interface FormActionsLangProps<Z extends FieldValues> {
  selectDisabled: boolean
  isLoaded: boolean
  isSubmitting: boolean
  methods: UseFormReturn<Z>
  selectName?: string
  hideSelector?: boolean
  onCancel: () => void
  onSubmit: (values: Z) => void
  onBeforeChange: () => Promise<boolean>
}

export const FormActionsLang = <T extends FieldValues>({
  selectDisabled,
  isLoaded,
  isSubmitting,
  methods,
  selectName = 'language',
  hideSelector,
  onCancel,
  onSubmit,
  onBeforeChange,
}: FormActionsLangProps<T>) => {
  const { t } = useTranslation()
  const { localizations } = useLocalizations()
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = methods

  const localizationsOptions = useMemo(() => {
    return localizations.map((item) => ({
      label: `${t(`localizations.${item.isoCode}`)} ${
        item.isoCode == DEFAULT_LANG
          ? `(${t(`common.changeLanguage.defaultLanguage`)})`
          : ''
      } `,
      value: item.isoCode,
    }))
  }, [localizations])

  const renderLanguageSelector = () => {
    if (hideSelector) return <></>
    return (
      <>
        <FormSelect
          control={control}
          name={selectName}
          label=""
          options={localizationsOptions}
          disabled={selectDisabled || !isLoaded}
          noOptionsMessage={t('select.empty')}
          menuPlacement="top"
          isClearable={false}
          onBeforeChange={onBeforeChange}
        />
        <Tooltip content={t('common.changeLanguage.tooltip')} className="ms-2">
          <Icon name="info_outline" tabIndex={0} />
        </Tooltip>
      </>
    )
  }

  return (
    <div className="form-actions-lang mt-5">
      <FormActions languageSelector={renderLanguageSelector()}>
        <button
          className="btn btn-outline-primary"
          disabled={isSubmitting}
          onClick={onCancel}
        >
          {t('common.cancel')}
        </button>
        <button
          className="btn btn-primary"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          {isSubmitting ? <Loader /> : t('common.save')}
        </button>
      </FormActions>
    </div>
  )
}
