import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  DragDropContext,
  Droppable,
  DropResult,
  DroppableProvided,
  Draggable,
  DraggableProvided,
} from 'react-beautiful-dnd'

import { Section } from '../../../core/sections/types'
import SectionComponent from './Section'
import useSections from '../../../core/sections/useSections'
import useNotifications from '../../../utils/notifications/useNotifications'
import EmptyState from '../../../components/EmptyState'

import './styles.scss'

interface SponsorsListProps {
  brandId?: string
}

const SponsorsList: FC<SponsorsListProps> = ({ brandId }) => {
  const { sections, fetchSections, updateSectionOrder, isLoaded } =
    useSections()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notify = useNotifications()

  const editSections = () => {
    navigate('sections/edit')
  }

  const createSections = () => {
    navigate('sections')
  }

  const brandHasSections = (): boolean => {
    if (typeof sections === 'undefined') return false
    return sections.length > 0
  }

  const handleReorderSections = async (result: DropResult) => {
    if (!result.destination || !brandId) return
    const section = sections.find((s) => s.id === result.draggableId)
    if (!section) return

    try {
      await updateSectionOrder({
        brandId,
        sectionId: section.id,
        index: result.destination.index,
      })
      notify.success(t('brand.sponsors.reorder.feedback.ok'))
    } catch (e) {
      notify.error(t('brand.sponsors.reorder.feedback.ko'))
    }
  }

  useEffect(() => {
    if (brandId) fetchSections(brandId)
  }, [])

  return (
    <div className="Sponsors">
      <div className="Sponsors__buttons">
        {brandHasSections() && (
          <button
            className="btn btn-outline-primary edit-sections"
            onClick={editSections}
          >
            {t('brand.sponsors.edit-sections')}
          </button>
        )}
        <button
          className="btn btn-primary create-sections"
          onClick={createSections}
        >
          {t('brand.sponsors.create-sections')}
        </button>
      </div>
      <div className="Sponsors__sections">
        {isLoaded && sections.length === 0 && (
          <EmptyState text={t('brand.sponsors.empty')} />
        )}
        {brandId && sections && (
          <DragDropContext onDragEnd={handleReorderSections}>
            <Droppable droppableId="sections">
              {(provided: DroppableProvided) => (
                <div
                  className="Sponsors__sections__droppable"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {sections.map((section: Section, index) => (
                    <Draggable
                      key={section.id}
                      draggableId={section.id}
                      index={index}
                    >
                      {(provided: DraggableProvided) => (
                        <SectionComponent
                          brandId={brandId}
                          section={section}
                          key={section.id}
                          provided={provided}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </div>
  )
}

export default SponsorsList
