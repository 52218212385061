import { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import {
  FormRadio,
  FormRichText,
  FormTextInput,
  FormToggle,
  FormProvider,
  Loader,
  UploadInput,
  MimeType,
} from '@liveconnect/components'

import {
  ElementInformativeForm,
  ElementInfoSectionForm,
} from '../../../../core/elements/types'
import { MultimediaType } from '../../../../core/shared/types'
import { buildValidationSchema } from './validations'

import useElements from '../../../../core/elements/useElements'
import useLocalizations from '../../../../core/localizations/useLocalizations'
import { useCustomRouter } from '../../../../utils/extractParams'
import useNotifications from '../../../../utils/notifications/useNotifications'
import { useBlockRouteChangeWithDialog } from '../../../../utils/routing/useBlockRouteChange'
import useUi from '../../../../core/ui/useUi'
import { characterCounterText } from '../../../../utils/characterCounterText'

import { FormActionsLang } from '../../../../components/forms/FormActionsLang'
import TranslationCard from '../../../../components/TranslationCard'

import { DEFAULT_LANG } from '../../../../i18n/config'
import useUploadInput from '../../../../utils/uploadInput/useUploadInput'

const FormElementInfoSectionTab = () => {
  const { t } = useTranslation()
  const { localizations } = useLocalizations()
  const { basePath } = useCustomRouter()
  const { elementId, directoryId } = useParams()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const navigate = useNavigate()
  const {
    isLoaded,
    element,
    fetchElementInformativeTab,
    addElementInformativeTab,
    editElementInformativeTab,
    uploadImageElement,
    editElementTranslationInformativeTab,
  } = useElements()

  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const INITIAL_VALUES = useMemo(() => {
    return {
      type: MultimediaType.Image,
      isVisible: false,
      language:
        (localizations || []).find((item) => item.isDefault)?.isoCode ||
        DEFAULT_LANG,
    }
  }, [localizations])

  const methods = useForm<ElementInfoSectionForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: INITIAL_VALUES,
  })

  const {
    control,
    formState,
    watch,
    getValues,
    reset,
    setValue,
    setError,
    formState: { dirtyFields },
  } = methods

  const watchSectionMediaType = watch('type')
  const watchLang = watch('language')

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  useBlockRouteChangeWithDialog(isDirtyWithoutLanguage && !isSubmitting)

  const isTranslating = useMemo(() => {
    if (watchLang) {
      return (
        watchLang !==
        ((localizations || []).find((item) => item.isDefault)?.isoCode ||
          DEFAULT_LANG)
      )
    }
    return false
  }, [watchLang, localizations])

  const detailToForm = useMemo<ElementInformativeForm | object>(() => {
    if (element?.infoSection) {
      const i18nData = element?.infoSection?.translations.find(
        (language) => language.language === watchLang
      )
      const { isVisible, type, multimediaUrl } = element.infoSection

      const form: ElementInfoSectionForm = {
        ...getValues(),
        isVisible,
        type,
        title: i18nData?.name ?? '',
        multimediaUrl,
        description: i18nData?.description ?? '',
      }

      return form
    }
    return {}
  }, [element?.infoSection, watchLang])

  const handleCancel = () => {
    navigate(`${basePath}/directories/${directoryId}/manage`)
  }

  const onSubmit = async (values: ElementInfoSectionForm) => {
    setIsSubmitting(true)

    const action = !elementId
      ? 'create'
      : isTranslating
      ? 'translation'
      : 'edit'

    try {
      const dataForm = { ...values }

      if (
        dataForm.type === MultimediaType.Image &&
        dataForm.multimediaUrl &&
        dataForm.multimediaUrl.includes('blob:')
      ) {
        const responseImage = await uploadImageElement(dataForm.multimediaUrl)
        responseImage && (dataForm.multimediaUrl = responseImage)
      }

      if (!element?.infoElement?.hasInformativeSection) {
        await addElementInformativeTab({
          elementId: elementId!,
          isVisible: dataForm.isVisible!,
          type: dataForm.type,
          multimediaUrl: dataForm.multimediaUrl,
          translations: [
            {
              language: dataForm.language!,
              name: dataForm.title!,
              description: dataForm.description!,
            },
          ],
        })
        await fetchElementInformativeTab(elementId!)
        setIsSubmitting(false)
      } else {
        if (isTranslating) {
          const { title, description, language } = dataForm
          await editElementTranslationInformativeTab(
            {
              language: language!,
              name: title!,
              description: description!,
            },
            elementId!
          )
        } else {
          await editElementInformativeTab(
            {
              isVisible: dataForm.isVisible!,
              type: dataForm.type,
              elementId: elementId!,
              multimediaUrl: dataForm.multimediaUrl,
              translations: [
                {
                  language: dataForm.language!,
                  description: dataForm.description!,
                  name: dataForm.title!,
                },
              ],
            },
            elementId!
          )
        }
        await fetchElementInformativeTab(elementId!)
        setIsSubmitting(false)
      }
      notify.success(t(`directories.formElement.infoTab.feedback.${action}.ok`))
    } catch (error: unknown) {
      setIsSubmitting(false)
      notify.error(t(`directories.formElement.infoTab.feedback.${action}.ko`))
    }
  }

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  useEffect(() => {
    if (detailToForm) {
      reset({ ...detailToForm })
    }
  }, [detailToForm])

  useEffect(() => {
    if (!element?.infoSection) {
      reset(INITIAL_VALUES)
    }
  }, [element?.infoSection])

  useEffect(() => {
    /* getInformativeTab() */
    elementId && fetchElementInformativeTab(elementId)
  }, [elementId])

  if (!isLoaded) return <Loader />

  return (
    <div>
      <FormProvider methods={methods}>
        {element &&
          element.infoElement?.hasInformativeSection &&
          element.infoSection && (
            <TranslationCard
              translations={element?.infoSection.translations}
              lang={watchLang ?? DEFAULT_LANG}
              entity={t('directories.formElement.InfoSection.entity')}
            />
          )}
        {!isTranslating && (
          <FormToggle
            label={t('directories.formElement.infoSectionTab.isVisible')}
            control={control}
            name="isVisible"
          />
        )}
        <p className="h4">
          {t('directories.formElement.infoSectionTab.title')}
        </p>
        <p>{t('directories.formElement.infoSectionTab.subtitle')}</p>
        <FormTextInput
          control={control}
          name="title"
          label={t('directories.formElement.infoTab.sectionTitle')}
          placeholder={t(
            'directories.formElement.infoTab.sectionTitle.placeholder'
          )}
          required
        />
        <FormRichText
          control={control}
          name="description"
          label={t('directories.formElement.infoTab.sectionDescription')}
          placeholder={t(
            'directories.formElement.infoTab.sectionDescription.placeholder'
          )}
          key={`description-${watchLang}`}
          required
          helperText={characterCounterText(watch('description') ?? '', 2000, t)}
        />
        {!isTranslating && (
          <>
            <div className="form-radio-container">
              <FormRadio
                control={control as any}
                name="type"
                options={[
                  { value: MultimediaType.Image, label: 'Imagen' },
                  { value: MultimediaType.Video, label: 'Video' },
                ]}
              />
            </div>
            {watchSectionMediaType === MultimediaType.Image && (
              <UploadInput
                control={control}
                title={t('directories.formElement.infoTab.sectionImageUrl')}
                name="multimediaUrl"
                accept={accept}
                onRemoveFile={() => setValue('multimediaUrl', '')}
                selectFilesError={(error) =>
                  setError('multimediaUrl', {
                    message: error.message,
                  })
                }
                required
                rules={{
                  image: {
                    maxWidth: 640,
                    maxHeight: 360,
                  },
                }}
                labels={labels}
              />
            )}
            {watchSectionMediaType === MultimediaType.Video && (
              <FormTextInput
                control={control}
                name="multimediaUrl"
                label={t('directories.formElement.infoTab.sectionVideoUrl')}
                placeholder={t(
                  'directories.formElement.infoTab.sectionVideoUrl.placeholder'
                )}
              />
            )}
          </>
        )}

        <FormActionsLang
          selectName="language"
          selectDisabled={!element?.infoSection}
          isLoaded={isLoaded}
          methods={methods}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          onCancel={handleCancel}
          onBeforeChange={handleBeforeChangeLang}
        />
      </FormProvider>
    </div>
  )
}

export default FormElementInfoSectionTab
