import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { FormRadio } from '@liveconnect/components'

import { Main } from '../../../components/Main'
import Heading from '../../../components/Heading'
import CustomForm from '../../../components/CustomForm'
import useNotifications from '../../../utils/notifications/useNotifications'
import { useLocalizations } from '../../../core'
import useMe from '../../../core/me/useMe'
import { DEFAULT_LANG } from '../../../i18n/config'
import { ChangeLocalizationForm } from '../../../core/me/types'

export const Language = () => {
  const { t } = useTranslation()
  const { localizations, fetchLocalizations } = useLocalizations()
  const notify = useNotifications()
  const { me, changeLocalization } = useMe()

  const methods = useForm<ChangeLocalizationForm>({
    mode: 'onChange',
    defaultValues: { isoCode: me?.isoCode || DEFAULT_LANG },
  })

  const { control, reset, handleSubmit } = methods

  const onSubmit = async (values: ChangeLocalizationForm) => {
    try {
      await changeLocalization(values.isoCode)
      setTimeout(() => {
        notify.success(t('me.language.succeessFeedback'))
      }, 10)
      reset()
    } catch (e) {
      notify.error(t('me.language.unknownError'))
    }
  }

  const radioOptions = useMemo(() => {
    if (!localizations) return []
    return localizations.map((loc) => ({ value: loc.isoCode, label: loc.name }))
  }, [localizations])

  useEffect(() => {
    fetchLocalizations()
  }, [])

  useEffect(() => {
    me?.isoCode && reset({ isoCode: me?.isoCode })
  }, [me?.isoCode])

  return (
    <Main ariaLabelledby="me-language">
      <Heading subheading={t('me.language.subtitle')}>
        {t('me.language.title')}
      </Heading>
      <CustomForm
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={reset}
        isSelectDisabled={false}
        hideSelector={true}
      >
        <FormRadio control={control} name="isoCode" options={radioOptions} />
      </CustomForm>
    </Main>
  )
}
