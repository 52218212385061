import { Icon } from '@liveconnect/icons'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SupportedLanguages } from '../../core/sections/types'
import { Translation } from '../../core/shared/types'
import { DEFAULT_LANG } from '../../i18n/config'
/* import InfoBlock from '../InfoBlock' */
import TranslationChips from '../TranslationsChips'

import './styles.scss'

interface TranslationCardProps {
  translations: Translation[][] | Translation[]
  lang: string
  entity: string
}

const TranslationCard: FC<TranslationCardProps> = ({
  translations,
  lang,
  entity,
}) => {
  const { t } = useTranslation()

  const isMultiple = () => {
    if (translations && translations.length > 0) {
      return !('language' in translations[0])
    }
    return false
  }

  const multipleEntities = useMemo(() => {
    if (isMultiple()) {
      return translations.length
    }
    return 1
  }, [translations])

  const mergedTranslations = useMemo(() => {
    if (isMultiple()) {
      const _mergedTranslations: Translation[] = []
      ;(translations as Translation[][]).map((transArray: Translation[]) => {
        transArray.map((trans) => {
          _mergedTranslations.push(trans)
        })
      })
      return _mergedTranslations
    }
    return translations as Translation[]
  }, [translations])

  const isSomeTranslationPending = useMemo(() => {
    return Object.values(SupportedLanguages).some((localization) => {
      return (
        mergedTranslations.filter((trans) => trans.language === localization)
          .length !== multipleEntities
      )
    })
  }, [mergedTranslations])

  if (!translations) {
    return <></>
  }

  const validatePending = () => {
    if (lang === DEFAULT_LANG) {
      return t('translate.component.pending', { entity })
    }
    return (
      <>
        <span className="withBold">
          {t('translate.component.pending.lang', {
            lang: t(`localizations.${lang}`),
          })}
        </span>{' '}
        {t('translate.component.pending', { entity })}
      </>
    )
  }

  return (
    <div className="TranslationCard">
      <Icon className="TranslationCard__icon" name="info_outline" />
      <p>
        {isSomeTranslationPending
          ? validatePending()
          : t('translate.component.complete')}
      </p>
      <TranslationChips
        translations={mergedTranslations}
        multipleEntities={multipleEntities}
        showDefault
      />
    </div>
  )
}

export default TranslationCard
