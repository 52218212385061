import { FC, useEffect, useState } from 'react'

import { Pagination, Loader } from '@liveconnect/components'

import { Main } from '../../components/Main'
import { ActivitiesListFilters } from './List/Filters'
import { ActivitiesListItem } from './List/Item'
import { TabsNav } from '../../components/Tabs/TabsNav'
import { TabLink } from '../../components/Tabs/types'
import HeadLineWithoutButton from '../../components/Headline/WithoutButton'
import useActivities from '../../core/activities/useActivities'

import { useTranslation } from 'react-i18next'
import EmptyState from '../../components/EmptyState'
import { useNavigate } from 'react-router-dom'
import { ActivityListParams } from '../../core/activities/types'
import usePermissions from '../../core/permissions/usePermissions'

import './styles.scss'

const ActivitiesListScreen: FC = () => {
  const [activeTab, setActiveTab] = useState<string>('current')
  const { activities, fetchActivities, isLoaded } = useActivities()
  const { verifySomeActivitiesEditor } = usePermissions()
  const navigate = useNavigate()
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [listParams, setListParams] = useState<ActivityListParams>({
    page: 1,
    page_size: 5,
    is_historical: activeTab == 'history',
  })

  const { t } = useTranslation()

  const tabs: TabLink[] = [
    {
      id: 'current',
      name: t('activities.currentActivities'),
    },
    {
      id: 'history',
      name: t('activities.activityHistory'),
    },
  ]

  const getActivities = (params: ActivityListParams) => {
    setListParams(params)
  }

  const onDelete = () => {
    fetchActivities(listParams)
  }

  const handleNewActivity = () => {
    navigate('create')
  }

  useEffect(() => {
    fetchActivities(listParams)
    if (isFirstLoad) setIsFirstLoad(false)
  }, [listParams])

  return (
    <Main ariaLabelledby="activities" className="activities">
      <div className="row mb-4 align-items-end">
        <HeadLineWithoutButton
          title={t('activities.title')}
          text={t('activities.subtitle')}
        />
        {activities?.items.length == 0 && isFirstLoad && (
          <div className="col-lg-auto">
            <button
              className="btn btn-primary"
              onClick={handleNewActivity}
              disabled={!verifySomeActivitiesEditor()}
            >
              {t('activities.addActivity')}
            </button>
          </div>
        )}
      </div>

      {activities?.items.length == 0 && isFirstLoad && (
        <EmptyState text={t('activities.noActivities')} />
      )}

      <div className="row">
        <div className="col-lg">
          <TabsNav tabs={tabs} active={activeTab} setActive={setActiveTab} />
        </div>
        <div className="col-lg-auto">
          <button
            className="btn btn-primary"
            onClick={handleNewActivity}
            disabled={!verifySomeActivitiesEditor()}
          >
            {t('activities.addActivity')}
          </button>
        </div>
      </div>

      <ActivitiesListFilters
        handler={getActivities}
        isHistorical={activeTab == 'history'}
      />

      {!isLoaded && <Loader />}

      {isLoaded && activities?.items.length == 0 && (
        <EmptyState text={t('activities.noActivitiesFiltered')} />
      )}

      {isLoaded && activities?.items && activities.items.length > 0 && (
        <>
          <div className="activities-grid mb-5">
            {activities?.items.map((activity) => (
              <ActivitiesListItem
                key={activity.id}
                activity={activity}
                isHistorical={activeTab == 'history'}
                onDelete={onDelete}
              />
            ))}
          </div>
          <Pagination
            currentPage={activities.page - 1}
            totalPages={activities.availablePages}
            totalItems={activities.availableItems}
            pageSize={activities.pageSize}
            singlePageText={(count) => t('common.pager.singlePage', { count })}
            multiplePageText={(start, end, total) =>
              t('common.pager.multiplePage', { start, end, total })
            }
            onChange={(pageIndex) =>
              getActivities({
                ...listParams,
                page: pageIndex + 1,
              })
            }
          />
        </>
      )}
    </Main>
  )
}

export default ActivitiesListScreen
