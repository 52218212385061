import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Loader } from '@liveconnect/communities-ui'
import { DropResult } from 'react-beautiful-dnd'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import InfoBlock from '../../../../components/InfoBlock'
import TableElements from './TableElements'
import TableDragElements from './TableDragElements'
import useElements from '../../../../core/elements/useElements'
import {
  ElementsHighParams,
  ElementsPublishParams,
  ElementsRequestParams,
} from '../../../../core/elements/types'
import useNotifications from '../../../../utils/notifications/useNotifications'

import './styles.scss'

export enum ManageDirectoryTabs {
  Highlighted = 'Highlighted',
  No_Highlighted = 'No_Highlighted',
}

const ElementsList = () => {
  const { t } = useTranslation()
  const { directoryId } = useParams()
  const notify = useNotifications()
  const {
    fetchElements,
    fetchDragElements,
    listNoHigh,
    listHigh,
    deleteElement,
    publishElement,
    highlightElement,
    orderElement,
  } = useElements()

  //FUNCTIONS OF NO HIGHLIGHTED

  const deleteNoHighlighted = async (idParam: string) => {
    try {
      await deleteElement(idParam)
      notify.success(t('directories.elementsList.delete.ok'))
      directoryId &&
        (await fetchElements({
          page: 1,
          page_size: 20,
          directoryId: directoryId,
        }))
    } catch (e) {
      notify.error(t('directories.elementsList.delete.ko'))
    }
  }
  const publishNoHighlighted = async (params: ElementsPublishParams) => {
    try {
      await publishElement(params.id, params.isPublished)
      if (params.isPublished) {
        notify.success(t('directories.elementsList.publish.ok'))
      } else {
        notify.success(t('directories.elementsList.unpublish.ok'))
      }

      directoryId &&
        (await fetchElements({
          page: 1,
          page_size: 20,
          directoryId: directoryId,
        }))
    } catch (e) {
      if (params.isPublished) {
        notify.error(t('directories.elementsList.publish.ko'))
      } else {
        notify.error(t('directories.elementsList.unpublish.ko'))
      }
    }
  }
  const searchNoHighlighted = async (param: string) => {
    if (directoryId) {
      await fetchElements({
        page: 1,
        page_size: 20,
        directoryId: directoryId,
        search: param,
      })
    }
  }

  const highlightedConfirm = async (params: ElementsHighParams) => {
    try {
      await highlightElement(params.id, params.isHighlight)
      notify.success(t('directories.elementsList.highlight.ok'))
      if (directoryId) {
        await fetchElements({
          page: 1,
          page_size: 20,
          directoryId: directoryId,
        })
        await fetchDragElements({ directoryId: directoryId })
      }
    } catch (e) {
      notify.error(t('directories.elementsList.highlight.ko'))
    }
  }

  const paginatorConfirm = async (params: ElementsRequestParams) => {
    try {
      if (directoryId) {
        await fetchElements({
          page: params.page,
          page_size: 20,
          directoryId: directoryId,
        })
      }
    } catch (e) {
      notify.error(t('common.unknownError'))
    }
  }

  //FUNCTIONS OF HIGHLIGHTED

  const deleteHighlighted = async (idParam: string) => {
    try {
      await deleteElement(idParam)
      notify.success(t('directories.elementsList.delete.ok'))
      directoryId && (await fetchDragElements({ directoryId: directoryId }))
    } catch (e) {
      notify.error(t('directories.elementsList.delete.ko'))
    }
  }

  const publishHighlighted = async (params: ElementsPublishParams) => {
    try {
      await publishElement(params.id, params.isPublished)
      if (params.isPublished) {
        notify.success(t('directories.elementsList.publish.ok'))
      } else {
        notify.success(t('directories.elementsList.unpublish.ok'))
      }
      directoryId && (await fetchDragElements({ directoryId: directoryId }))
    } catch (e) {
      if (params.isPublished) {
        notify.error(t('directories.elementsList.publish.ko'))
      } else {
        notify.error(t('directories.elementsList.unpublish.ko'))
      }
    }
  }
  const highlightedConfirmHigh = async (params: ElementsHighParams) => {
    try {
      await highlightElement(params.id, params.isHighlight)
      notify.success(t('directories.elementsList.highlight.ok'))
      if (directoryId) {
        await fetchDragElements({ directoryId: directoryId })
        await fetchElements({
          page: 1,
          page_size: 20,
          directoryId: directoryId,
        })
      }
    } catch (e) {
      notify.error(t('directories.elementsList.highlight.ko'))
    }
  }

  const dragHighlighted = async (params: DropResult) => {
    try {
      if (params.destination && params.draggableId && directoryId) {
        await orderElement(
          params.draggableId,
          params.destination.index + 1,
          directoryId
        )
        notify.success(t('directories.elementsList.drag.ok'))
      }
    } catch (e) {
      notify.error(t('directories.elementsList.drag.ko'))
    }
  }

  useEffect(() => {
    if (directoryId) {
      fetchElements({ page: 1, page_size: 20, directoryId: directoryId })
      fetchDragElements({ directoryId: directoryId })
    }
  }, [])

  return (
    <>
      <div className="row mb-4 ElementList">
        <div className="col">
          <InfoBlock
            className="mb-0"
            label={t('directories.elementsList.maxHighlightedItems', {
              number: 12,
            })}
          />
        </div>
      </div>
      {!listNoHigh || !listHigh ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="tabsTable">
            <Tabs id="tabsTable-elements">
              <Tab
                eventKey={ManageDirectoryTabs.Highlighted}
                title={t('directories.manage.tab.prominent')}
              >
                <TableDragElements
                  listElements={listHigh}
                  confirmDelete={(idParam: string) =>
                    deleteHighlighted(idParam)
                  }
                  confirmPublish={(params: ElementsPublishParams) =>
                    publishHighlighted(params)
                  }
                  confirmHighlighted={(params: ElementsHighParams) =>
                    highlightedConfirmHigh(params)
                  }
                  confirmDrag={(paramsDrop: DropResult) =>
                    dragHighlighted(paramsDrop)
                  }
                />
              </Tab>
              <Tab
                eventKey={ManageDirectoryTabs.No_Highlighted}
                title={t('directories.manage.tab.no.prominent')}
              >
                <TableElements
                  listElements={listNoHigh}
                  confirmDelete={(idParam: string) =>
                    deleteNoHighlighted(idParam)
                  }
                  confirmPublish={(params: ElementsPublishParams) =>
                    publishNoHighlighted(params)
                  }
                  confirmHighlighted={(params: ElementsHighParams) =>
                    highlightedConfirm(params)
                  }
                  confirmPaginator={(paramsPaginator: ElementsRequestParams) =>
                    paginatorConfirm(paramsPaginator)
                  }
                  confirmSearch={(paramSearch: string) =>
                    searchNoHighlighted(paramSearch)
                  }
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </>
  )
}

export default ElementsList
