import useFetch from '../../../utils/fetch/useFetch'
import { useAppDispatch, useAppSelector } from '../../reduxProvider'

import {
  hideLoader,
  showLoader,
  setGeneralList,
  setContactList,
  setSocialMediaDetail,
  setLegalList,
  setPageSectionDetail,
} from './store'

import {
  ConfigurationFooterContactInfoForm,
  ConfigurationFooterContactListResponse,
  ConfigurationFooterGeneralInfoForm,
  ConfigurationFooterGeneralListResponse,
  ConfigurationFooterLegalListResponse,
  ConfigurationFooterPageForm,
  ConfigurationFooterPageResponse,
  ConfigurationFooterSocialMediaForm,
  ConfigurationFooterSocialMediaResponse,
  UrlTypeFooterPage,
} from './types'

const useConfigurationFooter = () => {
  const dispatch = useAppDispatch()
  const {
    isLoaded,
    generalInfo,
    contactInfo,
    socialMediaInfo,
    legalInfo,
    pageSection,
  } = useAppSelector((state) => state.configurationFooter)
  const { get, post, put, del } = useFetch()

  enum ENDPOINTS {
    General = 'footer-general-info',
    Contact = 'footer-contact-info',
    Social = 'footer-social-media',
    Legal = 'footer-legal-info',
    Page = 'footer-section-page',
  }

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  // Page Section
  const fetchPageSectionDetail = async (id: string): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: ConfigurationFooterPageResponse | undefined = await get({
        endpoint: `${ENDPOINTS.Page}/${id}`,
      })
      if (response) dispatch(setPageSectionDetail(response))
    })
  }

  const createPageSection = async (data: ConfigurationFooterPageForm) => {
    const response: string | undefined = await post({
      endpoint: ENDPOINTS.Page,
      body: data,
    })
    return response
  }

  const updatePageSection = async (
    id: string,
    data: ConfigurationFooterPageForm
  ): Promise<void> => {
    await put({
      endpoint: `${ENDPOINTS.Page}/${id}`,
      body: data,
    })
  }

  const deletePageSection = async (
    id: string,
    type: UrlTypeFooterPage
  ): Promise<void> => {
    await del({
      endpoint: ENDPOINTS.Page,
      id,
    })

    switch (type) {
      case UrlTypeFooterPage.GeneralInfo:
        dispatch(
          setGeneralList({
            ...generalInfo.list!,
            pages:
              generalInfo?.list?.pages.filter((page) => page.id !== id) ?? [],
          })
        )
        break
      case UrlTypeFooterPage.ContactInfo:
        dispatch(
          setContactList({
            ...contactInfo.list!,
            pages:
              contactInfo?.list?.pages.filter((page) => page.id !== id) ?? [],
          })
        )
        break
      case UrlTypeFooterPage.LegalInfo:
        dispatch(
          setLegalList({
            ...legalInfo.list!,
            pages:
              legalInfo?.list?.pages.filter((page) => page.id !== id) ?? [],
          })
        )
        break
    }
  }

  // General Info
  const fetchGeneralInfoList = async (): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: ConfigurationFooterGeneralListResponse | undefined =
        await get({
          endpoint: ENDPOINTS.General,
        })
      if (response) dispatch(setGeneralList(response))
    })
  }

  const createGeneralInfo = async (
    data: ConfigurationFooterGeneralInfoForm
  ) => {
    await post({
      endpoint: ENDPOINTS.General,
      body: data,
    })
  }

  const updateGeneralInfo = async (
    id: string,
    data: ConfigurationFooterGeneralInfoForm
  ): Promise<void> => {
    await put({
      endpoint: `${ENDPOINTS.General}/${id}`,
      body: data,
    })
  }

  const translateGeneralInfo = async (
    id: string,
    data: ConfigurationFooterGeneralInfoForm
  ): Promise<void> => {
    await put({
      endpoint: `${ENDPOINTS.General}/${id}/translation`,
      body: data,
    })
  }

  // Contact Info
  const fetchContactInfoList = async (): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: ConfigurationFooterContactListResponse | undefined =
        await get({
          endpoint: ENDPOINTS.Contact,
        })
      if (response) dispatch(setContactList(response))
    })
  }

  const createContactInfo = async (
    data: ConfigurationFooterContactInfoForm
  ) => {
    await post({
      endpoint: ENDPOINTS.Contact,
      body: data,
    })
  }

  const updateContactInfo = async (
    id: string,
    data: ConfigurationFooterContactInfoForm
  ): Promise<void> => {
    await put({
      endpoint: `${ENDPOINTS.Contact}/${id}`,
      body: data,
    })
  }

  const translateContactInfo = async (
    id: string,
    data: ConfigurationFooterContactInfoForm
  ): Promise<void> => {
    await put({
      endpoint: `${ENDPOINTS.Contact}/${id}/translation`,
      body: data,
    })
  }

  // Social Media
  const fetchSocialMediaInfo = async (): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: ConfigurationFooterSocialMediaResponse | undefined =
        await get({
          endpoint: ENDPOINTS.Social,
        })
      if (response) dispatch(setSocialMediaDetail(response))
    })
  }

  const createSocialMediaInfo = async (
    data: ConfigurationFooterSocialMediaForm
  ) => {
    const response: ConfigurationFooterSocialMediaResponse | undefined =
      await post({
        endpoint: ENDPOINTS.Social,
        body: data,
      })
    if (response) dispatch(setSocialMediaDetail(response))
  }

  const updateSocialMediaInfo = async (
    id: string,
    data: ConfigurationFooterSocialMediaForm
  ): Promise<void> => {
    const response: ConfigurationFooterSocialMediaResponse | undefined =
      await put({
        endpoint: `${ENDPOINTS.Social}/${id}`,
        body: data,
      })
    if (response) dispatch(setSocialMediaDetail(response))
  }

  // Legal Info
  const fetchLegalInfoList = async (): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: ConfigurationFooterLegalListResponse | undefined =
        await get({
          endpoint: ENDPOINTS.Legal,
        })
      if (response) dispatch(setLegalList(response))
    })
  }

  return {
    isLoaded,

    // Page Section
    pageSection,
    fetchPageSectionDetail,
    createPageSection,
    updatePageSection,
    deletePageSection,

    // General Info
    generalInfo,
    fetchGeneralInfoList,
    createGeneralInfo,
    updateGeneralInfo,
    translateGeneralInfo,

    // Contact Info
    contactInfo,
    fetchContactInfoList,
    createContactInfo,
    updateContactInfo,
    translateContactInfo,

    // Social Media Info
    socialMediaInfo,
    fetchSocialMediaInfo,
    createSocialMediaInfo,
    updateSocialMediaInfo,

    // Legal Info
    legalInfo,
    fetchLegalInfoList,
  }
}

export default useConfigurationFooter
