import useFetch from '../../../utils/fetch/useFetch'
import { useAppDispatch, useAppSelector } from '../../reduxProvider'

import { hideLoader, setDetail, showLoader } from './store'
import {
  ConfigurationOnboardingDetailResponse,
  ConfigurationOnboardingForm,
  ConfigurationOnboardingTranslateForm,
} from './types'

const useConfigurationOnboarding = () => {
  const dispatch = useAppDispatch()
  const { isLoaded, detail } = useAppSelector(
    (state) => state.configurationOnboarding
  )
  const { get, put, post } = useFetch()

  const endpoint = 'onboarding-configuration'

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const fetchDetail = async (): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: ConfigurationOnboardingDetailResponse | undefined =
        await get({
          endpoint,
        })
      if (response) dispatch(setDetail(response))
    })
  }

  const create = async (data: ConfigurationOnboardingForm) => {
    const response: string | undefined = await post({
      endpoint,
      body: data,
    })
    return response
  }

  const update = async (id: string, data: ConfigurationOnboardingForm) => {
    const response: ConfigurationOnboardingDetailResponse | undefined =
      await put({
        endpoint: `${endpoint}/${id}`,
        body: data,
      })
    if (response) dispatch(setDetail(response))
  }

  const translate = async (
    id: string,
    data: ConfigurationOnboardingTranslateForm
  ): Promise<void> => {
    const response: ConfigurationOnboardingDetailResponse | undefined =
      await put({
        endpoint: `${endpoint}/${id}/translation`,
        body: data,
      })
    if (response) dispatch(setDetail(response))
  }

  const uploadImage = async (image: string) => {
    const form = new FormData()
    const blob = await fetch(image).then((r) => r.blob())
    form.set('imageFile', blob)
    const result: { uri: string } | undefined = await post({
      endpoint: `${endpoint}/image`,
      body: form,
      isMultipart: true,
    })
    return result?.uri ?? ''
  }

  return {
    detail,
    isLoaded,

    fetchDetail,
    create,
    update,
    translate,
    uploadImage,
  }
}

export default useConfigurationOnboarding
