import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'

import { Loader } from '@liveconnect/components'

import EmptyState from '../../components/EmptyState'
import { Main } from '../../components/Main'
import useTopicFamilies from '../../core/topicFamilies/useTopicFamilies'
import TopicRow from './TopicRow'
import { sortElementsArray } from '../../utils/shared'

const Sectors: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { topicFamilyList, isLoaded, fetchTopicFamilies, sortTopicFamilies } =
    useTopicFamilies()

  useEffect(() => {
    fetchTopicFamilies()
  }, [])

  const handleDragEnd = async (event: any) => {
    const oldIndex = event.source.index
    const newIndex = event.destination.index
    const arraySorted = sortElementsArray(topicFamilyList, oldIndex, newIndex)
    await sortTopicFamilies(arraySorted)
  }

  return (
    <Main ariaLabelledby="list-sectors-title" className="ListTopics">
      <div className="d-flex justify-content-between">
        <div>
          <h1 id="list-topics-title" className="h3">
            {t('topic.list.title')}
          </h1>
          <p>{t('topic.list.hint')}</p>
        </div>
        <button
          className="btn btn-primary h-100"
          onClick={() => navigate('new')}
        >
          {t('topic.list.newTopic')}
        </button>
      </div>
      {!isLoaded ? (
        <Loader />
      ) : (
        <section className="mt-5">
          {!topicFamilyList.length ? (
            <EmptyState text={t('topic.list.empty.title')} />
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="topic-rows">
                {(provided: DroppableProvided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {topicFamilyList.map((topic, i) => (
                      <Draggable
                        key={`${topic.id}-draggable`}
                        draggableId={topic.id}
                        index={i}
                      >
                        {(provided: DraggableProvided) => (
                          <div
                            key={topic.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <TopicRow sector={topic} provided={provided} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </section>
      )}
    </Main>
  )
}

export default Sectors
