import { configureStore } from '@reduxjs/toolkit'

import auth from '../auth/store'
import ui from '../ui/store'
import topicFamilies from '../topicFamilies/store'
import brandsInfo from '../brandSpace/store'
import tenant from '../tenant/store'
import posts from '../posts/store'
import settings from '../settings/store'
import members from '../members/store'
import localizations from '../localizations/store'
import sections from '../sections/store'
import channels from '../channels/store'
import activities from '../activities/store'
import permissions from '../permissions/store'
import badges from '../badges/store'
import directories from '../directories/store'
import elements from '../elements/store'
import multimedia from '../multimedia/store'
import configurationCorporate from '../configuration/corporate/store'
import configurationFooter from '../configuration/footer/store'
import configurationOnboarding from '../configuration/onboarding/store'
import tenantConfig from '../tenantConfig/store'
import me from '../me/store'

export const store = configureStore({
  reducer: {
    auth,
    ui,
    topicFamilies,
    channels,
    brandsInfo,
    badges,
    tenant,
    posts,
    settings,
    members,
    localizations,
    sections,
    permissions,
    activities,
    directories,
    elements,
    multimedia,
    configurationCorporate,
    configurationFooter,
    configurationOnboarding,
    tenantConfig,
    me,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export * from './hooks'
