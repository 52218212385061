import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Pagination, Loader } from '@liveconnect/components'

import { Main } from '../../../components/Main'
import useDirectories from '../../../core/directories/useDirectories'
import EmptyState from '../../../components/EmptyState'
import { DirectoryListItem } from '../../../core/directories/types'
import { DirectoriesListItem } from './DirectoriesListItem'
import usePermissions from '../../../core/permissions/usePermissions'

import './styles.scss'

const DirectoriesList: FC = () => {
  const { t } = useTranslation()
  const { directoriesList, fetchDirectories, isLoaded } = useDirectories()
  const navigate = useNavigate()
  const { verifySomePermissionsDirectories } = usePermissions()

  const handleNewDirectory = (): void => {
    navigate('new')
  }

  useEffect(() => {
    fetchDirectories({ page: 1, page_size: 5 })
  }, [])

  return (
    <Main
      ariaLabelledby="directories-list"
      className="DirectoriesList"
      title={t('navMenu.directories')}
      subtitle={t('directories.text')}
      rightColumn={
        <button
          className="btn btn-primary"
          onClick={handleNewDirectory}
          disabled={!verifySomePermissionsDirectories()}
        >
          {t('directories.create')}
        </button>
      }
    >
      {!isLoaded ? (
        <Loader />
      ) : (
        <>
          {directoriesList && directoriesList.items.length === 0 ? (
            <EmptyState text={t('directories.empty')} />
          ) : (
            <div className="DirectoriesList__content">
              {directoriesList &&
                directoriesList.items.map((directory: DirectoryListItem) => (
                  <DirectoriesListItem key={directory.id} {...directory} />
                ))}
              {directoriesList && (
                <div className="mt-4">
                  <Pagination
                    currentPage={directoriesList.page - 1}
                    totalPages={directoriesList.availablePages}
                    totalItems={directoriesList.availableItems}
                    pageSize={directoriesList.pageSize}
                    singlePageText={(count) =>
                      t('common.pager.singlePage', { count })
                    }
                    multiplePageText={(start, end, total) =>
                      t('common.pager.multiplePage', { start, end, total })
                    }
                    onChange={(pageIndex) =>
                      fetchDirectories({ page: pageIndex + 1, page_size: 5 })
                    }
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </Main>
  )
}

export default DirectoriesList
