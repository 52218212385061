import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Member, MemberListPayload, MembersState } from './types'

const initialState: MembersState = {
  list: [],
  page: 1,
  pageSize: 20,
  availablePages: 1,
  availableItems: 0,
  search: '',
  isLoaded: false,
}

export const slice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    clearList: () => initialState,
    setList: (state, action: PayloadAction<MemberListPayload>) => {
      state.list = action.payload.list
      state.page = action.payload.page
      state.pageSize = action.payload.pageSize
      state.availablePages = action.payload.availablePages
      state.availableItems = action.payload.availableItems
      state.search = action.payload.search
    },
    toggleEnable: (state, action: PayloadAction<string>) => {
      const memberIndex = state.list.findIndex(
        (member: Member) => member.id === action.payload
      )
      state.list[memberIndex].isEnabled = !state.list[memberIndex].isEnabled
    },
  },
})

export const { showLoader, hideLoader, clearList, setList, toggleEnable } =
  slice.actions

export default slice.reducer
