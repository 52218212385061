import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { socialMediaInputs } from './linksSocialMedia'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  let linksValidations = {}

  socialMediaInputs.map(({ name, label }) => {
    linksValidations = {
      ...linksValidations,
      ...{
        [name]: yup
          .string()
          .url(t('common.link.patternValidation'))
          .label(
            t('footerInfo.socialMedia.form.links.label', {
              name: label,
            })
          ),
      },
    }
  })
  return yup.object().shape({
    sectionTitle: yup
      .string()
      .label(t('footerInfo.socialMedia.form.sectionTitle.label'))
      .required(({ label }) => t('validations.required', { label }))
      .max(50, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 50 })
      ),
    ...linksValidations,
  })
}
