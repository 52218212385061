import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import {
  ActivitiesState,
  ActivityListResponse,
  AttendeesListResponse,
  DeleteAttendeePayload,
  CommentActivity,
  CommentPayloadActivity,
  DeleteCommentActivityPayload,
  ActivityDetail,
  ActivityCollaboratorsResponse,
  ActivityCollaboratorDetailResponse,
} from './types'

const initialState: ActivitiesState = {
  detail: null,
  list: null,
  attendees: null,
  comments: null,
  collaborators: null,
  collaborator: null,
  isLoaded: false,
}

export const activitiesSlice = createSlice({
  name: 'activitiesInfo',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<ActivityListResponse | null>) => {
      state.list = action.payload
    },
    setDetail: (state, action: PayloadAction<ActivityDetail | null>) => {
      state.detail = action.payload
    },
    setAttendees: (
      state,
      action: PayloadAction<AttendeesListResponse | null>
    ) => {
      state.attendees = action.payload
    },
    deleteAtten: (state, action: PayloadAction<DeleteAttendeePayload>) => {
      if (state.attendees) {
        const attendeeIndex = state.attendees.items.findIndex(
          (attendee) => attendee.id === action.payload.attendeeId
        )
        state.attendees.items.splice(attendeeIndex, 1)
      }
    },
    setComments: (state, action: PayloadAction<CommentActivity[] | null>) => {
      state.comments = action.payload
    },
    addComment: (state, action: PayloadAction<CommentPayloadActivity>) => {
      if (typeof action.payload.comment === 'undefined') return
      if (state.comments) {
        state.comments.unshift(action.payload.comment)
      }
    },
    updateComment: (state, action: PayloadAction<CommentPayloadActivity>) => {
      if (typeof action.payload.comment === 'undefined') return
      if (state.comments) {
        const commentIndex = state.comments.findIndex(
          (comment) =>
            action.payload.comment && comment.id === action.payload.comment.id
        )
        state.comments[commentIndex] = action.payload.comment
      }
    },
    deleteComment: (
      state,
      action: PayloadAction<DeleteCommentActivityPayload>
    ) => {
      if (state.comments) {
        const commentIndex = state.comments.findIndex(
          (comment) => comment.id === action.payload.commentId
        )
        state.comments.splice(commentIndex, 1)
      }
    },
    setCollaborators: (
      state,
      action: PayloadAction<ActivityCollaboratorsResponse>
    ) => {
      state.collaborators = action.payload
    },
    setCollaborator: (
      state,
      action: PayloadAction<ActivityCollaboratorDetailResponse>
    ) => {
      state.collaborator = action.payload
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setList,
  setDetail,
  setAttendees,
  deleteAtten,
  setComments,
  addComment,
  updateComment,
  deleteComment,
  setCollaborators,
  setCollaborator,
} = activitiesSlice.actions

export const selectActivities = (state: RootState) => state.activities

export default activitiesSlice.reducer
