import { Channel } from '../channels/types'

export interface Post {
  id: string
  tenantId: string
  title: string
  message: string
  image: string
  video: string
  comments: Comment[]
  totalComments: number
  publisher: Publisher
  channels: Channel[]
  themes: Theme[]
  createdAt: string
  publishedAt: string
  selectedPublisher: PublishersResponse
  publisherType: string
  isEditable: boolean
  isDeletable: boolean
  isPublishable?: boolean
  reaction: Reactions
  isHighlighted: boolean
  isSponsored: boolean
  originalLanguage: string
  publishDateTime: string | null
  state: PostStateEnum
  timezone: string
  sponsor: PublishersSponsoredResponse
}

export interface PublishersSponsoredResponse {
  image: string
  name: string
  slogan: string
  url: string
}

export interface PostContainerProps extends Post {
  openEdit: (params: EditPostFnParams) => void
  fetchPosts: () => Promise<void>
}

export interface Comment {
  id: string
  publisher: Publisher
  creationDate: string
  message: string
  reaction: Reactions
  isEditable: boolean
  isDeletable: boolean
  originalLanguage: string
  sponsor: PublishersSponsoredResponse | null
}

export interface CommentWithDate extends Omit<Comment, 'creationDate'> {
  creationDate: Date
}

export interface Reactions {
  fingerUpCount: number
  likeCount: number
  myReaction: ReactionTypeEnum
}

export enum ReactionTypeEnum {
  NONE = 'None',
  FINGERUP = 'FingerUp',
  LIKE = 'Like',
}

export enum PublisherTypeEnum {
  BRAND = 'Brand',
  USER = 'User',
  TENANT = 'Tenant',
}
export enum PostStateEnum {
  PUBLISHED = 'Published',
  DRAFTED = 'Drafted',
  SCHEDULED = 'Scheduled',
}

export interface CreatePostType {
  title: string
  message: string
  option?: boolean
  timezone?: string
  dates?: string
  publishDateTime: string | null
  state: PostStateEnum
  image: string | undefined
  publisherType: PublisherTypeEnum
  publisherId: string
  channelsIds: string[]
  themesIds: string[]
  isHighlighted: boolean
  imagePath?: string
  video: string
  channelsArray?: {
    id: string
    themes: string[]
  }[]
}
export interface EditPostType {
  title: string
  message: string
  image: string | undefined
  isHighlighted: boolean
  themesIds: string[]
  publisherType: PublisherTypeEnum
  publisherExternalId: string
}

export interface BadgePublisher {
  id: string
  title: string
  imageUrl: string
}

export interface Publisher {
  id: string
  externalId: string
  title: string
  description: string
  image: string
  type: string
  isEnabled: boolean
  isAnonymousMember: boolean
  badges: BadgePublisher[]
}

export type PostParams =
  | Record<
      string,
      string | number | Array<string | number> | boolean | undefined
    >
  | undefined

export interface PostListResponse {
  items: Array<Post>
  page: number
  pageSize: number
  availableItems: number
  availablePages: number
}

export interface PostListPayload {
  list: Array<Post>
  page: number
  pageSize: number
  hasNextPage: boolean
}

export interface PostImage {
  uri: string
}

export interface PostsState {
  detail: PostDetail | null
  list: Array<Post>
  metrics: PostMetrics | null
  page: number
  pageSize: number
  hasNextPage: boolean
  isLoaded: boolean
}

export interface PostMetrics {
  channelFollowers: number
  viewMoreCount: number
  sponsorLinkCount: number
  fingerUpReactionCount: number
  likeReactionCount: number
  commentCount: number
  totalViewers: number
  sponsorName: string
  sponsorImage: string
  sponsorUrl: string
  sponsorSlogan: string
  publishedAt: string
  unpinnedDate: string
  hidingDate: string
  timeZone: string
}

export interface FormPostCreateFields {
  publisher: string
  channelsIds: string[]
  isHighlighted: boolean
  isSponsored: boolean
  channelsArray?: {
    id: string
    themes: string[]
  }[]
  sponsoredTitle: string
  sponsoredUrl: string
  state: PostStateEnum
  sponsoredSlogan: string
  sponsoredImagePath: string
  sponsoredChannels: string[]
  sponsoredDateUnpin?: string
  sponsoredDateHide?: string
  title: string
  message: string
  imagePath: string
  video: string
  option: boolean
  timezone?: string
  date?: string
}

export interface PostCreateParamsType {
  title: string
  message: string
  image: string
  video: string
  publisherType: PublisherTypeEnum
  publisherId: string
  isHighlighted: boolean
  state: PostStateEnum
  publishDateTime: string | null
  timezone: string
  channelsIds: string[]
  themesIds: string[]
  isSponsored: boolean
  sponsorName: string | null
  sponsorURL: string | null
  sponsorSlogan: string | null
  sponsorImage: string | null
  unpinnedDate?: string | null
  hidingDate?: string | null
}

export interface UsePostsResult {
  post: PostDetail | null
  postList: Array<Post>
  metrics: PostMetrics | null
  isLoaded: boolean
  hasNextPostsPage: boolean
  fetchPost: (id: string) => Promise<void>
  fetchPosts: (params?: PostParams) => Promise<void>
  fetchNextPagePosts: (params?: PostParams) => Promise<void>
  createPost: (body: PostCreateParamsType) => Promise<void>
  updateSponsoredPost: (
    postId: string,
    body: SponsoredPostUpdateParams
  ) => Promise<Post | undefined>
  deletePost: (id: string) => Promise<void>
  publishPost: (
    id: string,
    publisherExternalId: string,
    publisherType: string
  ) => Promise<void>
  uploadPostImage: (image: string) => Promise<PostImage | undefined>
  getPublishers: () => Promise<PublishersResponse[]>
  getThemesByChannel: (
    channelId: Record<
      string,
      string | number | Array<string | number> | boolean
    >
  ) => Promise<Theme[]>
  fetchPostComments: (postId: string) => void
  createComment: (
    postId: string,
    commentToSave: CommentPostInterface,
    publisherType: string,
    publisherExternalId: string
  ) => Promise<void>
  deleteComment: (postId: string, id: string) => Promise<void>
  updateComment: (
    postId: string,
    publisherExternalId: string,
    publisherType: string,
    comment: CommentPutInterface
  ) => Promise<void>
  updatePost: (postId: string, body: EditPostType) => Promise<Post | undefined>
  updatePostReaction: (
    postId: string,
    publisherExternalId: string,
    publisherType: string,
    type: ReactionTypeEnum
  ) => Promise<Reactions | undefined>
  updateCommentReaction: (
    postId: string,
    commentId: string,
    publisherExternalId: string,
    publisherType: string,
    type: ReactionTypeEnum
  ) => Promise<Reactions | undefined>
  deletePostAndDisablePublisher: (
    params: DeletePostAndDisablePublisherParams
  ) => Promise<void>
  disablePublisher: (publiserId: string, isEnabled: boolean) => Promise<void>
  deleteCommentAndDisablePublisher: (
    params: DeleteCommentAndDisablePublisherParams
  ) => Promise<void>
  getTranslatePost: (
    brandId: string,
    lang: string
  ) => Promise<TranslatePost | undefined>
  getTranslateComment: (
    brandId: string,
    lang: string
  ) => Promise<TranslatePost | undefined>
  fetchPostMetrics: (postId: string) => Promise<void>
  viewMorePostSponsored: (postId: string) => Promise<void>
  viewPostSponsor: (postId: string) => Promise<void>
}

export interface DeletePostAndDisablePublisherParams {
  postId: string
  memberId: string
  publisherId: string
}

export interface DeleteCommentAndDisablePublisherParams {
  postId: string
  commentId: string
  memberId: string
  publisherId: string
}

export interface CommentPostInterface {
  message: string
}

export interface CommentPutInterface {
  id: string
  message: string
  publisherType: string
  publisherExternalId: string
}

export interface SetPostCommentsPayload {
  postId: string
  list: Comment[]
}

export interface UpdateCommentPayload {
  postId: string
  comment?: Comment
}

export interface DeleteCommentPayload {
  postId: string
  commentId: string
}

export interface PublishersResponse {
  id: string
  name: string
  type: string
  channels: Pick<Channel, 'id' | 'name'>[]
  profileImageUrl: string
  shortDescription: string
}

export interface Theme {
  id: string
  name: string
}

export interface SelectType {
  value: string
  label: string
  title: string
}

export interface UpdateReactionBody {
  postId?: string
  commentId?: string
  publisherExternalId: string
  publisherType: string
  type: ReactionTypeEnum
}

export interface EditPostFnParams {
  publisher: Publisher
  channels: Channel[]
  title: string
  message: string
  themes: Theme[]
  image: string
  id: string
  isHighlighted: boolean
  video: string
  publishDateTime: string | null
  state: PostStateEnum
  timezone: string
}

export interface DisablePublisherPayload {
  publisherId: string
}

export interface TranslatePost {
  translateContent: string
}

export enum TransLangs {
  ES = 'es',
  EN = 'en',
}

export interface PostDetail {
  id: string
  title: string
  message: string
  image: string
  video: string
  isHighlighted: boolean
  publisher: PostDetailPublisher
  createdAt: string
  publishedAt: string
  state: string
  publishDateTime: string
  unpinnedDate: string
  hidingDate: string
  timeZone: string
  isSponsored: boolean
  sponsor: PostDetailSponsor
  channels: Channel[]
  themes: Channel[]
}

export interface PostDetailChannel {
  id: string
  name: string
}

export interface PostDetailPublisher {
  id: string
  externalId: string
  type: string
  title: string
  description: string
  image: string
  isEnabled: boolean
  badges: PostDetailBadge[]
}

export interface PostDetailBadge {
  id: string
  title: string
  imageUrl: string
}

export interface PostDetailSponsor {
  image: string
  name: string
  url: string
  slogan: string
}

export interface SponsoredPostUpdateParams {
  title: string
  message: string
  image: string
  video: string
  publisherExternalId: string
  publisherType: string
  isHighlighted: boolean
  themesIds: string[]
  state: string
  publishDateTime: string | null
  timeZone: string
  isSponsored: boolean
  hidingDate?: string | null
  unpinnedDate?: string | null
  sponsorName: string
  sponsorSlogan: string
  sponsorURL: string
  sponsorImage: string
}
