import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { PostStateEnum } from '../../../core/posts/types'
import { useHtmlToString } from '../../../utils/useHtmlToString'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup
    .object()
    .shape({
      publisher: yup
        .string()
        .label(t('brand.edit.eventCode'))
        .required(({ label }) => t('validations.required', { label })),
      channelsIds: yup.array().when('publisher', {
        is: '3a88c5e5-a6e1-4898-b72b-103e4eed1731',
        then: yup
          .array()
          .label(t('post.create.channel.single.label'))
          .min(1, ({ label }) => t('validations.required', { label }))
          .required(({ label }) => t('validations.required', { label })),
      }),
      channelsArray: yup.array().when(['publisher', 'isSponsored'], {
        is: (publisher: string, isSponsored: boolean) =>
          publisher !== '3a88c5e5-a6e1-4898-b72b-103e4eed1731' && !isSponsored,
        then: yup.array().of(
          yup.object().shape({
            id: yup
              .string()
              .label(t('post.create.channel.single.label'))
              .required(({ label }) => t('validations.required', { label })),
            themes: yup
              .array()
              .label(t('post.create.themes.label'))
              .min(1, ({ label }) => t('validations.required', { label }))
              .required(({ label }) => t('validations.required', { label })),
          })
        ),
      }),
      sponsoredTitle: yup.string().when(['publisher', 'isSponsored'], {
        is: (publisher: string, isSponsored: boolean) =>
          publisher !== '3a88c5e5-a6e1-4898-b72b-103e4eed1731' && isSponsored,
        then: yup
          .string()
          .label(t('post.create.sponsored.name'))
          .max(50, ({ label }) =>
            t('validations.maxLength', { label, maxLength: 50 })
          )
          .required(({ label }) => t('validations.required', { label })),
      }),
      sponsoredUrl: yup.string().when(['publisher', 'isSponsored'], {
        is: (publisher: string, isSponsored: boolean) =>
          publisher !== '3a88c5e5-a6e1-4898-b72b-103e4eed1731' && isSponsored,
        then: yup
          .string()
          .label(t('post.create.sponsored.url'))
          .max(50, ({ label }) =>
            t('validations.maxLength', { label, maxLength: 50 })
          )
          .required(({ label }) => t('validations.required', { label })),
      }),
      sponsoredSlogan: yup.string().when(['publisher', 'isSponsored'], {
        is: (publisher: string, isSponsored: boolean) =>
          publisher !== '3a88c5e5-a6e1-4898-b72b-103e4eed1731' && isSponsored,
        then: yup
          .string()
          .label(t('post.create.sponsored.slogan'))
          .max(60, ({ label }) =>
            t('validations.maxLength', { label, maxLength: 60 })
          )
          .required(({ label }) => t('validations.required', { label })),
      }),
      title: yup
        .string()
        .label(t('post.create.title.label'))
        .max(200, ({ label }) =>
          t('validations.maxLength', { label, maxLength: 200 })
        )
        .required(({ label }) => t('validations.required', { label })),
      message: yup
        .string()
        .label(t('post.create.message.label'))
        .required(({ label }) => t('validations.required', { label }))
        .test(
          'post.create.message.label',
          ({ label }) => t('validations.maxLength', { label, maxLength: 5000 }),
          (val) => useHtmlToString(val ?? '').length <= 5000
        ),
      timezone: yup.string().when('option', {
        is: true,
        then: yup
          .string()
          .label(t('post.create.title.label'))
          .required(({ label }) => t('validations.required', { label })),
      }),
      date: yup
        .string()
        .label(t('post.create.title.label'))
        .when('state', {
          is: PostStateEnum.SCHEDULED,
          then: yup
            .string()
            .required(({ label }) => t('validations.required', { label }))
            .test(
              'program_date_test',
              t('post.validation.date', {
                label: t('post.date.timezone').toLowerCase(),
              }),
              function (value) {
                if (value) {
                  const today = new Date()
                  today.setHours(0, 0, 0)
                  today.setMilliseconds(0)
                  const program = new Date(value)
                  program.setHours(0, 0, 0)
                  program.setMilliseconds(0)
                  if (program.getTime() < today.getTime()) {
                    return false
                  }
                  return true
                }
                return false
              }
            ),
          otherwise: yup.string(),
        }),
      sponsoredDateUnpin: yup
        .string()
        .when(['publisher', 'isSponsored', 'option'], {
          is: (publisher: string, isSponsored: boolean, option: boolean) =>
            publisher !== '3a88c5e5-a6e1-4898-b72b-103e4eed1731' &&
            isSponsored &&
            option,
          then: yup
            .string()
            .label(t('post.create.sponsored.date.unpin'))
            .test(
              'date',
              t('post.create.sponsored.validation.unpin'),
              (value, context) => {
                const { date } = context.parent
                if (value && date) {
                  return new Date(date) < new Date(value)
                }
                return true
              }
            )
            .required(({ label }) => t('validations.required', { label })),
        })
        .nullable(),
      sponsoredDateHide: yup
        .string()
        .when(['publisher', 'isSponsored', 'option'], {
          is: (publisher: string, isSponsored: boolean, option: boolean) =>
            publisher !== '3a88c5e5-a6e1-4898-b72b-103e4eed1731' &&
            isSponsored &&
            option,
          then: yup
            .string()
            .label(t('post.create.sponsored.date.hide'))
            .test(
              'sponsoredDateUnpin',
              t('post.create.sponsored.validation.hide'),
              (value, context) => {
                const { sponsoredDateUnpin } = context.parent
                if (value && sponsoredDateUnpin) {
                  return new Date(sponsoredDateUnpin) < new Date(value)
                }
                return true
              }
            )
            .required(({ label }) => t('validations.required', { label })),
        })
        .nullable(),
    })
    .required()
}
