import { FC, useEffect } from 'react'
import { Route, Navigate, Routes, useParams } from 'react-router-dom'

import { Loader } from '@liveconnect/components'

import PrivateRoute from './PrivateRoute'
import WallScreen from '../pages/Wall'
import SchedulesPosts from '../pages/SchedulesPosts'
import BrandsRoutes from './BrandsRoutes'
import SectorsRouter from './SectorsRouter'
import UsersRoutes from './UsersRoutes'
import ActivitiesRoutes from './ActivitiesRouter'
import DirectoriesRoutes from './DirectoriesRoutes'
import PostCreate from '../pages/Wall/PostCreate'
import PermissionsRoutes from './PermissionsRouter'
import PostSponsoredEdit from '../pages/Wall/PostSponsoredEdit'
import WelcomePage from '../pages/Welcome'
import MetricsPage from '../pages/Wall/Metrics'
import ConfigurationRoutes from './ConfigurationRouter'
import { useSettings } from '../core'
import NotAvailable from '../pages/Error/NotAvailable'
import { AccessDenied } from '../pages/AccessDenied'
import { Language } from '../pages/Me/Language'

const CommunitiesRoutes: FC = () => {
  const { modulesStatus } = useSettings()

  if (!modulesStatus.isLoaded) return <Loader />

  if (
    !modulesStatus.modulesStatus.tenantStatus ||
    !modulesStatus.modulesStatus.moduleStatus
  )
    return <NotAvailable />

  return (
    <Routes>
      <Route path="/access-denied" element={<AccessDenied />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Navigate to="welcome" />} />
        <Route path="welcome" element={<WelcomePage />} />
        <Route path="wall" element={<WallScreen />} />
        <Route path="wall/sponsored/:postId" element={<PostSponsoredEdit />} />
        <Route path="wall/metrics/:postId" element={<MetricsPage />} />
        <Route path="wall/post/create" element={<PostCreate />} />
        <Route path="wall/shedules-posts" element={<SchedulesPosts />} />
        <Route path="users/*" element={<UsersRoutes />} />
        <Route path="sectors/*" element={<SectorsRouter />} />
        <Route path="brands/*" element={<BrandsRoutes />} />
        <Route path="activities/*" element={<ActivitiesRoutes />} />
        <Route path="directories/*" element={<DirectoriesRoutes />} />
        <Route path="permissions/*" element={<PermissionsRoutes />} />
        <Route path="configuration/*" element={<ConfigurationRoutes />} />
        <Route path="me/language" element={<Language />} />
      </Route>
    </Routes>
  )
}

export default CommunitiesRoutes
