import { FC, useEffect, useState } from 'react'
import { Link, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Icon, IconName } from '@liveconnect/icons'
import classNames from 'classnames'

import { useCustomRouter } from '../../utils/extractParams'
import usePermissions from '../../core/permissions/usePermissions'
import { configurationRoute, navbarItems } from './items'
import { NavbarItem } from './types'

import './styles.scss'

type NavbarProps = {
  rootClassName?: string
  menuItems?: NavbarItem[]
}

const Navbar: FC<NavbarProps> = ({ rootClassName = 'Menu', menuItems }) => {
  const { basePath } = useCustomRouter()
  const [openSubmenu, setOpenSubmenu] = useState<string>('')
  const [listItems, setListItems] = useState<NavbarItem[]>(
    navbarItems as NavbarItem[]
  )
  const {
    communityPermissions,
    verifyEditorSectorsChannelsThemes,
    verifyPermissionsMaster,
    verifyModeratorWall,
  } = usePermissions()

  const permissionRoute: NavbarItem = {
    key: 'permissions',
    trnsKey: 'navMenu.permissions',
    icon: 'admin_panel_settings',
    path: '/permissions',
    exact: false,
    disabled: false,
  }

  const handleClickGroup = (key: string) => {
    if (key === openSubmenu) setOpenSubmenu('')
    else setOpenSubmenu(key)
  }

  const handleMouseLeave = () => {
    setOpenSubmenu('')
  }

  useEffect(() => {
    if (communityPermissions) {
      const newList = [...listItems]
      if (!verifyEditorSectorsChannelsThemes()) {
        const indexItem = newList.findIndex(
          (elemento) => elemento.path === '/sectors'
        )

        if (indexItem !== -1) {
          newList.splice(indexItem, 1)
          setListItems(newList)
        }
      }
      if (!verifyModeratorWall()) {
        const indexItem = newList.findIndex(
          (elemento) => elemento.path === '/wall'
        )

        if (indexItem !== -1) {
          newList.splice(indexItem, 1)
          setListItems(newList)
        }
      }
      if (verifyPermissionsMaster()) {
        setListItems([...newList, permissionRoute, configurationRoute])
      }
    }
  }, [communityPermissions])

  return (
    <ul className={rootClassName} onMouseLeave={handleMouseLeave}>
      {(menuItems || listItems).map((item, index) => {
        return (
          <li className={`${rootClassName}__${index}`} key={index}>
            {item.submenu ? (
              <NavbarGroup
                item={item}
                basePath={basePath}
                rootClassName={rootClassName}
                onClick={() => handleClickGroup(item.key)}
                isOpen={openSubmenu === item.key}
              />
            ) : (
              <NavbarLink
                item={item}
                basePath={basePath}
                rootClassName={rootClassName}
              />
            )}
          </li>
        )
      })}
    </ul>
  )
}

interface NavbarItemProps {
  basePath: string
  item: NavbarItem
  rootClassName: string
}

const NavbarLink: FC<NavbarItemProps> = ({ basePath, item, rootClassName }) => {
  const { t } = useTranslation()
  const path = `${basePath}${item.path}`
  const active = useMatch({ path, end: item.exact })

  return (
    <Link
      to={path}
      className={classNames(`${rootClassName}__item`, {
        active,
        disabled: item.disabled,
      })}
    >
      {item.icon && (
        <div className={`${rootClassName}__icon`}>
          <Icon
            name={item.icon as IconName}
            className={classNames({ active })}
          />
        </div>
      )}
      <div className={`${rootClassName}__text`}>{t(item.trnsKey)}</div>
    </Link>
  )
}
interface NavbarGroupProps extends NavbarItemProps {
  onClick?: () => void
  isOpen: boolean
}

const NavbarGroup: FC<NavbarGroupProps> = ({
  basePath,
  item,
  rootClassName,
  onClick,
  isOpen,
}) => {
  const { t } = useTranslation()
  const actives = item.submenu?.map((item) => {
    const path = `${basePath}${item.path}`
    return !!useMatch({ path, end: item.exact })
  })
  const active = actives?.includes(true)

  return (
    <div>
      <div
        className={classNames(`${rootClassName}__item`, {
          active,
          disabled: item.disabled,
        })}
        onClick={onClick}
      >
        <div className={`${rootClassName}__icon`}>
          <Icon
            name={item.icon as IconName}
            className={classNames({ active })}
          />
        </div>
        <div className={`${rootClassName}__text`}>{t(item.trnsKey)}</div>
        <div className={`${rootClassName}__chevron`}>
          {isOpen ? (
            <Icon name="keyboard_arrow_up" />
          ) : (
            <Icon name="keyboard_arrow_down" />
          )}
        </div>
      </div>
      {isOpen && <Navbar menuItems={item.submenu} rootClassName="Submenu" />}
    </div>
  )
}

export default Navbar
