import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import {
  FormMultiSelect,
  FormTextInput,
  FormProvider,
  FormSelect,
} from '@liveconnect/components'

import { Option, FiltersModal } from '@liveconnect/communities-ui'

import useDirectories from '../../../core/directories/useDirectories'
import {
  ChannelSectorDirectory,
  SectorDirectory,
} from '../../../core/directories/types'

import { FilterButton } from '../../../components/Buttons/FilterButton'

import './styles.scss'
import { RegisterType, UserType } from '../../../core/members/types'

interface UserListFilters {
  search: string
  registerType: string
  userType: string
  sectorsIds: string[]
  channelsIds: string[]
  page: number
  pageSize: number
}

interface UserListFiltersProps {
  onChange: (data: UserListFilters) => void
}

const UserListFilters: FC<UserListFiltersProps> = ({ onChange }) => {
  const { t } = useTranslation()
  const { getSectorsDirectory } = useDirectories()

  const [visibleSlidePanel, setVisibleSlidePanel] = useState<boolean>(false)
  const [activeFilters, setActiveFilters] = useState<boolean>(false)
  const [sectors, setSectors] = useState<SectorDirectory[]>([])

  const DEFAULT_VALUES: UserListFilters = {
    search: '',
    registerType: '',
    userType: '',
    sectorsIds: [],
    channelsIds: [],
    page: 1,
    pageSize: 20,
  }

  const methods = useForm<UserListFilters>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  })

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { dirtyFields },
  } = methods

  const watchSector = watch('sectorsIds')

  const sectorsOptions = useMemo(() => {
    const options = sectors.map((topic) => {
      return {
        value: topic.id,
        label: topic.name,
      }
    })
    return options
  }, [sectors])

  const channelsOptions = useMemo(() => {
    if (watchSector && sectors) {
      const sectorsFiltered = sectors.filter((sector) =>
        watchSector.includes(sector.id)
      )
      const options: Option[] = []
      sectorsFiltered.map((sector) => {
        sector.channels.map((channel: ChannelSectorDirectory) => {
          options.push({
            value: channel.id,
            label: channel.name,
          })
        })
      })
      return options
    }
    return []
  }, [watchSector])

  const handleReset = () => {
    reset(DEFAULT_VALUES)
  }

  const handleActivateFilters = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, ...rest } = dirtyFields
    const hasActiveFilters = Object.keys(rest).length > 0
    setActiveFilters(hasActiveFilters)
  }

  const onSubmit = () => {
    const values = getValues()
    onChange(values)
    handleActivateFilters()
    setVisibleSlidePanel(false)
  }

  const debounce = (func: any) => {
    let timer: NodeJS.Timeout | null
    return function (...args: any[]) {
      let context: any
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, 500)
    }
  }

  const optimizedHandleChangeSearch = useCallback(debounce(onSubmit), [])

  const fetchSectors = async () => {
    const sectors = await getSectorsDirectory()
    sectors && setSectors(sectors)
  }

  useEffect(() => {
    fetchSectors()
  }, [])

  useEffect(() => {
    if (watchSector && watchSector.length === 0) {
      setValue('channelsIds', [])
    }
    if (!watchSector) {
      setValue('sectorsIds', [])
    }
  }, [watchSector])

  return (
    <div className="users-filters">
      <FormProvider methods={methods}>
        <div className="row align-items-baseline">
          <div className="col-lg">
            <div className="users-filters__search">
              <FormTextInput
                control={control}
                name="search"
                placeholder={t('common.search')}
                onChange={optimizedHandleChangeSearch}
              />
            </div>
          </div>
          <div className="col-lg-auto">
            <FilterButton
              isActive={activeFilters}
              onClick={() => setVisibleSlidePanel(true)}
            />
          </div>
        </div>

        <FiltersModal
          isOpened={visibleSlidePanel}
          onClose={() => setVisibleSlidePanel(false)}
          onFilter={handleSubmit(onSubmit)}
          onReset={handleReset}
          texts={{
            title: t('filtersModal.filters'),
            reset: t('filtersModal.clear'),
            viewResults: t('filtersModal.viewResults'),
          }}
        >
          <FiltersModal.Section title={t('users.filters.registerType')}>
            <FormSelect
              isClearable={false}
              control={control}
              label=""
              name="registerType"
              options={[
                { label: t('common.all'), value: '' },
                { label: t('user.list.register'), value: RegisterType.Active },
                {
                  label: t('user.list.preregister'),
                  value: RegisterType.Preregistered,
                },
              ]}
            />
          </FiltersModal.Section>

          <FiltersModal.Section title={t('users.filters.userType')}>
            <FormSelect
              isClearable={false}
              control={control}
              label=""
              name="userType"
              options={[
                { label: t('common.all'), value: '' },
                {
                  label: t('users.filters.fields.isEnabled.label'),
                  value: UserType.Enabled,
                },
                {
                  label: t('users.filters.fields.isDisabled.label'),
                  value: UserType.Disabled,
                },
              ]}
            />
          </FiltersModal.Section>

          <FiltersModal.Section>
            <FormMultiSelect
              control={control}
              name="sectorsIds"
              label={t('users.filters.fields.sectors.label')}
              placeholder={t('users.filters.fields.sectors.placeholder')}
              options={sectorsOptions}
              noOptionsMessage={t('select.empty')}
            />

            <FormMultiSelect
              control={control}
              name="channelsIds"
              label={t('users.filters.fields.channels.label')}
              placeholder={t('users.filters.fields.channels.placeholder')}
              options={channelsOptions}
              noOptionsMessage={t('select.empty')}
            />
          </FiltersModal.Section>
        </FiltersModal>
      </FormProvider>
    </div>
  )
}

export default UserListFilters
