import { TFunction } from 'i18next'

import { parseHtmlToString } from '@liveconnect/components'

export const characterCounterText = (
  value: string,
  max: number,
  t: TFunction
) =>
  t('common.characterCounter', { value: parseHtmlToString(value).length, max })
