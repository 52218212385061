import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    language: yup
      .string()
      .label(t('sections.language'))
      .required(({ label }) => t('validations.required', { label })),
    sections: yup
      .array()
      .of(
        yup.object({
          name: yup
            .string()
            .label(t('sections.validations.section.name'))
            .test(
              'notWhiteSpaces',
              ({ label }) => t('validations.notValid', { label }),
              (value) => {
                if (value) return !/^\s*$/.test(value)
                return true
              }
            )
            .required(({ label }) => t('validations.required', { label })),
          subsections: yup
            .array()
            .of(
              yup.object({
                name: yup
                  .string()
                  .label(t('sections.validations.subsection.name'))
                  .required(({ label }) => t('validations.required', { label }))
                  .test(
                    'notWhiteSpaces',
                    ({ label }) => t('validations.notValid', { label }),
                    (value) => {
                      if (value) return !/^\s*$/.test(value)
                      return true
                    }
                  ),
              })
            )
            .label(t('sections.validations.subsection')),
        })
      )
      .label(t('sections.validations.section'))
      .min(1, ({ label }) => t('validations.required', { label }))
      .required(({ label }) => t('validations.required', { label })),
  })
}
