import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChannelDetail, ChannelsState, Translation } from './types'

const initialState: ChannelsState = {
  isLoaded: false,
  detail: null,
}

export const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    setDetail: (state, action: PayloadAction<ChannelDetail>) => {
      state.detail = action.payload
    },
    setTranslations: (state, action: PayloadAction<Translation[]>) => {
      if (state.detail && state.detail.translations) {
        state.detail.translations = action.payload
      }
    },
    setBrandspaces: (state, action: PayloadAction<string[]>) => {
      if (state.detail && state.detail.brandSpaces) {
        state.detail.brandSpaces = action.payload
      }
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
  },
})

export const {
  setDetail,
  setTranslations,
  setBrandspaces,
  showLoader,
  hideLoader,
} = channelsSlice.actions

export default channelsSlice.reducer
