import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { ModulesStatus, Settings } from './types'
import { setSettings, setModulesStatus, setModulesStatusLoaded } from './store'

const MODULE_CODE = 'Communities'
const BFF_CODE = 'CommunitiesAdminBff'
const TENANT_BFF_CODE = 'TenantAdminBff'
const IDENTITY_CODE = 'IdsWeb'

const useSettings = () => {
  const dispatch = useAppDispatch()
  const { settings, modulesStatus } = useAppSelector((state) => state.settings)

  const fetchSettings = async () => {
    const settingsResponse = await fetch('/api/v1/settings')
    const appSettings: Settings = await settingsResponse.json()
    const isTenant = location.pathname.split('/')[1] === 't'
    if (isTenant) {
      const tenantid = location.pathname.split('/')[2]
      await fetchModulesStatus(tenantid, appSettings)
      await setInitialUrls(tenantid, appSettings)
    } else {
      dispatch(setSettings(appSettings))
    }
  }

  const fetchModulesStatus = async (
    tenantId: string,
    appSettings?: Settings
  ) => {
    dispatch(setModulesStatusLoaded(false))
    const mySettings = appSettings || settings
    if (!mySettings) return { moduleStatus: false, tenantStatus: false }
    const response = await fetch(
      `${mySettings.saasBff.url}/tenants/${tenantId}/modules/${MODULE_CODE}`
    )
    const moduleStatus: ModulesStatus = await response.json()
    dispatch(setModulesStatus(moduleStatus))
    dispatch(setModulesStatusLoaded(true))
  }

  const setInitialUrls = async (tenantId: string, appSettings?: Settings) => {
    const mySettings = (appSettings || settings) as Settings
    const response = await fetch(
      `${mySettings?.saasBff.url}/tenants/${tenantId}/urls/all`
    )
    const urlsArr = await response.json()
    const bffUrl = urlsArr.find(
      (item: Record<string, string>) => item.code === BFF_CODE
    ).url
    const tenantBffUrl = urlsArr.find(
      (item: Record<string, string>) => item.code === TENANT_BFF_CODE
    ).url
    const identityUrl = urlsArr.find(
      (item: Record<string, string>) => item.code === IDENTITY_CODE
    ).url
    dispatch(
      setSettings({
        ...mySettings,
        bff: { url: `${bffUrl}/api/v1` },
        tenantBff: { url: `${tenantBffUrl}/api/v1` },
        identity: { ...mySettings.identity, authority: identityUrl },
      })
    )
  }

  return {
    settings: settings,
    fetchSettings,

    fetchModulesStatus,
    modulesStatus,

    setInitialUrls,
  }
}

export default useSettings
