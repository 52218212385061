import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  CreateBatchSponsorsModel,
  CreateSectionsModel,
  ImageResult,
  UpdateIsHighlightedParams,
  Section,
  UpdateOrderParams,
  UpdateOrderSponsorParams,
  UpdateSponsorModel,
  UpdateSubsectionOrderParams,
  UploadSponsorImageModel,
  UseSectionsResult,
  SetIsFeaturedPayload,
} from './types'
import { hideLoader, showLoader, setList, setIsFeatured } from './store'
import useFetch from '../../utils/fetch/useFetch'
import { Sponsor } from '../brandSpace/types'

const useSections = (): UseSectionsResult => {
  const dispatch = useAppDispatch()
  const { isLoaded, list } = useAppSelector((state) => state.sections)
  const { get, put, post, patch, del } = useFetch()
  const baseEndpoint = 'brand-spaces'
  const sectionsEndpoint = 'sections'
  const sponsorsEndpoint = 'sponsors'

  const dispatchLoaders = async (asynCall: () => Promise<unknown>) => {
    dispatch(showLoader())
    try {
      await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const fetchSections = async (brandId: string): Promise<void> => {
    return await dispatchLoaders(async () => {
      const result: Section[] | undefined = await get({
        endpoint: `${baseEndpoint}/${brandId}/${sectionsEndpoint}`,
      })
      dispatch(setList(result ?? []))
    })
  }

  const createUpdateSections = async (
    params: CreateSectionsModel
  ): Promise<Section[] | undefined> => {
    const result: Section[] | undefined = await put({
      endpoint: `${baseEndpoint}/${params.brandId}/${sectionsEndpoint}`,
      body: params,
    })
    dispatch(setList(result ?? []))
    return result
  }

  const updateSubsectionOrder = async (
    params: UpdateSubsectionOrderParams
  ): Promise<void> => {
    if (
      list.some((s) =>
        s.subSections?.some(
          (ss) => ss.id === params.subsectionId && ss.index === params.index
        )
      )
    )
      return Promise.resolve()

    const backupList = Array.from(list)
    const section = list.find((s) => s.id === params.sectionId)
    if (!section || !section.subSections) return Promise.reject()
    const subsections = Array.from(section.subSections)
    const subsection = subsections.find((s) => s.id === params.subsectionId)
    if (subsection && subsection.index !== params.index) {
      const [removed] = subsections.splice(subsection.index, 1)
      subsections.splice(params.index, 0, removed)
      const orderedSubSections = subsections.map((s, i) => ({ ...s, index: i }))
      const updatedSections = list.map((s) => {
        if (s.id === params.sectionId)
          return { ...s, subSections: orderedSubSections }
        return s
      })
      dispatch(setList(updatedSections))
    }

    try {
      await patch({
        endpoint: `${baseEndpoint}/${params.brandId}/${sectionsEndpoint}`,
        id: params.subsectionId,
        body: { index: params.index },
      })
    } catch {
      dispatch(setList(backupList))
    }
  }

  const updateSectionOrder = async (
    params: UpdateOrderParams
  ): Promise<void> => {
    if (list.some((s) => s.id === params.sectionId && s.index === params.index))
      return Promise.resolve()
    const backupList = Array.from(list)
    const sections = Array.from(list)
    const section = sections.filter((s) => s.id === params.sectionId)[0]
    const [removed] = sections.splice(section.index, 1)
    sections.splice(params.index, 0, removed)
    const orderedSections = sections.map((sp, i) => ({ ...sp, index: i }))
    dispatch(setList(orderedSections))
    try {
      await patch({
        endpoint: `${baseEndpoint}/${params.brandId}/${sectionsEndpoint}`,
        id: params.sectionId,
        body: { index: params.index },
      })
    } catch {
      dispatch(setList(backupList))
    }
  }

  const updateIsFeatured = async (
    params: UpdateIsHighlightedParams
  ): Promise<void> => {
    const result: boolean | undefined = await patch({
      endpoint: `${baseEndpoint}/${params.brandId}/${sectionsEndpoint}/${params.sectionId}`,
      id: 'highlight',
      body: { isHighlighted: params.isHighlighted },
    })
    if (result) {
      const paramsStore: SetIsFeaturedPayload = {
        isFeatured: params.isHighlighted,
        sectionId: params.sectionId,
      }
      dispatch(setIsFeatured(paramsStore))
    }
  }

  const updateOrderSponsor = async (
    params: UpdateOrderSponsorParams
  ): Promise<void> => {
    if (
      list.some(
        (s) =>
          s.sponsors?.some(
            (sp) => sp.id === params.sponsorId && sp.index === params.index
          ) ||
          s.subSections?.some((ss) =>
            ss.sponsors?.some(
              (sp) => sp.id === params.sponsorId && sp.index === params.index
            )
          )
      )
    )
      return Promise.resolve()

    const backupList = Array.from(list)
    const updatedSections = list.map((section) => {
      if (section.sponsors?.find((sp) => sp.id === params.sponsorId)) {
        const sponsors = Array.from(section.sponsors)
        const sponsor = sponsors.filter((sp) => sp.id === params.sponsorId)[0]
        const [removed] = sponsors.splice(sponsor.index, 1)
        sponsors.splice(params.index, 0, removed)
        const orderedSponsors = sponsors.map((sp, i) => ({ ...sp, index: i }))
        return { ...section, sponsors: orderedSponsors }
      }
      const subsection = section.subSections?.find((ss) =>
        ss.sponsors?.find((sp) => sp.id === params.sponsorId)
      )
      if (subsection && subsection.sponsors && section.subSections) {
        const sponsors = Array.from(subsection.sponsors)
        const sponsor = sponsors.filter((sp) => sp.id === params.sponsorId)[0]
        const [removed] = sponsors.splice(sponsor.index, 1)
        sponsors.splice(params.index, 0, removed)
        const orderedSponsors = sponsors.map((sp, i) => ({ ...sp, index: i }))
        return {
          ...section,
          subSections: section.subSections.map((ss) => {
            if (ss.id === subsection.id)
              return { ...ss, sponsors: orderedSponsors }
            return ss
          }),
        }
      }
      return section
    })
    dispatch(setList(updatedSections))
    try {
      await patch({
        endpoint: sponsorsEndpoint,
        id: params.sponsorId,
        body: { index: params.index, brandSpaceId: params.brandId },
      })
    } catch {
      dispatch(setList(backupList))
    }
  }

  const uploadSponsorImage = async ({
    internalId,
    logoUrl,
  }: UploadSponsorImageModel): Promise<[string, string] | undefined> => {
    const form = new FormData()
    const blob = await fetch(logoUrl).then((r) => r.blob())
    form.set('imageFile', blob)
    try {
      const result: ImageResult | undefined = await post({
        endpoint: `${sponsorsEndpoint}/image`,
        body: form,
        isMultipart: true,
      })
      if (result) return [internalId, result.uri]
    } catch (error) {
      return Promise.resolve([internalId, ''])
    }
  }

  const createBatchSponsors = async ({
    sectionId,
    brandId,
    sponsors,
  }: CreateBatchSponsorsModel): Promise<Sponsor[] | undefined> => {
    const result: Sponsor[] | undefined = await post({
      endpoint: `${sponsorsEndpoint}`,
      /* body: sponsors.map((s) => ({ ...s, sectionId })), */
      body: {
        newSponsors: sponsors.map((s) => ({ ...s, sectionId })),
        brandSpaceId: brandId,
      },
    })
    if (result)
      dispatch(
        setList(
          list.map((s) => {
            if (s.id === sectionId) return { ...s, sponsors: result }
            if (s.subSections?.find((s) => s.id === sectionId)) {
              return {
                ...s,
                subSections: s.subSections.map((sub) => {
                  if (sub.id === sectionId) return { ...sub, sponsors: result }
                  return sub
                }),
              }
            }
            return s
          })
        )
      )
    return result
  }

  const updateSponsor = async (
    params: UpdateSponsorModel
  ): Promise<Sponsor | undefined> => {
    const result: Sponsor | undefined = await put({
      endpoint: sponsorsEndpoint,
      id: params.id,
      body: {
        name: params.name,
        logoUrl: params.logoUrl,
        websiteUrl: params.websiteUrl,
        brandSpaceId: params.brandId,
      },
    })
    if (result)
      dispatch(
        setList(
          list.map((s) => {
            if (s.sponsors?.find((sp) => sp.id === params.id)) {
              const sponsors = (s.sponsors || [result]).map((sp) => {
                if (sp.id === params.id) return result
                return sp
              })
              return { ...s, sponsors }
            }
            if (
              s.subSections?.some((ss) =>
                ss.sponsors?.find((sp) => sp.id === params.id)
              )
            ) {
              return {
                ...s,
                subSections: s.subSections.map((sub) => {
                  if (sub.sponsors?.find((sp) => sp.id === params.id)) {
                    const sponsors = (sub.sponsors || [result]).map((sp) => {
                      if (sp.id === params.id) return result
                      return sp
                    })
                    return { ...sub, sponsors }
                  }
                  return sub
                }),
              }
            }
            return s
          })
        )
      )
    return result
  }

  const deleteSponsor = async (sponsorId: string): Promise<void> => {
    await del({
      endpoint: sponsorsEndpoint,
      id: sponsorId,
    })
    dispatch(
      setList(
        list.map((s) => {
          if (s.sponsors?.some((sp) => sp.id === sponsorId)) {
            return {
              ...s,
              sponsors: s.sponsors.filter((sp) => sp.id !== sponsorId),
            }
          }
          if (
            s.subSections?.some((ss) =>
              ss.sponsors?.some((sp) => sp.id === sponsorId)
            )
          ) {
            return {
              ...s,
              subSections: s.subSections.map((ss) => {
                if (ss.sponsors?.some((sp) => sp.id === sponsorId)) {
                  return {
                    ...ss,
                    sponsors: ss.sponsors.filter((sp) => sp.id !== sponsorId),
                  }
                }
                return ss
              }),
            }
          }
          return s
        })
      )
    )
  }

  return {
    isLoaded,
    sections: list,
    fetchSections,
    createUpdateSections,
    updateSectionOrder,
    updateIsFeatured,
    createBatchSponsors,
    updateSponsor,
    uploadSponsorImage,
    deleteSponsor,
    updateOrderSponsor,
    updateSubsectionOrder,
  }
}

export default useSections
