import { FormTextInput, FormProvider } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { buildValidationSchema } from './validations'
import { DEFAULT_LANG } from '../../../../../i18n/config'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormActionsLang } from '../../../../../components/forms/FormActionsLang'
import { Main } from '../../../../../components/Main'
import {
  ChannelsTabs,
  FormLinkedThemesForm,
} from '../../../../../core/channels/types'
import { useBlockRouteChangeWithDialog } from '../../../../../utils/routing/useBlockRouteChange'
import useChannels from '../../../../../core/channels/useChannels'
import useUi from '../../../../../core/ui/useUi'
import { useCustomRouter } from '../../../../../utils/extractParams'
import useNotifications from '../../../../../utils/notifications/useNotifications'
import TranslationCard from '../../../../../components/TranslationCard'
import { Translation } from '../../../../../core/shared/types'

import './styles.scss'
import { scrollViewError } from '../../../../../utils/scroll/scrollViewError'

interface FormLinkedThemesProps {
  isNew?: boolean
}

export const FormLinkedThemes: FC<FormLinkedThemesProps> = ({
  isNew = false,
}) => {
  // #region Hooks
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { channelId, sectorId } = useParams()
  const { fetchChannelDetail, channel, createThemes, updateThemes } =
    useChannels()
  const { showConfirmation } = useUi()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const notify = useNotifications()
  // #endregion Hooks

  // #region Form definition
  const INITIAL_VALUES = isNew
    ? {
        language: DEFAULT_LANG,
        themes: [{ translations: [{ name: '', language: DEFAULT_LANG }] }],
      }
    : { language: DEFAULT_LANG }

  const methods = useForm<FormLinkedThemesForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: INITIAL_VALUES,
  })

  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState,
    setError,
    formState: { dirtyFields },
  } = methods

  const { fields, append, remove } = useFieldArray<FormLinkedThemesForm>({
    control,
    name: 'themes',
    keyName: 'id',
  })

  const watchLanguage = watch('language')
  // #endregion Form definition

  // #region useMemo
  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  const translationsArray = useMemo(() => {
    const newArray: Translation[] = []
    channel?.themeTranslations.map((locale) => {
      newArray.push({ language: locale, name: '' })
    })
    return newArray
  }, [channel])

  const locales = useMemo(() => {
    if (isNew) {
      return {
        title: t('topic.theme.title.create', {
          channelName: channel?.translations[0].name,
        }),
        subtitle: t('topic.theme.subtitle.create'),
        feedbackOk: t('topic.theme.create.feedback.ok'),
        feedbackKo: t('topic.theme.create.feedback.ko'),
      }
    }
    return {
      title: t('topic.theme.title.edit', {
        channelName: channel?.translations[0].name,
      }),
      subtitle: t('topic.theme.subtitle.edit'),
      feedbackOk: t('topic.theme.edit.feedback.ok'),
      feedbackKo: t('topic.theme.edit.feedback.ko'),
    }
  }, [isNew, channel])

  // #endregion useMemo

  useBlockRouteChangeWithDialog(isDirtyWithoutLanguage && !isSubmitting)

  const getFormattedValuesToCreate = useCallback(() => {
    const values = getValues()
    return values.themes.map((theme) => {
      const name =
        theme.translations.find(
          (translation) => translation.language === DEFAULT_LANG
        )?.name ?? ''
      return {
        name,
      }
    })
  }, [getValues()])

  const getFormattedValuesToEdit = useCallback(() => {
    const values = getValues()
    return values.themes.map((theme) => {
      const name =
        theme.translations.find(
          (translation) => translation.language === watchLanguage
        )?.name ?? ''
      return {
        id: theme.id,
        name,
      }
    })
  }, [getValues()])

  // #region Handlers
  const onSubmit = async () => {
    setIsSubmitting(true)
    try {
      if (isNew) {
        const values = getFormattedValuesToCreate()
        if (values && channel) await createThemes(channel.id, values)
        setIsSubmitting(false)
        navigate(`${basePath}/sectors/${sectorId}/channels/${channelId}/themes`)
        notify.success(locales.feedbackOk)
      } else {
        const values = getFormattedValuesToEdit()
        if (values && channel)
          await updateThemes(channel.id, watchLanguage, values)
        setIsSubmitting(false)
        notify.success(locales.feedbackOk)
      }
    } catch (error: any) {
      setIsSubmitting(false)
      const duplicates = error.body.filter(
        (err: any) => err.code === 'ENTITY_CONFLICT'
      )
      if (duplicates) {
        duplicates.map((duplicate: any) => {
          const index = Number(duplicate.property.split('.')[0])
          setError(`themes.${index}.translations.${0}.name`, {
            type: 'custom',
            message: t('topic.theme.duplicate.error'),
          })
          scrollViewError()
        })
      } else {
        notify.error(locales.feedbackKo)
      }
    }
  }

  const handleCancel = () => {
    navigate(`${basePath}/sectors/${sectorId}/channels/${channelId}`, {
      state: { channelCurrentTab: ChannelsTabs.THEMES },
    })
  }

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          onConfirm: () => {
            reset({ themes: channel?.themes })
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const appendTheme = () => {
    append({ translations: [{ name: '', language: watchLanguage }] })
  }

  const handleRemoveTheme = (index: number) => {
    remove(index)
  }
  // #endregion Handlers

  // #region useEffect
  useEffect(() => {
    if (channelId && sectorId && channel?.id !== channelId) {
      fetchChannelDetail(channelId, sectorId)
    }
  }, [])

  useEffect(() => {
    if (!isNew && channel) {
      reset({ ...getValues(), themes: channel.themes })
    }
  }, [channel, isNew])

  useEffect(() => {
    if (watchLanguage === undefined) return
    const values = getValues()
    values.themes.map((theme, index) => {
      const translation = theme.translations.some(
        (translation) => translation.language == watchLanguage
      )

      if (!translation) {
        setValue(`themes.${index}.translations`, [
          ...theme.translations,
          {
            name: '',
            language: watchLanguage,
          },
        ])
      }
    })
  }, [watchLanguage])

  // #endregion useEffect

  return (
    <Main
      ariaLabelledby="topics-channels-themes-create"
      title={locales.title}
      subtitle={locales.subtitle}
      isVisibleRequiredFieldsLeyend
    >
      {channel && channel.themes && !isNew && (
        <TranslationCard
          translations={translationsArray}
          lang={watchLanguage}
          entity={t('brand.theme.entity')}
        />
      )}
      <FormProvider methods={methods}>
        {fields.map((item, index) => (
          <ThemeInput
            key={`${item.id}-${index}`}
            themeIndex={index}
            methods={methods}
            isNew={isNew}
            actualLanguage={watchLanguage}
            handleRemoveTheme={handleRemoveTheme}
            readonlyValue={channel?.themes[index]?.translations[0].name}
          />
        ))}
        {isNew && (
          <button className="btn btn-arrow" onClick={appendTheme}>
            {t('topic.theme.addButton')}
            <Icon name="add" className="ms-1 me-0" />
          </button>
        )}
        <FormActionsLang
          selectName="language"
          selectDisabled={isNew}
          isLoaded={true}
          methods={methods}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          onCancel={handleCancel}
          onBeforeChange={handleBeforeChangeLang}
        />
      </FormProvider>
    </Main>
  )
}

interface ThemeInputProps {
  themeIndex: number
  methods: UseFormReturn<FormLinkedThemesForm, object>
  isNew: boolean
  actualLanguage: string
  readonlyValue: string | undefined
  handleRemoveTheme: (index: number) => void
}

export const ThemeInput: FC<ThemeInputProps> = ({
  themeIndex,
  methods,
  isNew,
  actualLanguage,
  readonlyValue,
  handleRemoveTheme,
}) => {
  const { t } = useTranslation()

  const { control, getValues } = methods

  const { fields } = useFieldArray<FormLinkedThemesForm>({
    control,
    name: `themes.${themeIndex}.translations`,
    keyName: 'id',
  })

  const isTranslation = useMemo(() => {
    return actualLanguage !== DEFAULT_LANG
  }, [actualLanguage])

  const placeholderInput = useMemo(() => {
    if (isTranslation) {
      return t('topic.theme.multilanguage.placeholder')
    }
    return t('topic.theme.defaultlanguage.placeholder')
  }, [isTranslation])

  return (
    <>
      {fields.map(
        (translation: any, index) =>
          translation.language == actualLanguage && (
            <div
              key={`${translation.id}-${themeIndex}-${index}`}
              className="row channel-theme mb-4"
            >
              <div className="col-9">
                <FormTextInput
                  control={control}
                  name={`themes.${themeIndex}.translations[${index}].name`}
                  label={t('topic.theme.modal.name.label')}
                  placeholder={placeholderInput}
                  readOnlyValue={isTranslation ? readonlyValue : undefined}
                  type="text"
                  required={true}
                />
              </div>

              {isNew && getValues().themes.length > 1 && (
                <div className="col-1 channel-theme__trash">
                  <Icon
                    name="delete_outline"
                    onClick={() => handleRemoveTheme(themeIndex)}
                  />
                </div>
              )}
            </div>
          )
      )}
    </>
  )
}
