import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Icon } from '@liveconnect/icons'
import {
  FormSelect,
  FormTextInput,
  FormProvider,
  TextControl,
  Loader,
  Tooltip,
  MimeType,
  UploadInput,
} from '@liveconnect/components'

import { Main } from '../../../components/Main'
import { buildValidationSchema } from './validations'
import useTopicFamilies from '../../../core/topicFamilies/useTopicFamilies'
import useNotifications from '../../../utils/notifications/useNotifications'
import FormActions from '../../../components/forms/FormActions'
import useUi from '../../../core/ui/useUi'
import useLocalizations from '../../../core/localizations/useLocalizations'
import { DEFAULT_LANG } from '../../../i18n/config'
import { useCustomRouter } from '../../../utils/extractParams'
import { FetchError } from '../../../utils/fetch/types'
import { useBlockRouteChangeWithDialog } from '../../../utils/routing/useBlockRouteChange'
import TranslationCard from '../../../components/TranslationCard'
import { TopicFamilyDetail } from '../../../core/topicFamilies/types'
import useUploadInput from '../../../utils/uploadInput/useUploadInput'

import './styles.scss'

interface EditTopicData {
  name: string
  imageUrl: string
  language: string
}

const EditTopic: FC = () => {
  const { sectorId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { localizations } = useLocalizations()
  const { showConfirmation } = useUi()
  const { basePath } = useCustomRouter()
  const notify = useNotifications()
  const { editTopicFamily, fetchTopicFamily, uploadImage, topicFamily } =
    useTopicFamilies()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isTranslating, setIsTranslating] = useState<boolean>(false)
  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })

  const methods = useForm<EditTopicData>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
  })
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    setValue,
    formState,
    formState: { isValid, dirtyFields },
  } = methods

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  useBlockRouteChangeWithDialog(isDirtyWithoutLanguage && !isSubmitting)

  const watchLang = watch('language')

  const defaultTopicFamily = useMemo(() => {
    return (topicFamily?.translations || []).find(
      (item) => item.language === DEFAULT_LANG
    )
  }, [topicFamily])

  const youShouldNotChange = () => {
    throw new Error('Cannot change value to disabled input')
  }

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          iconName: 'report_problem',
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const handleCancel = () => {
    navigate(`${basePath}/sectors`)
  }

  const onSubmit = async (values: EditTopicData) => {
    setIsSubmitting(true)
    try {
      if (values?.imageUrl?.includes('blob:')) {
        const imageUrl = await uploadImage(values.imageUrl)
        values = { ...values, imageUrl }
      }
      const response = await editTopicFamily({
        ...(topicFamily as TopicFamilyDetail),
        ...values,
      })
      if (response) {
        notify.success(t('topic.topic.modal.succeessFeedback'))
      }
      setIsSubmitting(false)
    } catch (error: unknown) {
      setIsSubmitting(false)
      const fetchError = error as FetchError
      if (fetchError.status === 409) {
        notify.warning(t('topic.channel.modal.formError'))
        setError('name', {
          type: 'custom',
          message: t(`validations.ENTITY_CONFLICT`, {
            label: t(`topic.topic.modal.name`).toLowerCase(),
          }),
        })
      } else {
        notify.error(t('topic.topic.modal.unknownError'))
      }
    }
  }

  useEffect(() => {
    if (watchLang !== DEFAULT_LANG) {
      setIsTranslating(true)
    } else {
      setIsTranslating(false)
    }
    if (topicFamily) {
      const i18nData = (topicFamily?.translations || []).find(
        (item) => item.language === watchLang
      )
      reset({
        ...topicFamily,
        ...i18nData,
        language: watchLang,
      })
    }
  }, [watchLang])

  useEffect(() => {
    if (topicFamily) {
      const i18nData = topicFamily.translations.find(
        (item) => item.language === DEFAULT_LANG
      )
      reset({ ...topicFamily, ...i18nData })
    }
  }, [topicFamily])

  useEffect(() => {
    sectorId && fetchTopicFamily(sectorId)
  }, [sectorId])

  const renderLanguageSelector = () => {
    return (
      <>
        <FormSelect
          control={control}
          name="language"
          label=""
          options={localizations.map((item) => ({
            label: t(`localizations.${item.isoCode}`),
            value: item.isoCode,
          }))}
          noOptionsMessage={t('select.empty')}
          menuPlacement="top"
          isClearable={false}
          onBeforeChange={handleBeforeChangeLang}
        />
        <Tooltip content={t('brand.changeLanguage.tooltip')} className="ms-2">
          <Icon name="info_outline" tabIndex={0} />
        </Tooltip>
      </>
    )
  }

  return (
    <Main ariaLabelledby="list-topics-title" className="EditTopic">
      <div className="d-flex justify-content-between">
        <h1 id="list-topics-title" className="h3">
          {t('topic.topic.edit.modal.title')}
        </h1>
        <div className="d-flex flex-row-reverse modal-required-legend">
          <div className="bd-highlight">{t('common.required')}</div>
        </div>
      </div>
      {topicFamily && (
        <TranslationCard
          translations={topicFamily.translations}
          lang={watchLang}
          entity={t('topic.entity')}
        />
      )}
      <FormProvider methods={methods}>
        <div className="EditTopic__form">
          <div className="row">
            <div className="col-12">
              {isTranslating && (
                <TextControl
                  label={t('topic.topic.modal.name')}
                  type="text"
                  name="name"
                  value={defaultTopicFamily?.name as string}
                  disabled={true}
                  onChange={youShouldNotChange}
                  required={true}
                />
              )}
              <FormTextInput
                control={control}
                name="name"
                label={isTranslating ? undefined : t('topic.topic.modal.name')}
                placeholder={t('topic.topic.modal.placeholder')}
                type="text"
                required={!isTranslating}
              />
            </div>
          </div>
          {!isTranslating && (
            <div className="row">
              <div className="col-12 upload__content">
                <UploadInput
                  control={control}
                  title={t('topic.topic.modal.image')}
                  name="imageUrl"
                  accept={accept}
                  onRemoveFile={() => setValue('imageUrl', '')}
                  selectFilesError={(error) =>
                    setError('imageUrl', {
                      message: error.message,
                    })
                  }
                  labels={labels}
                />
              </div>
            </div>
          )}
        </div>
        <FormActions languageSelector={renderLanguageSelector()}>
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-outline-primary"
          >
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {isSubmitting ? <Loader /> : t('common.save')}
          </button>
        </FormActions>
      </FormProvider>
    </Main>
  )
}

export default EditTopic
