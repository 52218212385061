import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import SectionsScreen from '../pages/Brand/EditSections'
import SponsorsScreen from '../pages/Brand/EditSponsors'

const SectionsRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SectionsScreen isNew={true} />} />
      <Route path="/:sectionId" element={<SectionsScreen isNew={false} />} />
      <Route
        path="/:sectionId/sponsors"
        element={<SponsorsScreen isNew={true} />}
      />
      <Route
        path="/:sectionId/sponsors/:sponsorId"
        element={<SponsorsScreen isNew={false} />}
      />
    </Routes>
  )
}

export default SectionsRouter
