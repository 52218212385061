import { useAppDispatch, useAppSelector } from '../reduxProvider'
import {
  UseChannelsResult,
  Channel,
  ChannelDetail,
  TranslationsSuccess,
  BrandSpacesSuccess,
  PostChannelPayload,
  PutChannelPayload,
  FormLinkedThemesFormResponse,
  ThemeChannel,
} from './types'
import {
  setDetail,
  showLoader,
  hideLoader,
  setTranslations,
  setBrandspaces,
} from './store'
import useFetch from '../../utils/fetch/useFetch'

const useChannels = (): UseChannelsResult => {
  const dispatch = useAppDispatch()
  const { detail, isLoaded } = useAppSelector((state) => state.channels)

  const { get, post, put, patch, del } = useFetch()
  const endpoint = 'channels'
  const themesEnpoint = 'themes'

  const getChannels = async (
    params: Record<string, string | Array<string | number> | boolean>
  ): Promise<Channel[] | undefined> =>
    await get({
      endpoint,
      params,
    })

  const fetchChannelDetail = async (id: string, sectorId: string) => {
    dispatch(showLoader())
    const response: ChannelDetail | undefined = await get({
      endpoint: `${endpoint}/${id}`,
      params: { sectorId },
    })
    if (response) {
      dispatch(setDetail(response))
    }
    dispatch(hideLoader())
  }

  const addChannel = async (channel: PostChannelPayload) => {
    dispatch(showLoader())
    const response: string | undefined = await post({
      endpoint: `${endpoint}`,
      body: channel,
    })
    if (response) {
      dispatch(hideLoader())
    }

    return response
  }

  const editChannel = async (channel: PutChannelPayload, id: string) => {
    const response: TranslationsSuccess | undefined = await put({
      endpoint,
      id,
      body: channel,
    })
    if (response) {
      dispatch(setTranslations(response.translations))
    }
    return response
  }

  const deleteChannel = async (id: string) => {
    await del({
      endpoint,
      id,
    })
  }

  const editBrandsChannel = async (brandspaces: string[], id: string) => {
    const response: BrandSpacesSuccess | undefined = await patch({
      endpoint: `${endpoint}/${id}`,
      id: 'assignbrandspaces',
      body: brandspaces,
    })
    if (response) {
      dispatch(setBrandspaces(response.brandSpaces))
    }
    return response
  }

  const createThemes = async (
    channelId: string,
    newThemes: { name: string }[]
  ): Promise<void> => {
    const response: FormLinkedThemesFormResponse | undefined = await post({
      endpoint: themesEnpoint,
      body: {
        channelId,
        newThemes,
      },
    })
    if (response) {
      dispatch(
        setDetail({
          ...detail!,
          ...response,
        })
      )
    }
  }

  const updateThemes = async (
    channelId: string,
    language: string,
    themesUpdated: { id: string; name: string }[]
  ): Promise<void> => {
    const response: FormLinkedThemesFormResponse | undefined = await put({
      endpoint: themesEnpoint,
      body: {
        channelId,
        language,
        themesUpdated,
      },
    })
    if (response) {
      dispatch(
        setDetail({
          ...detail!,
          ...response,
        })
      )
    }
  }

  const publishTheme = async (
    themeId: string,
    channelId: string,
    isPublished: boolean
  ) => {
    const response = await patch({
      endpoint: themesEnpoint,
      id: `${themeId}`,
      body: { channelId, published: isPublished },
    })
    if (response) {
      dispatch(
        setDetail({
          ...detail!,
          themes: detail!.themes.map((theme) => {
            if (theme.id === themeId) return { ...theme, isPublished }
            return theme
          }),
        })
      )
    }
  }

  const deleteTheme = async (id: string) => {
    try {
      await del({
        endpoint: themesEnpoint,
        id,
      })
      dispatch(
        setDetail({
          ...detail!,
          themes: detail!.themes.filter((theme) => theme.id !== id),
        })
      )
    } catch (e) {}
  }

  const sortThemes = async (themes: ThemeChannel[]) => {
    const oldOrder = detail?.themes ?? []
    try {
      dispatch(
        setDetail({
          ...detail!,
          themes,
        })
      )
      await put({
        endpoint: `${themesEnpoint}/reorder`,
        body: {
          themes: themes.map(({ id }, index) => ({ id, index })),
        },
      })
    } catch {
      dispatch(
        setDetail({
          ...detail!,
          themes: oldOrder,
        })
      )
    }
  }

  return {
    channel: detail,
    isLoaded,
    getChannels,
    fetchChannelDetail,
    addChannel,
    editChannel,
    deleteChannel,
    editBrandsChannel,
    createThemes,
    updateThemes,
    publishTheme,
    deleteTheme,
    sortThemes,
  }
}

export default useChannels
