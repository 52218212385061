import { useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { addHours, subSeconds, set } from 'date-fns'

import { yupResolver } from '@hookform/resolvers/yup'

import {
  AsyncSelectControl,
  FormDate,
  FormRichText,
  FormSelect,
  FormTextInput,
  Option,
  PaginatedResult,
  FormProvider,
  FormMultiSelect,
  Loader,
  MimeType,
  UploadInput,
  FormTextarea,
  FormToggle,
} from '@liveconnect/components'

import { buildValidationSchema } from './validations'
import {
  ActivityConfigForm,
  ActivityType,
  ActivityBrandSpace,
  ActivityDetail,
  Location,
  ActivityChannel,
} from '../../../../core/activities/types'

import { FormActionsLang } from '../../../../components/forms/FormActionsLang'
import { useCustomRouter } from '../../../../utils/extractParams'
import useNotifications from '../../../../utils/notifications/useNotifications'
import { DEFAULT_LANG } from '../../../../i18n/config'
import useUploadInput from '../../../../utils/uploadInput/useUploadInput'

import FormDateTime from '../../../../components/forms/FormDateTime'
import { useBlockRouteChangeWithDialog } from '../../../../utils/routing/useBlockRouteChange'
import TranslationCard from '../../../../components/TranslationCard'
import { FetchError } from '../../../../utils/fetch/types'
import InfoBlock from '../../../../components/InfoBlock'
import { useActivities, useLocalizations, useUi } from '../../../../core'
import { uploadFile } from '../../../../utils/fetch/uploadFile'
import { characterCounterText } from '../../../../utils/characterCounterText'

import './styles.scss'

interface ActivityForm extends ActivityConfigForm {
  transLanguage: string
}

const ActivityFormConfig = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { activityId } = useParams()
  const { basePath } = useCustomRouter()
  const { localizations } = useLocalizations()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const {
    isLoaded,
    activity,
    fetchActivity,
    createActivity,
    editActivity,
    editActivityTranslation,
    getBrandSpaces,
    uploadImageActivity,
    uploadFileActivity,
    getUrlJitsi,
    getLocations,
    getChannelsByBrandSpaces,
  } = useActivities()
  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })
  const [brandSpaces, setBrandSpaces] = useState<ActivityBrandSpace[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [endActivity, setEndActivity] = useState<boolean>(false)
  const [nodeType, setNodeType] = useState<string>('')
  const [predictions, setPredictions] = useState<Location[]>([])
  const [channels, setChannels] = useState<ActivityChannel[]>([])

  const defaultValues = {
    isPublished: false,
    transLanguage:
      (localizations || []).find((item) => item.isDefault)?.isoCode ||
      DEFAULT_LANG,
    translations: [
      {
        language: DEFAULT_LANG,
        title: '',
        description: '',
        imageURL: '',
        resultDescription: '',
        resultTitle: '',
      },
    ],
  }

  const methods = useForm<ActivityForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t, endActivity)),
    defaultValues,
  })

  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    trigger,
    setError,
    formState,
    formState: { dirtyFields },
  } = methods

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.transLanguage
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  useBlockRouteChangeWithDialog(isDirtyWithoutLanguage && !isSubmitting)

  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'translations', // unique name for your Field Array
  })

  const {
    type: watchActivityType,
    transLanguage: watchTransLanguage,
    hasMaxAttendees: watchHasMaxAttendees,
    maxActivityAttendees: watchMaxActivityAttendees,
    videoConferenceType: watchVideoConferenceType,
    roundTableType: watchRoundTableType,
    askAuthorType: watchAskAuthorType,
    faceToFaceType: watchFaceToFaceType,
    challengeType: watchChallengeType,
    expirationDate: watchExpirationDate,
    startDateParticipation: watchStartDateParticipation,
    endDateParticipation: watchEndDateParticipation,
  } = watch()

  const { labels: labelsDoc, accept: acceptDoc } = useUploadInput({
    allowedExtensions: [MimeType.PDF],
    fileUploaded: watchChallengeType?.documentFileName,
  })

  const watchBrandSpaces = watch('brandSpaceId')

  const {
    endDate: watchEndDateVideoConferenceType,
    startDate: watchStartDateVideoConferenceType,
  } = watchVideoConferenceType || {}

  const {
    endDate: watchEndDateAskAuthorType,
    startDate: watchStartDateAskAuthorType,
  } = watchAskAuthorType || {}

  const {
    endDate: watchEndDateRoundTableType,
    startDate: watchStartDateRoundTableType,
  } = watchRoundTableType || {}

  const {
    endDate: watchEndDateFaceToFaceType,
    startDate: watchStartDateFaceToFaceType,
  } = watchFaceToFaceType || {}

  const {
    endDate: watchEndDateChallengeType,
    startDate: watchStartDateChallengeType,
  } = watchChallengeType || {}

  const isEditLang = watchTransLanguage != DEFAULT_LANG

  const initBrandsWithoutSpace = async () => {
    if (brandSpaces.length == 0) {
      const _brandSpaces = await getBrandSpaces()
      setBrandSpaces(_brandSpaces)
    }
  }

  const eventCodes = useMemo(() => {
    if (!brandSpaces) return []
    const options = brandSpaces.map((brand: ActivityBrandSpace) => {
      return {
        value: brand.id,
        label: brand.name,
      }
    })
    return options
  }, [brandSpaces])

  const parseDates = (activity: ActivityDetail) => {
    if (
      activity.videoConferenceType ||
      activity.askAuthorType ||
      activity.roundTableType
    ) {
      const dateActivity =
        activity.videoConferenceType?.endDate ||
        activity.askAuthorType?.endDate ||
        activity.roundTableType?.endDate
      if (dateActivity) {
        const endDate = new Date(dateActivity).getTime()
        const today = new Date().getTime()
        if (today > endDate) {
          return false
        }
      }
    }
    return true
  }

  const activityTypes = useMemo(() => {
    return Object.values(ActivityType).map((type) => {
      return {
        value: type,
        label: t(`activity.form.config.type.options.${type}`),
      }
    })
  }, [])

  const languages = useMemo(() => {
    return localizations.map((item) => ({
      label: t(`localizations.${item.isoCode}`),
      value: item.isoCode,
    }))
  }, [localizations])

  const translationsComponent: { language: string; name: string }[] =
    useMemo(() => {
      if (activity) {
        return activity.translations.map((translation) => {
          return { language: translation.language, name: translation.title }
        })
      }
      return []
    }, [activity])

  const handleCancel = () => {
    navigate(`${basePath}/activities`)
  }

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          iconName: 'report_problem',
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const handleChangeTransLanguage = () => {
    if (!watchTransLanguage) return
    const values = getValues()
    const translation = values.translations.find(
      (translation) => translation.language == watchTransLanguage
    )
    if (!translation) {
      setValue('translations', [
        ...values.translations,
        {
          language: watchTransLanguage,
          title: '',
          description: '',
          imageURL: '',
          name: '',
          resultDescription: '',
          resultTitle: '',
        },
      ])
    }
  }

  const formatDataToSubmit = (values: ActivityForm) => {
    const { startDateParticipation, endDateParticipation, expirationDate } =
      values

    let videoConferenceType,
      faceToFaceType,
      askAuthorType,
      roundTableType,
      challengeType

    if (values.type == ActivityType.Video && values.videoConferenceType) {
      videoConferenceType = {
        ...values.videoConferenceType,
        startDate: new Date(values.videoConferenceType.startDate),
        endDate: new Date(values.videoConferenceType.endDate),
      }
    }

    if (values.type == ActivityType.AskAuthor && values.askAuthorType) {
      askAuthorType = {
        ...values.askAuthorType,
        startDate: new Date(values.askAuthorType.startDate),
        endDate: new Date(values.askAuthorType.endDate),
      }
    }

    if (values.type == ActivityType.RoundTable && values.roundTableType) {
      roundTableType = {
        ...values.roundTableType,
        startDate: new Date(values.roundTableType.startDate),
        endDate: new Date(values.roundTableType.endDate),
      }
    }

    if (values.type == ActivityType.FaceToFace && values.faceToFaceType) {
      faceToFaceType = {
        ...values.faceToFaceType,
        startDate: new Date(values.faceToFaceType.startDate),
        endDate: new Date(values.faceToFaceType.endDate),
      }
    }

    if (values.type == ActivityType.Challenge && values.challengeType) {
      challengeType = {
        ...values.challengeType,
        startDate: new Date(values.challengeType.startDate),
        endDate: new Date(values.challengeType.endDate),
      }
    }

    const _activity: ActivityConfigForm = {
      ...values,
      startDateParticipation: new Date(startDateParticipation),
      endDateParticipation: new Date(endDateParticipation),
      maxActivityAttendees: values.hasMaxAttendees
        ? values.maxActivityAttendees
        : undefined,
      expirationDate: addHours(
        subSeconds(
          set(new Date(expirationDate), {
            hours: 0,
            minutes: 0,
            seconds: 0,
          }),
          1
        ),
        24
      ),
      videoConferenceType,
      faceToFaceType,
      askAuthorType,
      roundTableType,
      challengeType,
    }
    return _activity
  }

  const handleChangeLocation = (value: string) => {
    const prediction = predictions.find(
      (prediction) => value === prediction.fullAddress
    )
    if (prediction) {
      setValue('faceToFaceType.location.latitude', prediction.latitude)
      setValue('faceToFaceType.location.longitude', prediction.longitude)
    } else {
      setValue('faceToFaceType.location.latitude', undefined)
      setValue('faceToFaceType.location.longitude', undefined)
    }
  }

  const handleSearchLocations = async (
    query: string,
    page: number
  ): Promise<PaginatedResult<any>> => {
    const response = await getLocations(query)
    const locations = response.filter((loc) => loc.latitude && loc.longitude)

    return new Promise((resolve) => {
      resolve({
        page,
        pageSize: 5,
        hasMoreElements: false,
        data: locations,
      })
      setPredictions(locations)
    })
  }

  const handleInitialLocations = async (
    query: string | string[]
  ): Promise<Location> => {
    const locations = await getLocations(query as string)

    const location = locations.find(
      (loc) => loc.fullAddress === query
    ) as Location

    return new Promise((resolve) => {
      resolve(location)
    })
  }

  const mapLocationToOption = (location: Location): Option<string> => {
    return {
      label: location.fullAddress,
      value: location.fullAddress,
    }
  }

  const selectFilesSuccess = (files: File[]) => {
    const reader = new FileReader()

    reader.onload = function (event: any) {
      const blobData = event.target.result
      const blobUrl = URL.createObjectURL(
        new Blob([blobData], { type: 'application/pdf' })
      )
      setValue('challengeType.documentFile', blobUrl)
      setValue('challengeType.documentFileName', files[0].name)
    }

    reader.onerror = function (event: any) {
      // eslint-disable-next-line no-console
      console.error('Error al leer el archivo:', event.target.error)
    }

    reader.readAsArrayBuffer(files[0])
  }

  const onSubmit = async (values: ActivityForm) => {
    setIsSubmitting(true)

    try {
      const _activity = formatDataToSubmit(values)
      if (_activity == undefined) return
      const position = values.translations.findIndex(
        (translation) => translation.language == watchTransLanguage
      )

      _activity.translations[position].imageURL = await uploadFile(
        _activity.translations[position].imageURL,
        uploadImageActivity
      )

      if (_activity.challengeType?.resultImage) {
        _activity.challengeType.resultImage = await uploadFile(
          _activity.challengeType.resultImage,
          uploadImageActivity
        )
      }

      if (_activity.challengeType?.documentFile) {
        _activity.challengeType.documentFile = await uploadFile(
          _activity.challengeType.documentFile,
          uploadFileActivity
        )
      }

      if (watchTransLanguage == DEFAULT_LANG) {
        _activity.translations[0].language = watchTransLanguage
        if (!activityId) {
          const response = await createActivity(_activity)
          if (response) {
            navigate(`../${response.id}/edit`)
            notify.success(t('activity.form.config.createSuccess'))
          }
          setIsSubmitting(false)
        } else {
          let edit: 'form' | 'translation' | 'all' = 'form'
          const dirtyFieldsKeys = Object.keys(dirtyFields)
          const isFormChanged = dirtyFieldsKeys.some(
            (key) => key != 'translations'
          )
          const isTranslationsChanged = dirtyFields.translations != undefined

          if (isTranslationsChanged) edit = 'translation'
          if (isFormChanged && isTranslationsChanged) edit = 'all'

          await editActivity({
            id: activityId,
            activity: _activity,
            edit,
          })
          setIsSubmitting(false)
          navigate(`${basePath}/activities`)
          notify.success(t('activity.form.config.editSuccess'))
        }
      } else {
        const translation = _activity.translations.find(
          (translation) => translation.language == watchTransLanguage
        )
        if (translation && activityId) {
          await editActivityTranslation({
            id: activityId,
            translation: translation,
          })
          navigate(`${basePath}/activities`)
          notify.success(t('activity.form.config.editSuccess'))
        }
      }
    } catch (error: any) {
      if (error.body[0].property === '0.maxactivityattendees') {
        const numberMax = Number(error.body[0].message.split(' ').at(-1))
        setError(
          'maxActivityAttendees',
          {
            type: 'custom',
            message: t('activity.form.config.limit.checkNumber', {
              max: numberMax,
            }),
          },
          { shouldFocus: true }
        )
        const errorText = t('validations.checkFields', {
          fields: t('activity.form.config.limit.label'),
        })
        notify.warning(errorText)
      } else {
        const toast = activityId
          ? 'activity.form.config.editError'
          : 'activity.form.config.editError'
        notify.error(t(toast))
      }
      setIsSubmitting(false)
    }
  }

  const disabledDate = (dateEnd: Date) => {
    const endDate = new Date(dateEnd).getTime()
    const today = new Date().getTime()
    if (today > endDate) {
      return true
    }
    return false
  }

  const channelsOptions = useMemo(() => {
    if (!channels) return []
    const options = channels.map((channel: ActivityChannel) => {
      return {
        value: channel.id,
        label: channel.name,
      }
    })
    return options
  }, [channels])

  const initChannelsWithSpace = async () => {
    if (watchBrandSpaces) {
      const _channels = await getChannelsByBrandSpaces([watchBrandSpaces])
      return setChannels(_channels)
    }
    return setChannels([])
  }

  useEffect(() => {
    initBrandsWithoutSpace()
  }, [])
  useEffect(() => {
    if (watchBrandSpaces && watchBrandSpaces !== '') {
      initChannelsWithSpace()
    }
  }, [watchBrandSpaces])

  const getActivity = async () => {
    try {
      if (activityId) await fetchActivity(activityId)
    } catch (error) {
      const fetchError = error as FetchError
      if (fetchError.status === 403) {
        navigate(`${basePath}/access-denied`)
      } else {
        notify.error(t('common.unknownError'))
      }
    }
  }

  useEffect(() => {
    getActivity()
  }, [activityId])

  useEffect(() => {
    if (!activity || !activityId) return
    reset({
      ...activity,
      brandSpaceId: activity.brandSpace.id,
    })
    const dateEndActivity =
      activity.videoConferenceType?.endDate ||
      activity.askAuthorType?.endDate ||
      activity.roundTableType?.endDate ||
      activity.faceToFaceType?.endDate ||
      activity.challengeType?.endDate
    dateEndActivity && setEndActivity(disabledDate(dateEndActivity))
  }, [activity])

  useEffect(() => {
    if (watchActivityType && watchMaxActivityAttendees) {
      trigger('maxActivityAttendees')
    }
    switch (watchActivityType) {
      case 'FaceToFace':
        setNodeType('faceToFaceType')
        setValue('hasMaxAttendees', false)
        break
      case 'Challenge':
        setNodeType('challengeType')
        setValue('hasMaxAttendees', false)
        break
      case 'VideoConference':
        setNodeType('videoConferenceType')
        setValue('hasMaxAttendees', true)
        break
      case 'RoundTable':
        setNodeType('roundTableType')
        setValue('hasMaxAttendees', true)
        break
      case 'AskAuthor':
        setNodeType('askAuthorType')
        setValue('hasMaxAttendees', true)
        break
    }
  }, [watchActivityType])

  useEffect(() => {
    // Trigger 'expirationDate' when any of the specified types and 'watchExpirationDate' changes
    if (
      (watchEndDateVideoConferenceType ||
        watchEndDateFaceToFaceType ||
        watchEndDateAskAuthorType ||
        watchEndDateChallengeType ||
        watchEndDateRoundTableType) &&
      watchExpirationDate
    ) {
      trigger('expirationDate')
    }

    // Trigger 'videoConferenceType.endDate' and other similar types when both start and end dates are available
    if (watchStartDateVideoConferenceType && watchEndDateVideoConferenceType) {
      trigger('videoConferenceType.endDate')
    }
    if (watchStartDateFaceToFaceType && watchEndDateFaceToFaceType) {
      trigger('faceToFaceType.endDate')
    }
    if (watchStartDateAskAuthorType && watchEndDateAskAuthorType) {
      trigger('askAuthorType.endDate')
    }
    if (watchStartDateRoundTableType && watchEndDateRoundTableType) {
      trigger('roundTableType.endDate')
    }
    if (watchStartDateChallengeType && watchEndDateChallengeType) {
      trigger('challengeType.endDate')
    }

    // Trigger 'endDateParticipation' and 'faceToFaceType.startDate', and other similar types when 'watchStartDateParticipation' changes
    if (watchEndDateParticipation) {
      trigger('endDateParticipation')
    }
    if (watchStartDateFaceToFaceType) {
      trigger('faceToFaceType.startDate')
    }
    if (watchStartDateAskAuthorType) {
      trigger('askAuthorType.startDate')
    }
    if (watchStartDateVideoConferenceType) {
      trigger('videoConferenceType.startDate')
    }
    if (watchStartDateRoundTableType) {
      trigger('roundTableType.startDate')
    }
  }, [
    watchEndDateVideoConferenceType,
    watchEndDateFaceToFaceType,
    watchEndDateAskAuthorType,
    watchEndDateRoundTableType,
    watchEndDateChallengeType,
    watchChallengeType,
    watchExpirationDate,
    watchStartDateVideoConferenceType,
    watchStartDateFaceToFaceType,
    watchStartDateAskAuthorType,
    watchStartDateRoundTableType,
    watchStartDateChallengeType,
    watchStartDateParticipation,
    watchEndDateParticipation,
  ])

  useEffect(() => {
    handleChangeTransLanguage()
  }, [watchTransLanguage])

  useEffect(() => {
    trigger('maxActivityAttendees')
  }, [watchHasMaxAttendees])

  return (
    <>
      {activityId && !isLoaded && <Loader />}
      <div
        className="AFC"
        style={{ display: !activityId || isLoaded ? 'block' : 'none' }}
      >
        <FormProvider methods={methods}>
          <div className="AFC__section">
            <div className="section_header">
              {activityId && activity && (
                <TranslationCard
                  translations={translationsComponent}
                  lang={watchTransLanguage}
                  entity={t('activities.entity')}
                />
              )}
              <span className="ActivityForm__header__title__required">
                {t('common.required')}
              </span>
            </div>

            <p className="AFC__title">{t('activity.form.config.data.title')}</p>
            <p className="AFC__subtitle">
              {t('activity.form.config.data.subtitle')}
            </p>
            {!isEditLang && (
              <div className="row">
                <div className="col-9">
                  <FormSelect
                    control={control}
                    name="brandSpaceId"
                    label={t('activity.form.config.brandSpaceId.label')}
                    placeholder={t(
                      'activity.form.config.brandSpaceId.placeholder'
                    )}
                    options={eventCodes}
                    noOptionsMessage={t('select.empty')}
                    isClearable={false}
                    required
                    disabled={activityId != undefined}
                    data-testid="AFC__brandSpaceId"
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-9">
                <FormMultiSelect
                  control={control}
                  name="channels"
                  label={t('activities.filters.fields.channels.connect')}
                  placeholder={t(
                    'activities.filters.fields.channels.placeholder'
                  )}
                  options={channelsOptions}
                  disabled={!watchBrandSpaces}
                  required
                  data-testid="AFC__channels"
                />
              </div>
            </div>
            {fields.map(
              (field, index) =>
                field.language == watchTransLanguage && (
                  <div key={field.id}>
                    <div className="row">
                      <div className="col-9">
                        <FormTextInput
                          control={control}
                          name={`translations.${index}.title`}
                          label={t('activity.form.config.title.label')}
                          placeholder={t(
                            'activity.form.config.title.placeholder'
                          )}
                          type="text"
                          required
                          data-testid={`AFC__translations.${index}.title`}
                          helperText={t(
                            'activity.form.config.title.placeholder.max'
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <FormRichText
                          control={control}
                          name={
                            `translations.${index}.description` as keyof ActivityForm
                          }
                          label={t('activity.form.config.description.label')}
                          placeholder={t(
                            'activity.form.config.description.placeholder'
                          )}
                          required
                          data-testid={`AFC__translations.${index}.description`}
                          helperText={characterCounterText(
                            watch(`translations.${index}.description`),
                            1400,
                            t
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <UploadInput
                          control={control}
                          title={t('activity.form.config.image.label')}
                          name={`translations.${index}.imageURL`}
                          accept={accept}
                          onRemoveFile={() =>
                            setValue(`translations.${index}.imageURL`, '')
                          }
                          selectFilesError={(error) =>
                            setError(`translations.${index}.imageURL`, {
                              message: error.message,
                            })
                          }
                          required
                          rules={{
                            image: {
                              maxFileSize: 1,
                              maxWidth: 584,
                              maxHeight: 296,
                            },
                          }}
                          data-testid={`AFC__translations.${index}.imageURL`}
                          labels={labels}
                        />
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>

          {!isEditLang && (
            <div className="AFC__section">
              <p className="AFC__title">{t('activity.form.config.title')}</p>
              <p className="AFC__subtitle">
                {t('activity.form.config.subtitle')}
              </p>
              <div className="row">
                <div className="col-9">
                  <div className="row">
                    <div className="col-6">
                      <FormSelect
                        control={control}
                        name="type"
                        label={t('activity.form.config.type.label')}
                        placeholder={t('activity.form.config.type.placeholder')}
                        options={activityTypes}
                        required
                        isClearable={false}
                        noOptionsMessage={t('select.empty')}
                        disabled={activityId != undefined}
                        data-testid={`AFC__type`}
                      />
                      {watchActivityType && (
                        <FormToggle
                          name="hasMaxAttendees"
                          control={control}
                          label={t('activity.form.config.hasLimit.label')}
                          disabled={[
                            ActivityType.Video,
                            ActivityType.AskAuthor,
                            ActivityType.RoundTable,
                          ].includes(watchActivityType)}
                        />
                      )}
                    </div>
                    <div className="col-6">
                      {watchActivityType && watchHasMaxAttendees && (
                        <FormTextInput
                          control={control}
                          name="maxActivityAttendees"
                          type="number"
                          label={t('activity.form.config.limit.label')}
                          placeholder={t(
                            'activity.form.config.limit.placeholder'
                          )}
                          required
                          data-testid={`AFC__maxActivityAttendees`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-9">
                  <div className="row">
                    <div className="col-6">
                      <FormDateTime
                        control={control}
                        name="startDateParticipation"
                        label={t(
                          'activity.form.config.startDateParticipation.label'
                        )}
                        placeholder={t(
                          'activity.form.config.startDateParticipation.placeholder'
                        )}
                        tooltip={t(
                          'activity.form.config.startDateParticipation.tooltip'
                        )}
                        disabled={endActivity}
                        required
                        data-testid={`AFC__startDateParticipation`}
                      />
                    </div>
                    <div className="col-6">
                      <FormDateTime
                        control={control}
                        name="endDateParticipation"
                        label={t(
                          'activity.form.config.endDateParticipation.label'
                        )}
                        placeholder={t(
                          'activity.form.config.endDateParticipation.placeholder'
                        )}
                        tooltip={t(
                          'activity.form.config.endDateParticipation.tooltip'
                        )}
                        disabled={endActivity}
                        required
                        data-testid={`AFC__endDateParticipation`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-9">
                  <div className="row">
                    <div className="col-6">
                      <FormDate
                        control={control}
                        name="expirationDate"
                        label={t('activity.form.config.expirationDate.label')}
                        placeholder={t(
                          'activity.form.config.expirationDate.placeholder'
                        )}
                        tooltip={t(
                          'activity.form.config.expirationDate.tooltip'
                        )}
                        min={new Date()}
                        required
                        data-testid={`AFC__expirationDate`}
                      />
                    </div>
                    <div className="col-6">
                      <FormSelect
                        control={control}
                        name="language"
                        label={t('activity.form.config.activityLanguage.label')}
                        placeholder={t(
                          'activity.form.config.activityLanguage.placeholder'
                        )}
                        options={languages}
                        required
                        isClearable={false}
                        noOptionsMessage={t('select.empty')}
                        data-testid={`AFC__language`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {watchActivityType && (
            <div className="AFC__section">
              {!isEditLang && (
                <p className="AFC__title">
                  {t('activity.form.config.infoSpecify', {
                    type: t(
                      `activity.form.config.type.options.${watchActivityType}`
                    ),
                  })}
                </p>
              )}
              {[
                ActivityType.Video,
                ActivityType.AskAuthor,
                ActivityType.RoundTable,
              ].includes(watchActivityType) &&
                !isEditLang && (
                  <>
                    <div className="row">
                      <div className="col-9">
                        <div className="row">
                          <div className="col-6">
                            <FormDateTime
                              control={control}
                              key={`${nodeType}.startDate`}
                              name={
                                `${nodeType}.startDate` as keyof ActivityForm
                              }
                              label={t('activity.form.config.startDate.label')}
                              placeholder={t(
                                'activity.form.config.startDate.placeholder'
                              )}
                              disabled={endActivity}
                              required
                              data-testid={`AFC__${nodeType}.startDate`}
                            />
                          </div>
                          <div className="col-6">
                            <FormDateTime
                              control={control}
                              key={`${nodeType}.endDate`}
                              name={`${nodeType}.endDate` as keyof ActivityForm}
                              label={t('activity.form.config.endDate.label')}
                              placeholder={t(
                                'activity.form.config.endDate.placeholder'
                              )}
                              tooltip={t(
                                'activity.form.config.endDate.tooltip'
                              )}
                              disabled={endActivity}
                              required
                              data-testid={`AFC__${nodeType}.endDate`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-9">
                        <p className="AFC__title mb-1">
                          {t('activity.form.config.linksVideoConference')}
                        </p>
                        <FormTextInput
                          control={control}
                          key={`${nodeType}.broadcastURL`}
                          name={`${nodeType}.broadcastURL`}
                          label={t(
                            'activity.form.config.linksVideoConference.broadcastURL.label'
                          )}
                          placeholder={t(
                            'activity.form.config.linksVideoConference.broadcastURL.placeholder'
                          )}
                          type="text"
                          required
                          data-testid={`AFC__${nodeType}.broadcastURL`}
                        />
                        {activity && (
                          <FormTextInput
                            control={control}
                            key={`${nodeType}.postBroadcastURL`}
                            name={`${nodeType}.postBroadcastURL`}
                            label={t(
                              'activity.form.config.linksVideoConference.postBroadcastURL.label'
                            )}
                            placeholder={t(
                              'activity.form.config.linksVideoConference.postBroadcastURL.placeholder'
                            )}
                            type="text"
                            tooltip={t(
                              'activity.form.config.linksVideoConference.postBroadcastURL.tooltip'
                            )}
                            disabled={parseDates(activity) || !activityId}
                            data-testid={`AFC__${nodeType}.postBroadcastURL`}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

              {watchActivityType == ActivityType.FaceToFace && !isEditLang && (
                <div className="row">
                  <div className="col-9">
                    <div className="row">
                      <div className="col-6">
                        <FormDateTime
                          control={control}
                          name={
                            'faceToFaceType.startDate' as keyof ActivityForm
                          }
                          label={t('activity.form.config.startDate.label')}
                          placeholder={t(
                            'activity.form.config.startDate.placeholder'
                          )}
                          required
                          data-testid={`AFC__faceToFaceType.startDate`}
                        />
                      </div>
                      <div className="col-6">
                        <FormDateTime
                          control={control}
                          name={'faceToFaceType.endDate' as keyof ActivityForm}
                          label={t('activity.form.config.endDate.label')}
                          placeholder={t(
                            'activity.form.config.endDate.placeholder'
                          )}
                          tooltip={t('activity.form.config.endDate.tooltip')}
                          required
                          data-testid={`AFC__faceToFaceType.endDate`}
                        />
                      </div>
                      <div className="col-6">
                        <Controller
                          control={control}
                          name="faceToFaceType.location.fullAddress"
                          render={({ field, fieldState }) => (
                            <AsyncSelectControl
                              name={field.name}
                              value={field.value}
                              error={fieldState.error}
                              onChange={(v) => {
                                field.onChange(v)
                                handleChangeLocation(v as string)
                              }}
                              label={t('activity.form.config.location.label')}
                              loadInitialOption={handleInitialLocations}
                              loadOptions={handleSearchLocations}
                              mapResponseToOptions={mapLocationToOption}
                              loadingMessage={t(
                                'activity.form.config.location.loading'
                              )}
                              placeholder={t(
                                'activity.form.config.location.placeholder'
                              )}
                              noOptionsMessage=""
                              required
                            />
                          )}
                        />
                      </div>
                      <div className="col-6">
                        <FormTextInput
                          control={control}
                          name="faceToFaceType.location.aditionalInformation"
                          label={t(
                            'activity.form.config.additionalLocationInfo.label'
                          )}
                          placeholder={t(
                            'activity.form.config.additionalLocationInfo.placeholder'
                          )}
                          data-testid={`AFC__faceToFaceType.location.aditionalInformation`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {watchActivityType == ActivityType.Challenge && (
                <>
                  {!isEditLang && (
                    <div className="row">
                      <div className="col-9">
                        <div className="row">
                          <div className="col-6">
                            <FormDateTime
                              control={control}
                              name={
                                'challengeType.startDate' as keyof ActivityForm
                              }
                              label={t('activity.form.config.startDate.label')}
                              placeholder={t(
                                'activity.form.config.startDate.placeholder'
                              )}
                              required
                              data-testid={`AFC__challenge.startDate`}
                            />
                          </div>
                          <div className="col-6">
                            <FormDateTime
                              control={control}
                              name={
                                'challengeType.endDate' as keyof ActivityForm
                              }
                              label={t('activity.form.config.endDate.label')}
                              placeholder={t(
                                'activity.form.config.endDate.placeholder'
                              )}
                              tooltip={t(
                                'activity.form.config.endDate.tooltip'
                              )}
                              required
                              data-testid={`AFC__challenge.endDate`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <FormTextInput
                          control={control}
                          name="challengeType.challengeUrl"
                          label={t('activity.form.config.challengeUrl.label')}
                          placeholder={t(
                            'activity.form.config.challengeUrl.placeholder'
                          )}
                          required
                          data-testid={`AFC__challenge.challengeUrl`}
                        />
                      </div>
                      <div className="col-12">
                        <UploadInput
                          control={control}
                          title={t(
                            'activity.form.config.challengeDocUrl.label'
                          )}
                          name="challengeType.documentFile"
                          accept={acceptDoc}
                          onRemoveFile={() =>
                            setValue(`challengeType.documentFile`, '')
                          }
                          selectFilesError={(error) =>
                            setError(`challengeType.documentFile`, {
                              message: error.message,
                            })
                          }
                          rules={{
                            application: {
                              maxFileSize: 10,
                            },
                          }}
                          data-testid={`AFC__translations.challengeDocumentUrl`}
                          labels={labelsDoc}
                          selectFilesSuccess={selectFilesSuccess}
                        />
                      </div>
                    </div>
                  )}

                  <p className="AFC__title">
                    {activityId && activity?.translations[0].title
                      ? t('activity.form.config.challengeResult.title', {
                          name: activity?.translations[0].title ?? '',
                        })
                      : t('activity.form.config.challengeResult.title.create')}
                  </p>

                  {activityId &&
                    new Date(watchEndDateParticipation) < new Date() && (
                      <>
                        <p className="AFC__subtitle">
                          {t('activity.form.config.challengeResult.subtitle')}
                        </p>

                        <div className="row">
                          {fields.map(
                            (field, index) =>
                              field.language == watchTransLanguage && (
                                <div key={field.id}>
                                  <div className="col-9">
                                    <FormTextInput
                                      control={control}
                                      name={`translations.${index}.resultTitle`}
                                      label={t(
                                        'activity.form.config.challengeTitle.label'
                                      )}
                                      placeholder={t(
                                        'activity.form.config.challengeTitle.placeholder'
                                      )}
                                      data-testid={`AFC__challenge.challengeTitle`}
                                      helperText={t(
                                        'validations.leyendField.max',
                                        { number: 120 }
                                      )}
                                    />
                                    <FormTextarea
                                      control={control}
                                      name={`translations.${index}.resultDescription`}
                                      label={t(
                                        'activity.form.config.challengeDescription.label'
                                      )}
                                      placeholder={t(
                                        'activity.form.config.challengeDescription.placeholder'
                                      )}
                                      data-testid={`AFC__challenge.challengeDescription`}
                                      rows={4}
                                      helperText={t(
                                        'validations.leyendField.max',
                                        { number: 1500 }
                                      )}
                                    />
                                  </div>
                                </div>
                              )
                          )}
                          {!isEditLang && (
                            <div className="col-12">
                              <UploadInput
                                control={control}
                                title={t(
                                  'activity.form.config.challengeImageUrl.label'
                                )}
                                name="challengeType.resultImage"
                                accept={accept}
                                onRemoveFile={() =>
                                  setValue(`challengeType.resultImage`, '')
                                }
                                selectFilesError={(error) =>
                                  setError(`challengeType.resultImage`, {
                                    message: error.message,
                                  })
                                }
                                rules={{
                                  image: {
                                    maxFileSize: 2,
                                    maxWidth: 584,
                                    maxHeight: 296,
                                  },
                                }}
                                data-testid={`AFC__translations.challengeImageUrl`}
                                labels={labels}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}

                  {(!activityId ||
                    new Date(watchEndDateParticipation) > new Date()) && (
                    <InfoBlock
                      label={t('activity.form.config.waitChallengeResult')}
                    />
                  )}
                </>
              )}
            </div>
          )}

          <FormActionsLang
            selectName="transLanguage"
            selectDisabled={!activity}
            isLoaded={true}
            methods={methods}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            onCancel={handleCancel}
            onBeforeChange={handleBeforeChangeLang}
          />
        </FormProvider>
      </div>
    </>
  )
}

export default ActivityFormConfig
