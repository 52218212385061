import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { BrandSummary } from '../../core/brandSpace/types'
import useCopy from '../../utils/copy/useCopy'
import { useBrandsInfo, usePermissions, useUi } from '../../core'
import useNotifications from '../../utils/notifications/useNotifications'
import { Card } from '../Card'

import './styles.scss'

interface BrandItemProps {
  brand: BrandSummary
  onBrandClick?: (brand: BrandSummary) => void
}

export const BrandItem: FC<BrandItemProps> = ({ brand }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { handleCopy } = useCopy()
  const { verifyEditorSpaces, verifyPermissionsMaster } = usePermissions()
  const { fetchBrands, deleteBrand } = useBrandsInfo()
  const notify = useNotifications()
  const { showConfirmation } = useUi()

  const handleDelete = () => {
    showConfirmation({
      title: t('brands.delete'),
      subtitle: t('brands.delete.modal.subtitle', {
        name: brand.name,
      }),
      text: t('brands.delete.modal.text'),
      confirmText: t('common.remove'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      confirmActionOptions: {
        confirmText: t('brands.delete.modal.confirmText'),
        word: t('brands.delete.modal.word'),
      },
      onConfirm: async () => {
        try {
          await deleteBrand(brand.id)
          fetchBrands()
          notify.success(t('brands.delete.toast.ok'))
        } catch (error) {
          notify.error(t('brands.delete.toast.ko'))
        }
      },
    })
  }

  const rowActions = useMemo(() => {
    const actions = []
    if (verifyEditorSpaces()) {
      actions.push(
        {
          label: t('brands.actions.edit'),
          onClick: () => {
            navigate(`${brand.id}/edit`)
          },
        },
        {
          label: t('brands.actions.copy'),
          onClick: () => {
            handleCopy(brand.id, t('brand.edit.create.title'))
          },
        }
      )
    } else {
      actions.push({
        label: t('brands.actions.copy'),
        onClick: () => {
          handleCopy(brand.id, t('brand.edit.create.title'))
        },
      })
    }

    if (verifyPermissionsMaster()) {
      actions.push({
        label: t('brands.delete'),
        onClick: handleDelete,
      })
    }

    return actions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand])

  return (
    <Card id={brand.id}>
      <Card.Image src={brand.logoImageUrl} alt={brand.name} />
      <Card.Content>
        <Card.Content.Header title={brand.name} actions={rowActions} />
        <Card.Content.Body description={brand.shortDescription} />
        <Card.Content.Bottom translations={brand.translations} />
      </Card.Content>
    </Card>
  )
}
