import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Loader } from '@liveconnect/components'

import { Main } from '../../../components/Main'
import { BrandSummary } from '../../../core/brandSpace/types'
import useBrandsInfo from '../../../core/brandSpace/useBrandSpace'
import EmptyState from '../../../components/EmptyState'
import usePermissions from '../../../core/permissions/usePermissions'
import { BrandItem } from '../../../components/BrandItem'

import './styles.scss'

const BrandListScreen: FC = () => {
  const { t } = useTranslation()
  const { brandList, fetchBrands, clearCurrentBrand, isLoaded } =
    useBrandsInfo()
  const navigate = useNavigate()
  const { verifyEditorSpaces } = usePermissions()

  const handleNewBrand = (): void => {
    navigate('new')
  }

  useEffect(() => {
    fetchBrands()
    clearCurrentBrand()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Main ariaLabelledby="brands" className="BrandList">
      <h3 className="BrandList__header">
        {t('brands.title')}
        {verifyEditorSpaces() && (
          <button className="btn btn-primary" onClick={handleNewBrand}>
            {t('brands.new')}
          </button>
        )}
      </h3>
      {!isLoaded && brandList.length === 0 ? (
        <Loader />
      ) : (
        <div
          className={classNames('BrandList__list', {
            'no-brands': brandList.length === 0,
          })}
        >
          {brandList.map((brand: BrandSummary) => (
            <BrandItem brand={brand} key={brand.id} />
          ))}
          {brandList.length === 0 && (
            <EmptyState text={t('brands.emptyState')} />
          )}
        </div>
      )}
    </Main>
  )
}

export default BrandListScreen
