import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'

import { FormTextInput, Loader } from '@liveconnect/components'

import useConfigurationFooter from '../../../../core/configuration/footer/useFooter'
import useNotifications from '../../../../utils/notifications/useNotifications'
import { DEFAULT_LANG } from '../../../../i18n/config'

import CustomForm from '../../../../components/CustomForm'
import TranslationCard from '../../../../components/TranslationCard'
import Heading from '../../../../components/Heading'
import CustomIcon from '../../../../components/CustomIcon'

import { ConfigurationFooterSocialMediaForm } from '../../../../core/configuration/footer/types'

import { buildValidationSchema } from './validations'
import { socialMediaInputs } from './linksSocialMedia'

import './styles.scss'

const FooterSocialMedia = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const {
    isLoaded,
    socialMediaInfo: { detail },
    fetchSocialMediaInfo,
    createSocialMediaInfo,
    updateSocialMediaInfo,
  } = useConfigurationFooter()

  const defaultValues = {
    language: DEFAULT_LANG,
  }

  const methods = useForm<ConfigurationFooterSocialMediaForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues,
  })

  const { control, watch, reset } = methods

  const formValues = watch()

  const isSocialMediaSetted = useMemo(() => {
    if (!detail) return false
    return detail.id !== '00000000-0000-0000-0000-000000000000'
  }, [isLoaded, detail])

  const onSubmit = async (values: ConfigurationFooterSocialMediaForm) => {
    const isTranslating = formValues.language !== DEFAULT_LANG
    let action = 'create'
    if (isSocialMediaSetted) action = 'edit'
    if (isTranslating) action = 'translate'
    try {
      if (!isSocialMediaSetted) {
        await createSocialMediaInfo(values)
        fetchSocialMediaInfo()
      } else {
        await updateSocialMediaInfo(detail!.id, values)
      }
      reset(formValues)
      notify.success(t(`footerInfo.socialMedia.feedback.${action}.ok`))
    } catch (error) {
      notify.error(t(`footerInfo.socialMedia.feedback.${action}.ko`))
    }
  }

  useEffect(() => {
    fetchSocialMediaInfo()
  }, [])

  useEffect(() => {
    if (detail && isSocialMediaSetted) {
      const translation = detail.socialMediaTranslations.find(
        (ct) => ct.language === formValues.language
      ) ?? {
        sectionTitle: '',
        facebookUrl: '',
        instagramUrl: '',
        linkedinUrl: '',
        twitterUrl: '',
        youtubeUrl: '',
        language: formValues.language,
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { language, ...restSocialMediaTranslations } = translation
      reset({
        ...formValues,
        ...restSocialMediaTranslations,
      })
    } else {
      reset(defaultValues)
    }
  }, [detail, formValues.language])

  if (!isLoaded) {
    return <Loader />
  }

  return (
    <CustomForm
      methods={methods}
      onSubmit={onSubmit}
      onCancel={() => reset(defaultValues)}
      isSelectDisabled={!isSocialMediaSetted}
    >
      {isSocialMediaSetted && detail && (
        <TranslationCard
          translations={detail.socialMediaTranslations.map(
            ({ sectionTitle, language }) =>
              ({
                language,
                name: sectionTitle,
              } as any)
          )}
          lang={formValues.language}
          entity={t('footerInfo.socialMedia.entity')}
        />
      )}
      <div className="row mb-5">
        <div className="col-9">
          <FormTextInput
            label={t('footerInfo.socialMedia.form.sectionTitle.label')}
            placeholder={t(
              'footerInfo.socialMedia.form.sectionTitle.placeholder'
            )}
            control={control}
            name="sectionTitle"
            required
            data-testid="footerInfo-socialMedia-sectionTitle"
            helperText={t('validations.leyendField.max', { number: 50 })}
          />
        </div>
      </div>

      <Heading as="h2">{t('footerInfo.socialMedia.links.title')}</Heading>
      <p>{t('footerInfo.socialMedia.links.subtitle')}</p>

      <div className="row">
        {socialMediaInputs.map(({ label, name, icon }) => (
          <div className="col-4" key={name}>
            <div className="form-entry-link-social-media">
              <CustomIcon
                name={icon}
                className={classnames({
                  'icon--active':
                    formValues[
                      name as keyof ConfigurationFooterSocialMediaForm
                    ],
                })}
              />
              <FormTextInput
                label={''}
                placeholder={t(
                  'footerInfo.socialMedia.form.links.placeholder',
                  {
                    name: label,
                  }
                )}
                control={control}
                name={name}
                data-testid={`footerInfo-socialMedia-links-${name.toLowerCase()}`}
              />
            </div>
          </div>
        ))}
      </div>
    </CustomForm>
  )
}

export default FooterSocialMedia
