import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Pagination, Loader } from '@liveconnect/components'

import { CardsGridItem } from './List/Item'
import useUi from '../../../core/ui/useUi'
import useNotifications from '../../../utils/notifications/useNotifications'
import useBadges from '../../../core/badges/useBadges'
import { BadgeListParams } from '../../../core/badges/types'
import EmptyState from '../../../components/EmptyState'
import AssignedUsers from './List/Item/AssignedUsers'
import usePermissions from '../../../core/permissions/usePermissions'

export const BadgesList = () => {
  const { t } = useTranslation()
  const { verifyPermissionsMaster } = usePermissions()
  const { showConfirmation } = useUi()
  const notify = useNotifications()
  const { brandId } = useParams()
  const { isLoaded, badgesList, fetchBadges, deleteBadge, publishBadge } =
    useBadges()
  const navigate = useNavigate()
  const [paramsList, setParamsList] = useState<BadgeListParams>({
    page: 1,
    page_size: 5,
    brandspace_id: brandId ?? '',
  })

  const handleConfirmDelete = (id: string) => {
    showConfirmation({
      title: t('common.warning'),
      subtitle: t('brand.badges.actions.delete.subtitle'),
      text: t('brand.badges.actions.delete.text'),
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: async () => {
        await confirmDelete(id)
      },
    })
  }

  const handleConfirmPublish = (id: string, newState: boolean) => {
    if (newState) {
      showConfirmation({
        title: t('common.warning'),
        subtitle: t('brand.badges.actions.publish.subtitle'),
        confirmText: t('common.publish'),
        cancelText: t('common.cancel'),
        iconName: 'report_problem',
        onConfirm: () => {
          confirmPublish(id, newState)
        },
      })
    } else {
      confirmPublish(id, newState)
    }
  }

  const confirmDelete = async (id: string) => {
    try {
      await deleteBadge(id)
      notify.success(t('brand.badges.actions.delete.toast.ok'))
      fetchBadges(paramsList)
    } catch (error) {
      notify.error(t('brand.badges.actions.delete.toast.ko'))
    }
  }

  const confirmPublish = async (id: string, newState: boolean) => {
    try {
      await publishBadge(id, newState)
      const toast = newState
        ? 'brand.badges.actions.publish.toast.ok'
        : 'brand.badges.actions.unPublish.toast.ok'
      notify.success(t(toast))
    } catch (error) {
      const toast = newState
        ? 'brand.badges.actions.publish.toast.ko'
        : 'brand.badges.actions.unPublish.toast.ko'
      notify.error(t(toast))
    }
  }

  const handleEdit = (id: string) => {
    navigate(`badges/${id}`)
  }

  const getBadges = (params: BadgeListParams) => {
    setParamsList(params)
  }

  useEffect(() => {
    fetchBadges(paramsList)
  }, [paramsList])

  return (
    <>
      {!isLoaded ? (
        <Loader />
      ) : (
        <div className="badges">
          <div className="text-end mb-5">
            <Link className="btn btn-primary" to="badges">
              {t('brand.badges.add')}
            </Link>
          </div>
          <div className="cards-grid mb-5">
            {badgesList.items.length === 0 && (
              <EmptyState text={t('brand.badges.emptyState')} />
            )}
            {badgesList.items.length > 0 &&
              badgesList.items.map((badge) => (
                <CardsGridItem
                  key={badge.id}
                  isDisabledClass={!badge.isPublished}
                  title={badge.name}
                  id={badge.id}
                  imageUrl={badge.image}
                  brandImageUrl={badge.brandSpace.logoImageUrl}
                  translations={badge.translations}
                  isPublished={badge.isPublished}
                  isPublishDisabled={
                    badge.members.length === 0 || !verifyPermissionsMaster()
                  }
                  handlePublish={(v: boolean) =>
                    handleConfirmPublish(badge.id, v)
                  }
                  publishText={t('brand.badges.publish')}
                  handleDelete={() => handleConfirmDelete(badge.id)}
                  handleEdit={() => handleEdit(badge.id)}
                  content={<AssignedUsers members={badge.members} />}
                />
              ))}
          </div>
          {brandId && (
            <Pagination
              currentPage={badgesList.page - 1}
              totalPages={badgesList.availablePages}
              totalItems={badgesList.availableItems}
              pageSize={badgesList.pageSize}
              singlePageText={(count) =>
                t('common.pager.singlePage', { count })
              }
              multiplePageText={(start, end, total) =>
                t('common.pager.multiplePage', { start, end, total })
              }
              onChange={(pageIndex) =>
                getBadges({ ...paramsList, page: pageIndex + 1 })
              }
            />
          )}
        </div>
      )}
    </>
  )
}
