import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ColumnDef } from '@tanstack/react-table'

import { Avatar } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import { useActivities, useUi } from '../../../../../core'
import { ActivityCollaboratorsResponseListItem } from '../../../../../core/activities/types'

import ContentTable from '../../../../../components/Table/ContentTable'
import TranslationChips from '../../../../../components/TranslationsChips'
import useNotifications from '../../../../../utils/notifications/useNotifications'
import { useCustomRouter } from '../../../../../utils/extractParams'

import './styles.scss'

const CollaboratorsList = () => {
  const { t } = useTranslation()
  const { basePath } = useCustomRouter()
  const navigate = useNavigate()
  const { activityId } = useParams()
  const { collaborators, deleteCollaborator } = useActivities()
  const notify = useNotifications()
  const { showConfirmation } = useUi()

  const handleDelete = async (row: ActivityCollaboratorsResponseListItem) => {
    showConfirmation({
      title: t('activity.collaborators.modalDelete.title'),
      subtitle: t('activity.collaborators.modalDelete.subtitle'),
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: async () => {
        try {
          await deleteCollaborator(row.id, activityId!)
          notify.success(t('activity.collaborators.modalDelete.feedback.ok'))
        } catch (error) {
          notify.error(t('activity.collaborators.modalDelete.feedback.ko'))
        }
      },
    })
  }

  const actions = (row: ActivityCollaboratorsResponseListItem) => {
    return [
      {
        onClick: () => {
          navigate(
            `${basePath}/activities/${activityId}/collaborators/${
              collaborators!.id
            }/${row.id}`
          )
        },
        label: t('common.edit'),
        icon: 'edit',
      },
      {
        onClick: () => handleDelete(row),
        label: t('common.delete'),
        icon: 'delete_outline',
      },
    ]
  }

  const columns: ColumnDef<ActivityCollaboratorsResponseListItem>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('activity.collaborators.table.name'),
        cell: ({ row }) => (
          <div className="d-flex align-items-center gap-3">
            <Avatar
              size="sm"
              imageUrl={row.original.image}
              name={row.original.name}
              surname=""
            />
            <span>{row.original.name}</span>
          </div>
        ),
      },
      {
        accessorKey: 'description',
        header: t('activity.collaborators.table.description'),
        cell: ({ row }) => (
          <span className="activity-collaborators-description">
            {row.original.description}
          </span>
        ),
      },
      {
        accessorKey: 'translations',
        header: t('activity.collaborators.table.language'),
        cell: ({ row }) => (
          <TranslationChips
            translations={row.original.translations}
            isArrayOfStrings
          />
        ),
      },
    ],
    []
  )

  return (
    <div className="mt-4">
      <ContentTable
        title={collaborators?.contributorTypes[0]?.contributorType ?? ''}
        columns={columns}
        headerChild={
          <button
            className="btn btn-arrow"
            onClick={() => {
              navigate(
                `${basePath}/activities/${activityId}/collaborators/${
                  collaborators!.id
                }/create`
              )
            }}
          >
            <span>{t('common.add')}</span>
            <Icon className="me-0 ms-1" name="add" />
          </button>
        }
        data={
          collaborators && collaborators.contributors
            ? collaborators.contributors
            : []
        }
        emptyText={t('activity.collaborators.table.empty', {
          name: collaborators?.contributorTypes[0].contributorType ?? '',
        })}
        actions={actions}
      />
    </div>
  )
}

export default CollaboratorsList
