import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormMultiSelect,
  FormSelect,
  FormTextInput,
  FormProvider,
  MimeType,
  UploadInput,
} from '@liveconnect/components'
import { Loader } from '@liveconnect/communities-ui'

import { Main } from '../../../../components/Main'
import { FormActionsLang } from '../../../../components/forms/FormActionsLang'
import BackButton from '../../../../components/Buttons/BackButton'
import TranslationCard from '../../../../components/TranslationCard'

import useUi from '../../../../core/ui/useUi'
import useLocalizations from '../../../../core/localizations/useLocalizations'
import {
  BrandDirectory,
  ChannelSectorDirectory,
  DirectoryDetail,
  DirectoryForm,
  PayloadDirectory,
  PutOnlyTrans,
  SectorDirectory,
  TypeDirectory,
} from '../../../../core/directories/types'
import useDirectories from '../../../../core/directories/useDirectories'

import useNotifications from '../../../../utils/notifications/useNotifications'
import { useCustomRouter } from '../../../../utils/extractParams'
import { useBlockRouteChangeWithDialog } from '../../../../utils/routing/useBlockRouteChange'
import useUploadInput from '../../../../utils/uploadInput/useUploadInput'

import { DEFAULT_LANG } from '../../../../i18n/config'
import { buildValidationSchema } from './validations'
import usePermissions from '../../../../core/permissions/usePermissions'

import './styles.scss'

const EditDirectory: FC = () => {
  const navigate = useNavigate()
  const { directoryId } = useParams()
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { localizations } = useLocalizations()
  const { basePath } = useCustomRouter()
  const {
    verifyModeratorDirectories,
    verifyBrandPermissionsDirectories,
    verifyChannelsPermissionsDirectories,
  } = usePermissions()
  const {
    getSectorsDirectory,
    fetchDirectoryDetail,
    directory,
    isLoaded,
    editDirectory,
    editDirectoryTranslation,
    uploadImageDirectory,
    getBrandsDirectory,
  } = useDirectories()
  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [topics, setTopics] = useState<SectorDirectory[]>([])
  const [brands, setBrands] = useState<BrandDirectory[]>([])

  const methods = useForm<DirectoryForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      language:
        (localizations || []).find((item) => item.isDefault)?.isoCode ||
        DEFAULT_LANG,
    },
  })
  const {
    control,
    formState,
    watch,
    reset,
    setValue,
    setError,
    formState: { dirtyFields },
  } = methods

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language

    return Object.keys(_dirtyFields).length > 0
  }, [formState])
  useBlockRouteChangeWithDialog(isDirtyWithoutLanguage && !isSubmitting)

  const watchType = watch('type')
  const watchSector = watch('sectorId')
  const watchLang = watch('language')

  const codeOptions = useMemo(() => {
    const options = brands.map((code: BrandDirectory) => {
      return {
        value: code.id,
        label: code.name,
      }
    })
    return options
  }, [brands])

  const sectorsOptions = useMemo(() => {
    const options = topics.map((topic: SectorDirectory) => {
      return {
        value: topic.id,
        label: topic.name,
      }
    })
    return options
  }, [topics])

  const channelsOptions = useMemo(() => {
    if (watchSector && topics.length > 0) {
      const channelsFilter = topics.filter(
        (topic: SectorDirectory) => topic.id === watchSector
      )
      if (channelsFilter.length > 0) {
        const options = channelsFilter[0].channels.map(
          (channel: ChannelSectorDirectory) => {
            return {
              value: channel.id,
              label: channel.name,
            }
          }
        )
        return options
      }
    }
  }, [topics, watchSector])

  const directoriesOptions = useMemo(() => {
    const options = [
      {
        value: TypeDirectory.Main,
        label: t('directories.type.menu'),
      },
      {
        value: TypeDirectory.Channel,
        label: t('topic.channels'),
      },
      {
        value: TypeDirectory.BrandSpace,
        label: t('navMenu.brand'),
      },
    ]
    return options
  }, [])

  const handleCancel = () => {
    navigate(`${basePath}/directories`)
  }

  const transformData = (val: DirectoryForm) => {
    let data
    if (watchLang === DEFAULT_LANG) {
      switch (val.type) {
        case TypeDirectory.Main:
          data = {
            name: val.name,
            description: val.description,
            type: val.type,
            mainDirectoryType: { imageUrl: val.imageUrl },
            language: val.language,
          }
          return data
        case TypeDirectory.Channel:
          data = {
            name: val.name,
            description: val.description,
            type: val.type,
            channelDirectoryType: {
              sectorId: val.sectorId,
              channels: val.channels,
            },
            language: val.language,
          }
          return data
        case TypeDirectory.BrandSpace:
          data = {
            name: val.name,
            description: val.description,
            type: val.type,
            brandSpaceDirectoryType: { brandSpaceId: val.code },
            language: val.language,
          }
          return data
        default:
          data = {
            name: val.name,
            description: val.description,
            language: val.language,
          }
          return data
      }
    }
    data = {
      name: val.name,
      description: val.description,
      language: val.language,
    }
    return data
  }

  const successPost = (data: DirectoryDetail) => {
    const i18nData = data.translations.find(
      (item) => item.language === watchLang
    )
    if (i18nData) {
      return reset({
        name: i18nData?.name,
        description: i18nData?.description,
        language: i18nData?.language,
        type: data.type,
        imageUrl: data.imageUrl,
        sectorId: data.sectorId,
        channels: data.channels,
        code: data.brandSpaceId,
      })
    }
  }

  const onSubmit = async (values: DirectoryForm) => {
    setIsSubmitting(true)
    try {
      const dataForm: PayloadDirectory | PutOnlyTrans | any =
        transformData(values)
      if (
        watchType === TypeDirectory.Main &&
        watchLang === DEFAULT_LANG &&
        values.imageUrl
      ) {
        const image: string = values.imageUrl
        if (image.includes('blob:')) {
          const responseImage = await uploadImageDirectory(image)
          responseImage &&
            dataForm.mainDirectoryType &&
            (dataForm.mainDirectoryType.imageUrl = responseImage)
        }
      }
      if (directoryId) {
        let response
        if (watchLang === DEFAULT_LANG) {
          response = await editDirectory(dataForm, directoryId)
        } else {
          response = await editDirectoryTranslation(dataForm, directoryId)
        }
        response && successPost(response)
        notify.success(
          t('directories.form.edit.toast.ok', { name: values.name })
        )
        setIsSubmitting(false)
      }
    } catch (error: unknown) {
      setIsSubmitting(false)
      notify.error(t('directories.form.edit.toast.ko', { name: values.name }))
    }
  }

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          onConfirm: () => {
            if (directory && directoryId) {
              const i18nData = directory.translations.find(
                (item) => item.language === DEFAULT_LANG
              )
              reset({
                name: i18nData?.name,
                language: i18nData?.language,
                description: i18nData?.description,
              })
            }
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const fetchSectors = async () => {
    const sectors = await getSectorsDirectory()
    sectors && setTopics(sectors)
  }
  const fetchDirectoryBrands = async () => {
    const brandsDirectory = await getBrandsDirectory()
    brandsDirectory && setBrands(brandsDirectory)
  }

  useEffect(() => {
    if (directory) {
      const i18nData = directory.translations.find(
        (item) => item.language === watchLang
      )
      if (i18nData) {
        return reset({
          name: i18nData?.name,
          description: i18nData?.description,
          language: watchLang,
          type: directory.type,
          imageUrl: directory.imageUrl,
          sectorId: directory.sectorId,
          channels: directory.channels,
          code: directory.brandSpaceId,
        })
      }
      return reset({
        name: '',
        description: '',
        language: watchLang,
        type: directory.type,
        imageUrl: directory.imageUrl,
        sectorId: directory.sectorId,
        channels: directory.channels,
        code: directory.brandSpaceId,
      })
    }
  }, [watchLang])

  useEffect(() => {
    if (directory && directoryId) {
      const i18nData = directory.translations.find(
        (item) => item.language === watchLang
      )
      reset({
        name: i18nData?.name,
        language: watchLang,
        description: i18nData?.description,
        type: directory.type,
        imageUrl: directory.imageUrl,
        sectorId: directory.sectorId,
        channels: directory.channels,
        code: directory.brandSpaceId,
      })
    }
  }, [directory])

  useEffect(() => {
    directoryId && fetchDirectoryDetail(directoryId)
  }, [directoryId])

  useEffect(() => {
    if (directory) {
      if (directory.type === TypeDirectory.BrandSpace) {
        fetchDirectoryBrands()
      }
      if (directory.type === TypeDirectory.Channel) {
        fetchSectors()
      }
    }
  }, [directory])

  useEffect(() => {
    if (directory?.type == TypeDirectory.Main) {
      if (!verifyModeratorDirectories()) {
        navigate(`${basePath}/access-denied`)
      }
    }
    if (directory?.type == TypeDirectory.BrandSpace && directory.brandSpaceId) {
      if (!verifyBrandPermissionsDirectories(directory.brandSpaceId)) {
        navigate(`${basePath}/access-denied`)
      }
    }
    if (directory?.type == TypeDirectory.Channel && directory.channels) {
      if (!verifyChannelsPermissionsDirectories(directory.channels)) {
        navigate(`${basePath}/access-denied`)
      }
    }
  }, [directory])

  return (
    <>
      {!isLoaded && directoryId ? (
        <Loader />
      ) : (
        <Main ariaLabelledby="edit-directory" className="EditDirectory">
          <div className="d-flex justify-content-between">
            <h1 id="edit-directory-title" className="h3">
              {directoryId ? t('directories.edit') : t('directories.create')}
            </h1>
            <BackButton
              label={t('directories.form.back')}
              onClick={() => navigate('../')}
            />
          </div>
          {directory && directoryId && (
            <TranslationCard
              translations={directory.translations}
              lang={watchLang}
              entity={t('directories.entity')}
            />
          )}
          <div className="d-flex justify-content-between EditDirectory__text">
            <div>
              <p id="directory-form-text" className="h3">
                {t('directories.form.text')}
              </p>
              <p className="leyend" id="directory-form-leyend">
                {directoryId
                  ? t('directories.form.leyend.edit')
                  : t('directories.form.leyend')}
              </p>
            </div>
            <div className="d-flex flex-row-reverse modal-required-legend">
              <div className="bd-highlight">{t('common.required')}</div>
            </div>
          </div>
          <FormProvider methods={methods}>
            <div className="EditDirectory__form">
              <div className="row">
                {watchLang === DEFAULT_LANG && (
                  <div className="col-8 contentInput">
                    <FormSelect
                      control={control}
                      name="type"
                      label={t('directories.form.type')}
                      placeholder={t('directories.form.type.placeholder')}
                      options={directoriesOptions}
                      required={true}
                      disabled={true}
                    />
                  </div>
                )}
                <div className="col-10 contentInput">
                  <FormTextInput
                    control={control}
                    name="name"
                    label={t('directories.form.name')}
                    placeholder={t('directories.form.name.placeholder')}
                    type="text"
                    required={true}
                  />
                </div>
                <div className="col-10 contentInput withMax">
                  <FormTextInput
                    control={control}
                    name="description"
                    label={t('directories.form.description')}
                    placeholder={t('directories.form.description.placeholder')}
                    type="text"
                    required={true}
                  />
                  <span className="max">
                    {t('directories.form.description.max')}
                  </span>
                </div>
              </div>
              {watchLang === DEFAULT_LANG && (
                <>
                  {watchType && (
                    <div className="row EditDirectory__form__info">
                      <div className="col-12">
                        <p className="h3 info">{t('directories.form.info')}</p>
                      </div>
                      {watchType === TypeDirectory.Main && (
                        <div className="col-12">
                          <UploadInput
                            control={control}
                            title={t('topic.topic.modal.image')}
                            name="imageUrl"
                            accept={accept}
                            onRemoveFile={() => setValue('imageUrl', '')}
                            selectFilesError={(error) =>
                              setError('imageUrl', {
                                message: error.message,
                              })
                            }
                            required
                            rules={{
                              image: {
                                maxWidth: 64,
                                maxHeight: 64,
                                maxFileSize: 50,
                              },
                            }}
                            labels={labels}
                          />
                        </div>
                      )}
                      {watchType === TypeDirectory.Channel && (
                        <>
                          <div className="col-8 contentInput">
                            <FormSelect
                              control={control}
                              name="sectorId"
                              label={t('navMenu.topics')}
                              placeholder={t(
                                'directories.form.sector.placeholder'
                              )}
                              options={sectorsOptions}
                              required={true}
                            />
                          </div>
                          <div className="col-8 contentInput">
                            <FormMultiSelect
                              control={control}
                              name="channels"
                              label={t(
                                'activities.filters.fields.channels.label'
                              )}
                              placeholder={t(
                                'activities.filters.fields.channels.placeholder'
                              )}
                              options={channelsOptions || []}
                              required={true}
                              disabled={!watchSector}
                            />
                          </div>
                        </>
                      )}
                      {watchType === TypeDirectory.BrandSpace && (
                        <div className="col-8">
                          <FormSelect
                            control={control}
                            name="code"
                            label={t('brand.edit.eventCode')}
                            placeholder={t('brand.edit.eventCode.placeholder')}
                            options={codeOptions}
                            required={true}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <FormActionsLang
              selectName="language"
              selectDisabled={false}
              isLoaded={true}
              methods={methods}
              isSubmitting={isSubmitting}
              onSubmit={onSubmit}
              onCancel={handleCancel}
              onBeforeChange={handleBeforeChangeLang}
            />
          </FormProvider>
        </Main>
      )}
    </>
  )
}

export default EditDirectory
