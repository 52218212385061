import { Translation } from '../shared/types'

export interface Channel {
  id: string
  name?: string
  brandSpaces?: string[]
  topicFamilyId: string
  description?: string
  image?: string
  shortDescription?: string
  isPublished: boolean
  language: string
  translations: Translation[]
}

export interface ThemeChannel {
  id: string
  isPublished: boolean
  translations: Translation[]
}

export interface ChannelDetail {
  id: string
  brandSpaces: string[]
  translations: Translation[]
  themes: ThemeChannel[]
  themeTranslations: string[]
}
export interface TranslationsSuccess {
  id: string
  translations: Translation[]
}
export interface BrandSpacesSuccess {
  id: string
  brandSpaces: string[]
}
export interface ChannelForm {
  id: string
  name: string
  brandSpaces?: string[]
  topicFamilyId: string
  isPublished: boolean
  language: string
}

export interface FormLinkedThemesForm {
  themes: ThemeChannel[]
  language: string
}

export interface FormLinkedThemesFormResponse {
  themes: ThemeChannel[]
  themeTranslations: string[]
}

export interface UpdateTopicChannels {
  channels: Channel[]
  topicFamilyId: string
}

export interface Channels {
  channels: Channel[]
  id: number
  language?: string
  topicFamilyId: string
}

export enum ChannelsTabs {
  CHANNEL_INFO = 'BASIC_INFO',
  THEMES = 'THEMES',
  BRANDSPACES = 'BRANDSPACES',
}

export interface FormStepChannel {
  channel: string
  language: string
  sectorId: string
}
export interface PostChannelPayload {
  name: string
  sectorId: string
}
export interface PutChannelPayload {
  name: string
  language: string
}

export interface FormStepBrandSpaces {
  brandSpaces: string[]
}

export interface ChannelUpdate {
  channel: Channel
  id: string
  topicFamilyId: string
  published: boolean
}

export interface ChannelsState {
  isLoaded: boolean
  detail: ChannelDetail | null
}

export interface UseChannelsResult {
  channel: ChannelDetail | null
  isLoaded: boolean
  getChannels: (
    params: Record<string, string | Array<string | number> | boolean>
  ) => Promise<Channel[] | undefined>
  fetchChannelDetail: (id: string, sectorId: string) => Promise<void>
  addChannel: (channel: PostChannelPayload) => Promise<string | undefined>
  editChannel: (
    channel: PutChannelPayload,
    id: string
  ) => Promise<TranslationsSuccess | undefined>
  deleteChannel: (id: string) => Promise<void>
  editBrandsChannel: (
    brandspaces: string[],
    id: string
  ) => Promise<BrandSpacesSuccess | undefined>
  createThemes: (
    channelId: string,
    newThemes: { name: string }[]
  ) => Promise<void>
  updateThemes: (
    channelId: string,
    language: string,
    themesUpdated: { id: string; name: string }[]
  ) => Promise<void>
  publishTheme: (
    themeId: string,
    channelId: string,
    isPublished: boolean
  ) => Promise<void>
  deleteTheme: (id: string) => Promise<void>
  sortThemes: (themes: ThemeChannel[]) => Promise<void>
}
