import { FC, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormMultiSelect,
  FormProvider,
  TextControl,
  Loader,
} from '@liveconnect/components'

import { useCustomRouter } from '../../../../utils/extractParams'
import FormActions from '../../../../components/forms/FormActions'
import { DEFAULT_LANG } from '../../../../i18n/config'
import useChannels from '../../../../core/channels/useChannels'
import { buildValidationSchema } from './validations'
import { FormStepBrandSpaces } from '../../../../core/channels/types'
import useBrandsInfo from '../../../../core/brandSpace/useBrandSpace'
import { BrandSummary } from '../../../../core/brandSpace/types'
import useNotifications from '../../../../utils/notifications/useNotifications'
import usePermissions from '../../../../core/permissions/usePermissions'

import './styles.scss'

const FormBrand: FC = ({}) => {
  const notify = useNotifications()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { verifyPermissionsMaster } = usePermissions()
  const { basePath } = useCustomRouter()
  const { brandList } = useBrandsInfo()
  const { editBrandsChannel, channel, isLoaded } = useChannels()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const getBrands = useMemo(
    () =>
      brandList.map((brand: BrandSummary) => ({
        value: brand.id,
        label: brand.name,
      })),
    [brandList]
  )

  const defaultChannel = useMemo(() => {
    return (channel?.translations || []).find(
      (item) => item.language === DEFAULT_LANG
    )
  }, [channel])

  const methods = useForm<FormStepBrandSpaces>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema()),
    defaultValues: {
      brandSpaces: [],
    },
  })

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isValid },
  } = methods
  const watchBrand = watch('brandSpaces')

  const handleCancel = () => {
    navigate(`${basePath}/sectors`)
  }

  const onSubmit = async (values: FormStepBrandSpaces) => {
    setIsSubmitting(true)
    try {
      if (channel) {
        await editBrandsChannel(values.brandSpaces, channel.id)
        navigate(`${basePath}/sectors`)
        notify.success(
          t('topic.channel.brandspaces.success', {
            nameChannel: defaultChannel?.name,
          })
        )
      }
    } catch (error: unknown) {
      notify.error(t('topic.channel.brandspaces.error'))
    }
    setIsSubmitting(false)
  }

  const youShouldNotChange = () => {
    throw new Error('Cannot change value to disabled input')
  }

  useEffect(() => {
    if (channel && channel.brandSpaces && channel.brandSpaces.length > 0) {
      reset({
        brandSpaces: channel.brandSpaces,
      })
    }
  }, [channel])

  return (
    <>
      {!isLoaded ? (
        <Loader />
      ) : (
        <div className="FormBrand">
          <FormProvider methods={methods}>
            <div className="FormBrand__text">
              <p>{t('topic.channel.brandspaces.title')}</p>
            </div>
            <div className="FormBrand__form">
              <div className="row">
                <div className="col-lg-5">
                  <TextControl
                    label={t('topic.channel.modal.name.label')}
                    type="text"
                    name="name"
                    value={defaultChannel?.name as string}
                    disabled={true}
                    onChange={youShouldNotChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-7">
                  <FormMultiSelect
                    control={control}
                    name="brandSpaces"
                    label={t('topic.channel.modal.brands.label')}
                    placeholder={t('topic.channel.modal.brands.placeholder')}
                    options={getBrands}
                    disabled={!verifyPermissionsMaster()}
                    required
                  />
                </div>
              </div>
            </div>

            <FormActions>
              <button
                type="button"
                onClick={handleCancel}
                className="btn btn-outline-primary"
              >
                {t('common.cancel')}
              </button>
              <button
                className="btn btn-primary"
                disabled={
                  !isValid ||
                  watchBrand.length === 0 ||
                  !verifyPermissionsMaster()
                }
                onClick={handleSubmit(onSubmit)}
              >
                {isSubmitting ? <Loader /> : t('common.save')}
              </button>
            </FormActions>
          </FormProvider>
        </div>
      )}
    </>
  )
}

export default FormBrand
