import { FC, useState, useEffect, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useForm } from 'react-hook-form'
import timezones from 'timezones-list'
import { Icon } from '@liveconnect/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'

import { PublisherProfile, TypePublishEnum } from '@liveconnect/communities-ui'
import {
  FormCheckbox,
  FormRichText,
  FormSelect,
  FormTextInput,
  FormToggle,
  FormProvider,
  Tooltip,
  FormDateTime,
} from '@liveconnect/components'

import { buildValidationSchema } from './validations'
import { Channel } from '../../../core/channels/types'
import { BrandInfo, Theme } from '../../../core/brandSpace/types'
import {
  CreatePostType,
  EditPostType,
  PostStateEnum,
  Publisher,
  PublisherTypeEnum,
} from '../../../core/posts/types'
import useNotifications from '../../../utils/notifications/useNotifications'
import { characterCounterText } from '../../../utils/characterCounterText'
import usePosts from '../../../core/posts/usePosts'
import { TopicFamilyListItem } from '../../../core/topicFamilies/types'
import useUi from '../../../core/ui/useUi'
import VideoUpload from './VideoUpload'
import { SimpleUploadControl } from '../../../components/forms/SimpleUploadControl'
import VideoPlayer from '../../../components/VideoPlayer'
import ChannelsArrayItem from './ChannelsArrayItem'
import InfoBlock from '../../../components/InfoBlock'

import './styles.scss'

export interface TopicInfoInterface {
  topics: TopicFamilyListItem[]
  channelsIds: Channel[]
}

export interface BrandInfoInterface {
  brand: BrandInfo
  channelsIds: Channel[]
  themesIds: Theme[]
}

export type FormInfo = TopicInfoInterface | BrandInfoInterface | undefined

interface CreatePostProps {
  onClose: () => void
  onCreate: () => void
  channels: Channel[]
  publisher: Publisher
  title: string
  message: string
  image: string
  selectedThemes: string[]
  id: string
  selectedPublisher: string
  isIfemaPublish: boolean
  isHighlighted: boolean
  video: string
  state: PostStateEnum
  publishDateTime: string | null
  timezone: string
}

const EditPost: FC<CreatePostProps> = ({
  onClose,
  onCreate,
  channels,
  publisher,
  title,
  message,
  image,
  video,
  selectedThemes,
  id,
  selectedPublisher,
  isHighlighted,
  state,
  publishDateTime,
  timezone,
}) => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()

  const { updatePost, uploadPostImage } = usePosts()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [imagePath, setimagePath] = useState<string>(image)
  const [showImageUploadModal, setShowImageUploadModal] =
    useState<boolean>(false)
  const [showVideoUploadModal, setShowVideoUploadModal] =
    useState<boolean>(false)
  const [videoURL, setVideoURL] = useState<string>(video)
  const [isVideoValid, setIsVideoValid] = useState<boolean>(true)
  const [timezoneList, setTimezoneList] = useState<
    { label: string; value: string }[]
  >([])

  const publisherTypeProps = publisher.type

  const getDate = (date: string) => {
    const tz = timezone
      ? timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
    return utcToZonedTime(date, tz).toString()
  }

  const methods = useForm<CreatePostType>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t, publisherTypeProps)),
    defaultValues: {
      publisherType: PublisherTypeEnum.TENANT,
      themesIds: selectedThemes,
      title,
      message,
      isHighlighted,
      video,
      option: state === PostStateEnum.SCHEDULED ? true : false,
      dates: publishDateTime
        ? getDate(publishDateTime as unknown as string)
        : undefined,
      timezone: timezone
        ? timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone,
      channelsArray: channels.map((item) => ({
        id: item.id,
        themes: selectedThemes,
      })),
    },
  })
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    watch,
  } = methods

  const { fields } = useFieldArray<CreatePostType>({
    control,
    name: 'channelsArray',
  })

  const watchOption = watch('option')

  const publisherProfileData = useMemo(() => {
    return {
      title: publisher.title,
      description: publisher.description,
      image: publisher.image,
      publishedAt: '',
      type: publisher.type as TypePublishEnum,
      id: publisher.id,
      redirect: false,
    }
  }, [publisher])

  const handleRemoveImage = () => {
    setimagePath('')
    setValue('image', '')
    setValue('imagePath', '')
  }

  const handleSetImage = (image: string) => {
    setimagePath(image)
    setShowImageUploadModal(false)
    handleRemoveVideo()
  }

  const handleRemoveVideo = () => {
    setVideoURL('')
    setValue('video', '')
    setIsVideoValid(true)
  }

  const handleSetVideo = (videoUrl: string) => {
    setValue('video', videoUrl)
    setVideoURL(videoUrl)
    setShowVideoUploadModal(false)
    handleRemoveImage()
  }

  const showCancel = () => {
    showConfirmation({
      title: t('common.warning'),
      subtitle: t('modal.cancel.generic.subtitle'),
      text: t('modal.cancel.generic.text'),
      confirmText: t('common.yes'),
      cancelText: t('common.no'),
      iconName: 'report_problem',
      onConfirm: onClose,
    })
  }

  const fetchTZ = async () => {
    const tzs = (await timezones).map((item) => {
      return {
        label: item.label,
        value: item.tzCode,
      }
    })
    tzs && setTimezoneList(tzs)
  }
  const transformData = (data: CreatePostType, stateParam: PostStateEnum) => {
    data.publishDateTime = null
    data.state = stateParam
    if (stateParam === PostStateEnum.SCHEDULED) {
      if (data.dates && data.timezone) {
        const newDate = new Date(data.dates)
        const utcDate = zonedTimeToUtc(newDate, data.timezone)
        data.publishDateTime = utcDate.toISOString()
      }
    }
    if (stateParam !== PostStateEnum.SCHEDULED) {
      delete data.timezone
      data.publishDateTime = null
    }

    delete data.dates
    delete data.option
    return data
  }

  const confirmSubmit = async (
    data: CreatePostType,
    stateParam: PostStateEnum
  ) => {
    if (publisherTypeProps === PublisherTypeEnum.TENANT) {
      data.publisherType = PublisherTypeEnum.TENANT
    } else {
      data.publisherType = PublisherTypeEnum.BRAND
      data.channelsIds = []
      data.themesIds = []
      for (const channel of data.channelsArray || []) {
        data.channelsIds.push(channel.id)
        for (const theme of channel.themes) {
          if (!data.themesIds.includes(theme)) data.themesIds.push(theme)
        }
      }
    }
    setIsSubmitting(true)
    const transData = transformData(data, stateParam)
    const post: EditPostType = {
      ...transData,
      isHighlighted: data.isHighlighted ? data.isHighlighted : false,
      publisherExternalId: selectedPublisher,
    }
    try {
      if (imagePath && imagePath.startsWith('blob')) {
        const image = await uploadPostImage(imagePath)
        post.image = image?.uri
      } else {
        post.image = imagePath
      }
      await updatePost(id, post)
      onCreate()
      notify.success(t('post.edit.succeessFeedback'))
    } catch (e) {
      notify.error(t('post.edit.unknownError'))
    } finally {
      onClose()
      setIsSubmitting(false)
    }
  }

  const onSubmit = async (data: CreatePostType, stateParam: PostStateEnum) => {
    if (
      state === PostStateEnum.DRAFTED &&
      stateParam === PostStateEnum.PUBLISHED
    ) {
      showConfirmation({
        title: t('post.actions.publishNow'),
        subtitle: t('post.confirmation.publishNow.subtitle'),
        cancelText: t('common.cancel'),
        iconName: 'report_problem',
        onConfirm: () => confirmSubmit(data, stateParam),
      })
    } else confirmSubmit(data, stateParam)
  }

  useEffect(() => {
    const shouldBlurActiveElement =
      !showVideoUploadModal || !showImageUploadModal
    if (shouldBlurActiveElement) (document.activeElement as HTMLElement).blur()
  }, [showVideoUploadModal, showImageUploadModal])

  useEffect(() => {
    fetchTZ()
    setimagePath(image)
  }, [])

  return (
    <>
      <Modal
        show={true}
        backdrop="static"
        onHide={onClose}
        size="lg"
        className="EditPost"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('post.actions.edit')}</Modal.Title>
        </Modal.Header>
        <FormProvider methods={methods}>
          <Modal.Body>
            <div className="CPBody">
              <div className="row contentInfo-block">
                <div className="col">
                  <InfoBlock className="mb-0" label={t('post.create.info')} />
                </div>
              </div>
              <div className="d-flex flex-row-reverse CPBody__legend">
                <div className="bd-highlight">{t('common.required')}</div>
              </div>
              <div className="d-flex justify-content-between CPBody__contentInfo">
                <PublisherProfile {...publisherProfileData} redirect={false} />
                {publisherTypeProps !== PublisherTypeEnum.TENANT && (
                  <div className="CPBody__toggle">
                    <label htmlFor="form-toggle-isHighlighted">
                      {t('post.create.step3.title')}
                    </label>
                    <FormToggle
                      label=""
                      control={control}
                      name="isHighlighted"
                    />
                  </div>
                )}
              </div>
              {publisherTypeProps === PublisherTypeEnum.TENANT ? (
                <div className="row CPBody__contentForm">
                  <div className="col-12 CPBody__contentForm__channelsContent">
                    <p>
                      {t('topic.channels')}:{' '}
                      <span className="content">
                        {channels.map((channel, index) => (
                          <span className="label" key={index}>
                            {channel.name}
                            {index !== channels.length - 1 && ', '}
                          </span>
                        ))}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="CPBody__contentForm__channelsList">
                  {fields.map((field, index) => (
                    <div
                      className="CPBody__contentForm__channelsList__item row"
                      key={index}
                    >
                      <div className="col-12">
                        <ChannelsArrayItem
                          field={field}
                          fieldIndex={index}
                          control={control}
                          setValue={setValue}
                          watch={watch}
                          disabledChannels={true}
                          channelsAvailable={channels.map((item) => ({
                            value: item.id,
                            label: item.name as string,
                          }))}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="row CPBody__contentForm">
                <div className="col-12">
                  <FormTextInput
                    control={control}
                    name="title"
                    label={t('post.create.title.label')}
                    placeholder={t('post.create.title.placeholder')}
                    type="text"
                    required={true}
                    helperText={t('validations.leyendField.max', {
                      number: 200,
                    })}
                  />
                </div>
              </div>
              <div className="row CPBody__contentForm">
                <div className="col-12">
                  <FormRichText
                    label={t('post.create.message.label')}
                    control={control}
                    name="message"
                    placeholder={t('post.modal.text')}
                    required={true}
                    helperText={characterCounterText(
                      watch('message') ?? '',
                      5000,
                      t
                    )}
                  />
                </div>
              </div>
              <div className="row contentInfo-block">
                <div className="col-12">
                  <InfoBlock label={t('post.create.remember')} />
                </div>
              </div>
              <div className="EditPost__uploader-container">
                <div className="EditPost__uploader-container__components">
                  <div className="imageUploader">
                    <SimpleUploadControl
                      control={control}
                      title={t('post.modal.image')}
                      label={t('common.addImage')}
                      name="imagePath"
                      value={imagePath}
                      onChange={handleSetImage}
                      onRemoveFile={handleRemoveImage}
                      isOpen={showImageUploadModal}
                      handleOpen={() => setShowImageUploadModal(false)}
                    />
                  </div>
                  <div className="EditPost__video">
                    {videoURL && (
                      <>
                        <div className="EditPost__video__player">
                          <VideoPlayer
                            url={videoURL}
                            onError={() => setIsVideoValid(false)}
                            onSuccess={() => setIsVideoValid(true)}
                          />
                          {!isVideoValid && (
                            <div className="EditPost__video__error">
                              <Icon name="report_problem" />
                              {t('post.videoUpload.error.message')}
                            </div>
                          )}
                        </div>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <div
                          className="EditPost__video__delete"
                          onClick={handleRemoveVideo}
                        >
                          <div className="EditPost__video__delete__inner">
                            <Icon name="delete_outline" />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="EditPost__uploader-container__buttons">
                  <span className="fw-bold d-none d-lg-inline" tabIndex={-1}>
                    <Tooltip
                      content={t('post.imageUpload.title')}
                      className={
                        imagePath || videoURL
                          ? 'toolActions withVideo'
                          : 'toolActions'
                      }
                      position="right"
                    >
                      <Icon
                        name="image"
                        onClick={() => setShowImageUploadModal(true)}
                        tabIndex={0}
                      />
                    </Tooltip>
                    <Tooltip
                      content={t('post.videoUpload.title')}
                      className={
                        videoURL || imagePath
                          ? 'toolActions withVideo'
                          : 'toolActions'
                      }
                      position="right"
                    >
                      <Icon
                        name="video_link"
                        className="EditPost__video-icon"
                        onClick={() => setShowVideoUploadModal(true)}
                        tabIndex={0}
                      />
                    </Tooltip>
                  </span>
                  <span className="fw-bold d-inline d-lg-none">
                    <Icon
                      name="image"
                      onClick={() => setShowImageUploadModal(true)}
                    />
                    <Icon
                      name="video_link"
                      className="EditPost__video-icon"
                      onClick={() => setShowVideoUploadModal(true)}
                    />
                  </span>
                </div>
              </div>
              <div className="row CPBody__contentForm">
                <div className="col-12 contentCheck">
                  <FormCheckbox
                    control={control}
                    name="option"
                    label={t('post.program.timezone')}
                    disabled={state === PostStateEnum.PUBLISHED}
                  />
                </div>
                {watchOption && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-4">
                        <FormSelect
                          control={control}
                          name="timezone"
                          label={t('post.timezone')}
                          placeholder={t('post.select.timezone')}
                          options={timezoneList}
                          required={true}
                          noOptionsMessage={t('select.empty')}
                          isClearable={false}
                        />
                      </div>
                      <div className="col-4">
                        <FormDateTime
                          control={control}
                          name="dates"
                          label={t('post.date.timezone')}
                          required
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="otherFooter">
            <div className="row align-items-center">
              <div className="col-auto">
                <button
                  className="btn btn-link"
                  onClick={handleSubmit((data) =>
                    onSubmit(data, PostStateEnum.DRAFTED)
                  )}
                  disabled={
                    state === PostStateEnum.PUBLISHED ||
                    !isValid ||
                    isSubmitting
                  }
                >
                  {t('post.save.drafted')}
                </button>
              </div>
              <div className="col contentAction">
                <button
                  className="btn btn-outline-primary"
                  onClick={showCancel}
                >
                  {t('common.cancel')}
                </button>
                {!watchOption ? (
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmit((data) =>
                      onSubmit(data, PostStateEnum.PUBLISHED)
                    )}
                    disabled={!isValid || isSubmitting || !isVideoValid}
                  >
                    {t('common.post')}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmit((data) =>
                      onSubmit(data, PostStateEnum.SCHEDULED)
                    )}
                    disabled={!isValid || isSubmitting || !isVideoValid}
                  >
                    {t('post.save.timezone')}
                  </button>
                )}
              </div>
            </div>
            {/* <div className="row contLeyend">
              <div className="col noLeft">
                <Icon name="info_outline" />
                {t('post.drafted.leyend')}{' '}
                <span>{t('schedulePosts.title')}</span>
              </div>
            </div> */}
          </Modal.Footer>
        </FormProvider>
      </Modal>
      {showVideoUploadModal && (
        <VideoUpload
          onClose={() => setShowVideoUploadModal(false)}
          onSetVideo={handleSetVideo}
        />
      )}
    </>
  )
}

export default EditPost
