import { FC } from 'react'
import { TabLink } from '../types'
import { TabsLink } from './TabsLink'

interface TabsNavProps {
  tabs: TabLink[]
  active: string
  setActive: (tabName: string) => void
}

export const TabsNav: FC<TabsNavProps> = ({ tabs, active, setActive }) => {
  return (
    <div className="tabs-nav">
      {tabs.map((tab) => (
        <TabsLink
          key={tab.id}
          data={tab}
          active={active}
          setActive={setActive}
        />
      ))}
    </div>
  )
}
