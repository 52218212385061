import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  FormTextInput,
  FormTextarea,
  Loader,
  MimeType,
  UploadInput,
} from '@liveconnect/components'

import { ConfigurationOnboardingForm } from '../../../core/configuration/onboarding/types'
import useConfigurationOnboarding from '../../../core/configuration/onboarding/useOnboarding'

import { buildValidationSchema } from './validations'
import { DEFAULT_LANG } from '../../../i18n/config'

import useUploadInput from '../../../utils/uploadInput/useUploadInput'
import useNotifications from '../../../utils/notifications/useNotifications'
import { uploadFile } from '../../../utils/fetch/uploadFile'

import { Main } from '../../../components/Main'
import CustomForm from '../../../components/CustomForm'
import TranslationCard from '../../../components/TranslationCard'
import Heading from '../../../components/Heading'
import { Separator } from '../../../components/Separator'

const OnboardingInfoPage = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const {
    isLoaded,
    detail,
    fetchDetail,
    uploadImage,
    create,
    update,
    translate,
  } = useConfigurationOnboarding()
  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })

  const defaultValues = {
    language: DEFAULT_LANG,
  }

  const methods = useForm<ConfigurationOnboardingForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues,
  })

  const { watch, control, setValue, setError, reset } = methods

  const formValues = watch()

  const isTranslating = useMemo(() => {
    return formValues.language !== DEFAULT_LANG
  }, [formValues.language])

  const isEdit = useMemo(() => {
    if (!detail) return false
    return detail.id !== '00000000-0000-0000-0000-000000000000'
  }, [detail])

  const onSubmit = async (values: ConfigurationOnboardingForm) => {
    const _values = { ...values }
    let action = 'create'
    if (isEdit) action = 'update'
    if (isTranslating) action = 'translate'
    try {
      _values.imageUrl = await uploadFile(_values.imageUrl, uploadImage)
      _values.logoUrl = await uploadFile(_values.logoUrl, uploadImage)

      reset(formValues)

      if (isEdit && detail) {
        if (!isTranslating) {
          update(detail.id, _values)
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { imageUrl, logoUrl, ..._translateValues } = _values
          translate(detail.id, _translateValues)
        }
      } else {
        await create(_values)
        fetchDetail()
      }

      notify.success(t(`onboardingInfo.form.feedback.${action}.ok`))
    } catch (error) {
      notify.error(t(`onboardingInfo.form.feedback.${action}.ko`))
    }
  }

  useEffect(() => {
    fetchDetail()
  }, [])

  const resetForm = () => {
    if (detail && detail.id) {
      const translation = detail.translations.find(
        (t) => t.language === formValues.language
      ) ?? {
        title: '',
        description: '',
        language: formValues.language,
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { translations, ...restDetail } = detail
      reset({
        ...formValues,
        ...restDetail,
        ...translation,
      })
    } else {
      reset(defaultValues)
    }
  }

  useEffect(() => {
    resetForm()
  }, [detail, formValues.language])

  if (!isLoaded) return <Loader />

  return (
    <Main
      ariaLabelledby="config-onboarding-info"
      title={t('onboardingInfo.title')}
      subtitle={t('onboardingInfo.subtitle')}
      isVisibleRequiredFieldsLeyend
    >
      <CustomForm
        methods={methods}
        onSubmit={onSubmit}
        onCancel={resetForm}
        isSelectDisabled={!isEdit}
      >
        {isEdit && detail && (
          <TranslationCard
            translations={detail.translations.map(
              ({ language, title }) =>
                ({
                  name: title,
                  language,
                } as any)
            )}
            lang={formValues.language}
            entity={t('onboardingInfo.entity')}
          />
        )}
        <Heading as="h2" subheading={t('onboardingInfo.detailedInfo.subtitle')}>
          {t('onboardingInfo.detailedInfo.title')}
        </Heading>
        <div className="row">
          <div className="col-6">
            <FormTextInput
              label={t('onboardingInfo.form.title.label')}
              placeholder={t('onboardingInfo.form.title.placeholder')}
              control={control}
              name="title"
              required
              data-testid="onboardingInfo-form-title"
            />
            <FormTextarea
              label={t('onboardingInfo.form.description.label')}
              placeholder={t('onboardingInfo.form.description.placeholder')}
              control={control}
              name="description"
              required
              data-testid="onboardingInfo-form-description"
              rows={4}
              helperText={t('validations.leyendField.max', { number: 140 })}
            />
          </div>
        </div>
        {!isTranslating && (
          <>
            <Separator />
            <Heading
              as="h2"
              subheading={t('onboardingInfo.visualInfo.subtitle')}
            >
              {t('onboardingInfo.visualInfo.title')}
            </Heading>
            <UploadInput
              control={control}
              title={t('onboardingInfo.form.imageUrl.label')}
              name="imageUrl"
              accept={accept}
              onRemoveFile={() => setValue('imageUrl', '')}
              selectFilesError={(error) =>
                setError('imageUrl', {
                  message: error.message,
                })
              }
              required
              rules={{
                image: {
                  maxFileSize: 1,
                  maxWidth: 635,
                  maxHeight: 315,
                },
              }}
              data-testid="onboardingInfo-form-imageUrl"
              labels={labels}
            />
            <Separator />
            <Heading
              as="h2"
              subheading={t('onboardingInfo.visualInfo.subtitle')}
            >
              {t('onboardingInfo.logoInfo.title')}
            </Heading>
            <UploadInput
              control={control}
              title={t('onboardingInfo.form.logoUrl.label')}
              name="logoUrl"
              accept={accept}
              onRemoveFile={() => setValue('logoUrl', '')}
              selectFilesError={(error) =>
                setError('logoUrl', {
                  message: error.message,
                })
              }
              required
              rules={{
                image: {
                  maxFileSize: 1,
                  maxWidth: 75,
                  maxHeight: 45,
                },
              }}
              data-testid="onboardingInfo-form-logoUrl"
              labels={labels}
            />
          </>
        )}
      </CustomForm>
    </Main>
  )
}

export default OnboardingInfoPage
