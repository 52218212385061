export const sortElementsArray = <T>(
  array: T[],
  sourceIndex: number,
  destinationIndex: number
): T[] => {
  // Validate that the indices are within the array bounds
  if (
    sourceIndex < 0 ||
    sourceIndex >= array.length ||
    destinationIndex < 0 ||
    destinationIndex >= array.length
  ) {
    throw new Error('Indices are out of array bounds.')
  }

  // Create a copy of the original array to avoid modifying it directly
  const copiedArray = [...array]

  // Extract the element to be moved
  const elementToMove = copiedArray.splice(sourceIndex, 1)[0]

  // Insert the element at the destination index
  copiedArray.splice(destinationIndex, 0, elementToMove)

  return copiedArray
}
