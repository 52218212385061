import { FC, ReactNode, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ContextMenuItem } from '@liveconnect/communities-ui'

import { Card } from '../../../../../components/Card'

import './styles.scss'

interface CardsGridItemProps {
  handleDelete?: () => void
  handleEdit?: () => void
  handlePublish?: (newState: boolean) => void
  id: string
  isDisabledClass?: boolean
  title: string
  imagePill?: string
  imageUrl: string
  brandImageUrl: string
  isPublished?: boolean
  publishText?: string
  isPublishDisabled?: boolean
  translations: string[]
  content?: ReactNode
}

export const CardsGridItem: FC<CardsGridItemProps> = ({
  id,
  translations,
  isDisabledClass = false,
  title,
  imagePill,
  imageUrl,
  isPublished,
  publishText,
  isPublishDisabled = false,
  brandImageUrl,
  handleDelete,
  handlePublish,
  handleEdit,
  content,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const contextMenuOptions = useMemo(() => {
    const actions: ContextMenuItem[] = []
    actions.push(
      {
        label: t('brand.badges.manage'),
        onClick: () => navigate(`badges/${id}/manage`),
      },
      {
        label: t('brand.badges.edit'),
        onClick: () => handleEdit!(),
      },
      {
        label: t('brand.badges.delete'),
        onClick: () => handleDelete!(),
      }
    )
    return actions
  }, [])

  return (
    <Card id={id} hasImage={false} isDisabled={isDisabledClass}>
      <Card.Image src={imageUrl} alt={title} tag={imagePill} />
      <Card.Content>
        <Card.Content.Top imageSrc={brandImageUrl} imageAlt={title}>
          <Card.Content.Top.Toggle
            value={isPublished!}
            label={publishText!}
            onChange={handlePublish!}
            isDisabled={isPublishDisabled!}
          />
        </Card.Content.Top>
        <Card.Content.Header
          title={title}
          actions={contextMenuOptions}
          hideId
        />
        <Card.Content.Body>{content}</Card.Content.Body>
        <Card.Content.Bottom translations={translations} />
      </Card.Content>
    </Card>
  )
}
