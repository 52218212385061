import { FC, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'

import { Loader } from '@liveconnect/components'

import {
  EditPostFnParams,
  PostParams,
  PostStateEnum,
  Publisher,
  PublishersResponse,
  PublisherTypeEnum,
} from '../../../core/posts/types'
import { PostContainer } from '../../Wall/Post'
import { Channel } from '../../../core/channels/types'
import usePosts from '../../../core/posts/usePosts'
import EditPost from '../../Wall/EditPost'

import './styles.scss'
import { WallFiltersParams } from '../../Wall/Filters'

interface EditingPostInterface {
  channels: Channel[]
  publisher: Publisher
  title: string
  message: string
  image: string
  id: string
  isHighlighted: boolean
  selectedThemes: string[]
  video: string
  publishDateTime: string | null
  state: PostStateEnum
  timezone: string
}

interface SchedulesComponentProps {
  statePosts: PostStateEnum
  publisherProp: PublishersResponse | undefined
  filters: Partial<WallFiltersParams>
}

const WallSchedulesComponent: FC<SchedulesComponentProps> = ({
  statePosts,
  publisherProp,
  filters,
}) => {
  const { t } = useTranslation()
  const [editingPost, setEditingPost] = useState<EditingPostInterface | null>(
    null
  )

  const {
    postList,
    hasNextPostsPage,
    fetchPosts,
    fetchNextPagePosts,
    isLoaded,
  } = usePosts()

  const editPost = ({
    publisher,
    channels,
    title,
    message,
    themes,
    image,
    id,
    isHighlighted,
    video,
    publishDateTime,
    state,
    timezone,
  }: EditPostFnParams) => {
    setEditingPost({
      channels,
      publisher,
      title,
      message,
      image,
      id,
      isHighlighted,
      selectedThemes: themes.map((theme) => theme.id),
      video,
      publishDateTime,
      state,
      timezone,
    })
  }

  const getParams = () => {
    let params: PostParams
    if (publisherProp === undefined) {
      params = {
        state: statePosts,
        is_pending_post: true,
        ...filters,
      }
      return params
    }
    params = {
      publisher_external_id: publisherProp.id,
      is_pending_post: true,
      publisher_type: publisherProp.type,
      state: statePosts,
      ...filters,
    }
    return params
  }

  const publisherPostContainer = () => {
    return {
      id: 'withoutPublish',
      name: PublisherTypeEnum.TENANT,
      type: PublisherTypeEnum.TENANT,
      channels: [],
      profileImageUrl: '',
    }
  }

  useEffect(() => {
    fetchPosts(getParams())
  }, [statePosts, filters, publisherProp])

  return (
    <div className="WallSchedules">
      {!isLoaded ? (
        <Loader />
      ) : (
        <>
          {postList && postList.length > 0 ? (
            <InfiniteScroll
              dataLength={postList.length}
              next={() => fetchNextPagePosts(getParams())}
              hasMore={hasNextPostsPage}
              loader={<Loader />}
              scrollableTarget="layout__scrollable"
            >
              {postList && (
                <div>
                  {postList.map((post) => (
                    <PostContainer
                      key={post.id}
                      {...post}
                      selectedPublisher={publisherPostContainer()}
                      publisherType={PublisherTypeEnum.TENANT}
                      isPublishable={true}
                      openEdit={editPost}
                      fetchPosts={() => fetchPosts(getParams())}
                    />
                  ))}
                </div>
              )}
            </InfiniteScroll>
          ) : (
            <div className="contEmpty">
              <p>
                {statePosts === PostStateEnum.DRAFTED
                  ? t('schedulePosts.empty.drafted')
                  : t('schedulePosts.empty.scheduled')}
              </p>
            </div>
          )}

          {editingPost && (
            <EditPost
              onClose={() => {
                setEditingPost(null)
              }}
              onCreate={() => {
                fetchPosts(getParams())
              }}
              selectedPublisher={editingPost.publisher.externalId}
              isIfemaPublish={
                editingPost.publisher.type === PublisherTypeEnum.TENANT
              }
              {...editingPost}
            />
          )}
        </>
      )}
    </div>
  )
}

export default WallSchedulesComponent
