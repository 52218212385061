import { CustomIconName } from '../../../../components/CustomIcon/types'

interface SocialMediaInputs {
  name: string
  label: string
  icon: CustomIconName
}

export const socialMediaInputs: SocialMediaInputs[] = [
  {
    name: 'linkedinUrl',
    label: 'Linkedin',
    icon: 'linkedin',
  },
  {
    name: 'twitterUrl',
    label: 'Twitter',
    icon: 'twitter',
  },
  {
    name: 'youtubeUrl',
    label: 'Youtube',
    icon: 'youtube',
  },
  {
    name: 'facebookUrl',
    label: 'Facebook',
    icon: 'facebook',
  },
  {
    name: 'instagramUrl',
    label: 'Instagram',
    icon: 'instagram',
  },
]
