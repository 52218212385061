export enum Permission {
  FullAccess = 'CommunityAdmin_FullAccess',
}

export interface PermissionListResponse {
  hasProfile: boolean
  actions: Permission[]
}

export interface PermissionsListPayload {
  list: Permission[]
}

export interface UserItem {
  name: string
  surname: string
  email: string
  hasPermissions: boolean
  id: string
}

export interface PermissionsState {
  list: Permission[]
  userList: UserItem[]
  userDetail: PermissionsDetailForm | null
  communityPermissions: PermissionsCommunity | null
  isLoaded: boolean
  isLoadedUsers: boolean
}

export interface PermissionsCommunity {
  moderatorPosts: boolean
  editorSectorsChannelsThemes: boolean
  editorSpaces: boolean
  moderatorDirectories: boolean
  brandSpacePermission: BrandSpacePermission[]
}

export interface PermissionsDetailForm {
  name: string
  surname: string
  email: string
  moderatorPosts: boolean
  editorSectorsChannelsThemes: boolean
  editorSpaces: boolean
  moderatorDirectories: boolean
  space365: string
  hasPermissions: boolean

  brandSpacePermission: BrandSpacePermission[]
}

export interface BrandSpacePermission {
  id: string
  brandSpaceId?: string
  name: string
  logoImageUrl: string
  permissions: PermissionsBrand
  channels: PermissionsChannel[]
}

export interface PermissionsBrand {
  directoriesEditor: boolean
}

export interface PermissionsChannel {
  id: string
  name: string
  permissions: PermissionChannel
}

export interface PermissionChannel {
  wallEditor: boolean
  usersModerator: boolean
  activitiesEditor: boolean
  directoriesEditorChannel: boolean
}

export interface PermissionsOptionBrandSpaces {
  id: string
  brandSpaceId: string
  name: string
  logoImageUrl: string
  channels: PermissionsOptionChannel[]
}

export interface PermissionsOptionChannel {
  id: string
  channelId: string
  name: string
}

export interface UsePermissionsResult {
  list: Array<Permission>
  userList: UserItem[]
  userDetail: PermissionsDetailForm | null
  isLoaded: boolean
  isLoadedUsers: boolean
  communityPermissions: PermissionsCommunity | null
  updatePermissions: (id: string, data: PermissionsDetailForm) => Promise<void>
  fetchList: () => Promise<void>
  fetchUsersList: () => void
  fetchUserDetail: (id: string) => void
  verifyPermissionsMaster: () => boolean
  verifyModeratorPosts: () => boolean
  verifyModeratorWall: () => boolean
  verifyEditorSectorsChannelsThemes: () => boolean
  verifyEditorSpaces: () => boolean
  verifySomeBrands: (idBrand: string) => boolean
  verifyModeratorDirectories: () => boolean
  verifySomePermissionsDirectories: () => boolean
  verifyBrandPermissionsDirectories: (idBrand: string) => boolean
  verifySomeBrandPermissionsDirectories: () => boolean
  verifySomeChannelPermissionsDirectories: () => boolean
  verifyChannelsPermissionsDirectories: (idChannels: string[]) => boolean
  verifyWallEditor: (idChannels: string[], externalId: string) => boolean
  verifyWallEditorComment: (externalId: string) => boolean
  verifyUsersModerator: (idChannels: string[]) => boolean
  verifySomeActivitiesEditor: () => boolean
  verifyActivitiesEditorTypeUser: (idChannels: string[]) => boolean
  verifyActivitiesEditorTypeBrand: (
    idChannels: string[],
    externalId: string
  ) => boolean
  getBrandSpaces: () => Promise<PermissionsOptionBrandSpaces[] | undefined>
  fetchCommunityPermissions: () => void
}
