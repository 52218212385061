import { SearchInput } from '@liveconnect/communities-ui'
import { Icon } from '@liveconnect/icons'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'

type ContentTableProps = {
  title: string
  titleLink: string
  urlLink: string
  placeHolderText: string
  handleSearch?: (text: string) => void
}

const SearchTable: FC<ContentTableProps> = ({
  title,
  titleLink,
  urlLink,
  placeHolderText,
  handleSearch,
}) => {
  return (
    <div className="SearchTable row align-items-center">
      <div className="col SearchTable__contentTitle">
        <p className="title">{title}</p>
      </div>
      <div className="col-auto">
        <div className="content-right">
          {handleSearch && (
            <div className="search-icon-control">
              <Icon name="search" />
              <SearchInput
                onSearch={(s: string) => {
                  handleSearch(s)
                }}
                defaultValue={''}
                placeholder={placeHolderText}
              />
            </div>
          )}
          <Link className="btn btn-primary" to={urlLink}>
            {titleLink}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SearchTable
