import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Loader, SelectControl, Tooltip } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import useActivities from '../../../../core/activities/useActivities'
import { ActivityType, ActivityDetail } from '../../../../core/activities/types'
import useLocalizations from '../../../../core/localizations/useLocalizations'
import { format } from 'date-fns'
import FormActions from '../../../../components/forms/FormActions'
import { DEFAULT_LANG } from '../../../../i18n/config'
import { useCustomRouter } from '../../../../utils/extractParams'
import TranslationCard from '../../../../components/TranslationCard'
/* import usePermissions from '../../../../core/permissions/usePermissions' */
import { FetchError } from '../../../../utils/fetch/types'
import useNotifications from '../../../../utils/notifications/useNotifications'

import './styles.scss'

interface ActivityFormDetailDataProps {
  label: string
  value: string | number | Date | ReactNode | undefined
  isDate?: boolean
}

const ActivityFormDetailData = ({
  label,
  value,
  isDate,
}: ActivityFormDetailDataProps) => {
  const getFormatedDate = (date: string | undefined) => {
    if (!date) return '-'
    return format(new Date(date as string), 'dd/MM/yyyy - HH:mm')
  }

  return (
    <div className="activity-detail-group">
      <label className="activity-detail-group__label">{label}</label>
      {isDate && (
        <div className="activity-detail-group__value">
          {getFormatedDate(value as string)}
        </div>
      )}
      {!isDate && (
        <div className="activity-detail-group__value">{value ?? '-'}</div>
      )}
    </div>
  )
}

interface ActivityFormDetailProps {
  setActivityTitle: (title: string) => void
}

const ActivityFormDetail: FC<ActivityFormDetailProps> = ({
  setActivityTitle,
}) => {
  const { activityId } = useParams()
  const { fetchActivity, activity, isLoaded } = useActivities()
  const { localizations } = useLocalizations()
  const { t } = useTranslation()
  const [language, setLanguage] = useState(DEFAULT_LANG)
  const { basePath } = useCustomRouter()
  const navigate = useNavigate()
  const notify = useNotifications()

  const getPropertyValueByActivityType = (field: string) => {
    let node: keyof ActivityDetail | undefined = undefined
    switch (activity?.type) {
      case 'FaceToFace':
        node = 'faceToFaceType'
        break
      case 'VideoConference':
        node = 'videoConferenceType'
        break
      case 'RoundTable':
        node = 'roundTableType'
        break
      case 'AskAuthor':
        node = 'askAuthorType'
        break
    }
    if (node && activity) return activity[node][field] ?? ''
    return ''
  }

  const localizationsOptions = useMemo(() => {
    return localizations
      .filter((loc) => {
        return activity?.translations.some(
          (activityTranslation) => activityTranslation.language === loc.isoCode
        )
      })
      .map((item) => ({
        label: `${t(`localizations.${item.isoCode}`)} ${
          item.isoCode == DEFAULT_LANG
            ? `(${t(`common.changeLanguage.defaultLanguage`)})`
            : ''
        } `,
        value: item.isoCode,
      }))
  }, [localizations, activity])

  const translationsComponent: { language: string; name: string }[] =
    useMemo(() => {
      if (activity) {
        return activity.translations.map((translation) => {
          return { language: translation.language, name: translation.title }
        })
      }
      return []
    }, [activity])

  const getIndexTranslationActive = () => {
    if (!activity) return 0
    return (
      activity.translations.findIndex(
        (translation) => translation.language === language
      ) ?? 0
    )
  }

  const handleCancel = () => {
    navigate(`${basePath}/activities`)
  }

  useEffect(() => {
    if (activity) setActivityTitle(activity.translations[0].title)
  }, [activity])

  const getActivity = async () => {
    try {
      if (activityId) await fetchActivity(activityId)
    } catch (error) {
      const fetchError = error as FetchError
      if (fetchError.status === 403) {
        navigate(`${basePath}/access-denied`)
      } else {
        notify.error(t('common.unknownError'))
      }
    }
  }

  useEffect(() => {
    getActivity()
  }, [activityId])

  return (
    <div>
      {activityId && !isLoaded && <Loader />}
      {activityId && isLoaded && activity && (
        <div className="AFC">
          <div className="AFC__section">
            <div className="section_header">
              {activityId && (
                <TranslationCard
                  translations={translationsComponent}
                  lang={language}
                  entity={t('activities.entity')}
                />
              )}
              <span className="ActivityForm__header__title__required">
                {t('common.required')}
              </span>
            </div>
            <p className="AFC__title">{t('activity.form.config.data.title')}</p>
            <div className="row">
              <div className="col-9">
                <ActivityFormDetailData
                  label={t('activity.form.config.brandSpaceId.label')}
                  value={activity.brandSpace.name}
                />
                <ActivityFormDetailData
                  label={t('activity.form.config.title.label')}
                  value={
                    activity.translations[getIndexTranslationActive()].title
                  }
                />
                <ActivityFormDetailData
                  label={t('activity.form.config.description.label')}
                  value={
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          activity.translations[getIndexTranslationActive()]
                            .description,
                      }}
                    />
                  }
                />
                <ActivityFormDetailData
                  label={t('activity.form.config.image.label')}
                  value={
                    <div className="activity-detail-group__image">
                      <img
                        src={
                          activity.translations[getIndexTranslationActive()]
                            .imageURL
                        }
                        alt={
                          activity.translations[getIndexTranslationActive()]
                            .title
                        }
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </div>

          <div className="AFC__section">
            <p className="AFC__title">{t('activity.form.config.title')}</p>
            <div className="row">
              <div className="col-9">
                <div className="row">
                  <div className="col-6">
                    <ActivityFormDetailData
                      label={t('activity.form.config.type.label')}
                      value={t(
                        `activity.form.config.type.options.${activity.type}`
                      )}
                    />
                  </div>
                  <div className="col-6">
                    <ActivityFormDetailData
                      label={t('activity.form.config.limit.label')}
                      value={activity.maxActivityAttendees}
                    />
                  </div>
                  <div className="col-6">
                    <ActivityFormDetailData
                      label={t(
                        'activity.form.config.startDateParticipation.label'
                      )}
                      value={activity.startDateParticipation}
                      isDate
                    />
                  </div>
                  <div className="col-6">
                    <ActivityFormDetailData
                      label={t(
                        'activity.form.config.endDateParticipation.label'
                      )}
                      value={activity.endDateParticipation}
                      isDate
                    />
                  </div>
                  <div className="col-6">
                    <ActivityFormDetailData
                      label={t('activity.form.config.expirationDate.label')}
                      value={activity.expirationDate}
                      isDate
                    />
                  </div>
                  <div className="col-6">
                    <ActivityFormDetailData
                      label={t('activity.form.config.activityLanguage.label')}
                      value={
                        (localizations || []).find(
                          (item) => item.isoCode === activity.language
                        )?.name
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {activity.type && (
            <div className="AFC__section">
              <p className="AFC__title">
                {t('activity.form.config.infoSpecify', {
                  type: t(`activity.form.config.type.options.${activity.type}`),
                })}
              </p>
              {[
                ActivityType.Video,
                ActivityType.AskAuthor,
                ActivityType.RoundTable,
              ].includes(activity.type) && (
                <>
                  <div className="row">
                    <div className="col-9">
                      <div className="row">
                        <div className="col-6">
                          <ActivityFormDetailData
                            label={t('activity.form.config.startDate.label')}
                            value={getPropertyValueByActivityType('startDate')}
                            isDate
                          />
                        </div>
                        <div className="col-6">
                          <ActivityFormDetailData
                            label={t('activity.form.config.endDate.label')}
                            value={getPropertyValueByActivityType('endDate')}
                            isDate
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-9">
                      <p className="AFC__title mb-1">
                        {t('activity.form.config.linksVideoConference')}
                      </p>
                      <ActivityFormDetailData
                        label={t(
                          'activity.form.config.linksVideoConference.broadcastURL.label'
                        )}
                        value={getPropertyValueByActivityType('broadcastURL')}
                      />
                      <ActivityFormDetailData
                        label={t(
                          'activity.form.config.linksVideoConference.postBroadcastURL.label'
                        )}
                        value={getPropertyValueByActivityType(
                          'postBroadcastURL'
                        )}
                      />
                    </div>
                  </div>
                </>
              )}
              {activity.type == ActivityType.FaceToFace && (
                <div className="row">
                  <div className="col-9">
                    <div className="row">
                      <div className="col-6">
                        <ActivityFormDetailData
                          label={t('activity.form.config.startDate.label')}
                          value={activity.faceToFaceType?.startDate}
                          isDate
                        />
                      </div>
                      <div className="col-6">
                        <ActivityFormDetailData
                          label={t('activity.form.config.endDate.label')}
                          value={activity.faceToFaceType?.endDate}
                          isDate
                        />
                      </div>
                      <div className="col-6">
                        <ActivityFormDetailData
                          label={t('activity.form.config.location.label')}
                          value={activity.faceToFaceType?.location.fullAddress}
                        />
                      </div>
                      <div className="col-6">
                        <ActivityFormDetailData
                          label={t(
                            'activity.form.config.additionalLocationInfo.label'
                          )}
                          value={
                            activity.faceToFaceType?.location
                              .aditionalInformation
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="form-actions-lang mt-5">
            <FormActions
              languageSelector={
                <>
                  <SelectControl
                    name="lang"
                    value={language}
                    onChange={function (v: string): void {
                      setLanguage(v)
                    }}
                    label={''}
                    hiddenLabel
                    options={localizationsOptions}
                    noOptionsMessage={undefined}
                    isClearable={false}
                    menuPlacement="top"
                  />
                  <Tooltip
                    content={t('common.changeLanguage.tooltip')}
                    className="ms-2"
                  >
                    <Icon name="info_outline" tabIndex={0} />
                  </Tooltip>
                </>
              }
            >
              <button
                className="btn btn-outline-primary"
                onClick={handleCancel}
              >
                {t('common.exit')}
              </button>
            </FormActions>
          </div>
        </div>
      )}
    </div>
  )
}

export default ActivityFormDetail
