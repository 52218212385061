import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { useHtmlToString } from '../../utils/useHtmlToString'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    code: yup
      .string()
      .label(t('brand.edit.eventCode'))
      .required(({ label }) => t('validations.required', { label })),
    name: yup
      .string()
      .label(t('brand.edit.brandName'))
      .max(
        50,
        t('validations.maxLength', {
          label: t('brand.edit.brandName'),
          maxLength: 50,
        })
      )
      .required(({ label }) => t('validations.required', { label })),
    title: yup
      .string()
      .label(t('brand.edit.brandTitle'))
      .max(
        60,
        t('validations.maxLength', {
          label: t('brand.edit.brandTitle'),
          maxLength: 60,
        })
      )
      .required(({ label }) => t('validations.required', { label })),
    shortDescription: yup
      .string()
      .label(t('brand.edit.brandShortDescription'))
      .max(
        60,
        t('validations.maxLength', {
          label: t('brand.edit.brandShortDescription'),
          maxLength: 60,
        })
      )
      .required(({ label }) => t('validations.required', { label })),
    logoImageUrl: yup
      .string()
      .label(t('brand.edit.logoImageUrl'))
      .required(({ label }) => t('validations.required', { label })),
    headerImageUrl: yup
      .string()
      .label(t('brand.edit.headerImageUrl'))
      .required(({ label }) => t('validations.required', { label })),
    profileImageUrl: yup
      .string()
      .label(t('brand.edit.profileImageUrl'))
      .required(({ label }) => t('validations.required', { label })),
    description: yup
      .string()
      .label(t('brand.edit.brandDescription'))
      .required(({ label }) => t('validations.required', { label }))
      .test(
        'brand.edit.brandDescription',
        ({ label }) => t('validations.maxLength', { label, maxLength: 1400 }),
        (val) => useHtmlToString(val ?? '').length <= 1400
      ),
  })
}
