import * as yup from 'yup'

export function buildValidationSchema(): yup.AnyObjectSchema {
  return yup
    .object()
    .shape({
      brandSpaces: yup.array(),
    })
    .required()
}
