import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  BrandInfo,
  BrandBasicInfo,
  AvailableBrand,
  BrandSummary,
} from './types'
import { setList, showLoader, hideLoader, setBrand } from './store'

import useFetch from '../../utils/fetch/useFetch'
import { DEFAULT_LANG } from '../../i18n/config'

const useBrandsInfo = () => {
  const dispatch = useAppDispatch()
  const { detail, list, isLoaded } = useAppSelector((state) => state.brandsInfo)
  const { get, put, post, del } = useFetch()

  const endpoint = 'brand-spaces'
  const partnersEndpoint = 'partners'

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const getBrandsChannels = async (): Promise<BrandSummary[]> => {
    const response: BrandSummary[] =
      (await get({ endpoint: `channels/${endpoint}` })) ?? []
    return response
  }

  const fetchBrandsChannels = async (): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: BrandSummary[] = await getBrandsChannels()
      dispatch(setList(response))
    })
  }

  const getBrands = async (): Promise<BrandSummary[]> => {
    const response: BrandSummary[] = (await get({ endpoint })) ?? []
    return response
  }

  const getAvailableBrands = async (): Promise<
    AvailableBrand[] | undefined
  > => {
    return await dispatchLoaders(async () => {
      return await get({ endpoint: `${endpoint}/event-codes` })
    })
  }

  const fetchBrands = async (): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: BrandSummary[] = await getBrands()
      dispatch(setList(response))
    })
  }

  const deleteBrand = async (brandId: string): Promise<void> =>
    await del({ endpoint, id: brandId })

  const fetchBrandDetail = async (
    brandId: string
  ): Promise<BrandInfo | undefined> => {
    return await dispatchLoaders<BrandInfo | undefined>(async () => {
      const response: BrandInfo | undefined = await get({
        endpoint: `${endpoint}/${brandId}`,
      })
      if (response) dispatch(setBrand(response))
      return response
    })
  }

  const clearCurrentBrand = (): void => {
    dispatch(setBrand(null))
  }

  interface ImageResult {
    uri: string
  }
  const uploadImage = async (
    image: string,
    endpoint: string,
    field: keyof BrandBasicInfo
  ): Promise<[keyof BrandBasicInfo, string] | undefined> => {
    const form = new FormData()
    const blob = await fetch(image).then((r) => r.blob())
    form.set('imageFile', blob)
    dispatch(showLoader())
    const result: ImageResult | undefined = await post({
      endpoint,
      body: form,
      isMultipart: true,
    })
    dispatch(hideLoader())
    if (result) return [field, result.uri]
  }

  const uploadBrandHeaderImage = async (
    image: string
  ): Promise<[keyof BrandBasicInfo, string] | undefined> =>
    uploadImage(image, `${endpoint}/images/header`, 'headerImageUrl')

  const uploadBrandLogoImage = async (
    image: string
  ): Promise<[keyof BrandBasicInfo, string] | undefined> =>
    uploadImage(image, `${endpoint}/images/logo`, 'logoImageUrl')

  const uploadBrandProfileImage = async (
    image: string
  ): Promise<[keyof BrandBasicInfo, string] | undefined> =>
    uploadImage(image, `${endpoint}/images/profile`, 'profileImageUrl')

  const uploadPartnerImage = async (
    image: string
  ): Promise<string | undefined> => {
    const result = await uploadImage(
      image,
      `${partnersEndpoint}/image`,
      'logoImageUrl'
    )
    return result ? result[1] : undefined
  }

  const uploadSponsorImage = async (
    image: string
  ): Promise<string | undefined> => {
    const result = await uploadImage(
      image,
      `${endpoint}/sponsors/image`,
      'logoImageUrl'
    )
    return result ? result[1] : undefined
  }

  const createBrand = async (
    basicInfo: BrandBasicInfo
  ): Promise<BrandInfo | undefined> => {
    const response: BrandInfo | undefined = await post({
      endpoint,
      body: basicInfo,
    })
    if (response) {
      const defTranslation = response.translations.filter(
        (t) => t.language === DEFAULT_LANG
      )[0]
      dispatch(
        setList([
          ...list,
          {
            ...response,
            ...defTranslation,
          },
        ])
      )
      dispatch(setBrand(response))
    }
    return response
  }

  const updateBrand = async (
    brandId: string,
    basicInfo: BrandBasicInfo
  ): Promise<void> => {
    const response: BrandInfo | undefined = await put({
      endpoint,
      id: brandId,
      body: basicInfo,
    })
    if (response) {
      dispatch(
        setList(
          list.map((brand: BrandSummary) => {
            if (brand.id === brandId) return { ...brand, ...basicInfo }
            return brand
          })
        )
      )
      dispatch(setBrand(response))
    }
  }

  const deletePartner = async (partnerId: string): Promise<void> => {
    await dispatchLoaders(async () => {
      await del({
        endpoint: partnersEndpoint,
        id: partnerId,
      })
    })
  }

  const deleteSponsor = async (
    brandId: string,
    sponsorId: string
  ): Promise<void> => {
    await dispatchLoaders(async () => {
      await del({
        endpoint: `${endpoint}/${brandId}/sponsors`,
        id: sponsorId,
      })
    })
  }

  const setDetail = (newBrand: BrandInfo): void => {
    dispatch(setBrand(newBrand))
  }

  return {
    brand: detail,
    brandList: list,
    isLoaded,
    getBrands,
    getAvailableBrands,
    fetchBrandDetail,
    fetchBrands,
    fetchBrandsChannels,
    clearCurrentBrand,
    createBrand,
    updateBrand,
    deleteBrand,
    setDetail,
    uploadBrandHeaderImage,
    uploadBrandLogoImage,
    uploadBrandProfileImage,
    uploadPartnerImage,
    uploadSponsorImage,
    deletePartner,
    deleteSponsor,
  }
}

export default useBrandsInfo
