import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Control, useFieldArray } from 'react-hook-form'
import { Icon } from '@liveconnect/icons'
import { FormTextInput, TextControl } from '@liveconnect/components'

import { Section } from '../../../core/sections/types'
import { DEFAULT_LANG } from '../../../i18n/config'
import { FormSection, FormSubsection, SectionsForm } from './types'
import { getTranslationFromSection } from './utils'
import classNames from 'classnames'

interface EditSectionComponentProps {
  control: Control<SectionsForm, object>
  section?: Section
  sectionIndex: number
  isNew: boolean
  currentLang: string
  watchSections: FormSection[]
  onClick: (index: number) => void
}

const EditSectionComponent: FC<EditSectionComponentProps> = ({
  control,
  section,
  sectionIndex,
  isNew,
  currentLang,
  watchSections,
  onClick,
}) => {
  const { t } = useTranslation()
  const { fields, append, remove } = useFieldArray<SectionsForm>({
    control,
    name: `sections.${sectionIndex}.subsections`,
  })
  const [withTrash, setWithTrash] = useState<boolean>(true)

  const youShouldNotChange = () => {
    throw new Error('Cannot change value to disabled input')
  }

  const renderSubsection = (
    subsection: FormSubsection,
    sectionIndex: number,
    index: number
  ): JSX.Element => {
    const realSubsection = section?.subSections?.[index]
    const isTranslation = currentLang !== DEFAULT_LANG
    let defaultLanguageInput = <></>

    if (!isNew && isTranslation && realSubsection?.id) {
      const defTranslation = getTranslationFromSection(
        realSubsection,
        DEFAULT_LANG
      )
      defaultLanguageInput = (
        <div className="row">
          <div className="col-11">
            <TextControl
              label={t('sections.list.subsection')}
              type="text"
              name={realSubsection.id}
              value={defTranslation?.name as string}
              disabled={true}
              onChange={youShouldNotChange}
              className="disabledInput"
            />
          </div>
        </div>
      )
    }
    return (
      <div className="EditSubsection" key={subsection.id}>
        {defaultLanguageInput}
        <div className="EditSubsection__contentForm row">
          <div className="col-11">
            <FormTextInput
              control={control}
              name={`sections.${sectionIndex}.subsections.${index}.name`}
              label={isTranslation ? undefined : t('sections.list.subsection')}
              placeholder={
                isTranslation
                  ? t('sections.list.subsection.placeholder')
                  : undefined
              }
              type="text"
              required={true}
            />
          </div>
          <div className="col-1 contentDelete trash">
            <button className="delete-section" onClick={() => remove(index)}>
              <Icon name="delete_outline" />
            </button>
          </div>
        </div>
      </div>
    )
  }

  const isTranslation = currentLang !== DEFAULT_LANG
  let defaultLanguageInput = <></>

  const shouldTranslate: boolean =
    !isNew && isTranslation && typeof section !== 'undefined'
  if (shouldTranslate && section) {
    const defTranslation = getTranslationFromSection(section, DEFAULT_LANG)
    defaultLanguageInput = (
      <div className="row">
        <div className="col-11 contForm">
          <TextControl
            label={t('sections.list.section')}
            type="text"
            name={section.id}
            value={defTranslation?.name as string}
            disabled={true}
            onChange={youShouldNotChange}
            className="disabledInput"
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    if ((watchSections.length === 1 && isNew) || isTranslation) {
      setWithTrash(false)
    }
  }, [watchSections])

  return (
    <div
      className={classNames('EditSection', {
        'is-translation': shouldTranslate,
      })}
    >
      {defaultLanguageInput}
      <div className="row">
        <div className="col-11 contForm">
          <FormTextInput
            control={control}
            name={`sections.${sectionIndex}.name`}
            label={isTranslation ? undefined : t('sections.list.section')}
            placeholder={
              isTranslation ? t('sections.list.section.placeholder') : undefined
            }
            type="text"
            required={true}
          />
        </div>
        {withTrash && (
          <div className="col-1 contentDelete">
            <button
              className="delete-section"
              onClick={() => onClick(sectionIndex)}
            >
              <Icon name="delete_outline" />
            </button>
          </div>
        )}
      </div>

      {fields.map((subsection: FormSubsection, subsectionIndex) =>
        renderSubsection(subsection, sectionIndex, subsectionIndex)
      )}
      {isNew && (
        <button className="add-subsection" onClick={() => append({ name: '' })}>
          <Icon name="add" />
          {t('sections.new.add-subsection')}
        </button>
      )}
    </div>
  )
}

export default EditSectionComponent
