import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    fromdate: yup
      .string()
      .nullable()
      .test(
        'endDate',
        t('validations.lowerThan', {
          label: t('post.filters.date.start'),
          other: t('post.filters.date.end'),
        }),
        (value, context) => {
          const { todate } = context.parent
          if (value && todate) {
            return new Date(todate) > new Date(value)
          }
          return true
        }
      ),
    todate: yup
      .string()
      .nullable()
      .test(
        'startDate',
        t('validations.greaterThan', {
          label: t('post.filters.date.end'),
          other: t('post.filters.date.start'),
        }),
        (value, context) => {
          const { fromdate } = context.parent
          if (value && fromdate) {
            return new Date(fromdate) < new Date(value)
          }
          return true
        }
      )
      .test(
        'notFuture',
        t('validations.notFuture', {
          label: t('post.filters.date.end'),
        }),
        (value) => {
          if (value) {
            return new Date(value) < new Date()
          }
          return true
        }
      ),
  })
}
