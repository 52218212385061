// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leyend-field{font-size:.7rem;margin-bottom:16px;margin-top:-2rem;display:block}[aria-labelledby=directories-form-element] .lc-form-control-wrapper:not(.select){margin-bottom:2rem}.form-radio-container{margin-bottom:2rem;display:flex;gap:2rem}.form-radio-container .Radio__item .lc-form-control-wrapper{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/app/pages/Directories/FormElement/styles.scss"],"names":[],"mappings":"AAQA,cACE,eAAA,CACA,kBAAA,CACA,gBAAA,CACA,aAAA,CAIA,iFACE,kBAAA,CAIJ,sBACE,kBAAA,CACA,YAAA,CACA,QAAA,CAEE,4DACE,eAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.leyend-field {\n  font-size: 0.7rem;\n  margin-bottom: 16px;\n  margin-top: -2rem;\n  display: block;\n}\n\n[aria-labelledby='directories-form-element'] {\n  .lc-form-control-wrapper:not(.select) {\n    margin-bottom: 2rem;\n  }\n}\n\n.form-radio-container {\n  margin-bottom: 2rem;\n  display: flex;\n  gap: 2rem;\n  .Radio__item {\n    .lc-form-control-wrapper {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leyend-field": `leyend-field`,
	"lc-form-control-wrapper": `lc-form-control-wrapper`,
	"select": `select`,
	"form-radio-container": `form-radio-container`,
	"Radio__item": `Radio__item`
};
export default ___CSS_LOADER_EXPORT___;
