import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { TenantConfig } from './types'
import { setConfig } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useTenantConfig = () => {
  const dispatch = useAppDispatch()
  const { tenantConfig } = useAppSelector((state) => state.tenantConfig)
  const { settings } = useAppSelector((state) => state.settings)
  const { get } = useFetch(settings?.tenantBff?.url)

  const endpoint = 'tenant-config'

  const fetchTenantConfig = async () => {
    const response: TenantConfig | undefined = await get({
      endpoint,
    })
    response && dispatch(setConfig(response))
  }

  const fetchPublicTenantConfig = async () => {
    const tenantId = window.location.pathname.split('/').at(2)
    if (tenantId) {
      const response: TenantConfig | undefined = await get({
        endpoint: `${endpoint}/public/${tenantId}`,
        isPublic: true,
      })
      response && dispatch(setConfig(response))
    }
  }

  return {
    tenantConfig,
    fetchTenantConfig,
    fetchPublicTenantConfig,
  }
}

export default useTenantConfig
