import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { MultimediaType } from '../../../../../core/shared/types'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup
    .object()
    .shape({
      nameMultimedia: yup
        .string()
        .label(t('directories.formElement.multimedia.form.label'))
        .required(({ label }) => t('validations.required', { label }))
        .max(40, ({ label }) =>
          t('validations.maxLength', { label, maxLength: 40 })
        ),
      imageUrl: yup.string().when('typeMultimedia', {
        is: MultimediaType.Image,
        then: yup
          .string()
          .required(({ label }) => t('validations.required', { label }))
          .label(t('directories.formElement.infoTab.elementImageUrl')),
      }),
      videoUrl: yup.string().when('typeMultimedia', {
        is: MultimediaType.Video,
        then: yup
          .string()
          .required(({ label }) => t('validations.required', { label }))
          .label(t('directories.formElement.infoTab.sectionVideoUrl')),
      }),
    })
    .required()
}
