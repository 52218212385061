// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container--linked-themes .TableComponent__body{grid-template-columns:repeat(2, minmax(200px, 1fr)) 140px 100px !important}.table-container--linked-themes .TableComponent__body__row .toggle-control__canvas{margin-right:0}.table-container--linked-themes .TableComponent__body__row .toggle-control__canvas:before,.table-container--linked-themes .TableComponent__body__row .toggle-control__canvas:after{top:0}`, "",{"version":3,"sources":["webpack://./src/app/pages/TopicFamilies/CreateChannels/LinkedThemes/styles.scss"],"names":[],"mappings":"AAWM,sDACE,0EAAA,CAGI,mFACE,cAAA,CACA,mLAEE,KAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.table-container {\n  &--linked-themes {\n    .TableComponent {\n      &__body {\n        grid-template-columns: repeat(2, minmax(200px, 1fr)) 140px 100px !important;\n        &__row {\n          .toggle-control {\n            &__canvas {\n              margin-right: 0;\n              &:before,\n              &:after {\n                top: 0;\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table-container--linked-themes": `table-container--linked-themes`,
	"TableComponent__body": `TableComponent__body`,
	"TableComponent__body__row": `TableComponent__body__row`,
	"toggle-control__canvas": `toggle-control__canvas`
};
export default ___CSS_LOADER_EXPORT___;
