import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormTextInput, Loader } from '@liveconnect/components'

import { useActivities } from '../../../../core'
import { ActivityCollaboratorsTypeForm } from '../../../../core/activities/types'
import { buildValidationSchema } from './validations'

import Heading from '../../../../components/Heading'
import CustomForm from '../../../../components/CustomForm'
import TranslationCard from '../../../../components/TranslationCard'
import useNotifications from '../../../../utils/notifications/useNotifications'
import { useCustomRouter } from '../../../../utils/extractParams'
import CollaboratorsList from './List'

import { DEFAULT_LANG } from '../../../../i18n/config'

const CollaboratorsActivity = () => {
  const { t } = useTranslation()
  const { activityId } = useParams()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const notify = useNotifications()
  const {
    isLoaded,
    fetchCollaborators,
    createTypeCollaborator,
    collaborators,
  } = useActivities()

  const isTypeSetted = useMemo(() => {
    if (!collaborators) return false
    return collaborators.id !== '00000000-0000-0000-0000-000000000000'
  }, [collaborators])

  const defaultValues = {
    language: DEFAULT_LANG,
    activityId,
  }

  const methods = useForm<ActivityCollaboratorsTypeForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues,
  })

  const { control, watch, reset } = methods

  const formValues = watch()

  const onSubmit = async (values: ActivityCollaboratorsTypeForm) => {
    const isTranslating = formValues.language !== DEFAULT_LANG
    let action = 'create'
    if (isTypeSetted) action = 'edit'
    if (isTranslating) action = 'translate'
    try {
      await createTypeCollaborator(values)
      notify.success(t(`activity.collaborators.feedback.${action}.ok`))
    } catch (error) {
      notify.error(t(`activity.collaborators.feedback.${action}.ko`))
    }
  }

  useEffect(() => {
    if (activityId) fetchCollaborators(activityId)
  }, [activityId])

  useEffect(() => {
    if (collaborators && isTypeSetted) {
      const translation = collaborators.contributorTypes.find(
        (ct) => ct.language === formValues.language
      )
      reset({
        ...formValues,
        contributorType: translation ? translation.contributorType : '',
      })
    } else {
      reset({ ...defaultValues })
    }
  }, [collaborators, formValues.language])

  if (!isLoaded) {
    return <Loader />
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <Heading as="h2">{t('activity.collaborators.title')}</Heading>
          <p>{t('activity.collaborators.subtitle')}</p>
        </div>
        <div className="col-auto">
          <p className="lc-main__required">{t('common.required')}</p>
        </div>
      </div>
      <CustomForm
        methods={methods}
        onSubmit={onSubmit}
        onCancel={() => navigate(`${basePath}/activities/`)}
        isSelectDisabled={!isTypeSetted}
      >
        {isTypeSetted && collaborators && (
          <TranslationCard
            translations={collaborators.translations.map(
              (t) =>
                ({
                  language: t,
                  name: t,
                } as any)
            )}
            lang={formValues.language}
            entity={t('activity.collaborators.type')}
          />
        )}
        <div className="row">
          <div className="col-9">
            <FormTextInput
              label={t('activity.collaborators.typeForm.name.label')}
              placeholder={t(
                'activity.collaborators.typeForm.name.placeholder'
              )}
              control={control}
              name="contributorType"
              required
              data-testid="activity-collaborators-typeName"
            />
          </div>
        </div>
        {isTypeSetted && <CollaboratorsList />}
      </CustomForm>
    </>
  )
}

export default CollaboratorsActivity
