import { FC, createContext, useContext } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Tag, ToggleControl, Tooltip } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { ContextMenu } from '@liveconnect/communities-ui'

import TranslationChips from '../TranslationsChips'

import {
  CardArrowButtonProps,
  CardContainerProps,
  CardContentBodyProps,
  CardContentBottomProps,
  CardContentHeaderProps,
  CardContentTopProps,
  CardContextType,
  CardImageProps,
  CardTagsProps,
  CardToggleProps,
} from './types'
import ArrowButton from '../Buttons/ArrowButton'

import './styles.scss'

const initialContext: CardContextType = {
  hasImage: true,
}

const CardContext = createContext<CardContextType>(initialContext)
const useCardContext = (): CardContextType => useContext(CardContext)

const CardContainer: FC<CardContainerProps> = ({
  id,
  children,
  className,
  isDisabled,
  hasImage,
}) => {
  return (
    <CardContext.Provider value={{ id, isDisabled, hasImage }}>
      <article
        className={classNames(
          'grid-card',
          {
            'grid-card--no-image': !hasImage,
          },
          className
        )}
      >
        {children}
      </article>
    </CardContext.Provider>
  )
}

const CardImage: FC<CardImageProps> = ({ src, alt, tag }) => (
  <div className="grid-card__image">
    {tag && <span className="grid-card__image-tag">{tag}</span>}
    <img src={src} alt={alt} />
  </div>
)

const CardContent: FC = ({ children }) => (
  <div className="grid-card__content">{children}</div>
)

const CardContentHeader: FC<CardContentHeaderProps> = ({
  title,
  actions,
  hideId = false,
}) => {
  const { t } = useTranslation()
  const { id } = useCardContext()

  return (
    <div className="grid-card__header">
      <div className="grid-card__title">
        <h3>{title}</h3>
        {!hideId && (
          <div className="grid-card__id">
            <span>ID</span>
            <Tooltip content={t('common.grid.card.tooltipId', { id })}>
              <Icon name="info_outline" tabIndex={0} />
            </Tooltip>
          </div>
        )}
      </div>
      {actions && actions.length > 0 && (
        <div className="grid-card__actions">
          <ContextMenu items={actions} />
        </div>
      )}
    </div>
  )
}

const CardContentTop: FC<CardContentTopProps> = ({
  children,
  preTitle,
  imageSrc,
  imageAlt,
}) => (
  <div className="grid-card__top">
    <div className="grid-card__top-left">
      {preTitle && <span className="grid-card__pre-title">{preTitle}</span>}
      {imageSrc && imageAlt && (
        <img className="grid-card__top-image" src={imageSrc} alt={imageAlt} />
      )}
    </div>
    <div className="grid-card__top-right">{children}</div>
  </div>
)

const CardToggle: FC<CardToggleProps> = ({
  label,
  onChange,
  value,
  isDisabled,
}) => {
  const { id } = useCardContext()

  return (
    <div className="grid-card__toggle">
      <ToggleControl
        label={label}
        id={`grid-card-toggle-${id}`}
        name={`grid-card-toggle-${id}`}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        className="mb-0"
      />
    </div>
  )
}

const CardArrowButton: FC<CardArrowButtonProps> = ({
  onClick,
  label,
  icon,
}) => {
  return <ArrowButton onClick={onClick} label={label} icon={icon} />
}

const CardContentBody: FC<CardContentBodyProps> = ({
  children,
  description,
}) => (
  <div className="grid-card__body">
    {description && <p className="grid-card__description">{description}</p>}
    {children}
  </div>
)

const CardContentBodyText: FC = ({ children }) => (
  <div className="grid-card__body-text">{children}</div>
)

const CardContentBodyBadges: FC<any> = ({ badges }) => (
  <div className="grid-card__body-text">{JSON.stringify(badges)}</div>
)

const CardContentBottom: FC<CardContentBottomProps> = ({
  children,
  translations,
}) => (
  <div className="grid-card__bottom">
    <div className="grid-card__bottom-left">{children}</div>
    <div className="grid-card__bottom-right">
      {translations && (
        <TranslationChips translations={translations} isArrayOfStrings />
      )}
    </div>
  </div>
)

const CardTags: FC<CardTagsProps> = ({ tags }) => {
  return (
    <div className="grid-card__tags">
      {tags.map((tag, index) => {
        return <Tag key={index.toString()}>{tag}</Tag>
      })}
    </div>
  )
}

export const Card = Object.assign(CardContainer, {
  Content: Object.assign(CardContent, {
    Top: Object.assign(CardContentTop, {
      Toggle: CardToggle,
      Arrow: CardArrowButton,
    }),
    Header: CardContentHeader,
    Body: Object.assign(CardContentBody, {
      Text: CardContentBodyText,
      Badges: CardContentBodyBadges,
    }),
    Bottom: Object.assign(CardContentBottom, {
      Tags: CardTags,
    }),
  }),
  Image: CardImage,
})
