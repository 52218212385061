import { ToggleControl, Tooltip } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'
import { DraggableProvided } from 'react-beautiful-dnd'
import TranslationChips from '../../../components/TranslationsChips'

import {
  ChannelTopicFamily,
  TopicFamilyListItem,
} from '../../../core/topicFamilies/types'

import useUi from '../../../core/ui/useUi'
import { DEFAULT_LANG } from '../../../i18n/config'
import useNotifications from '../../../utils/notifications/useNotifications'
import { useChannels, usePermissions, useTopicFamilies } from '../../../core'

import ContentTable, {
  OnDragEndProps,
} from '../../../components/Table/ContentTable'
import { sortElementsArray } from '../../../utils/shared'
import useCopy from '../../../utils/copy/useCopy'

import './styles.scss'
interface SectorRowProps {
  sector: TopicFamilyListItem
  provided: DraggableProvided
}

const TopicRow: FC<SectorRowProps> = ({ sector, provided }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showConfirmation } = useUi()
  const notify = useNotifications()
  const {
    updateIsPublished,
    fetchTopicFamilies,
    deleteTopicFamily,
    publishChannel,
    sortChannels,
  } = useTopicFamilies()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { verifyPermissionsMaster } = usePermissions()
  const { handleCopy } = useCopy()
  const { deleteChannel } = useChannels()
  const [isChannelsVisible, setIsChannelsVisible] = useState(false)

  const somePublished = useMemo(() => {
    return sector.channels.some((channel) => channel.isPublished)
  }, [sector])

  const handleUpdateIsPublished = async () => {
    setIsSubmitting(true)
    try {
      await updateIsPublished({
        id: sector.id,
        imageUrl: sector.imageUrl,
        isPublished: !sector.isPublished,
        language: DEFAULT_LANG,
        name: sector.name,
      })
      notify.success(
        t('sectors.actions.publish.feedback.ok', {
          sector: sector.name,
        })
      )
    } catch (error) {
      notify.error(
        t('sectors.actions.publish.feedback.ko', {
          sector: sector.name,
        })
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleChange = () => {
    if (sector.channels.length === 0 || !somePublished) {
      return showConfirmation({
        title: t('modal.cancel.generic.title'),
        subtitle: t('sector.modal.publish.warning'),
        confirmText: t('common.accept'),
      })
    }
    if (sector.isPublished && somePublished) {
      return showConfirmation({
        title: t('modal.cancel.generic.title'),
        subtitle: t('sector.modal.unpublish.warning'),
        confirmText: t('common.accept'),
      })
    }
    return showConfirmation({
      title: t('sector.actions.publish'),
      subtitle: t('sector.modal.publish.subtitle', {
        sector: sector.name,
      }),
      text: t('sector.modal.publish.text'),
      confirmText: t('common.publish'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: async () => {
        handleUpdateIsPublished()
      },
    })
  }

  const handleDelete = () => {
    showConfirmation({
      title: t('sector.delete'),
      subtitle: t('sector.delete.modal.subtitle', {
        name: sector.name,
      }),
      text: t('sector.delete.modal.text'),
      confirmText: t('common.remove'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      confirmActionOptions: {
        confirmText: t('sector.delete.modal.confirmText'),
        word: t('sector.delete.modal.word'),
      },
      onConfirm: async () => {
        try {
          await deleteTopicFamily(sector.id)
          fetchTopicFamilies()
          notify.success(t('sector.delete.toast.ok'))
        } catch (error: any) {
          if (error.body[0].property === '0.channelactivity') {
            warningChannelInUse()
          } else {
            notify.error(t('sector.delete.toast.ko'))
          }
        }
      },
    })
  }

  const warningChannelInUse = () => {
    setTimeout(() => {
      showConfirmation({
        title: t('common.warning'),
        subtitle: t('topic.delete.channelInUse.subtitle', {
          name: sector.name,
        }),
        text: t('topic.delete.channelInUse.text'),
        confirmText: t('common.accept'),
        iconName: 'report_problem',
      })
    }, 500)
  }

  const handlePublish = (value: boolean, channel: ChannelTopicFamily) => {
    const titleKey = value
      ? 'modal.topic.list.publish.title'
      : 'modal.topic.list.unpublish.title'
    const subtitleKey = value
      ? 'modal.topic.list.publish.subtitle'
      : 'modal.topic.list.unpublish.subtitle'

    const buttonKey = value ? 'common.publish' : 'common.unpublish'

    const notifySuccessKey = value
      ? 'modal.topic.list.publish.toast.ok'
      : 'modal.topic.list.unpublish.toast.ok'
    const notifyErroKey = value
      ? 'modal.topic.list.publish.toast.ko'
      : 'modal.topic.list.unpublish.toast.ko'

    showConfirmation({
      title: t(titleKey),
      subtitle: t(subtitleKey, {
        channel: channel.name,
      }),
      text: !value ? t('modal.topic.list.unpublish.channelWarning') : undefined,
      confirmText: t(buttonKey),
      cancelText: t('common.cancel'),
      onConfirm: async () => {
        try {
          await publishChannel(channel.id, value)
          notify.success(
            t(notifySuccessKey, {
              channel: channel.name,
            })
          )
        } catch (error) {
          notify.error(
            t(notifyErroKey, {
              channel: channel.name,
            })
          )
        }
      },
    })
  }

  const columns: ColumnDef<ChannelTopicFamily>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('topic.list.headers.name'),
        cell: ({ row }) => (
          <span className="topicList-channel-name">
            {row.original.name}
            <div className="contentTooltip ms-2">
              <span>ID</span>
              <Tooltip
                content={t('topic.channel.id', {
                  idChannel: row.original.id,
                })}
                className="ms-2"
              >
                <Icon name="info_outline" tabIndex={0} />
              </Tooltip>
            </div>
          </span>
        ),
      },
      {
        accessorKey: 'themes',
        header: t('topic.list.headers.themes'),
        cell: ({ row }) => (
          <>
            {row.original.themes > 0
              ? t('topic.list.themesNumber', {
                  number: row.original.themes,
                })
              : t('topic.list.themesNumber.empty')}
          </>
        ),
      },
      {
        accessorKey: 'brandSpaces',
        header: t('topic.list.headers.brands'),
        cell: ({ row }) => (
          <>
            {row.original.brandSpaces > 0
              ? t('topic.list.brandSpacesNumber', {
                  number: row.original.brandSpaces,
                })
              : t('topic.list.brandSpacesNumber.empty')}
          </>
        ),
      },
      {
        accessorKey: 'translations',
        header: t('translations.text'),
        cell: ({ row }) => (
          <TranslationChips
            translations={row.original.translations}
            isArrayOfStrings
          />
        ),
      },
      {
        accessorKey: 'isPublished',
        header: t('topic.list.headers.isPublished'),
        cell: ({ row }) => (
          <ToggleControl
            className="TopicRow__header__actions__publish"
            label=""
            hiddenLabel
            name="isPublished"
            value={row.original.isPublished}
            onChange={(value) => {
              handlePublish(value, row.original)
            }}
            disabled={
              row.original.themes === 0 || row.original.brandSpaces === 0
            }
          />
        ),
      },
    ],
    [sector.channels]
  )

  const handleDeleteChannel = (row: ChannelTopicFamily) => {
    showConfirmation({
      title: t('topic.channel.delete'),
      subtitle: t('topic.channel.delete.modal.subtitle', {
        name: row.name,
      }),
      text: t('topic.channel.delete.modal.text'),
      confirmText: t('common.remove'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      confirmActionOptions: {
        confirmText: t('topic.channel.delete.modal.confirmText'),
        word: t('topic.channel.delete.modal.word'),
      },
      onConfirm: async () => {
        try {
          await deleteChannel(row.id)
          fetchTopicFamilies()
          notify.success(t('topic.channel.delete.toast.ok'))
        } catch (error: any) {
          if (error.body[0].property === '0.channelactivity') {
            warningChannelInUse()
          } else {
            notify.error(t('topic.channel.delete.toast.ko'))
          }
        }
      },
    })
  }

  const actions = (row: ChannelTopicFamily) => {
    const actions = [
      {
        label: t('topic.channel.edit'),
        icon: 'edit',
        onClick: () => {
          navigate(`${sector.id}/channels/${row.id}`)
        },
      },
      {
        label: t('topic.channel.copy'),
        icon: 'content_copy',
        onClick: () => {
          handleCopy(row.id, t('topic.channel.entity'))
        },
      },
    ]

    if (verifyPermissionsMaster()) {
      actions.push({
        label: t('topic.channel.delete'),
        icon: 'delete_outline',
        onClick: () => handleDeleteChannel(row),
      })
    }

    return actions
  }

  const handleSortable = async (data: OnDragEndProps) => {
    const arraySorted = sortElementsArray(
      sector.channels,
      data.oldIndex,
      data.newIndex
    )
    await sortChannels(sector.id, arraySorted)
  }

  return (
    <>
      <div className="TopicRow">
        <div className="TopicRow__header">
          <div className="d-flex align-items-center">
            <button
              className="TopicRow__header__drag"
              {...provided.dragHandleProps}
            >
              <Icon name="drag_and_drop" />
            </button>
            <div className="TopicRow__header__image">
              <img
                src={sector.imageUrl}
                alt={sector.name}
                title={sector.name}
              />
            </div>
            <div className="TopicRow__header__title">
              <span className="TopicRow__header__title__name">
                {sector.name}
              </span>
              <div className="TopicRow__header__title__translations ms-4">
                <TranslationChips
                  translations={sector.translations}
                  showDefault
                  isArrayOfStrings
                />
              </div>
            </div>
          </div>
          <div className="TopicRow__header__actions">
            <ToggleControl
              id={`isPublished-${sector.id}`}
              className="TopicRow__header__actions__publish"
              label={t('sector.actions.publish')}
              name="isPublished"
              value={sector.isPublished}
              onChange={handleChange}
              disabled={
                isSubmitting ||
                (!sector.isPublished &&
                  (!sector.channels.length || !somePublished))
              }
            />
            <Icon
              name="edit"
              onClick={() => navigate(sector.id)}
              className="ms-5"
            />
            {verifyPermissionsMaster() && (
              <Icon
                name="delete_outline"
                onClick={handleDelete}
                className="ms-4"
              />
            )}
            <button
              className="TopicRow__header__actions__collapse"
              onClick={() => setIsChannelsVisible(!isChannelsVisible)}
            >
              <Icon
                name={
                  isChannelsVisible
                    ? 'keyboard_arrow_up'
                    : 'keyboard_arrow_down'
                }
              />
            </button>
          </div>
        </div>
        {isChannelsVisible && (
          <div className="TopicRow__channels">
            <ContentTable
              title={t('topic.channels')}
              columns={columns}
              headerChild={
                <button
                  className="btn btn-arrow"
                  onClick={() => {
                    navigate(`${sector.id}/channels/new`)
                  }}
                >
                  <span>{t('topic.channels.add')}</span>
                  <Icon className="me-0 ms-1" name="add" />
                </button>
              }
              data={sector.channels}
              emptyText={t('No hay temas añadidos al canal')}
              actions={actions}
              onDragEnd={handleSortable}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default TopicRow
