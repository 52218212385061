import { NavbarItem } from './types'

export const navbarItems: NavbarItem[] = [
  {
    key: 'wall',
    trnsKey: 'navMenu.wall',
    icon: 'wall',
    path: '/wall',
    exact: false,
    disabled: false,
  },
  {
    key: 'sectors',
    trnsKey: 'navMenu.topics',
    icon: 'group_work',
    path: '/sectors',
    exact: false,
    disabled: false,
  },
  {
    key: 'brands',
    trnsKey: 'navMenu.brand',
    icon: 'space_365',
    path: '/brands',
    exact: false,
    disabled: false,
  },
  {
    key: 'users',
    trnsKey: 'navMenu.users',
    icon: 'person',
    path: '/users',
    exact: false,
    disabled: false,
  },
  {
    key: 'activities',
    trnsKey: 'navMenu.activities',
    icon: 'event',
    path: '/activities',
    exact: false,
    disabled: false,
  },
  {
    key: 'directories',
    trnsKey: 'navMenu.directories',
    icon: 'apartment',
    path: '/directories',
    exact: false,
    disabled: false,
  },
]

export const configurationRoute: NavbarItem = {
  key: 'configuration',
  trnsKey: 'navMenu.configuration',
  icon: 'settings',
  exact: false,
  disabled: false,
  submenu: [
    {
      key: 'configurationCorporate',
      trnsKey: 'navMenu.configurationCorporate',
      path: '/configuration/corporate',
      exact: false,
      disabled: false,
    },
    {
      key: 'configurationFooter',
      trnsKey: 'navMenu.configurationFooter',
      path: '/configuration/footer',
      exact: false,
      disabled: false,
    },
    {
      key: 'configurationOnboarding',
      trnsKey: 'navMenu.configurationOnboarding',
      path: '/configuration/onboarding',
      exact: false,
      disabled: false,
    },
  ],
}
