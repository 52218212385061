import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { Loader } from '@liveconnect/components'
import { PublisherProfile, TypePublishEnum } from '@liveconnect/communities-ui'

import usePosts from '../../../core/posts/usePosts'

import BackButton from '../../../components/Buttons/BackButton'
import { Main } from '../../../components/Main'

import './styles.scss'
import { es } from 'date-fns/locale'

const MetricsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { postId } = useParams()

  const { metrics, fetchPostMetrics, isLoaded } = usePosts()

  useEffect(() => {
    postId && fetchPostMetrics(postId)
  }, [postId])

  return (
    <Main
      ariaLabelledby="metrics-page"
      className="metrics-page"
      title={t('metrics.title')}
      rightColumn={
        <BackButton
          label={t('metrics.backToWall')}
          onClick={() => navigate(-1)}
        />
      }
    >
      {!isLoaded ? (
        <Loader />
      ) : metrics ? (
        <>
          <PublisherProfile
            title={metrics.sponsorName}
            image={metrics.sponsorImage}
            type={TypePublishEnum.Brand}
            description={metrics.sponsorSlogan}
            publishedAt={metrics.publishedAt}
            redirect={true}
            onClick={() => {
              window.open(metrics.sponsorUrl)
            }}
          />

          <div className="row mt-5 mb-4">
            <div className="col-md-3">
              <p className="fw-bold mb-2">{t('metrics.timezone')}</p>
              <p>{metrics.timeZone}</p>
            </div>
            <div className="col-md-3">
              <p className="fw-bold mb-2">{t('metrics.publishedAt')}</p>
              <p>
                {format(new Date(metrics.publishedAt), 'dd MMMM yyyy HH:mm', {
                  locale: es,
                })}
              </p>
            </div>
            <div className="col-md-3">
              <p className="fw-bold mb-2">{t('metrics.unpinnedAt')}</p>
              <p>
                {format(new Date(metrics.unpinnedDate), 'dd MMMM yyyy HH:mm', {
                  locale: es,
                })}
              </p>
            </div>
            <div className="col-md-3">
              <p className="fw-bold mb-2">{t('metrics.hidingDate')}</p>
              <p>
                {format(new Date(metrics.hidingDate), 'dd MMMM yyyy HH:mm', {
                  locale: es,
                })}
              </p>
            </div>
          </div>

          <div className="metrics-panel">
            <div className="metrics-panel__title">
              <span>{t('metrics.metricsPanel')}</span>
            </div>

            <MetricsRow
              label={t('metrics.channelFollowers')}
              value={metrics.channelFollowers}
            />

            <MetricsRow
              label={t('metrics.totalViewers')}
              value={metrics.totalViewers}
            />

            <MetricsRow
              label={t('metrics.viewMoreCount')}
              value={metrics.viewMoreCount}
            />

            <MetricsRow
              label={t('metrics.sponsorLinkCount')}
              value={metrics.sponsorLinkCount}
            />

            <MetricsRow
              label={t('metrics.fingerUpReactionCount')}
              value={metrics.fingerUpReactionCount}
            />

            <MetricsRow
              label={t('metrics.likeReactionCount')}
              value={metrics.likeReactionCount}
            />

            <MetricsRow
              label={t('metrics.commentCount')}
              value={metrics.commentCount}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </Main>
  )
}

interface MetricsRowProps {
  label: string
  value: string | number
}

const MetricsRow: FC<MetricsRowProps> = ({ label, value }) => (
  <div className="metrics-panel__row">
    <div className="row">
      <div className="col-md-2">{value}</div>
      <div className="col-md-10">{label}</div>
    </div>
  </div>
)

export default MetricsPage
