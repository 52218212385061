import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    title: yup
      .string()
      .label(t('footerPage.form.title.label'))
      .required(({ label }) => t('validations.required', { label })),
    url: yup
      .string()
      .url(t('common.link.patternValidation'))
      .label(t('footerPage.form.url.label'))
      .required(({ label }) => t('validations.required', { label })),
  })
}
