import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  PermissionsCommunity,
  PermissionsDetailForm,
  PermissionsListPayload,
  PermissionsState,
  UserItem,
} from './types'

const initialState: PermissionsState = {
  list: [],
  isLoaded: false,
  userList: [],
  userDetail: null,
  isLoadedUsers: false,
  communityPermissions: null,
}

export const slice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    clearList: () => initialState,
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<PermissionsListPayload>) => {
      state.list = action.payload.list
      state.isLoaded = true
    },
    showLoaderUsers: (state) => {
      state.isLoadedUsers = false
    },
    hideLoaderUsers: (state) => {
      state.isLoadedUsers = true
    },
    setUserList: (state, action: PayloadAction<UserItem[]>) => {
      state.userList = action.payload
      state.isLoadedUsers = true
    },
    setUserDetail: (state, action: PayloadAction<PermissionsDetailForm>) => {
      state.userDetail = action.payload
    },
    setCommunityPermissions: (
      state,
      action: PayloadAction<PermissionsCommunity>
    ) => {
      state.communityPermissions = action.payload
      state.isLoadedUsers = true
    },
  },
})

export const {
  clearList,
  showLoader,
  hideLoader,
  setList,
  setUserList,
  setUserDetail,
  setCommunityPermissions,
  showLoaderUsers,
  hideLoaderUsers,
} = slice.actions

export default slice.reducer
