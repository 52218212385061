import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ColumnDef } from '@tanstack/react-table'

import { Icon } from '@liveconnect/icons'

import {
  ConfigurationFooterPage,
  UrlTypeFooterPage,
} from '../../../../../core/configuration/footer/types'
import useConfigurationFooter from '../../../../../core/configuration/footer/useFooter'
import { useUi } from '../../../../../core'
import { useCustomRouter } from '../../../../../utils/extractParams'
import useNotifications from '../../../../../utils/notifications/useNotifications'

import Heading from '../../../../../components/Heading'
import ContentTable from '../../../../../components/Table/ContentTable'
import TranslationChips from '../../../../../components/TranslationsChips'

interface ConfigurationFooterPagesProps {
  title: string
  data: ConfigurationFooterPage[]
  tabId: string
  type: UrlTypeFooterPage
}

export const ConfigurationFooterPages: FC<ConfigurationFooterPagesProps> = ({
  title,
  data,
  tabId,
  type,
}) => {
  const { t } = useTranslation()
  const { basePath } = useCustomRouter()
  const navigate = useNavigate()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { deletePageSection } = useConfigurationFooter()

  const handleDelete = async (row: ConfigurationFooterPage) => {
    showConfirmation({
      title: t('configurationFooterPages.modalDelete.title'),
      subtitle: t('configurationFooterPages.modalDelete.subtitle'),
      text: t('configurationFooterPages.modalDelete.text'),
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: async () => {
        try {
          await deletePageSection(row.id, type)
          notify.success(t('configurationFooterPages.modalDelete.feedback.ok'))
        } catch (error) {
          notify.error(t('configurationFooterPages.modalDelete.feedback.ko'))
        }
      },
    })
  }

  const actions = (row: ConfigurationFooterPage) => {
    const isFromMaster = row.id === '00000000-0000-0000-0000-000000000000'
    return [
      {
        onClick: () => {
          navigate(
            `${basePath}/configuration/footer/${type}/${tabId}/page/${row.id}`
          )
        },
        label: t('common.edit'),
        icon: 'edit',
        disabled: isFromMaster,
      },
      {
        onClick: () => handleDelete(row),
        label: t('common.delete'),
        icon: 'delete_outline',
        disabled: isFromMaster,
      },
    ]
  }

  const columns: ColumnDef<ConfigurationFooterPage>[] = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t('configurationFooterPages.table.title'),
      },
      {
        accessorKey: 'translations',
        header: t('configurationFooterPages.table.translations'),
        cell: ({ row }) => (
          <TranslationChips
            translations={row.original.translations}
            isArrayOfStrings
          />
        ),
      },
    ],
    []
  )

  return (
    <>
      <Heading as="h2">{t('configurationFooterPages.title')}</Heading>
      <ContentTable
        title={title}
        columns={columns}
        headerChild={
          <button
            className="btn btn-arrow"
            onClick={() => {
              navigate(
                `${basePath}/configuration/footer/${type}/${tabId}/page/create`
              )
            }}
          >
            <span>{t('common.add')}</span>
            <Icon className="me-0 ms-1" name="add" />
          </button>
        }
        data={data}
        emptyText={t('configurationFooterPages.table.empty')}
        actions={actions}
      />
    </>
  )
}
