import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

/* import { TabsComponent } from '@liveconnect/communities-ui' */

import ElementsList from './ElementsList'

import { Main } from '../../../components/Main'
import BackButton from '../../../components/Buttons/BackButton'
import useElements from '../../../core/elements/useElements'

const ManageDirectory = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { directoryId } = useParams()
  const { fetchDirectoryName, directoryName } = useElements()

  useEffect(() => {
    directoryId && fetchDirectoryName(directoryId)
  }, [directoryId])

  return (
    <Main
      title={t('directories.manage.title', {
        name: directoryName ? directoryName : '',
      })}
      subtitle={t('directories.manage.subtitle')}
      ariaLabelledby="directories-manage"
      rightColumn={
        <BackButton
          label={t('directories.form.back')}
          onClick={() => navigate('../')}
        />
      }
    >
      <ElementsList />
    </Main>
  )
}

export default ManageDirectory
