import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Loader } from '@liveconnect/components'

import useConfigurationFooter from '../../../../core/configuration/footer/useFooter'
import { ConfigurationFooterPages } from '../Shared/Pages'

import { UrlTypeFooterPage } from '../../../../core/configuration/footer/types'

const FooterLegalInfo = () => {
  const { t } = useTranslation()
  const {
    isLoaded,
    legalInfo: { list },
    fetchLegalInfoList,
  } = useConfigurationFooter()

  useEffect(() => {
    fetchLegalInfoList()
  }, [])

  if (!isLoaded) {
    return <Loader />
  }

  return (
    list && (
      <ConfigurationFooterPages
        title={t('footerInfo.legalInfo.table.title')}
        type={UrlTypeFooterPage.LegalInfo}
        tabId={list.id}
        data={list.pages}
      />
    )
  )
}

export default FooterLegalInfo
