import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Icon } from '@liveconnect/icons'
import classNames from 'classnames'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
  ContextMenu,
  PublisherProfile,
  TypePublishEnum,
} from '@liveconnect/communities-ui'

import useUi from '../../../core/ui/useUi'
import usePosts from '../../../core/posts/usePosts'
import useCulture from '../../../utils/culture'
import useNotifications from '../../../utils/notifications/useNotifications'
import { calculateShowMore, EllapsedPeriod, showRemainingTime } from './utils'
import {
  PostContainerProps,
  PostStateEnum,
  PublisherTypeEnum,
  Theme,
  TransLangs,
  TranslatePost,
} from '../../../core/posts/types'
import { ReactionComponent } from './Reaction'
import { CommentComponent } from './Comment'
import { MakeCommentComponent } from './MakeComment'
import VideoPlayer from '../../../components/VideoPlayer'
import { useCustomRouter } from '../../../utils/extractParams'
import ArrowButton from '../../../components/Buttons/ArrowButton'

import './index.scss'

export const PostContainer: FC<PostContainerProps> = ({
  publisher,
  title,
  message,
  image,
  video,
  publishedAt,
  id,
  selectedPublisher,
  publisherType,
  comments = [],
  totalComments,
  isEditable,
  isDeletable,
  isPublishable,
  isSponsored,
  channels,
  themes,
  reaction,
  isHighlighted,
  originalLanguage,
  state,
  publishDateTime,
  timezone,
  sponsor,
  fetchPosts,
  openEdit,
}) => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const {
    deletePost,
    deletePostAndDisablePublisher,
    disablePublisher,
    getTranslatePost,
    publishPost,
    fetchPostComments,
    viewMorePostSponsored,
    viewPostSponsor,
  } = usePosts()
  const { formatDate } = useCulture()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()

  const textContainerRef = useRef<HTMLDivElement>(null)
  const textParentRef = useRef<HTMLDivElement>(null)
  const [showExpand, setShowExpand] = useState<boolean>(false)
  const [showComplete, setShowComplete] = useState<boolean>(false)
  const [showTrans, setShowTrans] = useState<boolean>(false)
  const [textTrans, setTextTrans] = useState<string | undefined>(undefined)
  const [showMakeComment, setShowMakeComment] = useState<boolean>(false)
  const [showTwoComments, setShowTwoComments] = useState<boolean>(false)
  const [showAllComments, setShowAllComments] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [channelsIds, setChannelsIds] = useState<string[]>([])

  const handleShowComplete = async (show: boolean) => {
    setShowComplete(show)
    if (show) {
      try {
        await viewMorePostSponsored(id)
      } catch (error) {}
    }
  }

  const showInitialComments = async () => {
    if (totalComments > 0 && comments.length === 0) await fetchPostComments(id)
    setShowTwoComments(!showTwoComments)
    setShowMakeComment(!showMakeComment)
    setShowAllComments(false)
  }

  const handleDisableUserAndDeletePost = async () => {
    try {
      showConfirmation({
        title: t('user.modal.disableAndDelete'),
        subtitle: t('user.modal.disableAndDelete.question'),
        text: `${t('user.modal.disableAndDelete.text')} ${t(
          'user.modal.disableAndDelete.text2'
        )}`,
        confirmText: t('user.modal.disableAndDelete.button'),
        cancelText: t('common.cancel'),
        iconName: 'report_problem',
        onConfirm: async () => {
          await deletePostAndDisablePublisher({
            postId: id,
            memberId: publisher.externalId,
            publisherId: publisher.id,
          })
          await fetchPosts()
          notify.success(t('post.actions.disableAndDelete.feedback.ok'))
        },
      })
    } catch (e) {
      notify.error(t('post.actions.disableAndDelete.feedback.ko'))
    }
  }

  const handleDisableUser = async () => {
    if (!publisher.isEnabled) return
    try {
      showConfirmation({
        title: t('user.modal.disabled'),
        subtitle: t('user.modal.disabled.question'),
        text: `${t('user.modal.disabled.text')} ${t(
          'user.modal.disabled.text2'
        )}`,
        confirmText: t('user.modal.disabled.button'),
        cancelText: t('common.cancel'),
        iconName: 'report_problem',
        onConfirm: async () => {
          await disablePublisher(publisher.externalId, false)
          notify.success(t('post.actions.disable.feedback.ok'))
        },
      })
    } catch (e) {
      notify.error(t('post.actions.disable.feedback.ko'))
    }
  }

  const handleDeletePost = async () => {
    try {
      setIsSubmitting(true)
      await deletePost(id)
      await fetchPosts()
      notify.success(t('post.delete.succeessFeedback'))
    } catch (e) {
      notify.error(t('post.delete.unknownError'))
    } finally {
      setIsSubmitting(false)
    }
  }

  const handlePublishNow = async () => {
    try {
      setIsSubmitting(true)
      await publishPost(id, publisher.externalId, publisher.type)
      navigate('../')
      notify.success(t('post.actions.publishNow.feedback.ok'))
    } catch (e) {
      notify.error(t('post.actions.publishNow.feedback.ko'))
    } finally {
      setIsSubmitting(false)
    }
  }

  const formatRemainingTime = (utcDate: Date): string => {
    const remainingTime = showRemainingTime(utcDate)
    switch (remainingTime.periodType) {
      case EllapsedPeriod.NONE:
        return t('post.comment.ellapsed.now')
      case EllapsedPeriod.MINUTES:
        if (remainingTime.period === 1) return t('post.comment.ellapsed.minute')
        return t('post.comment.ellapsed.minutes', {
          minutes: remainingTime.period,
        })
      case EllapsedPeriod.HOURS:
        if (remainingTime.period === 1) return t('post.comment.ellapsed.hour')
        return t('post.comment.ellapsed.hours', { hours: remainingTime.period })
      case EllapsedPeriod.DAYS:
      default:
        return formatDate(remainingTime.period as Date, 'LONG_DATE')
    }
  }

  const navigateToProfile = async () => {
    if (publisher.isAnonymousMember) return
    if (isSponsored) {
      window.open(sponsor.url!, '_blank')
      try {
        await viewPostSponsor(id)
      } catch (error) {}
    } else {
      navigate(`${basePath}/users/${publisher.externalId}`)
    }
  }

  const handleEdit = () => {
    if (openEdit && !isSponsored) {
      openEdit({
        publisher,
        channels,
        title,
        message,
        themes,
        image,
        id,
        isHighlighted,
        video,
        publishDateTime,
        state,
        timezone,
      })
    } else if (isSponsored) {
      navigate(`${basePath}/wall/sponsored/${id}`)
    }
  }

  const handleViewMetrics = () => {
    navigate(`${basePath}/wall/metrics/${id}`)
  }

  const postActions = useMemo(() => {
    const actions = []
    if (isEditable) {
      actions.push({
        label: t('post.actions.edit'),
        onClick: handleEdit,
      })
      if (isSponsored && state === PostStateEnum.PUBLISHED) {
        actions.push({
          label: t('post.actions.viewMetrics'),
          onClick: handleViewMetrics,
        })
      }
    }

    if (isDeletable)
      actions.push({
        label: t('post.actions.delete'),
        onClick: () => {
          showConfirmation({
            title: t('post.actions.delete'),
            subtitle: t('post.confirmation.delete.subtitle'),
            text: t('post.confirmation.delete.text'),
            cancelText: t('common.cancel'),
            iconName: 'report_problem',
            onConfirm: handleDeletePost,
          })
        },
      })

    if (
      publisher.type === PublisherTypeEnum.USER &&
      isDeletable &&
      !publisher.isAnonymousMember
    ) {
      actions.push(
        {
          label: t(`post.actions.disable${publisher.isEnabled ? '' : 'd'}`),
          onClick: handleDisableUser,
          disabled: !publisher.isEnabled || isSubmitting,
        },
        {
          label: t('post.actions.disableAndDelete'),
          onClick: handleDisableUserAndDeletePost,
          disabled: !publisher.isEnabled || isSubmitting,
        }
      )
    }

    if (isPublishable && !isSponsored) {
      actions.push({
        label: t('post.actions.publishNow'),
        onClick: () => {
          showConfirmation({
            title: t('post.actions.publishNow'),
            subtitle: t('post.confirmation.publishNow.subtitle'),
            cancelText: t('common.cancel'),
            iconName: 'report_problem',
            onConfirm: handlePublishNow,
          })
        },
      })
    }
    return actions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEditable,
    isDeletable,
    title,
    message,
    image,
    themes,
    id,
    isHighlighted,
    video,
    publisher,
    channels,
    isSubmitting,
  ])

  const translateText = async () => {
    try {
      if (!showTrans && !textTrans) {
        const responseTrans: TranslatePost | undefined = await getTranslatePost(
          id,
          TransLangs.ES
        )
        setTextTrans(responseTrans?.translateContent)
      }
      setShowTrans(!showTrans)
    } catch (e) {
      notify.error(t('post.translate.error'))
    }
  }

  const publisherData: { title: string; image: string; description: string } =
    useMemo(() => {
      if (isSponsored) {
        return {
          title: sponsor.name!,
          image: sponsor.image!,
          description: sponsor.slogan!,
        }
      }
      return {
        title: publisher.title,
        image: publisher.image,
        description: publisher.description,
      }
    }, [isSponsored])

  useEffect(() => {
    const newArray: string[] = []
    channels.map((channel) => {
      newArray.push(channel.id)
    })
    setChannelsIds(newArray)
  }, [channels])

  useEffect(() => {
    if (textParentRef.current === null || textContainerRef.current === null)
      return
    let result = calculateShowMore(
      textParentRef.current,
      textContainerRef.current,
      message
    )

    if (showComplete) result = true
    setShowExpand(result)
  }, [textContainerRef.current, textParentRef.current, message])

  return (
    <div className="PostComponent">
      <PublisherProfile
        type={publisher.type as TypePublishEnum}
        redirect={
          (publisher.type === TypePublishEnum.User || isSponsored) &&
          !publisher.isAnonymousMember
        }
        publishedAt={formatRemainingTime(new Date(publishedAt))}
        {...publisherData}
        onClick={navigateToProfile}
        badges={publisher.badges}
        isSponsored={isSponsored}
        sponsoredText={t('post.sponsored')}
        texts={[
          t('badges.more', {
            number: publisher.badges.length - 1,
          }),
          t('badges.more.singular'),
        ]}
      />

      {postActions.length > 0 && (
        <div className="PostComponent__actions">
          <ContextMenu items={postActions} />
        </div>
      )}

      {channels && (
        <p className="PostComponent__channels">
          {channels.map((channel) => channel.name).join(', ')}
        </p>
      )}
      <h5 className="PostComponent__title">{title}</h5>
      {message && (
        <div
          className={classNames('PostComponent__text', { full: showComplete })}
          ref={textParentRef}
        >
          <div
            className="PostComponent__text__container"
            ref={textContainerRef}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
      )}
      {showExpand && (
        <div className="PostComponent__link">
          <ArrowButton
            onClick={() => handleShowComplete(!showComplete)}
            label={showComplete ? t('post.view_less') : t('post.view_more')}
            icon={showComplete ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          />
        </div>
      )}
      {showTrans && (
        <div
          className="PostComponent__transText"
          dangerouslySetInnerHTML={{ __html: textTrans ? textTrans : '' }}
        />
      )}
      {originalLanguage &&
        originalLanguage.toUpperCase() !== t('localizations.short.es-ES') && (
          <div className={showTrans ? 'upButtonContainer' : ''}>
            <ArrowButton
              onClick={translateText}
              label={
                showTrans ? t('post.close_translate') : t('post.open_tranlate')
              }
              icon={showTrans ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            />
          </div>
        )}
      {themes && (
        <div className="row PostComponent__themes">
          {themes.map((theme: Theme) => {
            return (
              <div className="contLabel Chip" key={theme.id}>
                {theme.name}
              </div>
            )
          })}
        </div>
      )}
      {image && (
        <img className="PostComponent__img" alt="example" src={image} />
      )}
      {video && <VideoPlayer url={video} className="PostComponent__video" />}

      <div className="PostComponent__reactions">
        <div className="reactions">
          <ReactionComponent
            publisherId={selectedPublisher.id}
            publisherType={publisherType}
            isPost={true}
            takenId={id}
            disabled={selectedPublisher.id === 'withoutPublish'}
            {...reaction}
          />
        </div>
        <div className="commentButton">
          <button
            onClick={showInitialComments}
            title={t('post.showComments')}
            disabled={selectedPublisher.id === 'withoutPublish'}
          >
            <Icon name="mode_comment" />
            {totalComments > 0 && (
              <span className="numberOfComments">{` ${totalComments}`}</span>
            )}
          </button>
        </div>
      </div>
      {showMakeComment && (
        <MakeCommentComponent
          takenId={id}
          publisherId={selectedPublisher.id}
          publisherType={publisherType}
          selectedPublisher={selectedPublisher}
        />
      )}
      {showTwoComments && comments?.length > 0 && (
        <div className="PostComponent__commentList">
          {comments.slice(0, 2).map((comment) => (
            <CommentComponent
              publisherId={selectedPublisher.id}
              publisherType={publisherType}
              key={comment.id}
              {...comment}
              takenId={id}
              originalLangComment={comment.originalLanguage}
              channelsIds={channelsIds}
            />
          ))}
          {comments.length > 2 && !showAllComments && (
            <button
              className="PostComponent__commentList__link"
              onClick={() => setShowAllComments(true)}
            >
              {t('posts.comment.viewMore')}
            </button>
          )}
        </div>
      )}
      {showAllComments &&
        comments
          .slice(2)
          .map((comment) => (
            <CommentComponent
              publisherId={selectedPublisher.id}
              publisherType={publisherType}
              key={comment.id}
              {...comment}
              takenId={id}
              originalLangComment={comment.originalLanguage}
              channelsIds={channelsIds}
            />
          ))}
      {comments.length > 2 && showAllComments && (
        <button
          className="PostComponent__commentList__link"
          onClick={() => setShowAllComments(false)}
        >
          {t('posts.comment.viewLess')}
        </button>
      )}
    </div>
  )
}
