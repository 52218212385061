import { FC, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Column, useTable } from 'react-table'

import { ToggleControl, Pagination, Avatar } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import EmptyState from '../../components/EmptyState'
import { Loader } from '../../components/Loader'
import { Main } from '../../components/Main'
import {
  Member,
  MemberBadge,
  MemberChannel,
  MemberRequestParams,
  RegisterType,
  UserType,
} from '../../core/members/types'
import useMembers from '../../core/members/useMembers'
import useUi from '../../core/ui/useUi'
import useNotifications from '../../utils/notifications/useNotifications'
import { useCustomRouter } from '../../utils/extractParams'
import useScroll from '../../utils/useScroll'
import HeadLineWithoutButton from '../../components/Headline/WithoutButton'
import useCulture from '../../utils/culture'
import UserListFilters from './Filters'
import { downloadCSV } from '../../utils/downloadCSV'
import usePermissions from '../../core/permissions/usePermissions'

import './styles.scss'

const UserList: FC = () => {
  const { t } = useTranslation()
  const {
    memberList,
    page,
    availablePages,
    availableItems,
    pageSize,
    isLoaded,
    fetchMembers,
    clearMemberList,
    toggleEnableMember,
    getCSV,
    deleteMember,
  } = useMembers()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { basePath } = useCustomRouter()
  const { scrollTop } = useScroll()
  const { formatDate } = useCulture()
  const { verifyPermissionsMaster } = usePermissions()

  const [listFilters, setListFilters] = useState<UserListFilters>({
    search: '',
    registerType: '',
    userType: '',
    sectorsIds: [],
    channelsIds: [],
    page: 1,
    pageSize: 20,
  })

  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const columns = useMemo<ReadonlyArray<Column<Member>>>(
    () => [
      {
        accessor: 'imageUrl',
      },
      {
        Header: t('user.list.name'),
        accessor: 'name',
        width: 200,
        minWidth: 200,
        Cell: ({ value, row }) => {
          return (
            <Link
              to={`${basePath}/users/${row.original.id}`}
              className="avatar-name"
            >
              <Avatar
                imageUrl={row.values.imageUrl || undefined}
                name={value}
                size={'sm'}
                surname={row.original.surname}
              />
              <span className="ms-2">
                {value} {row.original.surname}
              </span>
            </Link>
          )
        },
      },
      {
        Header: t('user.list.email'),
        accessor: 'email',
      },
      {
        Header: t('user.list.badges'),
        accessor: 'badges',
        Cell: ({ value }) => {
          if (value.length === 1) return <span>{value[0].title}</span>
          if (value.length >= 2)
            return (
              <button
                className="btn-link"
                onClick={() => {
                  handleShowBadges(value)
                }}
              >
                {t('user.list.badges.count', { count: value.length })}
              </button>
            )
          return null
        },
      },
      {
        Header: t('user.list.channels'),
        accessor: 'channels',
        Cell: ({ value }) => {
          if (value.length === 1) return <span>{value[0].name}</span>
          if (value.length >= 2)
            return (
              <button
                className="btn-link"
                onClick={() => {
                  handleShowChannels(value)
                }}
              >
                {t('user.list.channels.count', { count: value.length })}
              </button>
            )
          return null
        },
      },
      {
        Header: t('user.list.register'),
        accessor: 'registerDate',
        width: 240,
        minWidth: 240,
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex gap-3 justify-content-end">
              {row.original.registerType === RegisterType.Preregistered && (
                <div className="tag-static">{t('user.list.preregister')}</div>
              )}
              <span>{formatDate(new Date(value), 'DATE')}</span>
            </div>
          )
        },
      },
      {
        Header: t('user.list.enable'),
        accessor: 'isEnabled',
        width: 100,
        minWidth: 100,
        Cell: ({ value, row }) => (
          <ToggleControl
            name="isEnabled"
            value={value}
            onChange={() => {
              handleChange(row.original)
            }}
            disabled={row.original.registerType === RegisterType.Preregistered}
            label=""
            hiddenLabel
            className="mb-0 justify-content-center"
          />
        ),
      },
      {
        Header: '',
        accessor: 'id',
        width: 100,
        minWidth: 100,
        Cell: ({ row }) =>
          verifyPermissionsMaster() ? (
            <button
              className="btn-icon"
              type="button"
              onClick={() => handleDelete(row.original)}
            >
              <Icon name="delete_outline" />
            </button>
          ) : null,
      },
    ],
    []
  )
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: memberList,
      initialState: { hiddenColumns: ['imageUrl'] },
    })

  const memberChange = async (member: Member) => {
    try {
      await toggleEnableMember(member.id, !member.isEnabled)
      {
        member.isEnabled
          ? notify.success(t('user.modal.disabled.notif.success'))
          : notify.success(t('user.modal.enabled.notif.success'))
      }
    } catch (e) {
      {
        member.isEnabled
          ? notify.error(t('user.modal.disabled.notif.error'))
          : notify.error(t('user.modal.enabled.notif.error'))
      }
    }
  }

  const handleShowChannels = (channels: MemberChannel[]) => {
    showConfirmation({
      title: t('user.list.channels'),
      className: 'ConfirmationModal--overflow',
      iconName: false,
      text: (
        <ul className="d-flex direction-column flex-column gap-4 list-unstyled mb-0 text-start">
          {channels.map((channel) => (
            <li key={channel.id}>{channel.name}</li>
          ))}
        </ul>
      ),
    })
  }

  const handleShowBadges = (badges: MemberBadge[]) => {
    showConfirmation({
      title: t('user.list.badges'),
      className: 'ConfirmationModal--overflow',
      iconName: false,
      text: (
        <ul className="d-flex direction-column flex-column gap-4 list-unstyled mb-0 text-start">
          {badges.map((badge) => (
            <li key={badge.id} className="d-flex align-items-center gap-2">
              <Avatar
                size="sm"
                imageUrl={badge.imageUrl}
                name={badge.title}
                surname={''}
              />
              <span>{badge.title}</span>
            </li>
          ))}
        </ul>
      ),
    })
  }

  const handleDelete = (member: Member) => {
    showConfirmation({
      title: t('modalDeleteUser.title'),
      subtitle: t('modalDeleteUser.subtitle', {
        name: `${member.name} ${member.surname}`,
      }),
      text: t('modalDeleteUser.text'),
      confirmText: t('common.remove'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      confirmActionOptions: {
        confirmText: t('modalDeleteUser.confirmText'),
        word: t('modalDeleteUser.word'),
        checkboxes: [
          {
            text: t('modalDeteteUser.checkbox'),
            required: false,
          },
        ],
      },
      onConfirm: async (checkboxes) => {
        const [deleteContent] = checkboxes
        try {
          await deleteMember(member.id, deleteContent)
          handleChangeFilters(listFilters)
          notify.success(t('modalDeteteUser.feedback.success'))
        } catch (error) {
          notify.error(t('modalDeteteUser.feedback.error'))
        }
      },
    })
  }

  const handleChange = (member: Member) => {
    showConfirmation({
      title: member.isEnabled
        ? t('user.modal.disabled')
        : t('user.modal.enabled'),
      subtitle: member.isEnabled
        ? t('user.modal.disabled.question')
        : t('user.modal.enabled.question'),
      text: member.isEnabled
        ? `${t('user.modal.disabled.text')} ${t('user.modal.disabled.text2')}`
        : t('user.modal.enabled.text'),
      confirmText: member.isEnabled
        ? t('user.modal.disabled.button')
        : t('user.modal.enabled.button'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: async () => {
        memberChange(member)
      },
    })
  }

  const handleChangeFilters = (filters: UserListFilters) => {
    setListFilters(filters)
    const { search, userType, registerType, channelsIds } = filters

    const params: MemberRequestParams = {
      page: 1,
      page_size: 20,
      registerType: registerType as RegisterType,
      userType: userType as UserType,
      search,
      channels: channelsIds,
    }

    fetchMembers(params)
  }

  const handleExport = async () => {
    try {
      setIsDownloading(true)

      const response = await getCSV({
        registerType: listFilters.registerType as RegisterType,
        userType: listFilters.userType as UserType,
        search: listFilters.search,
        channels: listFilters.channelsIds,
      })
      if (response) {
        downloadCSV(
          response,
          `users_${formatDate(new Date(), 'DATE_TIME_EXPORT')}`
        )
      } else {
        notify.error(t('user.list.export.error'))
      }
    } catch (e) {
      notify.error(t('user.list.export.error'))
    } finally {
      setIsDownloading(false)
    }
  }

  useEffect(() => {
    scrollTop()
  }, [page])

  useEffect(() => {
    fetchMembers({
      page: listFilters.page,
      page_size: listFilters.pageSize,
    })
    return () => {
      clearMemberList()
    }
  }, [])

  return (
    <Main ariaLabelledby="user-list-title" className="UserList">
      <div className="d-flex justify-content-between">
        <div>
          <HeadLineWithoutButton
            title={t('users.list.title')}
            text={t('users.list.hint')}
          />
        </div>
      </div>

      <section className="mt-3">
        <>
          <div className="custom-table custom-table--sm">
            <div className="custom-table-header">
              <span className="custom-table-header__title">
                {t('user.list.totalUsers')}
              </span>
              <div className="d-flex align-items-center gap-4">
                <UserListFilters onChange={handleChangeFilters} />
                <button
                  type="button"
                  className="btn btn-primary btn-export"
                  onClick={handleExport}
                >
                  {isDownloading ? <Loader /> : t('common.export')}
                </button>
              </div>
            </div>
            {!isLoaded ? (
              <Loader />
            ) : !memberList.length ? (
              <EmptyState text={t('user.list.empty.title')} />
            ) : (
              <div>
                <table {...getTableProps()} className="table table-bordered">
                  <thead>
                    {headerGroups.map((headerGroup, headerIndex) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        key={headerIndex}
                      >
                        {headerGroup.headers.map((column, columnIndex) => (
                          <th
                            {...column.getHeaderProps({
                              style: {
                                minWidth: column.minWidth,
                                width: column.width,
                              },
                            })}
                            key={columnIndex}
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="custom-table-body" {...getTableBodyProps()}>
                    {rows.map((row, rowIndex) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()} key={rowIndex}>
                          {row.cells.map((cell, cellIndex) => (
                            <td {...cell.getCellProps()} key={cellIndex}>
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          {isLoaded && memberList.length > 0 && (
            <Pagination
              currentPage={page - 1}
              totalPages={availablePages}
              totalItems={availableItems}
              pageSize={pageSize}
              singlePageText={(count) =>
                t('common.pager.singlePage', { count })
              }
              multiplePageText={(start, end, total) =>
                t('common.pager.multiplePage', { start, end, total })
              }
              onChange={(pageIndex) =>
                fetchMembers({
                  page: pageIndex + 1,
                  registerType: listFilters.registerType as RegisterType,
                  userType: listFilters.userType as UserType,
                  page_size: 20,
                  search: listFilters.search,
                  channels: listFilters.channelsIds,
                })
              }
            />
          )}
        </>
      </section>
    </Main>
  )
}

export default UserList
