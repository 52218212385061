import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    sectionTitle: yup
      .string()
      .label(t('footerInfo.contactInfo.form.sectionTitle.label'))
      .required(({ label }) => t('validations.required', { label }))
      .max(50, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 50 })
      ),
  })
}
