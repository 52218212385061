import { BrandBasicInfo } from '../brandSpace/types'
import { BadgePublisher, PublishersSponsoredResponse } from '../posts/types'
import { Translation } from '../shared/types'

export interface Location {
  fullAddress: string
  aditionalInformation: string
  latitude: number | undefined
  longitude: number | undefined
}

export interface ActivityTranslations extends Translation {
  title: string
  description: string
  imageURL: string
  resultTitle: string
  resultDescription: string
}

export enum ActivityType {
  Video = 'VideoConference',
  FaceToFace = 'FaceToFace',
  RoundTable = 'RoundTable',
  AskAuthor = 'AskAuthor',
  Challenge = 'Challenge',
}

export interface Activity {
  language: string
  id: string
  brandSpaceId: string
  brandSpace: BrandBasicInfo
  type: ActivityType
  maxActivityAttendees: number
  totalActivityAttendees: number
  startDateParticipation: Date
  endDateParticipation: Date
  expirationDate: Date
  isPublished: boolean
  startDate: Date
  translations: ActivityTranslations[]
}

export interface ActivityDetail {
  id: string
  hasMaxAttendees: boolean
  brandSpaceId: string
  brandSpace: BrandBasicInfo
  maxActivityAttendees: number
  startDateParticipation: Date
  endDateParticipation: Date
  expirationDate: Date
  language: string
  type: ActivityType
  isPublished: boolean
  videoConferenceType?: VideoConferenceType
  askAuthorType?: VideoConferenceType
  roundTableType?: VideoConferenceType
  faceToFaceType?: FaceToFaceType
  challengeType?: ChallengeType
  channels: string[]
  translations: ActivityTranslations[]
}

export interface ActivityConfigForm {
  maxActivityAttendees?: number
  hasMaxAttendees: boolean
  startDateParticipation: Date
  endDateParticipation: Date
  expirationDate: Date
  language: string
  type: ActivityType
  isPublished: boolean
  brandSpaceId?: string
  videoConferenceType?: VideoConferenceType
  askAuthorType?: VideoConferenceType
  roundTableType?: VideoConferenceType
  faceToFaceType?: FaceToFaceType
  challengeType?: ChallengeType
  translations: ActivityTranslations[]
  channels: string[]
}

export interface VideoConferenceType {
  startDate: Date
  endDate: Date
  broadcastURL: string
  postBroadcastURL: string
}

export interface FaceToFaceType {
  startDate: Date
  endDate: Date
  location: Location
}

export interface ChallengeType {
  startDate: Date
  endDate: Date
  challengeUrl: string
  documentFile: string
  documentFileName: string
  resultImage: string
}

export interface ActivityListParams {
  channels_ids?: string[]
  activityTypes?: ActivityType[]
  page: number
  page_size: number
  brandspace_id?: string | null
  is_historical?: boolean
  search?: string
  startDate?: string | null
  endDate?: string | null
}

export interface ActivityListResponse {
  items: Array<Activity>
  page: number
  pageSize: number
  availableItems: number
  availablePages: number
}

export interface ActivitiesState {
  isLoaded: boolean
  list: ActivityListResponse | null
  detail: ActivityDetail | null
  attendees: AttendeesListResponse | null
  collaborators: ActivityCollaboratorsResponse | null
  collaborator: ActivityCollaboratorDetailResponse | null
  comments: CommentActivity[] | null
}

export interface JitsiResponse {
  userUrl: string
  moderatorUrl: string
}

export interface ReactionsActivity {
  fingerUpCount: number
  likeCount: number
  myReaction: ReactionTypeEnum
}

export interface Attendee {
  id: string
  name: string
  surname: string
  email: string
}

export interface AttendeesListParams {
  page: number
  page_size: number
}

export interface AttendeesListResponse {
  items: Array<Attendee>
  page: number
  pageSize: number
  availableItems: number
  availablePages: number
}

export interface DeleteAttendeePayload {
  attendeeId: string
}

export interface CommentActivity {
  id: string
  creationDate: string
  message: string
  originalLanguage: string
  isDeletable: boolean
  isEditable: boolean
  isModerate: boolean
  publisher: PublisherActivity
  reaction: ReactionsComment
  sponsor: PublishersSponsoredResponse | null
}
export interface CommentPayloadActivity {
  activityId: string
  comment: CommentActivity | undefined
}

export interface PublisherActivity {
  id: string
  externalId: string
  title: string
  description: string
  image: string
  type: string
  isEnabled: boolean
  badges: BadgePublisher[]
}
export interface CommentActivityInterface {
  message: string
}
export interface ReactionsComment {
  fingerUpCount: number
  likeCount: number
  myReaction: ReactionTypeEnum
}

export interface DeleteCommentActivityPayload {
  activityId: string
  commentId: string
}
export interface CommentActivityPutInterface {
  id: string
  message: string
}

export interface TranslateCommentActivity {
  translateContent: string
}

export enum ReactionTypeEnum {
  NONE = 'None',
  FINGERUP = 'FingerUp',
  LIKE = 'Like',
}

export interface DeleteCommentActivityAndDisablePublisherParams {
  activityId: string
  commentId: string
  memberId: string
  publisherId: string
}

export interface UseActivitiesResult {
  activity: ActivityDetail | null
  activities: ActivityListResponse | null
  isLoaded: boolean
  attendees: AttendeesListResponse | null
  comments: CommentActivity[] | null
  collaborators: ActivityCollaboratorsResponse | null
  collaborator: ActivityCollaboratorDetailResponse | null
  fetchActivities: (params: ActivityListParams) => Promise<void>
  fetchActivity: (activityId: string) => Promise<ActivityDetail | undefined>
  clearCurrentActivity: () => void
  clearCurrentActivities: () => void
  publishActivity: (activityId: string) => Promise<Activity | undefined>
  deleteActivity: (activityId: string) => Promise<void>
  createActivity: (
    activity: ActivityConfigForm
  ) => Promise<ActivityDetail | undefined>
  editActivity: (params: {
    id: string
    activity: ActivityConfigForm
    edit: 'all' | 'translation' | 'form'
  }) => Promise<ActivityDetail | undefined>
  editActivityTranslation: (params: {
    id: string
    translation: ActivityTranslations
  }) => Promise<ActivityTranslations | undefined>
  uploadImageActivity: (image: string) => Promise<string | undefined>
  uploadFileActivity: (file: string) => Promise<string | undefined>
  getBrandSpaces: () => Promise<ActivityBrandSpace[]>
  getChannelsByBrandSpaces: (
    brandSpaceIds?: string[]
  ) => Promise<ActivityChannel[]>
  getUrlJitsi: () => Promise<JitsiResponse | undefined>
  getAttendees: (
    activityId: string,
    params: AttendeesListParams
  ) => Promise<AttendeesListResponse | null>
  deleteAttendee: (activityId: string, attendeeId: string) => Promise<void>
  getCommentsActivity: (
    activityId: string,
    publisherId: string
  ) => Promise<CommentActivity[] | null>
  createCommentActivity: (
    activityId: string,
    commentToSave: CommentActivityInterface,
    publisherId: string
  ) => Promise<void>
  updateCommentActivity: (
    activityId: string,
    comment: CommentActivityPutInterface,
    publisherId: string
  ) => Promise<void>
  deleteCommentActivity: (activityId: string, id: string) => Promise<void>
  translateCommentActivity: (
    commentId: string,
    activityId: string,
    lang: string
  ) => Promise<TranslateCommentActivity | undefined>
  updateCommentActivityReaction: (
    postId: string,
    commentId: string,
    reaction: ReactionTypeEnum,
    publisherId: string
  ) => Promise<ReactionsActivity | undefined>
  deleteCommentActivivityAndDisablePublisher: (
    params: DeleteCommentActivityAndDisablePublisherParams
  ) => Promise<void>
  getLocations: (location: string) => Promise<Location[]>
  fetchCollaborators: (activityId: string) => Promise<void>
  createTypeCollaborator: (data: ActivityCollaboratorsTypeForm) => Promise<void>
  fetchCollaborator: (id: string, activityId: string) => Promise<void>
  createCollaborator: (
    data: ActivityCollaboratorsForm
  ) => Promise<string | undefined>
  editCollaborator: (
    id: string,
    data: ActivityCollaboratorsForm
  ) => Promise<void>
  translateCollaborator: (
    id: string,
    data: Partial<ActivityCollaboratorsForm>
  ) => Promise<void>
  deleteCollaborator: (id: string, activityId: string) => Promise<void>
}

export interface ActivityBrandSpace {
  id: string
  code: string
  name: string
}

export interface ActivityChannel {
  id: string
  name: string
  language: string
}

// Collaborators

// Form - Create collaborator type
export interface ActivityCollaboratorsTypeForm {
  activityId: string
  contributorType: string
  language: string
}

// Detail - Collaborators
export interface ActivityCollaboratorsResponse {
  id: string
  contributorTypes: {
    language: string
    contributorType: string
  }[]
  translations: Translation[]
  contributors: ActivityCollaboratorsResponseListItem[]
}

export interface ActivityCollaboratorsResponseListItem {
  id: string
  name: string
  image: string
  description: string
  translations: string[]
}

// Form - Create / Edit collaborator
export interface ActivityCollaboratorsForm {
  contributorInfoId: string
  activityId: string
  name: string
  company: string
  description: string
  image: string
  language: string
}

// Detail collaborator
export interface ActivityCollaboratorDetailResponse {
  id: string
  name: string
  company: string
  image: string
  translations: {
    name: string
    language: string
    description: string
  }[]
}

// Form - Translate Collaborator
export interface ActivityCollaboratorsTransForm {
  activityId: string
  language: string
  description: string
}
