import { useEffect, useMemo, useState } from 'react'
import {
  FormCheckbox,
  FormProvider,
  FormSelect,
  Loader,
} from '@liveconnect/components'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FormActionsLang } from '../../../components/forms/FormActionsLang'

import {
  BrandSpacePermission,
  PermissionsChannel,
  PermissionsDetailForm,
  PermissionsOptionBrandSpaces,
} from '../../../core/permissions/types'
import useUi from '../../../core/ui/useUi'
import usePermissions from '../../../core/permissions/usePermissions'
import useTenant from '../../../core/tenant/useTenant'
import useNotifications from '../../../utils/notifications/useNotifications'
import { useBlockRouteChangeWithDialog } from '../../../utils/routing/useBlockRouteChange'

import BackButton from '../../../components/Buttons/BackButton'
import { Main } from '../../../components/Main'
import AccordionItem from './AccordionItem'

import './styles.scss'

const PermissionsDetail = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [brandSpaces, setBrandSpaces] = useState<
    PermissionsOptionBrandSpaces[]
  >([])

  const {
    userDetail,
    isLoadedUsers,
    getBrandSpaces,
    fetchUserDetail,
    updatePermissions,
  } = usePermissions()

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { userId } = useParams()
  const { showConfirmation } = useUi()
  const { tenant } = useTenant()
  const notify = useNotifications()

  const methods = useForm<PermissionsDetailForm>({
    defaultValues: {
      moderatorPosts: false,
      editorSectorsChannelsThemes: false,
      editorSpaces: false,
      moderatorDirectories: false,
      hasPermissions: false,
    },
  })

  const {
    control,
    watch,
    setValue,
    formState: { isDirty },
  } = methods

  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const watchSpace365 = watch('space365')
  const watchBrandSpacePermissions = watch('brandSpacePermission')

  const { fields, prepend, remove,  } = useFieldArray<PermissionsDetailForm>({
    control,
    name: 'brandSpacePermission',
  })

  const brandSpacesWithoutSelected = useMemo(() => {
    const selectedSpaces = brandSpaces.map((item) => {
      const isSelected = watchBrandSpacePermissions?.some(
        (space) => space.id === item.id
      )
      return {
        value: item.id,
        label: item.name,
        isDisabled: isSelected,
      }
    })
    return selectedSpaces
  }, [brandSpaces, watchBrandSpacePermissions])

  const onSubmit = async (values: PermissionsDetailForm) => {
    if (userId) {
      try {
        setIsSubmitting(true)
        updatePermissions(userId, values)
        setIsSubmitting(false)
        notify.success(t('permissions.detail.success'))
      } catch (error) {
        notify.success(t('permissions.detail.error'))
      }
    }
  }

  const handleCancel = () => {
    navigate(-1)
  }

  const handleDeleteBrandSpace = (index: number) => {
    showConfirmation({
      title: t('common.warning'),
      subtitle: t('permissions.detail.modal.delete.subtitle'),
      confirmText: t('common.remove'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: () => {
        remove(index)
      },
    })
  }

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirty) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          iconName: 'report_problem',
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const getBrandSpacesOptions = async () => {
    const response = await getBrandSpaces()
    if (response) {
      setBrandSpaces(response)
    }
  }

  const appendBrandSpace = async (id: string) => {
    const response = brandSpaces.find((item) => item.id === id)
    if (response) {
      const brandSpacePermissions: BrandSpacePermission = {
        id: response.id,
        name: response.name,
        logoImageUrl: response.logoImageUrl,
        permissions: {
          directoriesEditor: false,
        },
        channels: response.channels.map(
          (channel) =>
            ({
              id: channel.id,
              name: channel.name,
              permissions: {
                wallEditor: false,
                usersModerator: false,
                activitiesEditor: false,
                directoriesEditorChannel: false,
              },
            } as PermissionsChannel)
        ),
      }

      prepend(brandSpacePermissions)
    }
  }

  useEffect(() => {
    if (userDetail) {
      methods.reset(userDetail)
    }
  }, [userDetail])

  useEffect(() => {
    if (userId) fetchUserDetail(userId)
  }, [userId])

  useEffect(() => {
    if (watchSpace365) {
      appendBrandSpace(watchSpace365)
      setValue('space365', '')
    }
  }, [watchSpace365])

  useEffect(() => {
    getBrandSpacesOptions()
  }, [])

  return (
    <Main
      ariaLabelledby="permissions-detail"
      className="PermissionsDetail"
      title={t('permissions.detail.title')}
      subtitle={t('permissions.detail.subtitle')}
      rightColumn={
        <BackButton
          label={t('permissions.detail.back')}
          onClick={() => navigate('../')}
        />
      }
    >
      {!isLoadedUsers ? (
        <Loader />
      ) : (
        <FormProvider methods={methods}>
          {userDetail && (
            <div className="permission-detail-section">
              <p className="h4 mb-2">
                {userDetail.name} {userDetail.surname}
              </p>
              <p>{userDetail.email}</p>
            </div>
          )}

          <div className="permission-detail-section">
            <p className="h4">{t('permissions.detail.general.title')}</p>

            <div className="permission-detail-checkbox">
              <FormCheckbox
                control={control}
                name="moderatorPosts"
                label={t('permissions.detail.fields.moderatorPosts.label', {
                  tenantName: tenant.name,
                })}
              />
              <p>
                {t('permissions.detail.fields.moderatorPosts.description', {
                  tenantName: tenant.name,
                })}
              </p>
            </div>

            <div className="permission-detail-checkbox">
              <FormCheckbox
                control={control}
                name="editorSectorsChannelsThemes"
                label={t(
                  'permissions.detail.fields.editorSectorsChannelsThemes.label'
                )}
              />
              <p>
                {t(
                  'permissions.detail.fields.editorSectorsChannelsThemes.description'
                )}
              </p>
            </div>

            <div className="permission-detail-checkbox">
              <FormCheckbox
                control={control}
                name="editorSpaces"
                label={t('permissions.detail.fields.editorSpaces.label')}
              />
              <p>{t('permissions.detail.fields.editorSpaces.description')}</p>
            </div>

            <div className="permission-detail-checkbox">
              <FormCheckbox
                control={control}
                name="moderatorDirectories"
                label={t(
                  'permissions.detail.fields.moderatorDirectories.label'
                )}
              />
              <p>
                {t(
                  'permissions.detail.fields.moderatorDirectories.description'
                )}
              </p>
            </div>
          </div>

          <div className="permission-detail-section">
            <p className="h4 mb-3">{t('permissions.detail.spaces365.title')}</p>
            <p className="mb-4">
              {t('permissions.detail.spaces365.description')}
            </p>

            <div className="row">
              <div className="col-md-6">
                <FormSelect
                  control={control}
                  name="space365"
                  options={brandSpacesWithoutSelected}
                  label={t('permissions.detail.fields.spaces365.label')}
                  placeholder={t(
                    'permissions.detail.fields.spaces365.placeholder'
                  )}
                  noOptionsMessage={t('select.empty')}
                />
              </div>
            </div>
          </div>

          {(fields as BrandSpacePermission[]).map((item, index) => (
            <AccordionItem
              control={control}
              key={item.id}
              title={item.name}
              image={item.logoImageUrl}
              id={item.id}
              index={index}
              channelsPermissions={item.channels}
              handleDelete={() => handleDeleteBrandSpace(index)}
            />
          ))}

          <FormActionsLang
            selectDisabled={true}
            isLoaded={true}
            methods={methods}
            hideSelector
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            onCancel={handleCancel}
            onBeforeChange={handleBeforeChangeLang}
          />
        </FormProvider>
      )}
    </Main>
  )
}

export default PermissionsDetail
