import { useTranslation } from 'react-i18next'
import { ActivityType } from '../../core/activities/types'

const useTypes = () => {
  const { t } = useTranslation()
  const transformType = (type: ActivityType) => {
    if (type === ActivityType.Video) {
      return t('activities.card.type.video')
    }
    if (type === ActivityType.FaceToFace) {
      return t('activities.filter.type.faceToFace')
    }
    if (type === ActivityType.AskAuthor) {
      return t('activities.filter.type.askAuthor')
    }
    if (type === ActivityType.RoundTable) {
      return t('activities.filter.type.roundTable')
    }
    return type
  }

  return {
    transformType,
  }
}

export default useTypes
