import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UsePostsResult,
  PostImage,
  PostListResponse,
  PostListPayload,
  PostParams,
  CreatePostType,
  PublishersResponse,
  Theme,
  CommentPostInterface,
  CommentPutInterface,
  EditPostType,
  Post,
  Comment,
  ReactionTypeEnum,
  Reactions,
  UpdateReactionBody,
  DeletePostAndDisablePublisherParams,
  DeleteCommentAndDisablePublisherParams,
  TranslatePost,
  PostStateEnum,
  PostDetail,
  SponsoredPostUpdateParams,
  PostMetrics,
} from './types'
import {
  setList,
  setDetail,
  showLoader,
  hideLoader,
  setPostComments,
  addPostComment,
  deletePostComment,
  updatePostComment,
  disablePublisher as storeDisablePublisher,
  setMetrics,
} from './store'
import useFetch from '../../utils/fetch/useFetch'

const usePosts = (): UsePostsResult => {
  const dispatch = useAppDispatch()
  const { detail, metrics, list, page, pageSize, hasNextPage, isLoaded } =
    useAppSelector((state) => state.posts)
  const { get, post, del, put, patch } = useFetch()

  const endpoint = 'posts'
  const endpointMetrics = 'sponsor-post-metrics'
  const endpointPublish = 'publishers'
  const endpointThemes = 'themes'
  const commentsEndpoint = 'comments'
  const reactionsEndpoint = 'reactions'

  const getPosts = async (params?: PostParams): Promise<PostListPayload> => {
    const response: PostListResponse | undefined = await get({
      endpoint,
      params,
    })
    return {
      list: response?.items || [],
      page: response?.page || 0,
      pageSize: response?.pageSize || 0,
      hasNextPage: response ? response.page < response.availablePages : false,
    }
  }

  const fetchPosts = async (params?: PostParams) => {
    dispatch(showLoader())
    const response: PostListPayload = await getPosts({
      page_size: pageSize,
      page: 1,
      ...params,
    })
    dispatch(setList(response))
    dispatch(hideLoader())
  }

  const fetchPost = async (id: string) => {
    const response: PostDetail | undefined = await get({
      endpoint: `${endpoint}/${id}/detail`,
    })
    if (response) dispatch(setDetail(response))
  }

  const fetchNextPagePosts = async (params: PostParams) => {
    const response: PostListPayload = await getPosts({
      page_size: pageSize,
      ...params,
      page: page + 1,
    })
    response.list = [...list, ...response.list]
    dispatch(setList(response))
  }

  const createPost = async (body: CreatePostType) => {
    await post({
      endpoint,
      body,
    })
  }

  const deletePost = async (id: string) => {
    await del({ endpoint, id })
  }

  const publishPost = async (
    id: string,
    publisherExternalId: string,
    publisherType: string
  ) => {
    await post({
      endpoint: `${endpoint}/${id}/change-state`,
      body: {
        state: PostStateEnum.PUBLISHED,
        publisherExternalId,
        publisherType,
      },
    })
  }

  const uploadPostImage = async (imageUrl: string) => {
    dispatch(showLoader())
    const imageData = new FormData()
    const imageBlob = await fetch(imageUrl).then((r) => r.blob())
    imageData.append('imageFile', imageBlob, 'postImage')
    const response: PostImage | undefined = await post({
      endpoint: `${endpoint}/image`,
      body: imageData,
      isMultipart: true,
    })
    dispatch(hideLoader())
    return response
  }

  const getThemesByChannel = async (params: PostParams): Promise<Theme[]> => {
    const response: Theme[] | undefined = await get({
      endpoint: endpointThemes,
      params,
    })
    return response ?? []
  }

  // Comments

  const fetchPostComments = async (postId: string) => {
    const response: Comment[] | undefined = await get({
      endpoint: `${endpoint}/${postId}/comments`,
    })
    dispatch(setPostComments({ postId, list: response ?? [] }))
  }

  const createComment = async (
    postId: string,
    commentToSave: CommentPostInterface,
    publisherType: string,
    selectedPublisher: string
  ): Promise<void> => {
    const response: Comment | undefined = await post({
      endpoint: commentsEndpoint,
      body: {
        ...commentToSave,
        postId,
        publisherType,
        publisherExternalId: selectedPublisher,
      },
    })
    dispatch(
      addPostComment({
        postId,
        comment: response,
      })
    )
  }

  const deleteComment = async (postId: string, commentId: string) => {
    await del({
      endpoint: commentsEndpoint,
      id: `${commentId}?postId=${postId}`,
    })
    dispatch(deletePostComment({ postId, commentId }))
  }

  const updateComment = async (
    postId: string,
    publisherExternalId: string,
    publisherType: string,
    comment: CommentPutInterface
  ): Promise<void> => {
    const result = await put<Comment>({
      endpoint: commentsEndpoint,
      id: comment.id,
      body: { ...comment, publisherExternalId, publisherType, postId },
    })
    dispatch(
      updatePostComment({
        postId,
        comment: result,
      })
    )
  }

  const updatePost = async (
    postId: string,
    body: EditPostType
  ): Promise<Post | undefined> => {
    const response: Post | undefined = await put({
      endpoint: `${endpoint}/${postId}`,
      body,
    })
    return response
  }

  const updateSponsoredPost = async (
    postId: string,
    body: SponsoredPostUpdateParams
  ) => {
    const response: Post | undefined = await put({
      endpoint: `${endpoint}/${postId}`,
      body,
    })
    return response
  }

  const updateReaction = async (
    body: UpdateReactionBody
  ): Promise<Reactions | undefined> => {
    const response: Reactions | undefined = await put({
      endpoint: reactionsEndpoint,
      body,
    })
    return response
  }

  const updatePostReaction = async (
    postId: string,
    publisherExternalId: string,
    publisherType: string,
    type: ReactionTypeEnum
  ): Promise<Reactions | undefined> => {
    const response: Reactions | undefined = await updateReaction({
      postId,
      publisherExternalId,
      publisherType,
      type,
    })
    return response
  }

  const updateCommentReaction = async (
    postId: string,
    commentId: string,
    publisherExternalId: string,
    publisherType: string,
    type: ReactionTypeEnum
  ): Promise<Reactions | undefined> => {
    const response: Reactions | undefined = await updateReaction({
      postId,
      commentId,
      publisherExternalId,
      publisherType,
      type,
    })
    return response
  }

  const deletePostAndDisablePublisher = async ({
    postId,
    memberId,
    publisherId,
  }: DeletePostAndDisablePublisherParams): Promise<void> => {
    await put({
      endpoint: `${endpoint}/delete-and-disable-user`,
      body: { postId, memberId, publisherId },
    })
    dispatch(storeDisablePublisher({ publisherId }))
  }

  const deleteCommentAndDisablePublisher = async ({
    postId,
    commentId,
    memberId,
    publisherId,
  }: DeleteCommentAndDisablePublisherParams): Promise<void> => {
    await put({
      endpoint: `${commentsEndpoint}/delete-and-disable-user`,
      body: { commentId, memberId, publisherId, postId },
    })
    dispatch(deletePostComment({ postId, commentId }))
    dispatch(storeDisablePublisher({ publisherId }))
  }

  const getPublishers = async (): Promise<PublishersResponse[]> => {
    const response: PublishersResponse[] | undefined = await get({
      endpoint: endpointPublish,
    })
    return response ?? []
  }

  const disablePublisher = async (
    publisherId: string,
    isEnabled: boolean
  ): Promise<void> => {
    await patch({
      endpoint: endpointPublish,
      id: publisherId,
      body: { isEnabled },
    })
    dispatch(storeDisablePublisher({ publisherId }))
  }

  const getTranslatePost = async (
    brandId: string,
    lang: string
  ): Promise<TranslatePost | undefined> => {
    const response: TranslatePost | undefined = await get({
      endpoint: `${endpoint}/${brandId}/translate`,
      params: {
        to: lang,
      },
    })
    return response
  }

  const getTranslateComment = async (
    brandId: string,
    lang: string
  ): Promise<TranslatePost | undefined> => {
    const response: TranslatePost | undefined = await get({
      endpoint: `${commentsEndpoint}/${brandId}/translate`,
      params: {
        to: lang,
      },
    })
    return response
  }

  const fetchPostMetrics = async (postId: string) => {
    dispatch(showLoader())
    const response: PostMetrics | undefined = await get({
      endpoint: `${endpointMetrics}/${postId}`,
    })
    if (response) {
      dispatch(setMetrics(response))
    }
    dispatch(hideLoader())
  }

  const viewMorePostSponsored = async (postId: string) => {
    await post({
      endpoint: `${endpointMetrics}/view-post`,
      body: { postId },
    })
  }

  const viewPostSponsor = async (postId: string) => {
    await post({
      endpoint: `${endpointMetrics}/view-sponsor`,
      body: { postId },
    })
  }

  return {
    post: detail,
    metrics,
    postList: list,
    isLoaded,
    hasNextPostsPage: hasNextPage,
    fetchPosts,
    fetchPost,
    fetchNextPagePosts,
    createPost,
    updateSponsoredPost,
    deletePost,
    publishPost,
    uploadPostImage,
    getPublishers,
    getThemesByChannel,
    fetchPostComments,
    createComment,
    deleteComment,
    updateComment,
    updatePost,
    updatePostReaction,
    updateCommentReaction,
    deletePostAndDisablePublisher,
    disablePublisher,
    deleteCommentAndDisablePublisher,
    getTranslatePost,
    getTranslateComment,
    fetchPostMetrics,
    viewMorePostSponsored,
    viewPostSponsor,
  }
}

export default usePosts
