import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  Activity,
  UseActivitiesResult,
  ActivityBrandSpace,
  ActivityConfigForm,
  ActivityListParams,
  ActivityListResponse,
  ActivityChannel,
  ActivityTranslations,
  AttendeesListParams,
  AttendeesListResponse,
  CommentActivity,
  ReactionTypeEnum,
  CommentActivityInterface,
  CommentActivityPutInterface,
  TranslateCommentActivity,
  JitsiResponse,
  ReactionsActivity,
  DeleteCommentActivityAndDisablePublisherParams,
  Location,
  ActivityDetail,
  ActivityCollaboratorsResponse,
  ActivityCollaboratorsTypeForm,
  ActivityCollaboratorDetailResponse,
  ActivityCollaboratorsForm,
} from './types'
import {
  setList,
  showLoader,
  hideLoader,
  setDetail,
  setAttendees,
  deleteAtten,
  setComments,
  addComment,
  updateComment,
  deleteComment,
  setCollaborators,
  setCollaborator,
} from './store'
import { disablePublisher as storeDisablePublisher } from '../posts/store'
import useFetch from '../../utils/fetch/useFetch'

const useActivities = (): UseActivitiesResult => {
  const dispatch = useAppDispatch()
  const {
    detail,
    list,
    isLoaded,
    attendees,
    comments,
    collaborators,
    collaborator,
  } = useAppSelector((state) => state.activities)
  const { get, put, post, del } = useFetch()

  const endpoint = 'activities'
  const endpointCollaboratorsInfo = 'contributor-info'
  const endpointCollaborators = 'contributor'

  const dispatchLoaders = async function dispatchLoaders<T>(
    asyncCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asyncCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const getActivities = async (
    params: ActivityListParams
  ): Promise<ActivityListResponse | undefined> => {
    const response: ActivityListResponse | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const createActivity = async (
    activity: ActivityConfigForm
  ): Promise<ActivityDetail | undefined> => {
    const response: ActivityDetail | undefined = await post({
      endpoint,
      body: activity,
    })

    return response
  }

  const editActivity = async ({
    id,
    activity,
    edit,
  }: {
    id: string
    activity: ActivityConfigForm
    edit: 'all' | 'translation' | 'form'
  }): Promise<ActivityDetail | undefined> => {
    let response: ActivityDetail | undefined

    if (edit == 'form' || edit == 'all') {
      response = await put({
        endpoint: `${endpoint}/${id}`,
        body: activity,
      })
    }

    if (edit == 'translation' || edit == 'all') {
      await editActivityTranslation({
        id,
        translation: activity.translations[0],
      })
    }

    return response
  }

  const editActivityTranslation = async ({
    id,
    translation,
  }: {
    id: string
    translation: ActivityTranslations
  }): Promise<ActivityTranslations | undefined> => {
    const response: ActivityTranslations | undefined = await put({
      endpoint: `${endpoint}/${id}/translation`,
      body: translation,
    })
    if (response && detail?.translations.length == 1)
      dispatch(
        setDetail({
          ...detail,
          translations: [...detail?.translations, response],
        })
      )
    return response
  }

  const uploadImageActivity = async (
    image: string
  ): Promise<string | undefined> => {
    const form = new FormData()
    const blob = await fetch(image).then((r) => r.blob())
    form.set('imageFile', blob)
    const result: { uri: string } | undefined = await post({
      endpoint: `${endpoint}/image`,
      body: form,
      isMultipart: true,
    })
    if (result) return result.uri
  }

  const uploadFileActivity = async (
    file: string
  ): Promise<string | undefined> => {
    const form = new FormData()
    const blob = await fetch(file).then((r) => r.blob())
    form.set('fileFile', blob)
    const result: { uri: string } | undefined = await post({
      endpoint: `${endpoint}/file`,
      body: form,
      isMultipart: true,
    })
    if (result) return result.uri
  }

  const fetchActivities = async (params: ActivityListParams): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: ActivityListResponse | undefined = await getActivities(
        params
      )
      if (response) {
        dispatch(setList(response))
      }
    })
  }

  const fetchActivity = async (
    activityId: string
  ): Promise<ActivityDetail | undefined> => {
    return await dispatchLoaders<ActivityDetail | undefined>(async () => {
      const response: ActivityDetail | undefined = await get({
        endpoint: `${endpoint}/${activityId}`,
      })
      if (response) dispatch(setDetail(response))
      return response
    })
  }

  const clearCurrentActivity = (): void => {
    dispatch(setDetail(null))
  }

  const clearCurrentActivities = (): void => {
    dispatch(setList(null))
  }

  const publishActivity = async (
    activityId: string
  ): Promise<Activity | undefined> => {
    const activity: Activity | undefined = await get({
      endpoint: `${endpoint}/${activityId}`,
    })
    const response: Activity | undefined = await put({
      endpoint: `${endpoint}/${activityId}`,
      body: {
        ...activity,
        brandSpaceId: activity?.brandSpace.id,
        isPublished: !activity?.isPublished,
      },
    })
    if (response && list) {
      dispatch(
        setList({
          ...list,
          items: list.items.map((activity) => {
            if (activity.id == activityId) {
              return { ...activity, isPublished: !activity.isPublished }
            }
            return activity
          }),
        })
      )
    }
    return response
  }

  const deleteActivity = async (activityId: string): Promise<void> =>
    await del({ endpoint, id: activityId })

  const getBrandSpaces = async (): Promise<ActivityBrandSpace[]> => {
    const response: ActivityBrandSpace[] =
      (await get({ endpoint: `${endpoint}/brandspaces` })) ?? []
    return response ? response : []
  }

  const getChannelsByBrandSpaces = async (
    brandSpaceIds?: string[]
  ): Promise<ActivityChannel[]> => {
    let response: ActivityChannel[] = []
    if (brandSpaceIds) {
      const params = { brandSpaces_ids: brandSpaceIds }
      response = (await get({ endpoint: `${endpoint}/channels`, params })) ?? []
    } else {
      response = (await get({ endpoint: `${endpoint}/channels` })) ?? []
    }
    return response ? response : []
  }

  const getUrlJitsi = async (): Promise<JitsiResponse | undefined> => {
    const response: JitsiResponse | undefined = await get({
      endpoint: `${endpoint}/jitsi`,
    })
    return response ? response : undefined
  }

  const getAttendees = async (
    activityId: string,
    params: AttendeesListParams
  ): Promise<AttendeesListResponse | null> => {
    const response: AttendeesListResponse | undefined = await get({
      endpoint: `${endpoint}/${activityId}/attendees`,
      params,
    })
    response && dispatch(setAttendees(response))
    return response ? response : null
  }

  const deleteAttendee = async (
    activityId: string,
    attendeeId: string
  ): Promise<void> => {
    await del({
      endpoint: `${endpoint}/${activityId}/attendees`,
      id: attendeeId,
    })
    dispatch(deleteAtten({ attendeeId }))
  }

  const getCommentsActivity = async (
    activityId: string,
    publisherId: string
  ): Promise<CommentActivity[] | null> => {
    const response: CommentActivity[] | undefined = await get({
      endpoint: `${endpoint}/${activityId}/comments/brandspace/${publisherId}`,
    })
    response && dispatch(setComments(response))
    return response ? response : null
  }

  const createCommentActivity = async (
    activityId: string,
    commentToSave: CommentActivityInterface,
    publisherId: string
  ): Promise<void> => {
    const response: CommentActivity | undefined = await post({
      endpoint: `${endpoint}/${activityId}/comments`,
      body: {
        message: commentToSave.message,
        brandSpaceId: publisherId,
      },
    })
    dispatch(
      addComment({
        activityId,
        comment: response,
      })
    )
  }

  const updateCommentActivity = async (
    activityId: string,
    comment: CommentActivityPutInterface,
    publisherId: string
  ): Promise<void> => {
    const response: CommentActivity | undefined = await put({
      endpoint: `${endpoint}/${activityId}/comments`,
      body: {
        id: comment.id,
        message: comment.message,
        brandSpaceId: publisherId,
      },
    })
    dispatch(
      updateComment({
        activityId,
        comment: response,
      })
    )
  }

  const deleteCommentActivity = async (
    activityId: string,
    commentId: string
  ) => {
    await del({ endpoint: `${endpoint}/${activityId}/comments`, id: commentId })
    dispatch(deleteComment({ activityId, commentId }))
  }

  const translateCommentActivity = async (
    commentId: string,
    activityId: string,
    lang: string
  ): Promise<TranslateCommentActivity | undefined> => {
    const response: TranslateCommentActivity | undefined = await get({
      endpoint: `${endpoint}/${activityId}/comments/${commentId}/translate`,
      params: {
        to: lang,
      },
    })
    return response
  }

  const updateCommentActivityReaction = async (
    activityId: string,
    commentId: string,
    reaction: ReactionTypeEnum,
    publisherId: string
  ): Promise<ReactionsActivity | undefined> => {
    const response: ReactionsActivity | undefined = await put({
      endpoint: `${endpoint}/${activityId}/comments/${commentId}/reaction`,
      body: { type: reaction, brandSpaceId: publisherId },
    })
    return response
  }
  const deleteCommentActivivityAndDisablePublisher = async ({
    activityId,
    commentId,
    memberId,
    publisherId,
  }: DeleteCommentActivityAndDisablePublisherParams): Promise<void> => {
    await put({
      endpoint: `${endpoint}/${activityId}/delete-and-disable-user`,
      body: { commentId, memberId },
    })
    dispatch(deleteComment({ activityId, commentId }))
    dispatch(storeDisablePublisher({ publisherId }))
  }

  const getLocations = async (location: string): Promise<Location[]> => {
    const response: Location[] | undefined = await get({
      endpoint: `${endpoint}/search-location`,
      params: {
        location,
      },
    })
    return response ?? []
  }

  const fetchCollaborators = async (activityId: string): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: ActivityCollaboratorsResponse | undefined = await get({
        endpoint: `${endpointCollaboratorsInfo}/${activityId}`,
      })
      if (response) {
        dispatch(setCollaborators(response))
      }
    })
  }

  const createTypeCollaborator = async (
    data: ActivityCollaboratorsTypeForm
  ) => {
    const response: ActivityCollaboratorsResponse | undefined = await post({
      endpoint: endpointCollaboratorsInfo,
      body: data,
    })

    if (response) dispatch(setCollaborators({ ...collaborators, ...response }))
  }

  const deleteCollaborator = async (id: string, activityId: string) => {
    await del({
      endpoint: `${endpointCollaborators}/${id}/activity/${activityId}`,
    })
    if (collaborators) {
      const contributors = collaborators.contributors.filter((c) => c.id !== id)
      dispatch(
        setCollaborators({
          ...collaborators,
          contributors,
        })
      )
    }
  }

  const fetchCollaborator = async (id: string, activityId: string) => {
    await dispatchLoaders(async () => {
      const response: ActivityCollaboratorDetailResponse | undefined =
        await get({
          endpoint: `${endpointCollaborators}/${id}/activity/${activityId}`,
        })
      if (response) dispatch(setCollaborator(response))
    })
  }

  const createCollaborator = async (
    data: ActivityCollaboratorsForm
  ): Promise<string | undefined> => {
    const response: string | undefined = await post({
      endpoint: endpointCollaborators,
      body: data,
    })
    return response
  }

  const translateCollaborator = async (
    id: string,
    data: Partial<ActivityCollaboratorsForm>
  ) => {
    await put({
      endpoint: `${endpointCollaborators}/${id}/translation`,
      body: data,
    })
  }

  const editCollaborator = async (
    id: string,
    data: ActivityCollaboratorsForm
  ) => {
    await put({
      endpoint: `${endpointCollaborators}/${id}`,
      body: data,
    })
  }

  return {
    activity: detail,
    activities: list,
    isLoaded,
    attendees,
    comments,
    collaborators,
    collaborator,
    fetchActivities,
    fetchActivity,
    createActivity,
    editActivity,
    editActivityTranslation,
    uploadImageActivity,
    uploadFileActivity,
    clearCurrentActivity,
    clearCurrentActivities,
    publishActivity,
    deleteActivity,
    getBrandSpaces,
    getChannelsByBrandSpaces,
    getUrlJitsi,

    getAttendees,
    deleteAttendee,

    getCommentsActivity,
    createCommentActivity,
    updateCommentActivity,
    deleteCommentActivity,
    translateCommentActivity,
    updateCommentActivityReaction,
    deleteCommentActivivityAndDisablePublisher,

    getLocations,

    fetchCollaborators,
    createTypeCollaborator,
    fetchCollaborator,
    createCollaborator,
    editCollaborator,
    translateCollaborator,
    deleteCollaborator,
  }
}

export default useActivities
