import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseMembersResult,
  MemberListResponse,
  MemberRequestParams,
  MemberDetail,
  CSVParams,
  MemberBadgesParams,
} from './types'
import {
  setList,
  clearList,
  toggleEnable,
  showLoader,
  hideLoader,
} from './store'
import useFetch from '../../utils/fetch/useFetch'

const useMembers = (): UseMembersResult => {
  const dispatch = useAppDispatch()
  const {
    list,
    page,
    pageSize,
    availablePages,
    availableItems,
    search,
    isLoaded,
  } = useAppSelector((state) => state.members)
  const { get, patch, del } = useFetch()

  const endpoint = 'members'

  const parseMemberParams = (requestParams: Partial<MemberRequestParams>) => {
    const params: MemberRequestParams = {
      page_size: pageSize,
      search: (requestParams?.search !== undefined
        ? requestParams?.search
        : search) as string,
      page: (requestParams?.page || page) as number,
      registerType: requestParams.registerType,
      channels: (requestParams?.channels ?? []) as string[],
      userType: requestParams.userType,
    }

    return params
  }

  const getMembers = async (params: Partial<MemberRequestParams>) => {
    const response: MemberListResponse | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const getMembersBadge = async (params: MemberBadgesParams) => {
    const response: MemberListResponse | undefined = await get({
      endpoint: `${endpoint}/badges`,
      params,
    })
    return response
  }

  const fetchMembers = async (params: Partial<MemberRequestParams>) => {
    dispatch(showLoader())
    const reqParams = parseMemberParams(params)
    const response: MemberListResponse | undefined = await getMembers(reqParams)
    response &&
      dispatch(
        setList({
          search: reqParams.search as string,
          list: response.items,
          page: response.page,
          pageSize: response.pageSize,
          availableItems: response.availableItems,
          availablePages: response.availablePages,
        })
      )
    dispatch(hideLoader())
  }

  const clearMemberList = () => dispatch(clearList())

  const toggleEnableMember = async (id: string, isEnabled: boolean) => {
    await patch({ endpoint, id, body: { isEnabled } })
    dispatch(toggleEnable(id))
  }
  const toggleEnableProfile = async (
    id: string,
    isEnabled: boolean
  ): Promise<MemberDetail | undefined> => {
    return await patch({ endpoint, id, body: { isEnabled } })
  }

  const getMemberDetail = async (
    memberId: string
  ): Promise<MemberDetail | undefined> =>
    await get({
      endpoint: `${endpoint}/${memberId}/profile`,
    })

  const getCSV = async (params: CSVParams) => {
    const response: any | undefined = await get({
      endpoint: `${endpoint}/csv`,
      params,
      isFile: true,
    })
    return response
  }

  const deleteMember = async (memberId: string, deleteContent: boolean) => {
    try {
      await del({
        endpoint,
        body: {
          memberId,
          deleteContent,
        },
      })
    } catch (e) {}
  }

  return {
    memberList: list,
    page,
    availablePages,
    availableItems,
    pageSize,
    search,
    isLoaded,
    getMembers,
    getMembersBadge,
    fetchMembers,
    getCSV,
    clearMemberList,
    toggleEnableMember,
    toggleEnableProfile,
    getMemberDetail,
    deleteMember,
  }
}

export default useMembers
