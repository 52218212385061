import { createSlice } from '@reduxjs/toolkit'

import { Confirmation, UiState } from './types'

const initialConfirmation: Confirmation = {
  isVisible: false,
  title: '',
  subtitle: '',
  text: '',
  confirmText: '',
  cancelText: '',
  onConfirm: null,
  onCancel: null,
}

const initialState: UiState = {
  isPanelVisible: false,
  confirmation: initialConfirmation,
}

export const UiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showPanel: (state) => {
      state.isPanelVisible = true
    },
    hidePanel: (state) => {
      state.isPanelVisible = false
    },
    showConfirmation: (state, action) => {
      state.confirmation = { ...action.payload, isVisible: true }
    },
    hideConfirmation: (state) => {
      state.confirmation = initialConfirmation
    },
  },
})

export const { showPanel, hidePanel, showConfirmation, hideConfirmation } =
  UiSlice.actions

export default UiSlice.reducer
