import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import UsersScreen from '../pages/Users'
import ProfileScreen from '../pages/Users/Detail'

const UsersRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersScreen />} />
      <Route path="/:profileId" element={<ProfileScreen />} />
    </Routes>
  )
}

export default UsersRoutes
