// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateTopic__form{min-height:calc(100vh - 350px)}.CreateTopic__form .upload__content{margin-top:30px}`, "",{"version":3,"sources":["webpack://./src/app/pages/TopicFamilies/CreateTopics/styles.scss"],"names":[],"mappings":"AASE,mBACE,8BAAA,CACA,oCACE,eAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.CreateTopic {\n  &__form {\n    min-height: calc(100vh - 350px);\n    .upload__content {\n      margin-top: 30px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateTopic__form": `CreateTopic__form`,
	"upload__content": `upload__content`
};
export default ___CSS_LOADER_EXPORT___;
