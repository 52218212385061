import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    sponsors: yup.array().of(
      yup.object().shape({
        id: yup.string().optional(),
        name: yup
          .string()
          .label(t('sponsors.fields.name'))
          .required(({ label }) => t('validations.required', { label }))
          .max(200, ({ label }) =>
            t('validations.maxLength', { label, maxLength: 200 })
          ),
        websiteUrl: yup
          .string()
          .label(t('sponsors.fields.website'))
          .url(t('common.link.patternValidation')),
        logoUrl: yup
          .string()
          .label(t('sponsors.fields.logo-url.validation'))
          .required(({ label }) => t('validations.required', { label })),
        index: yup
          .number()
          .label(t('sponsors.fields.order'))
          .required(({ label }) => t('validations.required', { label })),
      })
    ),
  })
}
