import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  Badge,
  BadgeListParams,
  BadgesList,
  ImageResult,
  PutBadgePayload,
  UseBadgesResult,
  BadgeMembersListParams,
  BadgeMembersList,
  PostBadgePayload,
} from './types'
import {
  setBadgesList,
  showLoader,
  hideLoader,
  setBadgeDetail,
  setMembersList,
} from './store'

import useFetch from '../../utils/fetch/useFetch'

const useBadges = (): UseBadgesResult => {
  const dispatch = useAppDispatch()
  const { list, isLoaded, detail, members } = useAppSelector(
    (state) => state.badges
  )
  const { get, del, post, put, patch } = useFetch()

  const badgesEndpoint = 'badges'

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const fetchBadges = async (params: BadgeListParams): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: BadgesList | undefined = await get({
        params,
        endpoint: badgesEndpoint,
      })
      response && dispatch(setBadgesList(response))
    })
  }

  const deleteBadge = async (id: string): Promise<void> =>
    await del({ endpoint: badgesEndpoint, id: id })

  const fetchBadgeDetail = async (
    badgeId: string
  ): Promise<Badge | undefined> => {
    return await dispatchLoaders<Badge | undefined>(async () => {
      const response: Badge | undefined = await get({
        endpoint: `${badgesEndpoint}/${badgeId}`,
      })
      if (response) dispatch(setBadgeDetail(response))
      return response
    })
  }

  const createBadge = async (body: PostBadgePayload) => {
    const response: Badge | undefined = await post({
      endpoint: badgesEndpoint,
      body,
    })
    return response
  }

  const uploadImageBadge = async (image: string) => {
    const form = new FormData()
    const blob = await fetch(image).then((r) => r.blob())
    form.set('imageFile', blob)
    dispatch(showLoader())
    try {
      const result: ImageResult | undefined = await post({
        endpoint: `${badgesEndpoint}/images/logo`,
        body: form,
        isMultipart: true,
      })
      return result?.uri ?? ''
    } finally {
      dispatch(hideLoader())
    }
  }

  const updateBadge = async (
    badgeId: string,
    badge: PutBadgePayload
  ): Promise<Badge | undefined> => {
    const response: Badge | undefined = await put({
      endpoint: `${badgesEndpoint}/${badgeId}`,
      body: badge,
    })
    if (response) dispatch(setBadgeDetail(response))
    return response
  }

  const publishBadge = async (
    badgeId: string,
    publish: boolean
  ): Promise<Badge | undefined> => {
    const response: Badge | undefined = await patch({
      endpoint: `${badgesEndpoint}/${badgeId}`,
      body: {
        publish,
      },
      id: 'publish',
    })
    if (response && list) {
      dispatch(
        setBadgesList({
          ...list,
          items: list.items.map((badge) => {
            if (badge.id == badgeId) {
              return { ...badge, isPublished: !badge.isPublished }
            }
            return badge
          }),
        })
      )
    }
    return response
  }

  const fetchMembers = async (
    badgeId: string,
    params: BadgeMembersListParams
  ) => {
    await dispatchLoaders(async () => {
      const response: BadgeMembersList | undefined = await get({
        params,
        endpoint: `${badgesEndpoint}/${badgeId}/search-users`,
      })
      if (response) {
        dispatch(setMembersList(response))
      }
    })
  }

  const deleteMember = async (
    badgeId: string,
    memberId: string
  ): Promise<void> => {
    await del({
      endpoint: `${badgesEndpoint}/${badgeId}/users`,
      id: memberId,
    })
  }

  const assignMember = async (badgeId: string, memberIds: string[]) => {
    const response: Badge | undefined = await put({
      endpoint: `${badgesEndpoint}/${badgeId}/users`,
      body: memberIds,
    })
    return response
  }

  return {
    badgesList: list,
    badgeDetail: detail,
    members,
    isLoaded,
    fetchBadges,
    deleteBadge,
    fetchBadgeDetail,
    createBadge,
    uploadImageBadge,
    updateBadge,
    publishBadge,
    fetchMembers,
    deleteMember,
    assignMember,
  }
}

export default useBadges
