import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  AccessDenyInfoResponse,
  ConfigurationCorporateDetailResponse,
  ConfigurationCorporateState,
} from './types'

const initialState: ConfigurationCorporateState = {
  isLoaded: false,
  detail: null,
  accessDenyInfo: null,
}

export const configurationCorporateSlice = createSlice({
  name: 'configurationCorporate',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    setDetail: (
      state,
      action: PayloadAction<ConfigurationCorporateDetailResponse>
    ) => {
      state.detail = action.payload
    },
    setAccessDenyInfo: (
      state,
      action: PayloadAction<AccessDenyInfoResponse[]>
    ) => {
      state.accessDenyInfo = action.payload
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
  },
})

export const { setDetail, setAccessDenyInfo, showLoader, hideLoader } =
  configurationCorporateSlice.actions

export default configurationCorporateSlice.reducer
