// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.element-name-and-tooltip{flex:1;display:flex}.element-name{display:block;word-break:break-word}`, "",{"version":3,"sources":["webpack://./src/app/pages/Directories/Manage/ElementsList/styles.scss"],"names":[],"mappings":"AAQA,0BACE,MAAA,CACA,YAAA,CAGF,cACE,aAAA,CACA,qBAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.element-name-and-tooltip {\n  flex: 1;\n  display: flex;\n}\n\n.element-name {\n  display: block;\n  word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element-name-and-tooltip": `element-name-and-tooltip`,
	"element-name": `element-name`
};
export default ___CSS_LOADER_EXPORT___;
