export const uploadFile = async (
  file: string,
  uploadFn: (image: string) => Promise<string | undefined>
) => {
  if (file.includes('blob:')) {
    const response = await uploadFn(file)
    if (response) return response
  }
  return file
}
