import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  DirectoryListItem,
  TypeDirectory,
} from '../../../../core/directories/types'

import useDirectories from '../../../../core/directories/useDirectories'
import useUi from '../../../../core/ui/useUi'
import useNotifications from '../../../../utils/notifications/useNotifications'
import useCopy from '../../../../utils/copy/useCopy'
import usePermissions from '../../../../core/permissions/usePermissions'

import './styles.scss'
import { Card } from '../../../../components/Card'

export const DirectoriesListItem: FC<DirectoryListItem> = ({
  id,
  name,
  description,
  type,
  hasElements,
  isPublished,
  translations,
  brandSpace,
  channels,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showConfirmation } = useUi()
  const notify = useNotifications()
  const { deleteDirectory, publishDirectory, fetchDirectories } =
    useDirectories()
  const { handleCopy } = useCopy()
  const { verifyPermissionsMaster } = usePermissions()

  const onManage = () => {
    navigate(`${id}/manage`)
  }

  const onEdit = () => {
    navigate(`${id}`)
  }

  const handleConfirmPublish = (value: boolean) => {
    if (!verifyPermissionsMaster()) return
    const action = value ? 'publish' : 'unpublish'
    showConfirmation({
      title: t(`directories.${action}`),
      subtitle: t(`directories.${action}.modal.subtitle`, { name }),
      confirmText: t(`common.${action}`),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: () => {
        onConfirmPublish(value)
      },
    })
  }

  const onConfirmPublish = async (value: boolean) => {
    const action = value ? 'publish' : 'unpublish'
    try {
      await publishDirectory(id, value)
      notify.success(t(`directories.${action}.toast.ok`, { name }))
    } catch (error) {
      notify.error(t(`directories.${action}.toast.ko`, { name }))
    }
  }

  const onDelete = () => {
    showConfirmation({
      title: t('directories.delete'),
      subtitle: t('directories.delete.modal.subtitle', {
        name,
      }),
      text: t('directories.delete.modal.text'),
      confirmText: t('common.remove'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      confirmActionOptions: {
        confirmText: t('directories.delete.modal.confirmText'),
        word: t('directories.delete.modal.word'),
      },
      onConfirm: async () => {
        try {
          await deleteDirectory(id)
          fetchDirectories({ page: 1, page_size: 5 })
          notify.success(t('directories.delete.toast.ok'))
        } catch (error) {
          notify.error(t('directories.delete.toast.ko'))
        }
      },
    })
  }

  const postActions = useMemo(() => {
    const actions = []
    actions.push(
      {
        label: t('directories.managment'),
        onClick: onManage,
      },
      {
        label: t('directories.edit'),
        onClick: onEdit,
      },
      {
        label: t('directories.entity.copy'),
        onClick: () => {
          handleCopy(id, t('directories.entity'))
        },
      }
    )

    if (verifyPermissionsMaster()) {
      actions.push({
        label: t('directories.delete'),
        onClick: onDelete,
      })
    }
    return actions
  }, [])

  const directoryLocation = useMemo(() => {
    switch (type) {
      case TypeDirectory.Main:
        return t('directories.type.menu')
      case TypeDirectory.BrandSpace:
        return t('navMenu.brand')
      case TypeDirectory.Channel:
        return t('topic.channels')
    }
  }, [type])

  const chips = useMemo(() => {
    switch (type) {
      case TypeDirectory.BrandSpace:
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return [brandSpace!]
      case TypeDirectory.Channel:
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return channels!
    }
    return []
  }, [type])

  return (
    <Card id={id} hasImage={false}>
      <Card.Content>
        <Card.Content.Top preTitle={directoryLocation}>
          <Card.Content.Top.Toggle
            value={isPublished}
            label={t('directories.publish')}
            onChange={handleConfirmPublish}
            isDisabled={!hasElements || !verifyPermissionsMaster()}
          />
        </Card.Content.Top>
        <Card.Content.Header title={name} actions={postActions} />
        <Card.Content.Body description={description} />
        <Card.Content.Bottom translations={translations}>
          <Card.Content.Bottom.Tags tags={chips} />
        </Card.Content.Bottom>
      </Card.Content>
    </Card>
  )
}
