import { FC, useMemo } from 'react'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { ToggleControl } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { useTranslation } from 'react-i18next'
import TranslationChips from '../../../../components/TranslationsChips'
import { Activity } from '../../../../core/activities/types'
import { Link } from 'react-router-dom'
import useActivities from '../../../../core/activities/useActivities'
import useNotifications from '../../../../utils/notifications/useNotifications'
import useUi from '../../../../core/ui/useUi'
import useCulture from '../../../../utils/culture'
import usePermissions from '../../../../core/permissions/usePermissions'

import './styles.scss'

interface ActivitiesListItemProps {
  activity: Activity
  isHistorical: boolean
  onDelete: () => void
}

export const ActivitiesListItem: FC<ActivitiesListItemProps> = ({
  activity,
  isHistorical,
  onDelete,
}) => {
  const { showConfirmation } = useUi()
  const { t } = useTranslation()
  const { publishActivity, deleteActivity } = useActivities()
  const notify = useNotifications()
  const { formatDate } = useCulture()
  const { verifyPermissionsMaster } = usePermissions()

  const {
    id,
    translations,
    type,
    totalActivityAttendees,
    maxActivityAttendees,
    isPublished,
    startDate,
    brandSpace: { logoImageUrl, name },
  } = activity

  const handleConfirmDelete = () => {
    if (isHistorical) {
      return showConfirmation({
        title: t('common.warning'),
        subtitle: t('activity.actions.delete.subtitle'),
        confirmText: t('common.delete'),
        cancelText: t('common.cancel'),
        iconName: 'report_problem',
        onConfirm: async () => {
          await confirmDelete()
        },
      })
    }
    return showConfirmation({
      title: t('common.warning'),
      subtitle: t('activity.actions.delete.subtitle'),
      text: t('activity.actions.delete.text'),
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: async () => {
        await confirmDelete()
      },
    })
  }

  const handleConfirmPublish = (newState: boolean) => {
    if (newState) {
      showConfirmation({
        title: t('common.warning'),
        subtitle: t('activity.actions.publish.subtitle'),
        text: t('activity.actions.publish.text'),
        confirmText: t('common.publish'),
        cancelText: t('common.cancel'),
        iconName: 'report_problem',
        onConfirm: () => {
          confirmPublish(newState)
        },
      })
    } else {
      confirmPublish(newState)
    }
  }

  const confirmDelete = async () => {
    try {
      await deleteActivity(id)
      notify.success(t('activity.actions.delete.toast.ok'))
      onDelete()
    } catch (error) {
      notify.error(t('activity.actions.delete.toast.ko'))
    }
  }

  const confirmPublish = async (newState: boolean) => {
    try {
      await publishActivity(id)
      const toast = newState
        ? 'activity.actions.publish.toast.ok'
        : 'activity.actions.unPublish.toast.ok'
      notify.success(t(toast))
    } catch (error) {
      const toast = newState
        ? 'activity.actions.publish.toast.ko'
        : 'activity.actions.unPublish.toast.ko'
      notify.error(t(toast))
    }
  }

  const translationsComponent: { language: string; name: string }[] =
    useMemo(() => {
      return translations.map((translation) => {
        return { language: translation.language, name: translation.title }
      })
    }, [translations])

  return (
    <div
      className={`activities-grid-item ${
        !isPublished && !isHistorical && 'activities-grid-item--unpublished'
      }`}
    >
      <div className="activities-grid-item__image">
        <span className="activities-grid-item__category">
          {t(`activity.form.config.type.options.${type}`)}
        </span>
        <img src={translations[0].imageURL} alt={translations[0].title} />
      </div>
      <div className="activities-grid-item__data">
        <div className="row h-100 align-items-center">
          <div className="col-lg">
            <img
              className="activities-grid-item__brand"
              src={logoImageUrl}
              alt={name}
            />
            <h2 className="activities-grid-item__title">
              {translations[0].title}
            </h2>
            <span className="activities-grid-item__date">
              {`${formatDate(new Date(startDate), 'LONG_DATE')}`}
              {format(new Date(startDate), `- hh:mm aaa`, {
                locale: es,
              })}
            </span>
            <span className="activities-grid-item__attendees">
              {maxActivityAttendees > 0
                ? t('activities.listItem.attendees', {
                    attendees: totalActivityAttendees,
                    maxAttendees: maxActivityAttendees,
                  })
                : t('activities.listItem.attendees.withoutMax', {
                    attendees: totalActivityAttendees,
                  })}
            </span>
          </div>
          <div className="col-lg-auto text-end">
            {!isHistorical && (
              <ToggleControl
                id={`publishActivity-${id}`}
                name="publishActivity"
                className="lc-form-control-wrapper--publish"
                label={t('activities.listItem.publishActivity')}
                value={isPublished}
                onChange={handleConfirmPublish}
                disabled={!verifyPermissionsMaster()}
              />
            )}

            {isHistorical && (
              <Link to={`${id}`}>{t('activities.listItem.viewDetail')}</Link>
            )}

            <div className="activities-grid-item__actions">
              {!isHistorical && (
                <Link to={`${id}/edit`}>
                  <Icon name="edit" />
                </Link>
              )}

              <button
                type="button"
                onClick={handleConfirmDelete}
                disabled={isPublished && !isHistorical}
                className={isPublished && !isHistorical ? 'isDisabled' : ''}
              >
                <Icon
                  className={
                    isPublished && !isHistorical ? 'icon--disabled' : ''
                  }
                  name="delete_outline"
                />
              </button>
            </div>

            <div className="activities-grid-item__translations">
              <span>{t('translations.text')}</span>
              <TranslationChips translations={translationsComponent} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
