import { FormSelect, MultiSelectTreeControl } from '@liveconnect/components'
import { useEffect, useMemo, useState } from 'react'
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  CreatePostType,
  FormPostCreateFields,
  SelectType,
} from '../../../../core/posts/types'
import usePosts from '../../../../core/posts/usePosts'

import './styles.scss'

interface ChannelsArrayItemProps {
  field: {
    id: string
    themes: string[]
  }
  fieldIndex: number
  control: Control<FormPostCreateFields, object>
  channelsAvailable: SelectType[]
  disabledChannels?: boolean
  watch: UseFormWatch<FormPostCreateFields>
  setValue: UseFormSetValue<FormPostCreateFields>
}

const ChannelsArrayItem = ({
  fieldIndex,
  control,
  channelsAvailable,
  disabledChannels,
  watch,
  setValue,
}: ChannelsArrayItemProps) => {
  const { t } = useTranslation()
  const { getThemesByChannel } = usePosts()
  const [themesOptions, setThemesOptions] = useState<SelectType[]>([])

  const watchChannel = watch(`channelsArray.${fieldIndex}.id`)
  const watchThemes = watch(`channelsArray.${fieldIndex}.themes`)

  const placeholderChannels = useMemo(() => {
    const watchLength = watchThemes ? watchThemes.length : 0
    if (watchLength === themesOptions.length) {
      return t('post.create.themes.label.all')
    }
    if (watchLength > 0) {
      return t('post.create.themes.label', {
        count: watchLength,
      })
    }
    return t('post.create.themes.label.empty')
  }, [watchThemes, themesOptions])

  const fetchThemes = async (channelId: string) => {
    if (!channelId || channelId.length === 0) {
      setThemesOptions([])
      return
    }
    const _themes = await getThemesByChannel({ channel_id: channelId })
    const parsThemes: SelectType[] = _themes.map((theme) => ({
      value: theme.id,
      label: theme.name,
      title: theme.name,
    }))
    parsThemes && setThemesOptions(parsThemes)
  }

  const handleBeforeChangeChannel = () => {
    setValue(`channelsArray.${fieldIndex}.themes`, [])
    return true
  }

  useEffect(() => {
    fetchThemes(watchChannel)
  }, [watchChannel])

  return (
    <div className="ChannelsArrayItem row">
      <div className="col-6">
        <FormSelect
          control={control}
          name={`channelsArray.${fieldIndex}.id` as keyof CreatePostType}
          label={t('post.create.channel.label')}
          placeholder={t('post.create.channel.label')}
          options={channelsAvailable}
          required={true}
          disabled={disabledChannels}
          onBeforeChange={handleBeforeChangeChannel}
          isClearable={false}
        />
      </div>
      <div className="col-6">
        <Controller
          control={control}
          name={`channelsArray.${fieldIndex}.themes` as keyof CreatePostType}
          render={({ field, fieldState }) => (
            <MultiSelectTreeControl
              error={fieldState.error}
              name={field.name}
              value={field.value ?? []}
              label={t('post.create.themes.label')}
              options={themesOptions}
              placeholder={t('post.create.themes.label')}
              placeholderInfo={() => placeholderChannels}
              required={true}
              onChange={field.onChange}
              onBlur={field.onBlur}
              disabled={!themesOptions.length}
            />
          )}
        />
      </div>
    </div>
  )
}

export default ChannelsArrayItem
