// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandList{display:flex;flex-direction:column}.BrandList__header{display:flex;justify-content:space-between}.BrandList__list{display:flex;flex-direction:column}.BrandList__list.no-brands{height:100%}`, "",{"version":3,"sources":["webpack://./src/app/pages/Brand/BrandList/styles.scss"],"names":[],"mappings":"AAQA,WACI,YAAA,CACA,qBAAA,CAEA,mBACI,YAAA,CACA,6BAAA,CAGJ,iBACI,YAAA,CACA,qBAAA,CAEA,2BACI,WAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.BrandList {\n    display: flex;\n    flex-direction: column;\n\n    &__header {\n        display: flex;\n        justify-content: space-between;\n    }\n\n    &__list {\n        display: flex;\n        flex-direction: column;\n\n        &.no-brands {\n            height: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BrandList": `BrandList`,
	"BrandList__header": `BrandList__header`,
	"BrandList__list": `BrandList__list`,
	"no-brands": `no-brands`
};
export default ___CSS_LOADER_EXPORT___;
