export interface ConfigurationFooterState {
  isLoaded: boolean
  generalInfo: ConfigurationFooterGeneralState
  contactInfo: ConfigurationFooterContactState
  socialMediaInfo: ConfigurationFooterSocialMediaState
  legalInfo: ConfigurationFooterLegalState
  pageSection: null | ConfigurationFooterPageResponse
}

export interface ConfigurationFooterGeneralState {
  list: null | ConfigurationFooterGeneralListResponse
}

export interface ConfigurationFooterContactState {
  list: null | ConfigurationFooterContactListResponse
}

export interface ConfigurationFooterSocialMediaState {
  detail: null | ConfigurationFooterSocialMediaResponse
}

export interface ConfigurationFooterLegalState {
  list: null | ConfigurationFooterLegalListResponse
}

export interface ConfigurationFooterPage {
  id: string
  title: string
  url: string
  language: string
  footerGeneralInfoId?: string
  footerContactInfoId?: string
  footerLegalInfoId?: string
  translations: string[]
}

export interface ConfigurationFooterPageResponse {
  id: string
  title: string
  url: string
  language: string
  footerGeneralInfoId?: string
  footerContactInfoId?: string
  footerLegalInfoId?: string
  translations: {
    id: string
    title: string
    url: string
    language: string
  }[]
}

export interface ConfigurationFooterGeneralListResponse {
  id: string
  footerId: string
  translations: string[]
  generalInfoTranslations: {
    sectionTitle: string
    language: string
  }[]
  pages: ConfigurationFooterPage[]
}

export interface ConfigurationFooterContactListResponse {
  id: string
  footerId: string
  translations: string[]
  contactInfoTranslations: {
    sectionTitle: string
    language: string
  }[]
  pages: ConfigurationFooterPage[]
}

export interface ConfigurationFooterSocialMediaResponse {
  id: string
  footerId: string
  translations: string[]
  socialMediaTranslations: {
    sectionTitle: string
    linkedinUrl: string
    twitterUrl: string
    youtubeUrl: string
    facebookUrl: string
    instagramUrl: string
    language: string
  }[]
}

export interface ConfigurationFooterLegalListResponse {
  id: string
  footerId: string
  translations: string[]
  pages: ConfigurationFooterPage[]
}

export interface DataTab {
  activeTabParam: DataTabNames
}

export enum DataTabNames {
  GeneralInfo = 'GeneralInfo',
  ContactInfo = 'ContactInfo',
  SocialMediaInfo = 'SocialMediaInfo',
  LegalInfo = 'LegalInfo',
}

export enum UrlTypeFooterPage {
  GeneralInfo = 'general',
  ContactInfo = 'contact',
  LegalInfo = 'legal',
}

export interface ConfigurationFooterGeneralInfoForm {
  sectionTitle: string
  language: string
}

export interface ConfigurationFooterContactInfoForm {
  footerId: string
  sectionTitle: string
  language: string
}

export interface ConfigurationFooterSocialMediaForm {
  sectionTitle: string
  language: string
  linkedinUrl: string
  twitterUrl: string
  youtubeUrl: string
  facebookUrl: string
  instagramUrl: string
}

export interface ConfigurationFooterPageForm {
  footerGeneralInfoId?: string
  footerContactInfoId?: string
  footerLegalInfoId?: string
  title: string
  url: string
  language: string
}
