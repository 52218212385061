import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  ContextMenu,
  isNullOrEmpty,
  getDateWithTimezoneDiff,
} from '@liveconnect/communities-ui'
import {
  FormTextarea,
  FormProvider,
  Loader,
  Avatar,
} from '@liveconnect/components'

import useUi from '../../../../core/ui/useUi'
import usePosts from '../../../../core/posts/usePosts'
import useCulture from '../../../../utils/culture'
import { buildValidationSchema } from './validations'
import { ReactionComponent } from '../Reaction'
import {
  Publisher,
  PublisherTypeEnum,
  PublishersSponsoredResponse,
  Reactions,
  TransLangs,
  TranslatePost,
} from '../../../../core/posts/types'
import {
  calculateShowMore,
  EllapsedPeriod,
  getTextWithEllipsis,
  showRemainingTime,
} from '../utils'
import useNotifications from '../../../../utils/notifications/useNotifications'
import useActivities from '../../../../core/activities/useActivities'
import { TranslateCommentActivity } from '../../../../core/activities/types'
import { useCustomRouter } from '../../../../utils/extractParams'
import { useNavigate } from 'react-router'
import ViewBadges from '../../../../components/ViewBadges'
import ArrowButton from '../../../../components/Buttons/ArrowButton'
import usePermissions from '../../../../core/permissions/usePermissions'

import './styles.scss'
export interface CommentComponentProps {
  takenId: string
  id: string
  publisher: Publisher
  creationDate: string
  message: string
  reaction: Reactions
  isEditable: boolean
  isDeletable: boolean
  publisherId: string
  publisherType: string
  originalLangComment: string
  inActivity?: boolean
  isModerate?: boolean
  isReactionable?: boolean
  channelsIds: string[]
  sponsor: PublishersSponsoredResponse | null
}

export const CommentComponent: FC<CommentComponentProps> = ({
  id,
  creationDate,
  message,
  publisher,
  takenId,
  reaction,
  isEditable,
  isDeletable,
  publisherId,
  publisherType,
  originalLangComment,
  inActivity,
  isReactionable = true,
  channelsIds,
  sponsor,
  isModerate,
}): JSX.Element => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { formatDate } = useCulture()
  const {
    deleteComment,
    updateComment,
    deleteCommentAndDisablePublisher,
    disablePublisher,
    getTranslateComment,
  } = usePosts()
  const {
    deleteCommentActivity,
    updateCommentActivity,
    translateCommentActivity,
    deleteCommentActivivityAndDisablePublisher,
  } = useActivities()
  const {
    verifyModeratorPosts,
    verifyUsersModerator,
    verifyActivitiesEditorTypeUser,
    verifyActivitiesEditorTypeBrand,
    verifyWallEditorComment,
  } = usePermissions()
  const [showEditComment, setShowEditComment] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [showComplete, setShowComplete] = useState<boolean>(false)
  const [showExpand, setShowExpand] = useState<boolean>(false)
  const textContainerRef = useRef<HTMLDivElement>(null)
  const textParentRef = useRef<HTMLDivElement>(null)
  const [showTrans, setShowTrans] = useState<boolean>(false)
  const [textTrans, setTextTrans] = useState<string | undefined>(undefined)
  const { basePath } = useCustomRouter()
  const navigate = useNavigate()

  const methods = useForm<{ message: string }>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: { message },
  })
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = methods

  const formatRemainingTime = (utcDate: Date): string => {
    const remainingTime = showRemainingTime(utcDate)
    switch (remainingTime.periodType) {
      case EllapsedPeriod.NONE:
        return t('post.comment.ellapsed.now')
      case EllapsedPeriod.MINUTES:
        if (remainingTime.period === 1) return t('post.comment.ellapsed.minute')
        return t('post.comment.ellapsed.minutes', {
          minutes: remainingTime.period,
        })
      case EllapsedPeriod.HOURS:
        if (remainingTime.period === 1) return t('post.comment.ellapsed.hour')
        return t('post.comment.ellapsed.hours', { hours: remainingTime.period })
      case EllapsedPeriod.DAYS:
      default:
        return formatDate(remainingTime.period as Date, 'LONG_DATE')
    }
  }

  const handleUpdateComment = async (content: { message: string }) => {
    setIsSubmitting(true)
    setErrorMessage(undefined)
    try {
      if (inActivity) {
        await updateCommentActivity(
          takenId,
          {
            message: content.message,
            id,
          },
          publisherId
        )
      } else {
        await updateComment(takenId, publisherId, publisherType, {
          id,
          message: content.message,
          publisherExternalId: publisherId,
          publisherType,
        })
      }
      setTextTrans(undefined)
      if (showTrans) setShowTrans(false)
      setErrorMessage(undefined)
      setShowEditComment(false)
    } catch (error: unknown) {
      setErrorMessage((error as unknown as { message: string })?.message ?? '')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleDisableUserAndDeleteComment = async () => {
    try {
      setIsSubmitting(true)
      if (inActivity) {
        await deleteCommentActivivityAndDisablePublisher({
          activityId: takenId,
          commentId: id,
          memberId: publisher.externalId,
          publisherId: publisher.id,
        })
      } else {
        await deleteCommentAndDisablePublisher({
          postId: takenId,
          commentId: id,
          memberId: publisher.externalId,
          publisherId: publisher.id,
        })
      }

      notify.success(t('post.comment.actions.disableAndDelete.feedback.ok'))
    } catch (e) {
      notify.error(t('post.comment.actions.disableAndDelete.feedback.ko'))
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleDisableUser = async () => {
    if (!publisher.isEnabled) return
    try {
      setIsSubmitting(true)
      await disablePublisher(publisher.id, false)
      notify.success(t('post.actions.disable.feedback.ok'))
    } catch (e) {
      notify.error(t('post.actions.disable.feedback.ko'))
    } finally {
      setIsSubmitting(false)
    }
  }

  const commentActions = useMemo(() => {
    const actions = []
    if (isEditable)
      actions.push({
        label: t('post.comment.actions.edit'),
        onClick: () => {
          setValue('message', message)
          setShowEditComment(true)
        },
      })
    if (isDeletable)
      actions.push({
        label: t('post.comment.actions.delete'),
        onClick: () => {
          showConfirmation({
            title: t('post.comment.actions.delete'),
            subtitle: t('post.comment.confirmation.delete.subtitle'),
            text: t('post.comment.confirmation.delete.text'),
            confirmText: t('common.delete'),
            cancelText: t('common.cancel'),
            iconName: 'report_problem',
            onConfirm: () => {
              if (inActivity) {
                deleteCommentActivity(takenId, id)
              } else {
                deleteComment(takenId, id)
              }
            },
          })
        },
      })
    if (publisher.type === PublisherTypeEnum.USER) {
      if (!inActivity) {
        actions.push(
          {
            label: t(`post.actions.disable${publisher.isEnabled ? '' : 'd'}`),
            onClick: () => {
              showConfirmation({
                title: t('user.modal.disabled'),
                subtitle: t('user.modal.disabled.question'),
                text: t('user.modal.disabled.text'),
                confirmText: t('common.disabled'),
                cancelText: t('common.cancel'),
                iconName: 'report_problem',
                onConfirm: () => {
                  handleDisableUser()
                },
              })
            },
            disabled: !publisher.isEnabled || isSubmitting,
          },
          {
            label: t('post.comment.actions.disableAndDelete'),
            onClick: () => {
              showConfirmation({
                title: t('common.disabled.delete'),
                subtitle: t(
                  'activity.form.comments.modal.disabled.delete.subtitle'
                ),
                text: t('user.modal.disabled.text'),
                confirmText: t('common.yes'),
                cancelText: t('common.no'),
                iconName: 'report_problem',
                onConfirm: () => {
                  handleDisableUserAndDeleteComment()
                },
              })
            },
            disabled: !publisher.isEnabled || isSubmitting,
          }
        )
      } else {
        if (isModerate) {
          actions.push(
            {
              label: t(`post.actions.disable${publisher.isEnabled ? '' : 'd'}`),
              onClick: () => {
                showConfirmation({
                  title: t('user.modal.disabled'),
                  subtitle: t('user.modal.disabled.question'),
                  text: t('user.modal.disabled.text'),
                  confirmText: t('common.disabled'),
                  cancelText: t('common.cancel'),
                  iconName: 'report_problem',
                  onConfirm: () => {
                    handleDisableUser()
                  },
                })
              },
              disabled: !publisher.isEnabled || isSubmitting,
            },
            {
              label: t('post.comment.actions.disableAndDelete'),
              onClick: () => {
                showConfirmation({
                  title: t('common.disabled.delete'),
                  subtitle: t(
                    'activity.form.comments.modal.disabled.delete.subtitle'
                  ),
                  text: t('user.modal.disabled.text'),
                  confirmText: t('common.yes'),
                  cancelText: t('common.no'),
                  iconName: 'report_problem',
                  onConfirm: () => {
                    handleDisableUserAndDeleteComment()
                  },
                })
              },
              disabled: !publisher.isEnabled || isSubmitting,
            }
          )
        }
      }
    }
    return actions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable, isDeletable, message, publisher, isSubmitting])

  const translateComment = async () => {
    try {
      if (!showTrans && !textTrans) {
        let responseTrans: TranslatePost | undefined | TranslateCommentActivity
        if (inActivity) {
          responseTrans = await translateCommentActivity(
            id,
            takenId,
            TransLangs.ES
          )
        } else {
          responseTrans = await getTranslateComment(id, TransLangs.ES)
        }

        setTextTrans(responseTrans?.translateContent)
      }
      setShowTrans(!showTrans)
    } catch (e) {
      notify.error(t('post.translate.error'))
    }
  }

  const navigateToProfile = () => {
    if (publisher.type === 'User') {
      navigate(`${basePath}/users/${publisher.externalId}`)
    }
  }

  useEffect(() => {
    if (textParentRef.current === null || textContainerRef.current === null)
      return
    const _showExpand = calculateShowMore(
      textParentRef.current,
      textContainerRef.current,
      message
    )

    if (!_showExpand) {
      setShowComplete(false)
    }
    setShowExpand(_showExpand)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textContainerRef.current, textParentRef.current, message])

  useEffect(() => {
    if (!textContainerRef?.current?.firstChild) return
    const textContainer = textContainerRef.current.firstChild
    if (showExpand && !showComplete)
      textContainer.textContent = getTextWithEllipsis(message)
    else textContainer.textContent = message
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, showComplete, showExpand, textContainerRef.current])

  return (
    <div id={id} className="Comment">
      <div className="Comment__publisherImage">
        <Avatar
          name={sponsor ? sponsor.name : publisher.title}
          surname={''}
          imageUrl={sponsor ? sponsor.image : publisher.image}
          size="md"
          alt={sponsor ? sponsor.name : publisher.title}
        />
      </div>
      <div className="Comment__container">
        <div className="Comment__container__body">
          <div className="Comment__container__body__meta">
            <div className="Comment__container__body__meta__publisher">
              <span
                className={`publisherName publisherName--${publisher.type}`}
                onClick={navigateToProfile}
                aria-hidden="true"
              >
                {sponsor ? sponsor.name : publisher.title}
              </span>
              <span className="publisherDescription">
                {sponsor ? sponsor.slogan : publisher.description}
              </span>
              {publisher.badges && publisher.badges.length > 0 && (
                <ViewBadges badges={publisher.badges} />
              )}
              <span className="d-block d-sm-none timePostMobile">
                {formatRemainingTime(new Date(creationDate))}
              </span>
            </div>
            <div
              className="Comment__container__body__meta__date"
              title={formatDate(
                getDateWithTimezoneDiff(
                  new Date(creationDate.replace('Z', ''))
                ),
                'LONG_DATE_TIME'
              )}
            >
              <span className="d-none d-sm-block timePost">
                {formatRemainingTime(new Date(creationDate))}
              </span>
              {publisher.type !== PublisherTypeEnum.TENANT ? (
                <>
                  {publisher.type === PublisherTypeEnum.USER ? (
                    <>
                      {!inActivity ? (
                        <>
                          {verifyUsersModerator(channelsIds) && (
                            <>
                              {commentActions.length > 0 &&
                                !showEditComment && (
                                  <div className="Comment__container__body__meta__actions">
                                    <ContextMenu items={commentActions} />
                                  </div>
                                )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {verifyActivitiesEditorTypeUser(channelsIds) && (
                            <>
                              {commentActions.length > 0 &&
                                !showEditComment && (
                                  <div className="Comment__container__body__meta__actions">
                                    <ContextMenu items={commentActions} />
                                  </div>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {!inActivity ? (
                        <>
                          {verifyWallEditorComment(publisher.externalId) && (
                            <>
                              {commentActions.length > 0 &&
                                !showEditComment && (
                                  <div className="Comment__container__body__meta__actions">
                                    <ContextMenu items={commentActions} />
                                  </div>
                                )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {verifyActivitiesEditorTypeBrand(
                            channelsIds,
                            publisher.externalId
                          ) && (
                            <>
                              {commentActions.length > 0 &&
                                !showEditComment && (
                                  <div className="Comment__container__body__meta__actions">
                                    <ContextMenu items={commentActions} />
                                  </div>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {verifyModeratorPosts() && (
                    <>
                      {commentActions.length > 0 && !showEditComment && (
                        <div className="Comment__container__body__meta__actions">
                          <ContextMenu items={commentActions} />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {!showEditComment && (
            <div
              ref={textParentRef}
              className={classNames('Comment__container__body__content', {
                full: showComplete || showTrans,
              })}
              title={message}
            >
              <div
                ref={textContainerRef}
                className="Comment__container__body__content__textContainer"
              >
                <p>{message}</p>
              </div>
              {showExpand && (
                <div className="containerLink">
                  <ArrowButton
                    onClick={() => setShowComplete(!showComplete)}
                    label={
                      showComplete ? t('post.view_less') : t('post.view_more')
                    }
                    icon={
                      showComplete ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                    }
                  />
                </div>
              )}
              {showTrans && (
                <div className="Comment__container__body__content__textContainer__transText">
                  {textTrans}
                </div>
              )}
              {originalLangComment &&
                originalLangComment.toUpperCase() !==
                  t('localizations.short.es-ES') && (
                  <div className={showTrans ? 'upButtonContainer' : ''}>
                    <ArrowButton
                      onClick={translateComment}
                      label={
                        showTrans
                          ? t('post.close_translate')
                          : t('post.open_tranlate')
                      }
                      icon={
                        showTrans ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                      }
                    />
                  </div>
                )}
            </div>
          )}
          {showEditComment && (
            <div className="Comment__container__body__editor">
              <div className="Comment__container__body__editor__body">
                <FormProvider methods={methods}>
                  <FormTextarea
                    control={control}
                    name="message"
                    label=""
                    rows={2}
                    required={true}
                    placeholder={t('post.comment.create.message.placeholder')}
                  />
                </FormProvider>
              </div>
              <div className="Comment__container__body__editor__actions">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setShowEditComment(false)}
                >
                  {t('common.cancel')}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit(handleUpdateComment)}
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? <Loader /> : t('common.save_changes')}
                </button>
              </div>
              <span
                className={classNames('error', {
                  show: !isNullOrEmpty(errorMessage),
                })}
              >
                {errorMessage}
              </span>
            </div>
          )}
        </div>
        {isReactionable && (
          <div className="Comment__container__reactions">
            <ReactionComponent
              isPost={false}
              takenId={takenId}
              commentId={id}
              disabled={showEditComment}
              publisherId={publisherId}
              publisherType={publisherType}
              {...reaction}
              inActivity={inActivity}
            />
          </div>
        )}
      </div>
    </div>
  )
}
