// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel-theme .lc-form-control-wrapper{margin-bottom:0}.channel-theme svg.icon{cursor:pointer}.channel-theme__trash .icon{margin-top:39px}`, "",{"version":3,"sources":["webpack://./src/app/pages/TopicFamilies/CreateChannels/LinkedThemes/FormLinkedThemes/styles.scss"],"names":[],"mappings":"AASE,wCACE,eAAA,CAEF,wBACE,cAAA,CAGA,4BACE,eAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.channel-theme {\n  .lc-form-control-wrapper {\n    margin-bottom: 0;\n  }\n  svg.icon {\n    cursor: pointer;\n  }\n  &__trash {\n    .icon {\n      margin-top: 39px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"channel-theme": `channel-theme`,
	"lc-form-control-wrapper": `lc-form-control-wrapper`,
	"icon": `icon`,
	"channel-theme__trash": `channel-theme__trash`
};
export default ___CSS_LOADER_EXPORT___;
