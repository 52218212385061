import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import es from '../locales/es.json'
import en from '../locales/en.json'

export const DEFAULT_LANG = 'es-ES'
export const ENGLISH_LANG = 'en-GB'
// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  fallbackLng: 'es',
  resources: {
    es: { translation: es },
    en: { translation: en },
  },
  supportedLngs: ['es', 'en'],
  nonExplicitSupportedLngs: true,
  interpolation: {
    escapeValue: false,
  },
})
