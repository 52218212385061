import { FC } from 'react'
import { ActivityCardProps } from './types'
import useCulture from '../../../utils/culture'
import { Icon } from '@liveconnect/icons'
import useTypes from '../../../utils/activities/useTypes'

import './styles.scss'

const ActivityCard: FC<ActivityCardProps> = ({
  onClick,
  title,
  type,
  id,
  start,
  imageUrl,
  logoImageUrl,
  isSponsored,
}) => {
  const { formatDate } = useCulture()
  const { transformType } = useTypes()

  return (
    <div
      onClick={() => onClick && onClick(id)}
      aria-hidden="true"
      className={!isSponsored ? 'activityCard' : 'activityCard promotedCard'}
    >
      <div className="activityCard__image">
        <div className="image-container">
          <div className="rampant">
            <img className="image" src={imageUrl} alt="" />
            <div className="brand-image">
              <img src={logoImageUrl} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="activityCard__text">
        <p className="date">
          <Icon name="events" />
          {`${formatDate(new Date(start), 'LONG_DATE')}`}
        </p>
        <p className="title">{title}</p>
        <span className="type">{transformType(type)}</span>
      </div>
    </div>
  )
}

export default ActivityCard
