import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormCheckbox,
  FormRichText,
  FormSelect,
  FormTextInput,
  FormToggle,
  Loader,
  FormProvider,
  Tooltip,
  FormDateTime,
  Option,
  MultiSelectTreeControl,
} from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import timezones from 'timezones-list'
import { zonedTimeToUtc } from 'date-fns-tz'

import InfoBlock from '../../../components/InfoBlock'
import { Main } from '../../../components/Main'
import {
  FormPostCreateFields,
  PostStateEnum,
  PublisherTypeEnum,
  PublishersResponse,
  SponsoredPostUpdateParams,
} from '../../../core/posts/types'
import { FetchError } from '../../../utils/fetch/types'
import useUi from '../../../core/ui/useUi'
import usePosts from '../../../core/posts/usePosts'
import { buildValidationSchema } from './validation'
import useNotifications from '../../../utils/notifications/useNotifications'
import FormActions from '../../../components/forms/FormActions'
import { SimpleUploadControl } from '../../../components/forms/SimpleUploadControl'
import VideoPlayer from '../../../components/VideoPlayer'
import VideoUpload from './VideoUpload'
import usePermissions from '../../../core/permissions/usePermissions'

import './styles.scss'
import { characterCounterText } from '../../../utils/characterCounterText'
import { useCustomRouter } from '../../../utils/extractParams'

const PostSponsoredEdit: FC = () => {
  const { t } = useTranslation()
  const {
    uploadPostImage,
    updateSponsoredPost,
    fetchPost,
    post,
    getPublishers,
  } = usePosts()
  const { verifyModeratorWall } = usePermissions()
  const { postId } = useParams()
  const navigate = useNavigate()
  const { showConfirmation } = useUi()
  const notify = useNotifications()
  const { basePath } = useCustomRouter()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [showImageUploadModal, setShowImageUploadModal] =
    useState<boolean>(false)

  const [showImageSponsorUploadModal, setShowImageSponsorUploadModal] =
    useState<boolean>(false)

  const [showVideoUploadModal, setShowVideoUploadModal] =
    useState<boolean>(false)
  const [imagePath, setimagePath] = useState<string>('')
  const [imagePathSponsor, setimagePathSponsor] = useState<string>('')
  const [videoURL, setVideoURL] = useState<string>('')
  const [isVideoValid, setIsVideoValid] = useState<boolean>(true)
  const [timezoneList, setTimezoneList] = useState<
    { label: string; value: string }[]
  >([])
  const [publisherOptions, setPublisherOptions] = useState<Option<string>[]>([])
  const [publishers, setPublishers] = useState<PublishersResponse[]>([])

  const methods = useForm<FormPostCreateFields>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      isSponsored: true,
      channelsArray: [{ id: '', themes: [] }],
    },
  })

  const {
    control,
    reset,
    watch,
    setError,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = methods

  const watchOption = watch('option')
  const watchSponsored = watch('isSponsored')

  const watchChannelsIds = watch('channelsIds')

  const channelOptions = useMemo(() => {
    if (post) {
      const _publisher = publishers.find(
        (item) => item.id === post.publisher.externalId
      )
      if (_publisher) {
        return _publisher.channels.map((item) => {
          return {
            label: item.name,
            value: item.id,
            title: item.name,
          }
        })
      }
    }
    return []
  }, [post, publishers])

  const placeholderChannels = useMemo(() => {
    const watchLength = watchChannelsIds ? watchChannelsIds.length : 0
    if (watchLength === channelOptions.length) {
      return t('post.header.select.channels.all')
    }
    if (watchLength > 0) {
      return t('post.header.select.channels', {
        count: watchLength,
      })
    }
    return t('post.header.select.channels.empty')
  }, [watchChannelsIds, channelOptions])

  const fetchTZ = async () => {
    const tzs = (await timezones).map((item) => {
      return {
        label: item.label,
        value: item.tzCode,
      }
    })
    tzs && setTimezoneList(tzs)
  }

  const handleSetImage = (image: string) => {
    setimagePath(image)
    setShowImageUploadModal(false)
    handleRemoveVideo()
  }

  const handleSetImageSponsor = (image: string) => {
    setimagePathSponsor(image)
    setShowImageSponsorUploadModal(false)
  }

  const handleRemoveImageSponsor = () => {
    setimagePathSponsor('')
    setValue('sponsoredImagePath', '')
  }

  const handleRemoveImage = () => {
    setimagePath('')
    setValue('imagePath', '')
  }

  const handleRemoveVideo = () => {
    setVideoURL('')
    setValue('video', '')
    setIsVideoValid(true)
  }

  const handleSetVideo = (videoUrl: string) => {
    setValue('video', videoUrl)
    setVideoURL(videoUrl)
    setShowVideoUploadModal(false)
    handleRemoveImage()
  }

  const handleError = () => {
    return showConfirmation({
      title: t('common.warning'),
      subtitle: t('post.create.sponsored.error'),
      text: t('post.create.sponsored.error.text'),
      confirmText: t('common.accept'),
      iconName: 'report_problem',
      onConfirm: () => {
        setError('sponsoredDateUnpin', {
          type: 'custom',
          message: t(`post.create.sponsored.error`),
        })
        setError('sponsoredDateHide', {
          type: 'custom',
          message: t(`post.create.sponsored.error`),
        })
      },
    })
  }

  const onSubmit = async (
    values: FormPostCreateFields,
    stateParam: PostStateEnum
  ) => {
    setIsSubmitting(true)
    const dataSend: SponsoredPostUpdateParams = {
      title: values.title,
      message: values.message,
      image: values.imagePath || '',
      video: values.video || '',
      publisherType: PublisherTypeEnum.BRAND,
      publisherExternalId: values.publisher,
      isHighlighted: values.isHighlighted,
      state: stateParam,
      publishDateTime: null,
      timeZone: values.timezone || '',
      themesIds: [],
      isSponsored: values.isSponsored,
      sponsorName: values.sponsoredTitle,
      sponsorURL: values.sponsoredUrl,
      sponsorSlogan: values.sponsoredSlogan,
      sponsorImage: values.sponsoredImagePath,
      hidingDate: values.sponsoredDateHide || null,
      unpinnedDate: values.sponsoredDateUnpin || null,
    }

    if (values.date && values.timezone) {
      const newDate = new Date(values.date)
      const utcDate = zonedTimeToUtc(newDate, values.timezone)
      dataSend.publishDateTime = utcDate.toISOString()
    }
    /* DATE SPONSORED */
    if (
      values.isSponsored &&
      values.sponsoredDateUnpin &&
      values.sponsoredDateHide &&
      values.timezone
    ) {
      const utcDateUnpinned = zonedTimeToUtc(
        new Date(values.sponsoredDateUnpin),
        values.timezone
      )
      dataSend.unpinnedDate = utcDateUnpinned.toISOString()
      const utcDateHide = zonedTimeToUtc(
        new Date(values.sponsoredDateHide),
        values.timezone
      )
      dataSend.hidingDate = utcDateHide.toISOString()
    }

    try {
      if (imagePath && imagePath.startsWith('blob')) {
        const image = await uploadPostImage(imagePath)
        if (image) {
          dataSend.image = image.uri
        }
      }
      /* UPLOAD IMAGE SPONSORED */
      if (
        values.isSponsored &&
        imagePathSponsor &&
        imagePathSponsor.startsWith('blob')
      ) {
        const image = await uploadPostImage(imagePathSponsor)
        if (image) {
          dataSend.sponsorImage = image.uri
        }
      }
      /*END UPLOAD IMAGE SPONSORED */
      await updateSponsoredPost(postId!, dataSend)
      notify.success(t('post.edit.succeessFeedback.sponsored'))
      navigate(-1)
    } catch (error: unknown) {
      const fetchError = error as FetchError
      if (fetchError.status === 409) {
        handleError()
      } else {
        notify.error(t('post.edit.unknownError.sponsored'))
      }
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    if (watchSponsored) {
      setValue('option', true)
    }
  }, [watchSponsored])

  useEffect(() => {
    const shouldBlurActiveElement =
      !showVideoUploadModal ||
      !showImageUploadModal ||
      !showImageSponsorUploadModal
    if (shouldBlurActiveElement) (document.activeElement as HTMLElement).blur()
  }, [showVideoUploadModal, showImageUploadModal, showImageSponsorUploadModal])

  useEffect(() => {
    if (post) {
      reset({
        publisher: post.publisher.externalId,
        channelsIds: post.channels.map((channel) => channel.id),
        isHighlighted: post.isHighlighted,
        isSponsored: post.isSponsored,
        channelsArray: post.channels,
        sponsoredTitle: post.sponsor.name,
        sponsoredUrl: post.sponsor.url,
        sponsoredSlogan: post.sponsor.slogan,
        sponsoredImagePath: post.sponsor.image,
        sponsoredChannels: post.channels.map((channel) => channel.id),
        sponsoredDateUnpin: post.unpinnedDate,
        sponsoredDateHide: post.hidingDate,
        title: post.title,
        message: post.message,
        imagePath: post.image,
        video: post.video,
        option: [PostStateEnum.SCHEDULED, PostStateEnum.PUBLISHED].includes(
          post.state as PostStateEnum
        ),
        timezone: post.timeZone,
        state: post.state as PostStateEnum,
        date:
          post.state === PostStateEnum.PUBLISHED
            ? post.publishedAt.substring(0, post.publishedAt.length - 5)
            : post.publishDateTime,
      })
      setPublisherOptions([
        { label: post.publisher.title, value: post.publisher.externalId },
      ])
      setimagePathSponsor(post.sponsor.image)
    }
  }, [post])

  const fetchPublishers = async () => {
    try {
      const _publishers = await getPublishers()
      if (_publishers) {
        setPublishers(_publishers)
      }
    } catch (error) {
      notify.error(t('common.unknownError'))
    }
  }

  useEffect(() => {
    fetchTZ()
  }, [])

  useEffect(() => {
    if (postId) {
      fetchPost(postId)
      fetchPublishers()
    }
  }, [postId])

  useEffect(() => {
    if (!verifyModeratorWall()) {
      navigate(`${basePath}/access-denied`)
    }
  }, [])

  useEffect(() => {
    if (post?.state !== PostStateEnum.PUBLISHED) {
      if (watchOption) {
        setValue('state', PostStateEnum.SCHEDULED)
      } else {
        setValue('state', PostStateEnum.DRAFTED)
      }
    }
  }, [watchOption])

  return (
    <Main ariaLabelledby="postSponsoredEdit" className="PostCreate">
      <h1
        id="post-create-title"
        className="h3"
        data-testid="PostSponsoredEdit__title"
      >
        {t('post.edit.title')}
      </h1>
      <div className="row">
        <div className="col">
          <InfoBlock
            className="mb-0"
            label={t('post.create.info')}
            data-testid="PostSponsoredEdit__infoBlock"
          />
        </div>
      </div>
      <div className="PostSponsoredEdit__form">
        <FormProvider methods={methods}>
          <div className="row mbInput">
            <div className="col-7">
              <FormSelect
                control={control}
                name="publisher"
                label={t('post.header.select.publisher')}
                placeholder={t('post.header.select.publisher')}
                options={publisherOptions}
                required
                disabled
                noOptionsMessage={t('select.empty')}
                data-testid="PostSponsoredEdit__publisher"
              />
            </div>
          </div>
          <div className="row mbInput">
            <div className="PostSponsoredEdit__form__toggle col-3">
              <FormToggle
                label={t('post.create.step3.title')}
                control={control}
                name="isHighlighted"
                data-testid="PostSponsoredEdit__highlighted"
              />
            </div>
            <div className="PostSponsoredEdit__form__toggle col-3">
              <FormToggle
                label={t('post.create.sponsored')}
                control={control}
                name="isSponsored"
                data-testid="PostSponsoredEdit__isSponsored"
                disabled={true}
              />
            </div>
          </div>
          <div className="sponsoredSection">
            <p className="sponsoredSection__title">
              {t('post.create.sponsored.title')}
            </p>
            <div className="row">
              <div className="col-4 mbInput">
                <FormTextInput
                  control={control}
                  name="sponsoredTitle"
                  label={t('post.create.sponsored.name')}
                  placeholder={t('post.create.sponsored.name.placeholder')}
                  type="text"
                  required={true}
                  data-testid="PostSponsoredEdit__sponsoredTitle"
                />
              </div>
              <div className="col-4 mbInput">
                <FormTextInput
                  control={control}
                  name="sponsoredUrl"
                  label={t('post.create.sponsored.url')}
                  placeholder={t('post.create.sponsored.url.placeholder')}
                  type="text"
                  required={true}
                  data-testid="PostSponsoredEdit__sponsoredUrl"
                />
              </div>
              <div className="col-8 mbInput sponsoredSection__slogan">
                <FormTextInput
                  control={control}
                  name="sponsoredSlogan"
                  label={t('post.create.sponsored.slogan')}
                  placeholder={t('post.create.sponsored.slogan.placeholder')}
                  type="text"
                  required={true}
                  data-testid="PostSponsoredEdit__sponsoredSlogan"
                />
                <span className="max">
                  {t('post.create.max', {
                    numberCharac: 60,
                  })}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p className="sponsoredSection__titleLogo">
                  {t('post.create.sponsored.logo')} <span>*</span>
                </p>
              </div>
              <div className="col-12 mbInput">
                <div className="uploader-container">
                  <div className="uploader-container__components">
                    <div className="imageUploader">
                      <SimpleUploadControl
                        control={control}
                        title={t('post.modal.image')}
                        label={t('common.addImage')}
                        name="sponsoredImagePath"
                        value={imagePathSponsor}
                        height={300}
                        width={300}
                        onChange={handleSetImageSponsor}
                        onRemoveFile={handleRemoveImageSponsor}
                        isOpen={showImageSponsorUploadModal}
                        handleOpen={() => setShowImageSponsorUploadModal(false)}
                      />
                    </div>
                  </div>
                  <div className="uploader-container__buttons">
                    <span className="fw-bold d-none d-lg-inline" tabIndex={-1}>
                      <Tooltip
                        content={t('post.imageUpload.title')}
                        className={
                          imagePathSponsor
                            ? 'toolActions withVideo'
                            : 'toolActions'
                        }
                        position="right"
                      >
                        <Icon
                          name="image"
                          onClick={() => setShowImageSponsorUploadModal(true)}
                          tabIndex={0}
                          data-testid="PostSponsoredEdit__sponsoredUploader"
                        />
                      </Tooltip>
                    </span>
                    <span className="fw-bold d-inline d-lg-none">
                      <Icon
                        name="image"
                        onClick={() => setShowImageSponsorUploadModal(true)}
                      />
                      <Icon
                        name="image"
                        onClick={() => setShowImageSponsorUploadModal(true)}
                        tabIndex={0}
                        data-testid="PostSponsoredEdit__sponsoredUploader_mobile"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-6 mbInput">
                <Controller
                  control={control}
                  name="sponsoredChannels"
                  render={({ field, fieldState }) => (
                    <MultiSelectTreeControl
                      error={fieldState.error}
                      name={field.name}
                      value={field.value ?? []}
                      label={t('post.create.channel.label')}
                      options={channelOptions}
                      placeholder={t('post.create.channel.label')}
                      placeholderInfo={() => placeholderChannels}
                      required={true}
                      disabled
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      data-testid="PostSponsoredEdit__sponsoredChannels"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row mbInput">
            <div className="col-9">
              <FormTextInput
                control={control}
                name="title"
                label={t('post.create.title.label')}
                placeholder={t('post.create.title.placeholder')}
                type="text"
                required={true}
                data-testid="PostSponsoredEdit__titleForm"
                helperText={t('validations.leyendField.max', {
                  number: 200,
                })}
              />
            </div>
          </div>
          <div className="row mbInput">
            <div className="col-12">
              <FormRichText
                label={t('post.create.message.label')}
                control={control}
                name="message"
                placeholder={t('post.modal.text')}
                required
                data-testid="PostSponsoredEdit__message"
                helperText={characterCounterText(
                  watch('message') ?? '',
                  5000,
                  t
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <InfoBlock
                label={t('post.create.remember')}
                data-testid="PostSponsoredEdit__infoRemember"
              />
            </div>
          </div>
          <div className="row mbInput">
            <div className="col-12">
              <div className="uploader-container">
                <div className="uploader-container__components">
                  <div className="imageUploader">
                    <SimpleUploadControl
                      control={control}
                      title={t('post.modal.image')}
                      label={t('common.addImage')}
                      name="imagePath"
                      value={imagePath}
                      onChange={handleSetImage}
                      onRemoveFile={handleRemoveImage}
                      isOpen={showImageUploadModal}
                      handleOpen={() => setShowImageUploadModal(false)}
                    />
                  </div>
                  <div className="videoUploader">
                    {videoURL && (
                      <>
                        <div className="videoUploader__player">
                          <VideoPlayer
                            url={videoURL}
                            onError={() => setIsVideoValid(false)}
                            onSuccess={() => setIsVideoValid(true)}
                          />
                          {!isVideoValid && (
                            <div className="videoUploader__error">
                              <Icon name="report_problem" />
                              {t('post.videoUpload.error.message')}
                            </div>
                          )}
                        </div>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <div
                          className="videoUploader__delete"
                          onClick={handleRemoveVideo}
                        >
                          <div className="videoUploader__delete__inner">
                            <Icon
                              name="delete_outline"
                              data-testid="PostSponsoredEdit__deleteVideo"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="uploader-container__buttons">
                  <span className="fw-bold d-none d-lg-inline" tabIndex={-1}>
                    <Tooltip
                      content={t('post.imageUpload.title')}
                      className={
                        imagePath || videoURL
                          ? 'toolActions withVideo'
                          : 'toolActions'
                      }
                      position="right"
                    >
                      <Icon
                        name="image"
                        onClick={() => setShowImageUploadModal(true)}
                        tabIndex={0}
                        data-testid="PostSponsoredEdit__imageUpload"
                      />
                    </Tooltip>
                    <Tooltip
                      content={t('post.videoUpload.title')}
                      className={
                        videoURL || imagePath
                          ? 'toolActions withVideo'
                          : 'toolActions'
                      }
                      position="right"
                    >
                      <Icon
                        name="video_link"
                        className="CreatePost__video-icon"
                        onClick={() => setShowVideoUploadModal(true)}
                        tabIndex={0}
                        data-testid="PostSponsoredEdit__videoUpload"
                      />
                    </Tooltip>
                  </span>
                  <span className="fw-bold d-inline d-lg-none">
                    <Icon
                      name="image"
                      onClick={() => setShowImageUploadModal(true)}
                      data-testid="PostSponsoredEdit__imageUpload_mobile"
                    />
                    <Icon
                      name="video_link"
                      className="CreatePost__video-icon"
                      onClick={() => setShowVideoUploadModal(true)}
                      data-testid="PostSponsoredEdit__videoUpload_mobile"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mbInput">
            <div className="col-12 contentCheck">
              <FormCheckbox
                control={control}
                name="option"
                label={t('post.program.timezone')}
                data-testid="PostSponsoredEdit__option"
                disabled={
                  post !== null && PostStateEnum.PUBLISHED === post.state
                }
              />
            </div>
          </div>
          {watchOption && (
            <>
              <div className="row mbInput">
                <div className="col-4">
                  <FormSelect
                    control={control}
                    name="timezone"
                    label={t('post.timezone')}
                    placeholder={t('post.select.timezone')}
                    options={timezoneList}
                    required={true}
                    noOptionsMessage={t('select.empty')}
                    isClearable={false}
                    disabled={
                      post !== null && PostStateEnum.PUBLISHED === post.state
                    }
                    data-testid="PostSponsoredEdit__timezone"
                  />
                </div>
                <div className="col-4">
                  <FormDateTime
                    control={control}
                    name="date"
                    label={t('post.date.timezone')}
                    required
                    disabled={
                      post !== null && PostStateEnum.PUBLISHED === post.state
                    }
                    data-testid="PostSponsoredEdit__date"
                  />
                </div>
              </div>
              {watchSponsored && (
                <div className="row mbInput">
                  <div className="col-4">
                    <FormDateTime
                      control={control}
                      name="sponsoredDateUnpin"
                      label={t('post.create.sponsored.date.unpin')}
                      tooltip={t('post.create.sponsored.date.unpin.tooltip')}
                      required
                      data-testid="PostSponsoredEdit__sponsoredDateUnpin"
                    />
                  </div>
                  <div className="col-4">
                    <FormDateTime
                      control={control}
                      name="sponsoredDateHide"
                      label={t('post.create.sponsored.date.hide')}
                      tooltip={t('post.create.sponsored.date.hide.tooltip')}
                      required
                      data-testid="PostSponsoredEdit__sponsoredDateHide"
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <FormActions
            languageSelector={
              <button
                className="rightButton"
                onClick={handleSubmit((data) =>
                  onSubmit(data, PostStateEnum.DRAFTED)
                )}
                disabled={
                  !isValid ||
                  isSubmitting ||
                  watchOption ||
                  (watchSponsored && !imagePathSponsor)
                }
                data-testid="PostSponsoredEdit__drafted"
              >
                <p>{t('post.save.drafted')}</p>
                <Icon name="chevron_right" />
              </button>
            }
          >
            <button
              className="btn btn-outline-primary"
              onClick={() => navigate(-1)}
            >
              {t('common.cancel')}
            </button>
            {!watchOption ? (
              <button
                className="btn btn-primary"
                onClick={handleSubmit((data) =>
                  onSubmit(data, PostStateEnum.PUBLISHED)
                )}
                disabled={
                  !isValid || isSubmitting || !isVideoValid || watchSponsored
                }
                data-testid="PostSponsoredEdit__published"
              >
                {isSubmitting ? (
                  <Loader />
                ) : post?.state === PostStateEnum.PUBLISHED ? (
                  t('common.save')
                ) : (
                  t('post.save.timezone')
                )}
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={handleSubmit((data) =>
                  onSubmit(
                    data,
                    post?.state === PostStateEnum.PUBLISHED
                      ? PostStateEnum.PUBLISHED
                      : PostStateEnum.SCHEDULED
                  )
                )}
                disabled={
                  !isValid ||
                  isSubmitting ||
                  !isVideoValid ||
                  (watchSponsored && !imagePathSponsor)
                }
                data-testid="PostSponsoredEdit__scheduled"
              >
                {isSubmitting ? (
                  <Loader />
                ) : post?.state === PostStateEnum.PUBLISHED ? (
                  t('common.save')
                ) : (
                  t('post.save.timezone')
                )}
              </button>
            )}
          </FormActions>
        </FormProvider>
      </div>
      {showVideoUploadModal && (
        <VideoUpload
          onClose={() => setShowVideoUploadModal(false)}
          onSetVideo={handleSetVideo}
          data-testid="PostSponsoredEdit__videoUploadModal"
        />
      )}
    </Main>
  )
}

export default PostSponsoredEdit
