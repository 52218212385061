import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { useNavigate } from 'react-router'
import {
  UsePermissionsResult,
  PermissionListResponse,
  UserItem,
  PermissionsCommunity,
  PermissionsChannel,
  PermissionsDetailForm,
  PermissionsOptionBrandSpaces,
  Permission,
} from './types'
import {
  hideLoaderUsers,
  setCommunityPermissions,
  setList,
  setUserDetail,
  setUserList,
  showLoaderUsers,
} from './store'
import useFetch from '../../utils/fetch/useFetch'
import { useCustomRouter } from '../../utils/extractParams'

const usePermissions = (): UsePermissionsResult => {
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const dispatch = useAppDispatch()
  const {
    list,
    isLoaded,
    userList,
    userDetail,
    isLoadedUsers,
    communityPermissions,
  } = useAppSelector((state) => state.permissions)
  const dispatchLoaders = async function dispatchLoaders<T>(
    asyncCall: () => Promise<T>
  ) {
    dispatch(showLoaderUsers())
    try {
      return await asyncCall()
    } finally {
      dispatch(hideLoaderUsers())
    }
  }
  const { get, put } = useFetch()

  const endpoint = 'permissions'

  const updatePermissions = async (id: string, data: PermissionsDetailForm) => {
    await dispatchLoaders(async () => {
      const response = await put<PermissionsDetailForm>({
        endpoint: `${endpoint}/editors/${id}`,
        body: data,
      })
      if (response) {
        dispatch(setUserDetail(response))
      }
    })
  }

  const getBrandSpaces = async (): Promise<
    PermissionsOptionBrandSpaces[] | undefined
  > => {
    const response: PermissionsOptionBrandSpaces[] | undefined = await get({
      endpoint: `${endpoint}/brand-spaces`,
    })
    return response
  }

  const getList = async () => {
    const response: PermissionListResponse | undefined = await get({
      endpoint,
    })
    return response
  }

  const fetchList = async () => {
    await dispatchLoaders(async () => {
      const response: PermissionListResponse | undefined = await getList()
      if (!response?.hasProfile) navigate(`${basePath}/access-denied`)
      if (response) {
        dispatch(setList({ list: response.actions }))
      }
    })
  }

  const getUsersList = async () => {
    const response: UserItem[] | undefined = await get({
      endpoint: `${endpoint}/editors`,
    })
    return response
  }

  const fetchUsersList = async () => {
    await dispatchLoaders(async () => {
      const response: UserItem[] | undefined = await getUsersList()
      response && dispatch(setUserList(response))
    })
  }

  const fetchUserDetail = async (id: string) => {
    await dispatchLoaders(async () => {
      const response: PermissionsDetailForm | undefined = await get({
        endpoint: `${endpoint}/editors/${id}`,
      })
      response && dispatch(setUserDetail(response))
    })
  }
  const fetchCommunityPermissions = async () => {
    await dispatchLoaders(async () => {
      const response: PermissionsCommunity | undefined = await get({
        endpoint: `${endpoint}/community-permissions`,
      })
      if (response) dispatch(setCommunityPermissions(response))
    })
  }
  /* verifyPermissionsMaster = Si el user es de tipo FullAccess */
  const verifyPermissionsMaster = () => {
    if (list.includes(Permission.FullAccess)) return true
    return false
  }
  /* verifyModeratorPosts = Si tienes permisos sobre moderatorPosts, lo usa lo usa PostContainer y CommentComponent*/
  const verifyModeratorPosts = () => {
    if (verifyPermissionsMaster() || communityPermissions?.moderatorPosts) {
      return true
    }
    return false
  }
  const haveAnyWallEditor = () => {
    if (
      communityPermissions &&
      communityPermissions.brandSpacePermission.length > 0
    ) {
      return communityPermissions.brandSpacePermission.some((obj) =>
        obj.channels.some((canal) => canal.permissions.wallEditor)
      )
    }
    return false
  }
  const verifyModeratorWall = () => {
    if (
      verifyPermissionsMaster() ||
      communityPermissions?.moderatorPosts ||
      haveAnyWallEditor()
    ) {
      return true
    }
    return false
  }
  /* verifyEditorSectorsChannelsThemes = Si tienes permisos sobre editorSectorsChannelsThemes, lo usa SectorsRouter y Navbar */
  const verifyEditorSectorsChannelsThemes = () => {
    if (
      verifyPermissionsMaster() ||
      communityPermissions?.editorSectorsChannelsThemes
    ) {
      return true
    }
    return false
  }
  /* verifyEditorSpaces = Si tienes permisos sobre editorSpaces, lo usa BrandEditScreen y BrandListScreen */
  const verifyEditorSpaces = () => {
    if (verifyPermissionsMaster() || communityPermissions?.editorSpaces) {
      return true
    }
    return false
  }
  const haveSomeBrands = (idBrand: string) => {
    return communityPermissions?.brandSpacePermission.some(
      (obj) => obj.id === idBrand
    )
  }
  /* verifySomeBrands = Mandamos un brandSpace y comprobamos si existe ese id dentro de brandSpacePermission, lo usa BrandEditScreen */
  const verifySomeBrands = (idBrand: string) => {
    if (verifyPermissionsMaster() || haveSomeBrands(idBrand)) {
      return true
    }
    return false
  }
  /* verifyModeratorDirectories = Si tienes permisos para crear/editar directorios de tipo Menu, lo usa EditDirectory y  CreateDirectory */
  const verifyModeratorDirectories = () => {
    if (
      verifyPermissionsMaster() ||
      communityPermissions?.moderatorDirectories
    ) {
      return true
    }
    return false
  }
  /* verifySomePermissionsDirectories = Si existe algún tipo de permiso sobre directorios, lo usa DirectoriesList */
  const verifySomePermissionsDirectories = () => {
    if (
      verifyPermissionsMaster() ||
      communityPermissions?.moderatorDirectories ||
      communityPermissions?.brandSpacePermission.some(
        (obj) => obj.permissions.directoriesEditor
      ) ||
      communityPermissions?.brandSpacePermission.some((obj) =>
        obj.channels.some(
          (channel) => channel.permissions.directoriesEditorChannel
        )
      )
    ) {
      return true
    }
    return false
  }

  const haveBrandPermissionsDirectories = (idBrand: string) => {
    return communityPermissions?.brandSpacePermission.some(
      (obj) => obj.id === idBrand && obj.permissions.directoriesEditor
    )
  }
  /* verifyBrandPermissionsDirectories = Si la brandSpace que mandamos tiene permisos sobre directorios, lo usa EditDirectory */
  const verifyBrandPermissionsDirectories = (idBrand: string) => {
    if (verifyPermissionsMaster() || haveBrandPermissionsDirectories(idBrand)) {
      return true
    }
    return false
  }

  const someBrandPermissionsDirectories = () => {
    if (
      communityPermissions &&
      communityPermissions.brandSpacePermission.length > 0
    ) {
      return communityPermissions.brandSpacePermission.some(
        (obj) => obj.permissions.directoriesEditor
      )
    }
    return false
  }
  /* verifyBrandPermissionsDirectories = Si alguna brandSpace tiene permisos sobre directorios, lo usa CreateDirectory */
  const verifySomeBrandPermissionsDirectories = () => {
    if (verifyPermissionsMaster() || someBrandPermissionsDirectories()) {
      return true
    }
    return false
  }

  const haveSomeChannelPermissionsDirectories = () => {
    return communityPermissions?.brandSpacePermission.some((obj) =>
      obj.channels.some(
        (channel) => channel.permissions.directoriesEditorChannel
      )
    )
  }
  /* verifySomeChannelPermissionsDirectories = Si alguno de los canales tiene permisos sobre directorios, lo usa CreateDirectory */
  const verifySomeChannelPermissionsDirectories = () => {
    if (verifyPermissionsMaster() || haveSomeChannelPermissionsDirectories()) {
      return true
    }
    return false
  }

  const haveChannelsPermissionsDirectories = (idChannels: string[]) => {
    return communityPermissions?.brandSpacePermission.some((obj) => {
      return obj.channels.some((channel) => {
        return (
          idChannels.includes(channel.id) &&
          channel.permissions.directoriesEditorChannel
        )
      })
    })
  }
  /* verifyChannelsPermissionsDirectories = Si alguno de los canales que enviamos en un array tiene permisos sobre directorios, lo usa EditDirectory */
  const verifyChannelsPermissionsDirectories = (idChannels: string[]) => {
    if (
      verifyPermissionsMaster() ||
      haveChannelsPermissionsDirectories(idChannels)
    ) {
      return true
    }
    return false
  }

  const haveWallEditorPermission = (id: string, idChannels?: string[]) => {
    const elementExists = communityPermissions?.brandSpacePermission.filter(
      (element) => element.id === id
    )
    if (elementExists && elementExists.length > 0) {
      return elementExists[0].channels.some((channel: PermissionsChannel) => {
        if (idChannels) {
          return (
            idChannels.includes(channel.id) && channel.permissions.wallEditor
          )
        }
        return channel.permissions.wallEditor
      })
    }
    return false
  }
  /* verifyWallEditor = Si el externalId que enviamos tiene canal de los que enviamos con permisos sobre wallEditor, lo usa PostContainer y CommentComponent */
  const verifyWallEditor = (idChannels: string[], externalId: string) => {
    if (
      verifyPermissionsMaster() ||
      haveWallEditorPermission(externalId, idChannels)
    ) {
      return true
    }
    return false
  }
  /* verifyWallEditorComment = Si el externalId que enviamos tiene algún canal con permisos sobre wallEditor, lo usa CommentComponent */
  const verifyWallEditorComment = (externalId: string) => {
    if (verifyPermissionsMaster() || haveWallEditorPermission(externalId)) {
      return true
    }
    return false
  }
  /* verifyUsersModerator = Si alguno de los canales que enviamos tiene permisos sobre usuarios, lo usa PostContainer, CommentComponent y ProfileScreen */
  const verifyUsersModerator = (idChannels: string[]) => {
    if (
      verifyPermissionsMaster() ||
      communityPermissions?.brandSpacePermission.some((object) => {
        return object.channels.some((channel: PermissionsChannel) => {
          return (
            idChannels.includes(channel.id) &&
            channel.permissions.usersModerator
          )
        })
      })
    ) {
      return true
    }
    return false
  }
  /* verifySomeActivitiesEditor = Si alguno de los canales tiene permisos sobre activitiesEditor, se usa en ActivitiesListScreen */
  const verifySomeActivitiesEditor = () => {
    if (
      verifyPermissionsMaster() ||
      communityPermissions?.brandSpacePermission.some((object) => {
        return object.channels.some((channel: PermissionsChannel) => {
          return channel.permissions.activitiesEditor
        })
      })
    ) {
      return true
    }
    return false
  }

  /* verifyActivitiesEditorTypeUser = Si alguno de los canales que enviamos en un array tiene permisos sobre actividades, lo usa el componente Comment de tipo User */

  const verifyActivitiesEditorTypeUser = (idChannels: string[]) => {
    if (
      verifyPermissionsMaster() ||
      communityPermissions?.brandSpacePermission.some((object) => {
        return object.channels.some((channel: PermissionsChannel) => {
          return (
            idChannels.includes(channel.id) &&
            channel.permissions.activitiesEditor
          )
        })
      })
    ) {
      return true
    }
    return false
  }

  const haveActivitiesEditorBrandPermissions = (
    idChannels: string[],
    id: string
  ) => {
    const elementExists = communityPermissions?.brandSpacePermission.filter(
      (element) => element.id === id
    )
    if (elementExists && elementExists.length > 0) {
      return elementExists[0].channels.some((channel: PermissionsChannel) => {
        return (
          idChannels.includes(channel.id) &&
          channel.permissions.activitiesEditor
        )
      })
    }
    return false
  }

  /* verifyActivitiesEditorTypeBrand = Si existe el externalId y alguno de los canales que enviamos en un array tiene permisos sobre actividades, lo usa el componente Comment de tipo Brand */

  const verifyActivitiesEditorTypeBrand = (
    idChannels: string[],
    externalId: string
  ) => {
    if (
      verifyPermissionsMaster() ||
      haveActivitiesEditorBrandPermissions(idChannels, externalId)
    ) {
      return true
    }
    return false
  }

  return {
    list,
    userList,
    userDetail,
    isLoaded,
    isLoadedUsers,
    communityPermissions,
    updatePermissions,
    fetchList,
    fetchUsersList,
    fetchUserDetail,
    verifyPermissionsMaster,
    verifyModeratorPosts,
    verifyModeratorWall,
    verifyEditorSectorsChannelsThemes,
    verifyEditorSpaces,
    verifySomeBrands,
    verifyModeratorDirectories,
    verifySomePermissionsDirectories,
    verifyBrandPermissionsDirectories,
    verifySomeBrandPermissionsDirectories,
    verifySomeChannelPermissionsDirectories,
    verifyChannelsPermissionsDirectories,
    verifyWallEditor,
    verifyWallEditorComment,
    verifyUsersModerator,
    verifySomeActivitiesEditor,
    verifyActivitiesEditorTypeUser,
    verifyActivitiesEditorTypeBrand,
    getBrandSpaces,
    fetchCommunityPermissions,
  }
}

export default usePermissions
