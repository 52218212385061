// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedulesPosts{width:100%}.schedulesPosts .contentTabs{margin-top:20px}.schedulesPosts .contentTabs .infinite-scroll-component{overflow:hidden !important}`, "",{"version":3,"sources":["webpack://./src/app/pages/SchedulesPosts/styles.scss"],"names":[],"mappings":"AAQA,gBACI,UAAA,CACA,6BACI,eAAA,CACA,wDACI,0BAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.schedulesPosts {\n    width: 100%;\n    .contentTabs {\n        margin-top: 20px;\n        .infinite-scroll-component {\n            overflow: hidden !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schedulesPosts": `schedulesPosts`,
	"contentTabs": `contentTabs`,
	"infinite-scroll-component": `infinite-scroll-component`
};
export default ___CSS_LOADER_EXPORT___;
