import { FC, useState } from 'react'
import { Control } from 'react-hook-form'

import { Icon } from '@liveconnect/icons'
import { FormCheckbox } from '@liveconnect/components'

import {
  PermissionsChannel,
  PermissionsDetailForm,
} from '../../../../core/permissions/types'

import './styles.scss'
import { useTranslation } from 'react-i18next'

type AccordionItemProps = {
  title: string
  image: string
  id: string
  index: number
  channelsPermissions: PermissionsChannel[]
  control: Control<PermissionsDetailForm>
  handleDelete: (id: string) => void
}

const AccordionItem: FC<AccordionItemProps> = ({
  title,
  channelsPermissions,
  image,
  id,
  index,
  control,
  handleDelete,
}) => {
  const [show, setShow] = useState<boolean>(true)
  const { t } = useTranslation()

  const handleClick = () => {
    setShow(!show)
  }

  return (
    <div
      className="accordion accordion-flush AccordionItem"
      data-testid="AccordionItem"
    >
      <div className="accordion-item">
        <div className="accordion-header" id="headingOne">
          <div className="contentImage">
            <img src={image} alt={`${title}`} title={`${title}`} />
            <h3 data-testid={`AccordionItem__title__${id}`}>{title}</h3>
          </div>
          <div className="actions">
            <FormCheckbox
              control={control}
              name={`brandSpacePermission.${index}.permissions.directoriesEditor`}
              label={t('permissions.list.directoriesEditor')}
              data-testid={`AccordionItem__directoriesEditor__${id}`}
            />
            <div className="vr"></div>
            <button
              type="button"
              onClick={() => handleDelete(id)}
              className="btn btn-outline-primary deleteBtn"
              data-testid={`AccordionItem__delete__${id}`}
            >
              <Icon name="delete_outline" />
            </button>
            <div className="vr"></div>
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${id}`}
              aria-expanded="true"
              aria-controls={`collapse-${id}`}
              onClick={handleClick}
              data-testid={`AccordionItem__click__${id}`}
            ></button>
          </div>
        </div>
        <div
          id={`collapse-${id}`}
          className={`accordion-collapse collapse ${show ? 'show' : ''}`}
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div className="col-12 info">
              <h4>{t('permissions.list.title')}</h4>
            </div>
            <div
              className="col-12 tableAccordion"
              data-testid={`AccordionItem__tableAccordion__${id}`}
            >
              <div className="header row">
                <div className="col-2 cell">
                  <p>{t('sections.list.table.columns.name')}</p>
                </div>
                <div className="col cell">
                  <p>{t('permissions.list.wallEditor')}</p>
                </div>
                <div className="col cell">
                  <p>{t('permissions.list.usersModerator')}</p>
                </div>
                <div className="col cell">
                  <p>{t('permissions.list.activitiesEditor')}</p>
                </div>
                <div className="col cell">
                  <p>{t('permissions.list.directoriesChannel')}</p>
                </div>
              </div>
              {channelsPermissions.map((channel, channelIndex) => (
                <div className="body row" key={channel.id}>
                  <div className="col-2 cell">{channel.name}</div>
                  <div className="col cell check">
                    <FormCheckbox
                      control={control}
                      name={`brandSpacePermission.${index}.channels.${channelIndex}.permissions.wallEditor`}
                      label=""
                      data-testid={`AccordionItem__tableAccordion__${id}__${index}.channels.${channelIndex}.permissions.wallEditor`}
                    />
                  </div>
                  <div className="col cell check">
                    <FormCheckbox
                      control={control}
                      name={`brandSpacePermission.${index}.channels.${channelIndex}.permissions.usersModerator`}
                      label=""
                      data-testid={`AccordionItem__tableAccordion__${id}__${index}.channels.${channelIndex}.permissions.usersModerator`}
                    />
                  </div>
                  <div className="col cell check">
                    <FormCheckbox
                      control={control}
                      name={`brandSpacePermission.${index}.channels.${channelIndex}.permissions.activitiesEditor`}
                      label=""
                      data-testid={`AccordionItem__tableAccordion__${id}__${index}.channels.${channelIndex}.permissions.activitiesEditor`}
                    />
                  </div>
                  <div className="col cell check">
                    <FormCheckbox
                      control={control}
                      name={`brandSpacePermission.${index}.channels.${channelIndex}.permissions.directoriesEditorChannel`}
                      label=""
                      data-testid={`AccordionItem__tableAccordion__${id}__${index}.channels.${channelIndex}.permissions.directoriesEditorChannel`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccordionItem
