import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@liveconnect/icons'
import classNames from 'classnames'
import { isNullOrEmpty } from '@liveconnect/communities-ui'

import { Reactions, ReactionTypeEnum } from '../../../../core/posts/types'
import usePosts from '../../../../core/posts/usePosts'

import './styles.scss'
import useActivities from '../../../../core/activities/useActivities'

export interface ReactionComponentProps {
  fingerUpCount?: number
  likeCount?: number
  myReaction: ReactionTypeEnum
  isPost: boolean
  takenId: string
  publisherId: string
  publisherType: string
  commentId?: string
  disabled?: boolean
  inActivity?: boolean
}

export const ReactionComponent: FC<ReactionComponentProps> = ({
  takenId,
  commentId,
  isPost,
  fingerUpCount,
  likeCount,
  myReaction,
  disabled,
  publisherId,
  publisherType,
  inActivity,
}): JSX.Element => {
  const { t } = useTranslation()
  const { updatePostReaction, updateCommentReaction } = usePosts()
  const { updateCommentActivityReaction, activity } = useActivities()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [internalReaction, setInternalReaction] = useState<ReactionTypeEnum>(
    myReaction ?? ReactionTypeEnum.NONE
  )
  const [internalFingerUpCount, setInternalFingerUpCount] = useState<number>(
    fingerUpCount ?? 0
  )
  const [internalLikeCount, setInternalLikeCount] = useState<number>(
    likeCount ?? 0
  )

  const getInternalCount = useCallback(
    (reactionType: ReactionTypeEnum): number => {
      switch (reactionType) {
        case ReactionTypeEnum.NONE:
          return 0
        case ReactionTypeEnum.FINGERUP:
          if (
            internalReaction === ReactionTypeEnum.FINGERUP &&
            internalFingerUpCount === 0
          )
            return 1
          return internalFingerUpCount
        case ReactionTypeEnum.LIKE:
          if (
            internalReaction === ReactionTypeEnum.LIKE &&
            internalLikeCount === 0
          )
            return 1
          return internalLikeCount
      }
    },
    [internalReaction, internalFingerUpCount, internalLikeCount]
  )

  const handleReaction = async (reactionType: ReactionTypeEnum) => {
    let reactionResult: Reactions | undefined
    setIsSubmitting(true)
    try {
      if (isPost) {
        reactionResult = await updatePostReaction(
          takenId,
          publisherId,
          publisherType,
          reactionType
        )
      } else if (commentId && !isNullOrEmpty(commentId)) {
        if (inActivity && activity) {
          reactionResult = await updateCommentActivityReaction(
            takenId,
            commentId,
            reactionType,
            activity.brandSpace.id
          )
        } else {
          reactionResult = await updateCommentReaction(
            takenId,
            commentId,
            publisherId,
            publisherType,
            reactionType
          )
        }
      }

      if (reactionResult) {
        setInternalReaction(reactionResult.myReaction)
        setInternalFingerUpCount(reactionResult.fingerUpCount)
        setInternalLikeCount(reactionResult.likeCount)
      } else {
        setInternalReaction(ReactionTypeEnum.NONE)
        setInternalFingerUpCount(0)
        setInternalLikeCount(0)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="Reactions">
      <div
        className={classNames('Reactions__thumbsUp', {
          current: internalReaction === ReactionTypeEnum.FINGERUP,
        })}
      >
        <button
          onClick={() =>
            handleReaction(
              internalReaction !== ReactionTypeEnum.FINGERUP
                ? ReactionTypeEnum.FINGERUP
                : ReactionTypeEnum.NONE
            )
          }
          title={t('post.reactions.finger_up')}
          disabled={isSubmitting || disabled === true}
        >
          <Icon name="like" />
        </button>
        {getInternalCount(ReactionTypeEnum.FINGERUP) !== 0 && (
          <span className="Reactions__thumbsUp__count">
            {getInternalCount(ReactionTypeEnum.FINGERUP)}
          </span>
        )}
      </div>
      <div
        className={classNames('Reactions__likeUp', {
          current: internalReaction === ReactionTypeEnum.LIKE,
        })}
      >
        <button
          onClick={() =>
            handleReaction(
              internalReaction !== ReactionTypeEnum.LIKE
                ? ReactionTypeEnum.LIKE
                : ReactionTypeEnum.NONE
            )
          }
          title={t('post.reactions.like_up')}
          disabled={isSubmitting || disabled === true}
        >
          <Icon name="enthusiasm" />
        </button>
        {getInternalCount(ReactionTypeEnum.LIKE) !== 0 && (
          <span className="Reactions__likeUp__count">
            {getInternalCount(ReactionTypeEnum.LIKE)}
          </span>
        )}
      </div>
    </div>
  )
}
