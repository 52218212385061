import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { isNullOrEmpty } from '@liveconnect/communities-ui'
import {
  FormTextarea,
  FormProvider,
  Loader,
  Avatar,
} from '@liveconnect/components'

import usePosts from '../../../../core/posts/usePosts'
import {
  CommentPostInterface,
  PublishersResponse,
} from '../../../../core/posts/types'
import { buildValidationSchema } from './validations'
import useActivities from '../../../../core/activities/useActivities'

import './styles.scss'

export interface MakeCommentProps {
  takenId: string
  publisherId: string
  publisherType: string
  selectedPublisher: PublishersResponse
  inActivity?: boolean
}

export const MakeCommentComponent: FC<MakeCommentProps> = ({
  takenId,
  publisherId,
  publisherType,
  selectedPublisher,
  inActivity,
}): JSX.Element => {
  const { t } = useTranslation()
  const { createComment } = usePosts()
  const { createCommentActivity } = useActivities()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const methods = useForm<{ message: string }>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
  })
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isValid },
  } = methods
  const watchMessage = watch('message')

  const handlePublish = async (
    takenId: string,
    comment: CommentPostInterface
  ) => {
    try {
      if (inActivity) {
        await createCommentActivity(takenId, comment, publisherId)
      } else {
        await createComment(takenId, comment, publisherType, publisherId)
      }
      setErrorMessage(undefined)
    } catch (error: unknown) {
      setErrorMessage((error as unknown as { message: string })?.message ?? '')
    }
  }

  const handleClick = async (content: { message: string }) => {
    setIsSubmitting(true)
    try {
      await handlePublish(takenId, { message: content.message })
      reset({ message: '' })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="MakeComment">
      <div className="MakeComment__publisherImage">
        <Avatar
          name={selectedPublisher.name}
          surname={''}
          imageUrl={selectedPublisher.profileImageUrl}
          size="md"
          alt={selectedPublisher.name}
        />
      </div>
      <div className="MakeComment__container">
        <div className="MakeComment__container__body">
          <FormProvider methods={methods}>
            <FormTextarea
              control={control}
              name="message"
              label=""
              placeholder={t('post.comment.create.message.placeholder')}
              rows={2}
            />
          </FormProvider>
        </div>
        <div className="MakeComment__container__actions">
          {!isNullOrEmpty(watchMessage) && (
            <button
              className="btn btn-primary"
              onClick={handleSubmit(handleClick)}
              disabled={!isValid || isSubmitting}
            >
              {isSubmitting ? <Loader /> : t('post.comment.publish')}
            </button>
          )}
          <span
            className={classNames('error', {
              show: !isNullOrEmpty(errorMessage),
            })}
          >
            {errorMessage}
          </span>
        </div>
      </div>
    </div>
  )
}
