import { Section, Subsection, Translation } from '../../../core/sections/types'

export const getTranslationFromSection = (
  section: Section | Subsection,
  language: string
): Translation | undefined => {
  return section.translations.find(
    (translation) => translation.language === language
  )
}
