// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-nav__link{all:unset;border-bottom:none;padding:15px;font-size:1rem;color:#1b1b1b;background:none;min-width:20%;text-align:center;transition:all .275s ease-in-out}.tabs-nav__link--active{font-weight:bold;background-color:rgba(0,0,0,0);border-bottom:2px solid #e32424;color:#1b1b1b}`, "",{"version":3,"sources":["webpack://./src/app/components/Tabs/TabsNav/TabsLink/styles.scss"],"names":[],"mappings":"AAQA,gBACI,SAAA,CACA,kBAAA,CACA,YAAA,CACA,cAAA,CACA,aAAA,CACA,eAAA,CACA,aAAA,CACA,iBAAA,CACA,gCAAA,CAEA,wBACI,gBAAA,CACA,8BAAA,CACA,+BAAA,CACA,aAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.tabs-nav__link{\n    all: unset;\n    border-bottom: none;\n    padding: 15px;\n    font-size: 1rem;\n    color: #1b1b1b;\n    background: none;\n    min-width: 20%;\n    text-align: center;\n    transition: all .275s ease-in-out;\n\n    &--active {\n        font-weight: bold;\n        background-color: transparent;\n        border-bottom: 2px solid #E32424;\n        color: #1b1b1b;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs-nav__link": `tabs-nav__link`,
	"tabs-nav__link--active": `tabs-nav__link--active`
};
export default ___CSS_LOADER_EXPORT___;
