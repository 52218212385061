import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  ConfigurationFooterContactListResponse,
  ConfigurationFooterGeneralListResponse,
  ConfigurationFooterLegalListResponse,
  ConfigurationFooterPageResponse,
  ConfigurationFooterSocialMediaResponse,
  ConfigurationFooterState,
} from './types'

const initialState: ConfigurationFooterState = {
  isLoaded: false,
  generalInfo: { list: null },
  contactInfo: { list: null },
  socialMediaInfo: { detail: null },
  legalInfo: { list: null },
  pageSection: null,
}

export const configurationFooterSlice = createSlice({
  name: 'configurationFooter',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setPageSectionDetail: (
      state,
      action: PayloadAction<ConfigurationFooterPageResponse>
    ) => {
      state.pageSection = action.payload
    },
    setGeneralList: (
      state,
      action: PayloadAction<ConfigurationFooterGeneralListResponse>
    ) => {
      state.generalInfo.list = action.payload
    },
    setContactList: (
      state,
      action: PayloadAction<ConfigurationFooterContactListResponse>
    ) => {
      state.contactInfo.list = action.payload
    },
    setSocialMediaDetail: (
      state,
      action: PayloadAction<ConfigurationFooterSocialMediaResponse>
    ) => {
      state.socialMediaInfo.detail = action.payload
    },
    setLegalList: (
      state,
      action: PayloadAction<ConfigurationFooterLegalListResponse>
    ) => {
      state.legalInfo.list = action.payload
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setGeneralList,
  setContactList,
  setSocialMediaDetail,
  setLegalList,
  setPageSectionDetail,
} = configurationFooterSlice.actions

export default configurationFooterSlice.reducer
