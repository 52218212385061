import { useAppDispatch, useAppSelector } from '../reduxProvider'
import { UseUiResult } from './types'
import {
  showPanel,
  hidePanel,
  showConfirmation,
  hideConfirmation,
} from './store'

const useUi = (): UseUiResult => {
  const dispatch = useAppDispatch()
  const { isPanelVisible, confirmation } = useAppSelector((state) => state.ui)

  const toggleShowPanel = () => {
    isPanelVisible ? dispatch(hidePanel()) : dispatch(showPanel())
  }

  return {
    isPanelVisible,
    confirmation,
    toggleShowPanel,
    showConfirmation: (data) => dispatch(showConfirmation(data)),
    hideConfirmation: () => dispatch(hideConfirmation()),
  }
}

export default useUi
