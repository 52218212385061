import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '@liveconnect/communities-ui'
import { Link } from 'react-router-dom'
import { Icon } from '@liveconnect/icons'

import { Main } from '../../../components/Main'
import usePermissions from '../../../core/permissions/usePermissions'
import EmptyState from '../../../components/EmptyState'
import useNotifications from '../../../utils/notifications/useNotifications'

import './styles.scss'

const PermissionsList: FC = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { fetchUsersList, userList, isLoadedUsers } = usePermissions()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleFetch = async () => {
    setIsSubmitting(true)
    try {
      await fetchUsersList()
    } catch (error) {
      notify.error(t('common.unknownError'))
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    handleFetch()
  }, [])

  return (
    <Main
      ariaLabelledby="permissions-list"
      className="PermissionsList"
      title={t('permissions.title')}
      subtitle={t('permissions.subtitle')}
    >
      <div className="PermissionsList__content" data-testid="PermissionsList">
        <div
          className="col-12 tablePermissions"
          data-testid="PermissionsList__tablePermissions"
        >
          <div className="SearchTable row align-items-center">
            <div className="col SearchTable__contentTitle">
              <p className="title" data-testid="PermissionsList__title">
                {t('permissions.list')}
              </p>
            </div>
            <div className="col-auto">
              <div className="content-right">
                <button
                  type="button"
                  className="btn btn-primary btnFetch"
                  onClick={handleFetch}
                  data-testid="PermissionsList__reload"
                >
                  {isSubmitting ? <Loader /> : t('permissions.list.reload')}
                </button>
              </div>
            </div>
          </div>
          {!isLoadedUsers ? (
            <Loader />
          ) : (
            <div className="row">
              {userList.length > 0 ? (
                <div className="col-12 noPadding">
                  <div className="header row">
                    <div className="col-5 cell">
                      <p>{t('user.list.name')}</p>
                    </div>
                    <div className="col-4 cell">
                      <p>{t('user.list.email')}</p>
                    </div>
                    <div className="col-2 cell">
                      <p>{t('navMenu.permissions')}</p>
                    </div>
                    <div className="col-1 cell">{''}</div>
                  </div>
                  {userList.map((user) => (
                    <div className="body row" key={`${user.id}`}>
                      <div className="col-5 cell">
                        {user.name} {user.surname}
                      </div>
                      <div className="col-4 cell">{user.email}</div>
                      <div className="col-2 cell">
                        {!user.hasPermissions && (
                          <span className="label">
                            {t('permissions.list.pending')}
                          </span>
                        )}
                      </div>
                      <div className="col-1 cell actions">
                        <Link
                          to={`${user.id}`}
                          data-testid={`PermissionsList__edit__${user.id}`}
                        >
                          <Icon name="edit" />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="col-12 noPadding empty">
                  <EmptyState text={t('permissions.list.empty')} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Main>
  )
}

export default PermissionsList
