/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  setElementsListNoHigh,
  setElementsListHigh,
  setElementInfoTab,
  showLoader,
  hideLoader,
  setDirectoryName,
  setElementInformativeTab,
  setElementInformativeTabBool,
  setElementInformativeSectionVisible,
} from './store'

import useFetch from '../../utils/fetch/useFetch'
import {
  ElementListItem,
  UseElementsResult,
  ElementsRequestParams,
  ElementsListResponse,
  ElementInfoTabDetail,
  ElementInfoTabForm,
  ElementInfoTabTranslateForm,
  DragElementsRequestParams,
  ElementDragListItem,
  ElementInformativeDetail,
  ElementInformativeForm,
  ElementInformativeFormTranslation,
} from './types'

const useElements = (): UseElementsResult => {
  const dispatch = useAppDispatch()
  const { detail, listNoHigh, listHigh, isLoaded, directoryName } =
    useAppSelector((state) => state.elements)
  const { get, post, patch, del, put } = useFetch()

  const endpoint = 'elements'
  const informativeEndpoint = 'element-informative'

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const getElements = async (
    params: ElementsRequestParams
  ): Promise<ElementsListResponse | undefined> => {
    const response: ElementsListResponse | undefined = await get({
      endpoint: `${endpoint}/search/no-hihglighted`,
      params,
    })
    return response
  }

  const fetchElements = async (
    params: ElementsRequestParams
  ): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: ElementsListResponse | undefined = await getElements(
        params
      )
      response && dispatch(setElementsListNoHigh(response))
    })
  }

  const getDragElements = async (
    params: DragElementsRequestParams
  ): Promise<ElementDragListItem[]> => {
    const response: ElementDragListItem[] =
      (await get({
        endpoint: `${endpoint}/search/hihglighted`,
        params,
      })) ?? []
    return response
  }

  const fetchDragElements = async (
    params: DragElementsRequestParams
  ): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: ElementDragListItem[] | undefined = await getDragElements(
        params
      )
      response && dispatch(setElementsListHigh(response))
    })
  }

  const fetchElementInfoTab = async (id: string) => {
    dispatch(showLoader())
    const response: ElementInfoTabDetail | undefined = await get({
      endpoint: `${endpoint}/${id}/main-info`,
    })
    if (response) {
      dispatch(setElementInfoTab(response))
      dispatch(setElementInformativeTab(null))
    }
    dispatch(hideLoader())
  }

  const addElementInfoTab = async (data: ElementInfoTabForm) => {
    dispatch(showLoader())
    const response: string | undefined = await post({
      endpoint: `${endpoint}/main-info`,
      body: data,
    })
    dispatch(hideLoader())
    if (response) return response
  }

  const editElementInfoTab = async (data: ElementInfoTabForm, id: string) => {
    const response: ElementInfoTabDetail | undefined = await patch({
      endpoint: `${endpoint}`,
      body: data,
      id: `${id}/main-info`,
    })
    if (response) {
      dispatch(setElementInfoTab({ ...detail.infoElement, ...response }))
    }
    return response
  }

  const editElementTranslationInfoTab = async (
    data: ElementInfoTabTranslateForm,
    id: string
  ) => {
    const response: ElementInfoTabDetail | undefined = await patch({
      endpoint: `${endpoint}`,
      body: data,
      id: `${id}/main/translations`,
    })
    if (response) {
      dispatch(setElementInfoTab({ ...detail.infoElement, ...response }))
    }
    return response
  }

  const uploadImageElement = async (
    image: string
  ): Promise<string | undefined> => {
    const form = new FormData()
    const blob = await fetch(image).then((r) => r.blob())
    form.set('imageFile', blob)
    const result: { uri: string } | undefined = await post({
      endpoint: `${endpoint}/image`,
      body: form,
      isMultipart: true,
    })
    if (result) return result.uri
  }

  const fetchDirectoryName = async (idDirectory: string) => {
    dispatch(showLoader())
    const response: string | undefined = await get({
      endpoint: `directories/${idDirectory}/name`,
    })
    if (response) {
      dispatch(setDirectoryName(response))
    }
    dispatch(hideLoader())
  }

  const deleteElement = async (id: string): Promise<void> => {
    await del({
      endpoint,
      id,
    })
  }

  const publishElement = async (
    id: string,
    publish: boolean
  ): Promise<void> => {
    const response = await patch({
      endpoint: `${endpoint}/${id}`,
      body: {
        isPublished: publish,
      },
      id: 'publish',
    })
  }

  const highlightElement = async (
    id: string,
    highlight: boolean
  ): Promise<void> => {
    await patch({
      endpoint: `${endpoint}/${id}`,
      body: {
        isHighlighted: highlight,
      },
      id: 'highlight',
    })
  }

  const orderElement = async (
    id: string,
    index: number,
    idDirectory: string
  ): Promise<void> => {
    const response: ElementDragListItem[] | undefined = await patch({
      endpoint: `${endpoint}/${id}`,
      body: {
        index,
        directoryId: idDirectory,
      },
      id: 'change-order',
    })
    response && dispatch(setElementsListHigh(response))
  }

  const fetchElementInformativeTab = async (id: string) => {
    dispatch(showLoader())
    try {
      const response: ElementInformativeDetail | undefined = await get({
        endpoint: `${informativeEndpoint}/${id}`,
      })
      if (response) {
        dispatch(setElementInformativeTab(response))
      }
    } catch (error: any) {
      if (error.body[0].code === 'ENTITY_NOT_FOUND') {
        dispatch(setElementInformativeTab(null))
      }
    }
    dispatch(hideLoader())
  }

  const addElementInformativeTab = async (data: ElementInformativeForm) => {
    dispatch(showLoader())
    const response: string | undefined = await post({
      endpoint: informativeEndpoint,
      body: data,
    })

    if (response) dispatch(setElementInformativeTabBool(true))

    dispatch(hideLoader())
  }

  const editElementInformativeTab = async (
    data: ElementInformativeForm,
    id: string
  ) => {
    const response: ElementInformativeDetail | undefined = await put({
      endpoint: `${informativeEndpoint}/${id}`,
      body: data,
    })
    if (response) {
      dispatch(setElementInformativeTab({ ...detail.infoSection, ...response }))
      dispatch(setElementInformativeSectionVisible(data.isVisible))
    }
  }

  const editElementTranslationInformativeTab = async (
    data: ElementInformativeFormTranslation,
    id: string
  ) => {
    const response: ElementInformativeDetail | undefined = await patch({
      endpoint: informativeEndpoint,
      body: data,
      id: `${id}/translations`,
    })
    if (response) {
      dispatch(setElementInformativeTab({ ...detail.infoSection, ...response }))
    }
  }

  return {
    element: detail,
    listNoHigh,
    listHigh,
    directoryName,
    isLoaded,
    fetchElements,
    fetchDragElements,
    fetchElementInfoTab,
    addElementInfoTab,
    editElementInfoTab,
    editElementTranslationInfoTab,
    uploadImageElement,
    fetchDirectoryName,
    deleteElement,
    publishElement,
    highlightElement,
    orderElement,
    fetchElementInformativeTab,
    addElementInformativeTab,
    editElementInformativeTab,
    editElementTranslationInformativeTab,
  }
}

export default useElements
