import * as yup from 'yup'
import { TFunction } from 'react-i18next'

import { useHtmlToString } from '../../../utils/useHtmlToString'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    title: yup
      .string()
      .label(t('onboardingInfo.form.title.label'))
      .required(({ label }) => t('validations.required', { label })),
    description: yup
      .string()
      .label(t('onboardingInfo.form.description.label'))
      .required(({ label }) => t('validations.required', { label }))
      .test(
        'onboardingInfo.form.description.label',
        ({ label }) => t('validations.maxLength', { label, maxLength: 140 }),
        (val) => useHtmlToString(val ?? '').length <= 140
      ),
    imageUrl: yup
      .string()
      .label(t('onboardingInfo.form.imageUrl.label'))
      .required(({ label }) => t('validations.required', { label })),
    logoUrl: yup
      .string()
      .label(t('onboardingInfo.form.logoUrl.label'))
      .required(({ label }) => t('validations.required', { label })),
  })
}
