import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BadgePublisher } from '../../core/posts/types'

import './styles.scss'

interface ViewBadgesProps {
  badges: BadgePublisher[]
}

const ViewBadges: FC<ViewBadgesProps> = ({ badges }) => {
  const { t } = useTranslation()
  const sliceBadges = useMemo(() => {
    if (badges.length >= 3) {
      return badges.slice(badges.length - 3).map((badge: BadgePublisher) => (
        <div key={badge.id} className="contentImage">
          <img src={badge.imageUrl} alt={badge.title} />
        </div>
      ))
    }
    return badges.slice(0, badges.length).map((badge: BadgePublisher) => (
      <div key={badge.id} className="contentImage">
        <img src={badge.imageUrl} alt={badge.title} />
      </div>
    ))
  }, [badges])

  return (
    <div className="ViewBadges">
      {badges && badges.length > 0 && (
        <div className="ViewBadges__badges">
          {sliceBadges}
          <p>
            {badges[badges.length - 1].title}{' '}
            {badges.length >= 3 &&
              t('badges.more', {
                number: badges.length - 1,
              })}
            {badges.length === 2 && t('badges.more.singular')}
          </p>
        </div>
      )}
    </div>
  )
}

export default ViewBadges
