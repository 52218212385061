export interface DirectoriesState {
  detail: DirectoryDetail | null
  list: DirectoriesListResponse | null
  isLoaded: boolean
}

export enum TypeDirectory {
  Main = 'Main',
  BrandSpace = 'BrandSpace',
  Channel = 'Channel',
}

export interface DirectoryForm {
  name: string
  description: string
  type: TypeDirectory
  imageUrl: string
  sectorId: string
  channels: string[]
  code: string
  language: string
}

export interface PayloadDirectory {
  name: string
  description: string
  type: TypeDirectory
  language: string
  mainDirectoryType?: MainDirectoryType
  channelDirectoryType?: ChannelDirectoryType
  brandSpaceDirectoryType?: BrandSpaceDirectoryType
}

export interface MainDirectoryType {
  imageUrl: string
}
export interface ChannelDirectoryType {
  sectorId: string
  channels: string[]
}

export interface BrandSpaceDirectoryType {
  brandSpaceId: string
}

export interface PutOnlyTrans {
  name: string
  description: string
  language: string
}

export interface DirectoryDetail {
  id: string
  translations: TranslationDetailDirectory[]
  type: TypeDirectory
  imageUrl?: string
  sectorId?: string
  channels?: string[]
  brandSpaceId?: string
}

export interface TranslationDetailDirectory {
  language: string
  name: string
  description: string
}

export interface DirectoryListItem {
  id: string
  isPublished: boolean
  hasElements: boolean
  type: TypeDirectory
  name: string
  description: string
  translations: string[]
  channels?: string[]
  brandSpace?: string
}

export interface DirectoriesListResponse {
  items: DirectoryListItem[]
  page: number
  pageSize: number
  availableItems: number
  availablePages: number
}

export interface DirectoriesRequestParams {
  page: number
  page_size: number
}

export interface SectorDirectory {
  id: string
  name: string
  channels: ChannelSectorDirectory[]
}

export interface ResponseSectorsByDirectory {
  directoryId: string
  sectors: SectorDirectory[]
}

export interface ChannelSectorDirectory {
  id: string
  name: string
}

export interface BrandDirectory {
  id: string
  name: string
}

export interface UseDirectoriesResult {
  directory: DirectoryDetail | null
  directoriesList: DirectoriesListResponse | null
  isLoaded: boolean
  fetchDirectories: (params: DirectoriesRequestParams) => Promise<void>
  fetchDirectoryDetail: (id: string) => Promise<void>
  publishDirectory: (id: string, publish: boolean) => Promise<void>
  deleteDirectory: (id: string) => Promise<void>
  getSectorsDirectory: () => Promise<SectorDirectory[] | undefined>
  getSectorsByDirectory: (
    directoryId: string
  ) => Promise<ResponseSectorsByDirectory | undefined>
  getBrandsDirectory: () => Promise<BrandDirectory[] | undefined>
  addDirectory: (channel: PayloadDirectory) => Promise<string | undefined>
  editDirectory: (
    directory: PayloadDirectory,
    id: string
  ) => Promise<DirectoryDetail | undefined>
  editDirectoryTranslation: (
    directory: PutOnlyTrans,
    idDirectory: string
  ) => Promise<DirectoryDetail | undefined>
  uploadImageDirectory: (image: string) => Promise<string | undefined>
}
