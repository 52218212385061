import { FC, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Loader } from '@liveconnect/communities-ui'
import { Icon } from '@liveconnect/icons'

import './styles.scss'

interface VideoPlayerProps {
  url: string
  className?: string
  onError?: () => void
  onSuccess?: () => void
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  url,
  className,
  onError,
  onSuccess,
}) => {
  const { t } = useTranslation()
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleError = () => {
    setHasError(true)
    setIsLoading(false)
    onError && onError() // Avisar al padre que el video da error
  }

  const handleReady = () => {
    setIsLoading(false)
    onSuccess && onSuccess() // Avisar al padre que el video es reproducible
  }

  useEffect(() => {
    setHasError(false)
    setIsLoading(true)
  }, [url])

  return (
    <div className={classNames('VideoPlayer', className)}>
      {isLoading && <Loader />}
      {!hasError ? (
        <div className="VideoPlayer__video-wrapper">
          <ReactPlayer
            url={url}
            controls={true}
            onError={handleError}
            onReady={handleReady}
            width="100%"
          />
        </div>
      ) : (
        <div className="VideoPlayer__error">
          <div className="VideoPlayer__error__box">
            <Icon name="report_problem" />
            <div className="VideoPlayer__error__box__text">
              {t('post.videoUpload.error')}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoPlayer
