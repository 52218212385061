import { Icon } from '@liveconnect/icons'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd'

import { Sponsor } from '../../../../core/brandSpace/types'
import TranslationChips from '../../../../components/TranslationsChips'
import { Subsection } from '../../../../core/sections/types'
import { DEFAULT_LANG } from '../../../../i18n/config'
import { getTranslationFromSection } from '../../EditSections/utils'
import { SponsorHeader, SponsorRow } from './SponsorRow'

import './styles.scss'

interface SubsectionComponentProps {
  subsection: Subsection
  provided: DraggableProvided
  handleEditSponsor: (sponsor: Sponsor) => void
  handleDeleteSponsor: (sponsor: Sponsor) => void
  handleReorderSponsors: (result: DropResult) => void
}

const SubsectionComponent: FC<SubsectionComponentProps> = ({
  subsection,
  provided,
  handleEditSponsor,
  handleDeleteSponsor,
  handleReorderSponsors,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const defTranslation = getTranslationFromSection(subsection, DEFAULT_LANG)
  const [isSectionCollapsed, setIsSectionCollapsed] = useState<boolean>(false)

  const handleAddSponsor = (subsection: Subsection) => {
    navigate(`sections/${subsection.id}/sponsors`)
  }

  return (
    <div
      className="Subsection"
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <div className="Subsection__header">
        <div className="Subsection__header__flex-start">
          <div className="Subsection__header__dd">
            <div className="subsection-wrapper" {...provided.dragHandleProps}>
              <Icon name="drag_and_drop" />
            </div>
          </div>
          <div className="Subsection__header__title-and-languages me-3">
            <div className="Subsection__header__title-and-languages__title">
              {defTranslation?.name}:
            </div>
          </div>
          <TranslationChips
            translations={subsection.translations}
            showDefault
          />
        </div>
        <div className="Subsection__header__flex-end">
          <div className="Subsection__header__collapse">
            <button onClick={() => setIsSectionCollapsed(!isSectionCollapsed)}>
              {!isSectionCollapsed && <Icon name="keyboard_arrow_up" />}
              {isSectionCollapsed && <Icon name="keyboard_arrow_down" />}
            </button>
          </div>
        </div>
      </div>
      {!isSectionCollapsed && (
        <div className="Subsection__body">
          <div className="Subsection__body__sponsors">
            {subsection.sponsors && subsection.sponsors.length > 0 && (
              <div className="SponsorList">
                <SponsorHeader />
                <DragDropContext onDragEnd={handleReorderSponsors}>
                  <Droppable droppableId={`${subsection.id}.sponsors`}>
                    {(dropProvided: DroppableProvided) => (
                      <div
                        className="SponsorList__droppable"
                        {...dropProvided.droppableProps}
                        ref={dropProvided.innerRef}
                      >
                        {subsection.sponsors &&
                          subsection.sponsors.map(
                            (sponsor: Sponsor, sponsorIndex: number) => (
                              <Draggable
                                key={sponsor.id}
                                draggableId={sponsor.id}
                                index={sponsorIndex}
                              >
                                {(dragProvided: DraggableProvided) => (
                                  <SponsorRow
                                    key={sponsor.id}
                                    sponsor={sponsor}
                                    handleEdit={handleEditSponsor}
                                    handleDelete={handleDeleteSponsor}
                                    provided={dragProvided}
                                  />
                                )}
                              </Draggable>
                            )
                          )}
                        {dropProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
            <div className="Subsection__body__sponsors__add">
              <button onClick={() => handleAddSponsor(subsection)}>
                <Icon name="add" />
                {t('common.add')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SubsectionComponent
