import * as yup from 'yup'
import { TFunction } from 'react-i18next'

import { DEFAULT_LANG } from '../../../i18n/config'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    name: yup
      .string()
      .label(t('corporateInfo.form.name.label'))
      .required(({ label }) => t('validations.required', { label })),
    slogan: yup
      .string()
      .label(t('corporateInfo.form.slogan.label'))
      .required(({ label }) => t('validations.required', { label }))
      .max(60, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 60 })
      ),
    eventsUrl: yup
      .string()
      .url(t('common.link.patternValidation'))
      .label(t('corporateInfo.form.eventsUrl.label'))
      .required(({ label }) => t('validations.required', { label })),
    imageUrl: yup
      .string()
      .label(t('corporateInfo.form.imageUrl.label'))
      .when('language', {
        is: (language: string) => language === DEFAULT_LANG,
        then: yup
          .string()
          .required(({ label }) => t('validations.required', { label })),
        otherwise: yup.string(),
      }),
    accessDenyText: yup
      .string()
      .label(t('corporateInfo.form.accessDenyText.label'))
      .required(({ label }) => t('validations.required', { label })),
    accessDenyUrl: yup
      .string()
      .url(t('common.link.patternValidation'))
      .label(t('corporateInfo.form.accessDenyUrl.label'))
      .required(({ label }) => t('validations.required', { label })),
  })
}
