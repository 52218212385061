import { FC } from 'react'

import './styles.scss'

interface WithoutButtonProps {
  title: string
  text?: string
  leyend?: string
}

const HeadLineWithoutButton: FC<WithoutButtonProps> = ({
  title,
  text,
  leyend,
}) => {
  return (
    <div className="WithoutButton">
      <div className="WithoutButton__contentTitle">
        <h1 className="h3">{title}</h1>
        {leyend && <p>{leyend}</p>}
      </div>

      {text && <p>{text}</p>}
    </div>
  )
}

export default HeadLineWithoutButton
