import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useAuth from '../../../core/auth/useAuth'
import { SigninCallbackError } from '../../../core/auth/utils'

import { useSettings } from '../../../core'

const SigninCallback: FC = () => {
  const navigate = useNavigate()
  const { signinRedirectCallback } = useAuth()
  const { setInitialUrls, fetchModulesStatus } = useSettings()

  useEffect(() => {
    async function handleRedirect() {
      try {
        const { redirectPath } = await signinRedirectCallback()
        const tenant = redirectPath.split('/')[2]
        await fetchModulesStatus(tenant)
        await setInitialUrls(tenant)
        navigate({ pathname: redirectPath })
      } catch (e) {
        if (e instanceof SigninCallbackError) {
          navigate(e.redirectPath)
        } else {
          throw e
        }
      }
    }

    handleRedirect()
  }, [signinRedirectCallback, navigate])

  return <></>
}

export default SigninCallback
