export interface MemberBadge {
  id: string
  title: string
  imageUrl: string
}
export interface Member {
  id: string
  name: string
  surname: string
  email: string
  jobTitle: string
  isEnabled: boolean
  imageUrl: string
  badges: MemberBadge[]
  channels: MemberChannel[]
  registerType: RegisterType
  registerDate: string
}

export enum RegisterType {
  Preregistered = 'Preregistered',
  Active = 'Active',
}
export enum UserType {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export interface MemberListPayload {
  list: Array<Member>
  page: number
  pageSize: number
  availablePages: number
  availableItems: number
  search: string
}

export type MemberParams =
  | Record<string, string | number | Array<string | number> | boolean>
  | undefined

export interface MemberRequestParams {
  page: number
  page_size: number
  search?: string
  registerType?: RegisterType
  userType?: UserType
  channels: string[]
}

export interface MemberBadgesParams {
  page: number
  page_size: number
  search?: string
  brandSpaceId: string
}

export interface CSVParams {
  search: string
  registerType: RegisterType
  userType: UserType
  channels: string[]
}

export interface MemberListResponse {
  items: Array<Member>
  page: number
  pageSize: number
  availableItems: number
  availablePages: number
}

export interface MembersState {
  list: Member[]
  page: number
  pageSize: number
  availableItems: number
  availablePages: number
  search: string
  isLoaded: boolean
}

export interface MemberChannel {
  id: string
  isEnabled?: boolean
  name: string
}

export interface JobsMember {
  company: string
  jobTitle: string
  isHighlighted: boolean
  id?: string
}

export interface MemberDetail {
  id: string
  name: string
  surname: string
  imageUrl: string
  email: string
  isEnabled: boolean
  positions: JobsMember[]
  description: string
  badges: MemberBadge[]
  channels: MemberChannel[]
}

export interface UseMembersResult {
  memberList: Member[]
  page: number
  availablePages: number
  availableItems: number
  pageSize: number
  search: string
  isLoaded: boolean
  getMembers: (
    params: Partial<MemberRequestParams>
  ) => Promise<MemberListResponse | undefined>
  getMembersBadge: (
    params: MemberBadgesParams
  ) => Promise<MemberListResponse | undefined>
  fetchMembers: (params: Partial<MemberRequestParams>) => void
  clearMemberList: () => void
  toggleEnableMember: (id: string, isEnabled: boolean) => Promise<void>
  toggleEnableProfile: (
    id: string,
    isEnabled: boolean
  ) => Promise<MemberDetail | undefined>
  getMemberDetail: (memberId: string) => Promise<MemberDetail | undefined>
  getCSV: (params: CSVParams) => Promise<any | undefined>
  deleteMember: (memberId: string, deleteContent: boolean) => Promise<void>
}
