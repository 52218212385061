import { FC, useMemo } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Icon } from '@liveconnect/icons'
import useLocalizations from '../../core/localizations/useLocalizations'
import { Translation } from '../../core/shared/types'

import './styles.scss'

interface TranslationsComponentProps {
  translations: Translation[] | string[]
  label?: string
  showDefault?: boolean
  multipleEntities?: number
  isArrayOfStrings?: boolean
}

const TranslationChips: FC<TranslationsComponentProps> = ({
  translations,
  showDefault = false,
  multipleEntities,
  isArrayOfStrings = false,
}) => {
  const { t } = useTranslation()
  const { localizations } = useLocalizations()

  const formattedTranslations = useMemo(() => {
    if (!localizations) return []
    const completedTranslations: Translation[] = []
    if (!isArrayOfStrings) {
      if (!multipleEntities) return translations as Translation[]

      localizations.map((language) => {
        const isTranslated =
          (translations as Translation[]).filter(
            (trans) => trans.language === language.isoCode
          ).length === multipleEntities

        if (isTranslated)
          completedTranslations.push({ name: '', language: language.isoCode })
      })
    } else {
      ;(translations as string[]).map((language) => {
        completedTranslations.push({ name: '', language })
      })
    }
    return completedTranslations
  }, [translations, multipleEntities, localizations])

  const translationsLanguages = useMemo(() => {
    return formattedTranslations.map((translation) => translation.language)
  }, [formattedTranslations])

  const languages = useMemo(() => {
    return localizations.map((language) => {
      let languageShort = t(`localizations.short.${language.isoCode}`)
      if (showDefault && language.isDefault) {
        languageShort += ` (${t('common.changeLanguage.defaultLanguage')})`
      }
      return {
        language: languageShort,
        isTranslated: translationsLanguages.includes(language.isoCode),
      }
    })
  }, [translationsLanguages])

  return (
    <ul className="translations">
      {languages.map((translation, index) => (
        <li
          key={index}
          className={classNames('translations__language', {
            'translations__language--translated': translation.isTranslated,
          })}
        >
          {translation.isTranslated && <Icon name="check_circle_outline" />}
          <span>{translation.language}</span>
        </li>
      ))}
    </ul>
  )
}

export default TranslationChips
