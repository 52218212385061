import { Icon } from '@liveconnect/icons'
import { FC } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Sponsor } from '../../../../core/brandSpace/types'

interface SponsorRowProps {
  sponsor: Sponsor
  provided: DraggableProvided
  handleEdit: (row: Sponsor) => void
  handleDelete: (row: Sponsor) => void
}

export const SponsorRow: FC<SponsorRowProps> = ({
  sponsor,
  provided,
  handleEdit,
  handleDelete,
}) => {
  const { t } = useTranslation()
  return (
    <div
      className="SponsorRow row"
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <div className="SponsorRow__dd col-1">
        <div className="SponsorRow__dd__wrapper" {...provided.dragHandleProps}>
          <Icon name="drag_and_drop" />
        </div>
      </div>
      <div className="SponsorRow__logo col-3">
        <img src={sponsor.logoUrl} alt={sponsor.name} title={sponsor.name} />
      </div>
      <div className="SponsorRow__name col-4">{sponsor.name}</div>
      <div className="SponsorRow__website col-3">
        <a
          href={sponsor.websiteUrl}
          target="_blank"
          rel="noreferrer"
          title={sponsor.websiteUrl}
        >
          {sponsor.websiteUrl}
        </a>
      </div>
      <div className="SponsorRow__actions col-1">
        <button
          className="SponsorRow__actions__edit"
          onClick={() => handleEdit(sponsor)}
          title={t('common.edit')}
        >
          <Icon name="edit" />
        </button>
        <button
          className="SponsorRow__actions__delete"
          onClick={() => handleDelete(sponsor)}
          title={t('common.delete')}
        >
          <Icon name="delete_outline" />
        </button>
      </div>
    </div>
  )
}

export const SponsorHeader: FC = () => {
  const { t } = useTranslation()
  return (
    <div className="SponsorList__header row">
      <div className="SponsorList__header__dd col-1">&nbsp;</div>
      <div className="SponsorList__header__logo col-3">
        {t('sections.list.table.columns.logo')}
      </div>
      <div className="SponsorList__header__name col-4">
        {t('sections.list.table.columns.name')}
      </div>
      <div className="SponsorList__header__website col-3">
        {t('sections.list.table.columns.website')}
      </div>
      <div className="SponsorList__header__actions col-1">&nbsp;</div>
    </div>
  )
}
