export const scrollViewError = () => {
  const errorFields = document.getElementsByClassName('form-control is-invalid')
  const first = errorFields[0]
  if (first) {
    return first.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }
  return
}
