// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-collaborators-description{max-width:700px;display:inline-block;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/app/pages/Activities/DetailForm/Collaborators/List/styles.scss"],"names":[],"mappings":"AAQA,oCACE,eAAA,CACA,oBAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.activity-collaborators-description {\n  max-width: 700px;\n  display: inline-block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activity-collaborators-description": `activity-collaborators-description`
};
export default ___CSS_LOADER_EXPORT___;
