import { FC, useMemo } from 'react'

import { Avatar } from '@liveconnect/components'

import { useTranslation } from 'react-i18next'
import { Member } from '../../../../../../core/brandSpace/types'

interface AssignedUsersProps {
  members: Member[]
}

const AssignedUsers: FC<AssignedUsersProps> = ({
  members,
}: AssignedUsersProps) => {
  const { t } = useTranslation()

  const sliceBadges = useMemo(() => {
    if (members.length >= 3) {
      return members.slice(members.length - 3).map((member: Member) => (
        <div key={member.id} className="contentImage">
          <Avatar
            key={member.id}
            name={member.name}
            surname={member.surname}
            alt={`${member.name} ${member.surname}`}
            imageUrl={member.imageUrl || undefined}
            size="sm"
          />
        </div>
      ))
    }
    return members.slice(0, members.length).map((member: Member) => (
      <div key={member.id} className="contentImage">
        <Avatar
          key={member.id}
          name={member.name}
          surname={member.surname}
          alt={`${member.name} ${member.surname}`}
          imageUrl={member.imageUrl || undefined}
          size="sm"
        />
      </div>
    ))
  }, [members])

  return members.length === 0 ? (
    <span className="no-members">{t('brand.badges.noAssignedUsers')}</span>
  ) : (
    <div className="contentAvatar">
      <div className="contentAvatar__badges">
        {sliceBadges}

        {members.length >= 1 && (
          <p>
            {members[members.length - 1].name}{' '}
            {members.length >= 3 &&
              t('badges.more.user', {
                number: members.length - 1,
              })}
            {members.length === 2 && t('badges.more.singular.user')}
          </p>
        )}
      </div>
    </div>
  )
}

export default AssignedUsers
