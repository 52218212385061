import { FC, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ColumnDef } from '@tanstack/react-table'
import { t } from 'i18next'

import { Icon } from '@liveconnect/icons'
import { ToggleControl, Loader } from '@liveconnect/components'

import { ChannelsTabs, ThemeChannel } from '../../../../core/channels/types'
import EmptyState from '../../../../components/EmptyState'
import TranslationChips from '../../../../components/TranslationsChips'
import useChannels from '../../../../core/channels/useChannels'
import ContentTable, {
  OnDragEndProps,
} from '../../../../components/Table/ContentTable'

import usePermissions from '../../../../core/permissions/usePermissions'
import useNotifications from '../../../../utils/notifications/useNotifications'
import useUi from '../../../../core/ui/useUi'
import { sortElementsArray } from '../../../../utils/shared'

import './styles.scss'

export const LinkedThemes = () => {
  const { t } = useTranslation()
  const { channel, isLoaded } = useChannels()

  return !isLoaded || !channel ? (
    <Loader />
  ) : (
    <div>
      <div className="row align-items-end mb-3">
        <div className="col">
          <p>{t('topic.themes.subtitle')}</p>
        </div>
        <div className="col-auto">
          <Link className="btn btn-primary" to={'themes/create'}>
            {t('topic.themes.add')}
          </Link>
        </div>
      </div>
      <LinkedThemesGrid themes={channel.themes} />
    </div>
  )
}

export const LinkedThemesGrid: FC<{ themes: ThemeChannel[] }> = ({
  themes,
}) => {
  const { channel, publishTheme, deleteTheme, sortThemes } = useChannels()
  const { verifyPermissionsMaster } = usePermissions()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const navigate = useNavigate()

  const columns: ColumnDef<ThemeChannel>[] = useMemo(
    () => [
      {
        accessorFn: (row) => row.translations[0].name,
        header: t('topic.themes.table.themes'),
        cell: ({ row }) => <span>{row.original.translations[0].name}</span>,
      },
      {
        accessorFn: (row) => row.translations,
        header: t('topic.themes.table.translations'),
        cell: ({ row }) => (
          <TranslationChips translations={row.original.translations} />
        ),
      },
      {
        accessorKey: 'isPublished',
        header: t('topic.themes.table.active'),
        cell: ({ row }) => (
          <ToggleControl
            className="mb-0"
            label=""
            hiddenLabel
            name="isPublished"
            value={row.original.isPublished}
            onChange={(value) => {
              handlePublish(value, row.original)
            }}
          />
        ),
      },
    ],
    [themes]
  )

  const actions = (row: ThemeChannel) => {
    if (verifyPermissionsMaster()) {
      return [
        {
          onClick: () => handleDelete(row),
          label: t('common.delete'),
          icon: 'delete_outline',
          disabled: row.isPublished,
        },
      ]
    }
    return []
  }

  const handlePublish = async (value: boolean, row: ThemeChannel) => {
    const titleKey = value
      ? 'modal.topic.theme.activate.title'
      : 'modal.topic.theme.desactivate.title'
    const subtitleKey = value
      ? 'modal.topic.theme.activate.subtitle'
      : 'modal.topic.theme.desactivate.subtitle'

    const buttonKey = value ? 'common.activate' : 'common.desactivate'

    const notifySuccessKey = value
      ? 'topic.theme.activate.feedback.ok'
      : 'topic.theme.desactivate.feedback.ok'
    const notifyErroKey = value
      ? 'topic.theme.activate.feedback.ok'
      : 'topic.theme.desactivate.feedback.ko'

    showConfirmation({
      title: t(titleKey),
      subtitle: t(subtitleKey, {
        theme: row.translations[0].name,
      }),
      iconName: 'report_problem',
      confirmText: t(buttonKey),
      cancelText: t('common.cancel'),
      onConfirm: async () => {
        try {
          await publishTheme(row.id, channel!.id, value)
          navigate(`.`, {
            state: { channelCurrentTab: ChannelsTabs.THEMES },
          })
          notify.success(t(notifySuccessKey))
        } catch (error) {
          notify.error(t(notifyErroKey))
        }
      },
    })
  }

  const handleDelete = (row: ThemeChannel) => {
    if (row.isPublished) return
    showConfirmation({
      title: t('modalDeleteTheme.title'),
      subtitle: t('modalDeleteTheme.subtitle', {
        name: `${row.translations[0].name}`,
      }),
      text: t('modalDeleteTheme.text'),
      confirmText: t('common.remove'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      confirmActionOptions: {
        confirmText: t('modalDeleteTheme.confirmText'),
        word: t('modalDeleteTheme.word'),
      },
      onConfirm: async () => {
        try {
          await deleteTheme(row.id)
          notify.success(t('modalDeleteTheme.feedback.success'))
        } catch (error) {
          notify.error(t('modalDeleteTheme.feedback.error'))
        }
      },
    })
  }

  const handleSortable = async (data: OnDragEndProps) => {
    const themesSortabled = sortElementsArray(
      themes,
      data.oldIndex,
      data.newIndex
    )
    await sortThemes(themesSortabled)
  }

  return (
    <ContentTable
      title={t('topic.themes.table.title')}
      columns={columns}
      headerChild={
        <Link to={'themes'}>
          <Icon className="table-header__icon-btn" name="edit" />
        </Link>
      }
      data={themes}
      emptyText={t('No hay temas añadidos al canal')}
      actions={actions}
      onDragEnd={handleSortable}
    />
  )
}
