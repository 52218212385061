// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormBrand__text p{margin-bottom:40px;margin-top:8px}.FormBrand__form{min-height:calc(100vh - 550px)}.FormBrand__form .isTranslating .form-label-wrapper{display:none}`, "",{"version":3,"sources":["webpack://./src/app/pages/TopicFamilies/CreateChannels/FormBrand/styles.scss"],"names":[],"mappings":"AAUQ,mBACE,kBAAA,CACA,cAAA,CAIJ,iBACE,8BAAA,CAEE,oDACE,YAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.FormBrand {\n    &__text {\n        p {\n          margin-bottom: 40px;\n          margin-top: 8px;\n        }\n      }\n    \n      &__form {\n        min-height: calc(100vh - 550px);\n        .isTranslating {\n          .form-label-wrapper {\n            display: none;\n          }\n        }\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormBrand__text": `FormBrand__text`,
	"FormBrand__form": `FormBrand__form`,
	"isTranslating": `isTranslating`,
	"form-label-wrapper": `form-label-wrapper`
};
export default ___CSS_LOADER_EXPORT___;
