import * as yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { TFunction } from 'react-i18next'
import { PublisherTypeEnum } from '../../../core/posts/types'
import { useHtmlToString } from '../../../utils/useHtmlToString'

export function buildValidationSchema(
  t: TFunction,
  publisherType: string
): yup.AnyObjectSchema {
  const postShape: ObjectShape = {
    title: yup
      .string()
      .label(t('post.create.title.label'))
      .max(200, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 200 })
      )
      .required(({ label }) => t('validations.required', { label })),
    message: yup
      .string()
      .label(t('post.create.message.label'))
      .required(({ label }) => t('validations.required', { label }))
      .test(
        'post.create.message.label',
        ({ label }) => t('validations.maxLength', { label, maxLength: 5000 }),
        (val) => useHtmlToString(val ?? '').length <= 5000
      ),
    option: yup
      .boolean()
      .required(({ label }) => t('validations.required', { label })),
    dates: yup
      .string()
      .label(t('post.create.title.label'))
      .test(
        'program_date_test',
        t('post.validation.date', {
          label: t('post.date.timezone').toLowerCase(),
        }),
        function (value) {
          if (value) {
            const today = new Date()
            today.setHours(0, 0, 0)
            today.setMilliseconds(0)
            const program = new Date(value)
            program.setHours(0, 0, 0)
            program.setMilliseconds(0)
            if (program.getTime() < today.getTime()) {
              return false
            }
            return true
          }
          return true
        }
      ),
    timezone: yup.string().label(t('post.create.title.label')),
  }
  if (publisherType !== PublisherTypeEnum.TENANT) {
    postShape.channelsArray = yup.array().of(
      yup.object().shape({
        id: yup
          .string()
          .label(t('post.create.channel.single.label'))
          .required(({ label }) => t('validations.required', { label })),
        themes: yup
          .array()
          .label(t('post.create.themes.label'))
          .min(1, ({ label }) => t('validations.required', { label }))
          .required(({ label }) => t('validations.required', { label })),
      })
    )
  }
  return yup.object().shape(postShape)
}
