import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import CorporateInfoPage from '../pages/Config/Corporate'
import ConfigurationFooterPage from '../pages/Config/Footer'
import ConfigurationFooterPagesForm from '../pages/Config/Footer/Shared/Pages/DetailForm'
import OnboardingInfoPage from '../pages/Config/Onboarding'

const ConfigurationRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/corporate" element={<CorporateInfoPage />} />
      <Route path="/footer" element={<ConfigurationFooterPage />} />
      <Route
        path="/footer/:type/:tabId/page/create"
        element={<ConfigurationFooterPagesForm />}
      />
      <Route
        path="/footer/:type/:tabId/page/:id"
        element={<ConfigurationFooterPagesForm />}
      />
      <Route path="/onboarding" element={<OnboardingInfoPage />} />
    </Routes>
  )
}

export default ConfigurationRoutes
