import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Icon } from '@liveconnect/icons'
import { Loader, randomId } from '@liveconnect/communities-ui'
import {
  FormTextInput,
  FormProvider,
  UploadInput,
  MimeType,
} from '@liveconnect/components'

import { EditSponsorForm, FormSponsor } from './types'
import { buildValidationSchema } from './validations'
import { DEFAULT_LANG } from '../../../i18n/config'
import useSections from '../../../core/sections/useSections'
import FormActions from '../../../components/forms/FormActions'
import { getTranslationFromSection } from '../EditSections/utils'
import {
  CreateUpdateSponsorModel,
  Section,
  Subsection,
} from '../../../core/sections/types'
import useNotifications from '../../../utils/notifications/useNotifications'
import useUploadInput from '../../../utils/uploadInput/useUploadInput'
import { Sponsor } from '../../../core/brandSpace/types'
import { useCustomRouter } from '../../../utils/extractParams'
import { BrandTabs } from '../types'
import { useBlockRouteChangeWithDialog } from '../../../utils/routing/useBlockRouteChange'

import './styles.scss'

interface SponsorsScreenProps {
  isNew: boolean
}

const SponsorsScreen: FC<SponsorsScreenProps> = ({ isNew }) => {
  const { t } = useTranslation()
  const {
    sections,
    fetchSections,
    uploadSponsorImage,
    createBatchSponsors,
    updateSponsor,
  } = useSections()
  const { brandId, sectionId, sponsorId } = useParams()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const notify = useNotifications()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })

  const methods = useForm<EditSponsorForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      sponsors: [
        {
          name: '',
          logoUrl: '',
          websiteUrl: '',
          index: 0,
          internalId: randomId(),
        },
      ],
    },
  })
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { isValid, isDirty },
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const { fields, append, remove } = useFieldArray<EditSponsorForm>({
    name: 'sponsors',
    control: control,
    keyName: 'internalId',
  })

  const section: Section | Subsection | undefined = useMemo(() => {
    if (sections.some((s) => s.id === sectionId))
      return sections.filter((s) => s.id === sectionId)[0]
    else {
      for (const sect of sections) {
        if (
          sect.subSections &&
          sect.subSections.some((s) => s.id === sectionId)
        )
          return sect.subSections.filter((s) => s.id === sectionId)[0]
      }
    }
  }, [sections])
  const sectionName: string = useMemo(() => {
    if (!section) return ''
    const defTranslation = getTranslationFromSection(section, DEFAULT_LANG)
    return defTranslation?.name ?? ''
  }, [section])
  const sponsor: Sponsor | undefined = useMemo(() => {
    if (isNew || !section || !section.sponsors) return
    return section.sponsors.filter((s) => s.id === sponsorId)[0]
  }, [section])

  const handleRemoveSponsor = (sponsor: FormSponsor) => {
    const index: number = fields.indexOf(
      fields.filter((f) => f.internalId === sponsor.internalId)[0]
    )
    remove(index)
  }

  const onCancel = () => {
    navigate(`${basePath}/brands/${brandId}/edit`, {
      state: { brandCurrentTab: BrandTabs.SPONSORS },
    })
  }

  const onSubmit = async ({ sponsors }: EditSponsorForm) => {
    setIsSubmitting(true)
    try {
      const imagePromises: Promise<[string, string] | undefined>[] = []
      for (const sponsor of sponsors) {
        if (sponsor.logoUrl.includes('blob:') && brandId)
          imagePromises.push(
            uploadSponsorImage({
              internalId: sponsor.internalId,
              logoUrl: sponsor.logoUrl,
            })
          )
      }
      const imageUrls = await Promise.all(imagePromises)
      const request: CreateUpdateSponsorModel[] = []
      for (const sponsor of sponsors) {
        request.push({
          ...sponsor,
          logoUrl:
            imageUrls.filter(
              (imageAndId?: [string, string]) =>
                imageAndId && sponsor.internalId === imageAndId[0]
            )[0]?.[1] ?? sponsor.logoUrl,
        })
      }
      if (isNew && sectionId && brandId) {
        await createBatchSponsors({
          sectionId,
          brandId,
          sponsors: request,
        })
      } else {
        brandId &&
          (await updateSponsor({
            ...request[0],
            id: sponsors[0].id ?? '',
            brandId: brandId,
          }))
      }
      notify.success(t(`sponsors.${isNew ? 'create' : 'update'}.feedback.ok`))
      reset({})
      navigate(`${basePath}/brands/${brandId}/edit`, {
        state: { brandCurrentTab: BrandTabs.SPONSORS },
      })
      setIsSubmitting(false)
    } catch (e) {
      notify.error(t(`sponsors.${isNew ? 'create' : 'update'}.feedback.ko`))
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (!isNew && sponsor)
      setValue('sponsors', [{ ...sponsor, internalId: randomId() }])
  }, [sponsor])

  useEffect(() => {
    brandId && fetchSections(brandId)
  }, [])

  return (
    <section className="Sponsors">
      <h2 className="Sponsors__header">
        {t(
          `sponsors.title.${isNew ? 'new' : 'edit'}`,
          isNew ? { section: sectionName } : undefined
        )}
        <span className="required">{t('common.required')}</span>
      </h2>
      <div className="Sponsors__body">
        <FormProvider methods={methods}>
          {fields.map((field, index) => (
            <div className="Sponsors__body__sponsor" key={field.internalId}>
              <div className="Sponsors__body__sponsor__close">
                {fields.length > 1 && (
                  <button onClick={() => handleRemoveSponsor(field)}>
                    <Icon name="close" />
                  </button>
                )}
              </div>
              <FormTextInput
                control={control}
                name={`sponsors.${index}.name`}
                label={t('sponsors.fields.name')}
                type="text"
                required={true}
              />
              <FormTextInput
                control={control}
                name={`sponsors.${index}.websiteUrl`}
                label={t('sponsors.fields.website')}
                type="text"
              />
              <UploadInput
                control={control}
                title={t('sponsors.fields.logo-url')}
                name={`sponsors.${index}.logoUrl`}
                accept={accept}
                onRemoveFile={() => setValue(`sponsors.${index}.logoUrl`, '')}
                selectFilesError={(error) =>
                  setError(`sponsors.${index}.logoUrl`, {
                    message: error.message,
                  })
                }
                required
                rules={{
                  image: {
                    maxWidth: 144,
                    maxHeight: 48,
                    maxFileSize: 1,
                  },
                }}
                labels={labels}
              />
            </div>
          ))}
        </FormProvider>
        {isNew && (
          <div className="Sponsors__body__add">
            <button
              onClick={() =>
                append({
                  name: '',
                  logoUrl: '',
                  websiteUrl: '',
                  index: fields.length,
                  internalId: randomId(),
                })
              }
            >
              <Icon name="add" />
              {t('sponsors.add')}
            </button>
          </div>
        )}
      </div>
      <FormActions>
        <button
          className="btn btn-outline-primary"
          disabled={isSubmitting}
          onClick={onCancel}
        >
          {t('common.cancel')}
        </button>
        <button
          className="btn btn-primary"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          {isSubmitting ? <Loader /> : t(isNew ? 'common.add' : 'common.save')}
        </button>
      </FormActions>
    </section>
  )
}

export default SponsorsScreen
