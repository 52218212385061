import { FC } from 'react'

import Menu from '../Menu'
import AppHeader from '../Header'
import Snackbar from '../Snackbar'
import SidePanel from '../SidePanel'
import ConfirmationModal from '../ConfirmationModal'

import './index.scss'

export const scrollableId = 'layout__scrollable'

const Layout: FC = ({ children }) => {
  return (
    <div className="layout">
      <div className="layout__header">
        <AppHeader />
        <div className="layout__snackbar">
          <Snackbar />
        </div>
      </div>

      <SidePanel />
      <ConfirmationModal />
      <div className="layout__scrollable" id={scrollableId}>
        <div className="layout__content container">
          <div className="layout__nav">
            <div className="layout__nav__sticky">
              <Menu />
            </div>
          </div>
          <div className="layout__main">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Layout
