import { useParams } from 'react-router'
import useFetch from '../../../utils/fetch/useFetch'
import { useAppDispatch, useAppSelector } from '../../reduxProvider'

import { hideLoader, setDetail, setAccessDenyInfo, showLoader } from './store'
import {
  AccessDenyInfoResponse,
  ConfigurationCorporateDetailResponse,
  ConfigurationCorporateForm,
  ConfigurationCorporateTranslateForm,
} from './types'

const useConfigurationCorporate = () => {
  const dispatch = useAppDispatch()
  const { isLoaded, detail, accessDenyInfo } = useAppSelector(
    (state) => state.configurationCorporate
  )
  const { tenantId } = useParams()
  const { get, put, post } = useFetch()

  const endpoint = 'tenants'

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const fetchDetail = async (): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: ConfigurationCorporateDetailResponse | undefined =
        await get({
          endpoint: `${endpoint}/${tenantId}`,
        })
      if (response) dispatch(setDetail(response))
    })
  }

  const update = async (data: ConfigurationCorporateForm): Promise<void> => {
    const response: ConfigurationCorporateDetailResponse | undefined =
      await put({
        endpoint: `${endpoint}/${tenantId}`,
        body: data,
      })
    if (response) dispatch(setDetail(response))
  }

  const translate = async (
    data: ConfigurationCorporateTranslateForm
  ): Promise<void> => {
    await put({
      endpoint: `${endpoint}/${tenantId}/translation`,
      body: data,
    })
  }

  const uploadImage = async (image: string) => {
    const form = new FormData()
    const blob = await fetch(image).then((r) => r.blob())
    form.set('imageFile', blob)
    const result: { uri: string } | undefined = await post({
      endpoint: `${endpoint}/image`,
      body: form,
      isMultipart: true,
    })
    return result?.uri ?? ''
  }

  const fetchAccessDenyPageInfo = async (tenantId: string) => {
    await dispatchLoaders(async () => {
      const response: AccessDenyInfoResponse[] | undefined = await get({
        endpoint: `${endpoint}/access-deny-info/${tenantId}`,
      })
      if (response) dispatch(setAccessDenyInfo(response))
    })
  }

  return {
    detail,
    isLoaded,

    fetchDetail,
    update,
    translate,
    uploadImage,

    accessDenyInfo,
    fetchAccessDenyPageInfo,
  }
}

export default useConfigurationCorporate
