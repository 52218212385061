import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Tab, Tabs } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'

import { FormSelect } from '@liveconnect/components'
import WallSchedulesComponent from './WallSchedules/WallSchedules'
import {
  PostStateEnum,
  PublishersResponse,
  SelectType,
} from '../../core/posts/types'
import usePosts from '../../core/posts/usePosts'

import { FilterButton } from '../../components/Buttons/FilterButton'
import { WallFilters, WallFiltersParams } from '../Wall/Filters'
import usePermissions from '../../core/permissions/usePermissions'

import './styles.scss'

enum SchedulesPostsTabs {
  SCHEDULED = 'SCHEDULED',
  DRAFTED = 'DRAFTED',
}
export interface dataTab {
  activeTabParam: SchedulesPostsTabs
}

const SchedulesPosts: FC = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { verifyModeratorWall } = usePermissions()
  const navigate = useNavigate()
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false)
  const [filtersState, setFiltersState] = useState<WallFiltersParams | null>()
  const [valuesPublishers, setValuesPublishers] = useState<SelectType[]>([])
  const [publishers, setPublishers] = useState<PublishersResponse[]>([])
  const [selectedPublish, setSelectedPublish] = useState<PublishersResponse>()
  const { getPublishers } = usePosts()
  const [currentTab, setCurrentTab] = useState<SchedulesPostsTabs>(
    SchedulesPostsTabs.SCHEDULED
  )
  const [selectedChannels, setSelectedChannels] = useState<string[]>([])
  const [selectedFiltersChannelsIds, setSelectedFiltersChannelsIds] = useState<
    string[] | null
  >(null)

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      publisher: '',
    },
  })

  const { control, watch, setValue } = methods

  const watchPublisher = watch('publisher')

  const parsePublishers = (publis: PublishersResponse[]) => {
    return publis?.map((publisher: PublishersResponse) => ({
      value: publisher.id,
      label: publisher.name,
    }))
  }

  const parseChannels = () => {
    const _publisher = publishers.find(
      (publish) => publish.id === watchPublisher
    )
    const parsChannels = _publisher?.channels.map((channel) => ({
      value: channel.id,
      label: channel.name ? channel.name : '',
    }))
    if (parsChannels) {
      if (selectedFiltersChannelsIds) {
        setSelectedChannels(selectedFiltersChannelsIds)
      } else {
        setSelectedChannels(
          _publisher?.channels.map((channel) => channel.id) ?? []
        )
      }
    }
  }

  const fetchPublishers = async () => {
    const _publishers = await getPublishers()
    setPublishers(_publishers)
    const transformValues = parsePublishers(_publishers)
    transformValues.unshift({
      value: '',
      label: t('post.select.allPublishers'),
    })
    transformValues && setValuesPublishers(transformValues)
  }

  const filters = useMemo(() => {
    if (filtersState) {
      return {
        showsponsored: filtersState.showsponsored,
        publisher_external_id:
          filtersState.publisher_external_id === ''
            ? undefined
            : filtersState.publisher_external_id,
        channels_ids:
          filtersState.publisher_external_id === ''
            ? undefined
            : filtersState.channels_ids,
      }
    }
    return {}
  }, [filtersState])

  useEffect(() => {
    fetchPublishers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const _selectedPublish = publishers.find(
      (publish) => publish.id === watchPublisher
    )
    setSelectedPublish(_selectedPublish)
    parseChannels()
  }, [watchPublisher])

  const changeState = (param: SchedulesPostsTabs | any) => {
    setCurrentTab(param)
    navigate('./', {
      state: { activeTabParam: param },
    })
  }

  useEffect(() => {
    if (state && (state as dataTab)) {
      return setCurrentTab((state as dataTab).activeTabParam)
    }
    return setCurrentTab(SchedulesPostsTabs.SCHEDULED)
  }, [state])

  useEffect(() => {
    if (!verifyModeratorWall()) {
      navigate(`${basePath}/access-denied`)
    }
  }, [])

  const onChangeFilters = async (filters: WallFiltersParams) => {
    setSelectedFiltersChannelsIds(filters.channels_ids)
    setValue('publisher', filters.publisher_external_id)
    setFiltersState(filters)
  }

  return (
    <div className="schedulesPosts">
      <WallFilters
        isActive={filtersVisible}
        onClose={() => setFiltersVisible(false)}
        onChange={onChangeFilters}
        publishers={publishers}
        selectedPublisher={watchPublisher}
        selectedChannels={selectedChannels}
        isScheduled
      />
      <div className="row">
        <div className="col-12">
          <h1 id="not-found-title" className="h3">
            {t('schedulePosts.title')}
          </h1>
          <p>{t('schedulePosts.text')}</p>
        </div>
      </div>
      <div className="row align-items-end">
        <div className="col">
          <FormSelect
            control={control}
            label=""
            name="publisher"
            options={valuesPublishers}
            noOptionsMessage={t('select.empty')}
            menuPlacement="bottom"
            isClearable={false}
          />
        </div>
        <div className="col-auto mb-3">
          <FilterButton
            isActive={
              filtersState?.fromdate ||
              filtersState?.todate ||
              filtersState?.showsponsored
                ? true
                : false
            }
            onClick={() => {
              setFiltersVisible(!filtersVisible)
            }}
          />
        </div>
      </div>

      <div className="row contentTabs">
        <div className="col-2"></div>
        <div className="col-9">
          <Tabs
            id="justify-tab-example"
            className="mb-5"
            mountOnEnter
            unmountOnExit
            transition={false}
            activeKey={currentTab}
            onSelect={(key) => changeState(key)}
          >
            <Tab
              eventKey={SchedulesPostsTabs.SCHEDULED}
              title={t('schedulePosts.tab.scheduled')}
            >
              <WallSchedulesComponent
                statePosts={PostStateEnum.SCHEDULED}
                publisherProp={selectedPublish}
                filters={filters}
              />
            </Tab>
            <Tab
              eventKey={SchedulesPostsTabs.DRAFTED}
              title={t('schedulePosts.tab.drafted')}
            >
              <WallSchedulesComponent
                statePosts={PostStateEnum.DRAFTED}
                publisherProp={selectedPublish}
                filters={filters}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default SchedulesPosts
