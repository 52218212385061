import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { ToggleControl, Tooltip } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { ContextMenu } from '@liveconnect/communities-ui'
import {
  DragDropContext,
  Droppable,
  DropResult,
  DroppableProvided,
  Draggable,
  DraggableProvided,
} from 'react-beautiful-dnd'

import EmptyState from '../../../../../components/EmptyState'
import TranslationChips from '../../../../../components/TranslationsChips'
import {
  ElementDragListItem,
  ElementsHighParams,
  ElementsPublishParams,
} from '../../../../../core/elements/types'
import useUi from '../../../../../core/ui/useUi'
import { useCustomRouter } from '../../../../../utils/extractParams'
import SearchTable from '../../../../../components/Table/SearchTable'
import useCopy from '../../../../../utils/copy/useCopy'

import './styles.scss'

type TableDragElementsProps = {
  listElements: ElementDragListItem[]
  confirmDelete: (idElement: string) => void
  confirmPublish: (params: ElementsPublishParams) => void
  confirmHighlighted: (params: ElementsHighParams) => void
  confirmDrag: (param: DropResult) => void
}

const TableDragElements: FC<TableDragElementsProps> = ({
  listElements,
  confirmDelete,
  confirmPublish,
  confirmHighlighted,
  confirmDrag,
}) => {
  const { t } = useTranslation()
  const { basePath } = useCustomRouter()
  const { directoryId } = useParams()
  const { showConfirmation } = useUi()
  const navigate = useNavigate()
  const { handleCopy } = useCopy()

  const handlePublish = (idElement: string, stateToggle: boolean) => {
    const paramsRequest = {
      id: idElement,
      isPublished: stateToggle,
    }
    showConfirmation({
      title: stateToggle
        ? t('directories.elementsList.publish.title')
        : t('directories.elementsList.unpublish.title'),
      subtitle: stateToggle
        ? t('directories.elementsList.publish.subtitle')
        : t('directories.elementsList.unpublish.subtitle'),
      confirmText: stateToggle ? t('common.publish') : t('common.unpublish'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: () => {
        confirmPublish(paramsRequest)
      },
    })
  }

  const handleHighlighted = (idElement: string, stateToggle: boolean) => {
    const paramsRequest = {
      id: idElement,
      isHighlight: stateToggle,
    }
    showConfirmation({
      title: t('directories.elementsList.highlight.title'),
      subtitle: t('directories.elementsList.highlight.subtitle'),
      confirmText: t('common.highlight'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: () => {
        confirmHighlighted(paramsRequest)
      },
    })
  }

  const handleConfirmDelete = (param: string) => {
    showConfirmation({
      title: t('directories.elementsList.delete.title'),
      subtitle: t('directories.elementsList.delete.subtitle'),
      text: t('directories.elementsList.delete.text'),
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: async () => {
        await confirmDelete(param)
      },
    })
  }

  const handleReorderElements = async (result: DropResult) => {
    confirmDrag(result)
  }

  const rowActions = (element: ElementDragListItem) => {
    const actions = []
    actions.push(
      {
        label: t('directories.editElement'),
        onClick: () => {
          navigate(
            `${basePath}/directories/${directoryId}/elements/${element.elementId}`
          )
        },
      },
      {
        label: t('directories.elementsList.copy'),
        onClick: () => {
          handleCopy(element.elementId, t('directories.formElement.entity'))
        },
      },
      {
        label: t('directories.elementsList.delete.title'),
        onClick: () => {
          handleConfirmDelete(element.elementId)
        },
      }
    )
    return actions
  }

  return (
    <div className="TableDragElements">
      <SearchTable
        title={t('directories.manage.elementsList')}
        titleLink={t('directories.createElement')}
        placeHolderText={t('common.search')}
        urlLink={`../${directoryId}/elements/create`}
      />
      <div className="header row">
        <div className="col cell name">
          <p>{t('sections.list.table.columns.name')}</p>
        </div>
        <div className="col-3 cell">
          <p>{t('sections.list.table.columns.website')}</p>
        </div>
        <div className="col-2 cell">
          <p>{t('sections.validations.language')}</p>
        </div>
        <div className="col-1 cell">
          <p>{t('topic.list.headers.isPublished')}</p>
        </div>
        <div className="col-1 cell">
          <p>{t('directories.elementsList.isHighlighted')}</p>
        </div>
        <div className="col-1 cell">{''}</div>
      </div>
      {listElements.length > 0 ? (
        <DragDropContext onDragEnd={handleReorderElements}>
          <Droppable droppableId="elements">
            {(provided: DroppableProvided) => (
              <div
                className="Sponsors__sections__droppable"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {listElements.map((element: ElementDragListItem, index) => (
                  <Draggable
                    key={element.elementId}
                    draggableId={element.elementId}
                    index={index}
                  >
                    {(provided: DraggableProvided) => (
                      <div
                        className="body row"
                        key={`${element.elementId}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div
                          className="col-4 cell drag"
                          {...provided.dragHandleProps}
                        >
                          <Icon className="dragIcon" name="drag_and_drop" />
                          <div className="element-name-and-tooltip">
                            <span className="element-name">{element.name}</span>
                            <div className="contentTooltip ms-2">
                              <span>ID</span>
                              <Tooltip
                                content={t('topic.channel.id', {
                                  idChannel: element.elementId,
                                })}
                              >
                                <Icon name="info_outline" tabIndex={0} />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="col-3 cell">
                          <a
                            href={element.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {element.url}
                          </a>
                        </div>
                        <div className="col-2 cell">
                          <TranslationChips
                            translations={element.translations}
                            isArrayOfStrings
                          />
                        </div>
                        <div className="col-1 cell toggleCell">
                          <ToggleControl
                            className=""
                            label=""
                            hiddenLabel
                            name="isPublished"
                            value={element.isPublished}
                            onChange={() =>
                              handlePublish(
                                element.elementId,
                                !element.isPublished
                              )
                            }
                          />
                        </div>
                        <div className="col-1 cell toggleCell">
                          <ToggleControl
                            className=""
                            label=""
                            hiddenLabel
                            name="isHighlighted"
                            value={element.isHighlight}
                            onChange={() =>
                              handleHighlighted(
                                element.elementId,
                                !element.isHighlight
                              )
                            }
                          />
                        </div>
                        <div className="col-1 cell actions">
                          <ContextMenu items={rowActions(element)} />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="row empty">
          <EmptyState text={t('directories.elementsList.empty')} />
        </div>
      )}
    </div>
  )
}

export default TableDragElements
