import { useAppSelector } from '../reduxProvider'
import { UseTenantResult } from './types'

const useTenant = (): UseTenantResult => {
  const { detail } = useAppSelector((state) => state.tenant)

  return {
    tenant: detail,
  }
}

export default useTenant
