import { FC, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormTextInput, FormProvider, Loader } from '@liveconnect/components'

import { buildValidationSchema } from './validations'
import { useCustomRouter } from '../../../../utils/extractParams'
import useNotifications from '../../../../utils/notifications/useNotifications'
import {
  FormStepChannel,
  TranslationsSuccess,
} from '../../../../core/channels/types'
import useUi from '../../../../core/ui/useUi'
import {
  FetchError,
  ServerValidationError,
} from '../../../../utils/fetch/types'
import useLocalizations from '../../../../core/localizations/useLocalizations'
import { DEFAULT_LANG } from '../../../../i18n/config'
import { useBlockRouteChangeWithDialog } from '../../../../utils/routing/useBlockRouteChange'
import useChannels from '../../../../core/channels/useChannels'
import { FormActionsLang } from '../../../../components/forms/FormActionsLang'
import TranslationCard from '../../../../components/TranslationCard'

import './styles.scss'

interface FormChannelProps {
  channelId: string | undefined
}

const FormChannel: FC<FormChannelProps> = ({ channelId }) => {
  const { sectorId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const notify = useNotifications()
  const { localizations } = useLocalizations()
  const { basePath } = useCustomRouter()
  const { channel, editChannel, addChannel, isLoaded } = useChannels()
  const { showConfirmation } = useUi()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isTranslating, setIsTranslating] = useState<boolean>(false)

  const methods = useForm<FormStepChannel>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      channel: '',
      language:
        (localizations || []).find((item) => item.isDefault)?.isoCode ||
        DEFAULT_LANG,
    },
  })

  const {
    control,
    reset,
    watch,
    setError,
    formState,
    formState: { isDirty, dirtyFields },
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const watchLang = watch('language')

  const defaultChannel = useMemo(() => {
    return (channel?.translations || []).find(
      (item) => item.language === DEFAULT_LANG
    )
  }, [channel])

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  const handleCancel = () => {
    navigate(`${basePath}/sectors`)
  }

  const handleSuccessPut = (
    resPut: TranslationsSuccess,
    nameChannel: string
  ) => {
    navigate(`${basePath}/sectors/${sectorId}/channels/${resPut.id}`)
    if (watchLang === DEFAULT_LANG) {
      notify.success(
        t('topic.channel.edit.success', {
          nameChannel: nameChannel,
        })
      )
    } else {
      notify.success(t('sections.update.translations.feedback.ok'))
    }

    setIsSubmitting(false)
  }

  const controlError409 = (error: FetchError) => {
    notify.warning(t('topic.channel.modal.formError'))
    setIsSubmitting(false)
    const validation = error.body as ServerValidationError[]
    if (validation) {
      setError('channel', {
        type: 'custom',
        message: t(`validations.ENTITY_CONFLICT`, {
          label: t(`topic.channel.modal.name.label`).toLowerCase(),
        }),
      })
    }
  }

  const onSubmit = async (values: FormStepChannel) => {
    setIsSubmitting(true)
    /* CREATE CHANNEL */
    if (!channelId) {
      try {
        if (sectorId) {
          const payload = {
            name: values.channel,
            sectorId: sectorId,
          }
          const response = await addChannel({ ...payload })
          if (response) {
            navigate(`${basePath}/sectors/${sectorId}/channels/${response}`)
            const notifyText = channelId
              ? t('topic.channel.edit.success', {
                  nameChannel: payload.name,
                })
              : t('topic.channel.create.success', {
                  nameChannel: payload.name,
                })
            notify.success(t(notifyText))
            setIsSubmitting(false)
          }
        }
      } catch (error: unknown) {
        const fetchError = error as FetchError
        if (fetchError.status === 409) {
          controlError409(fetchError)
        } else {
          notify.error(
            t('topic.channel.create.error', {
              nameChannel: values.channel,
            })
          )
        }
      }
    } else {
      /* EDIT CHANNEL */
      try {
        if (sectorId) {
          const payload = {
            name: values.channel,
            language: values.language,
          }
          const response = await editChannel(payload, channelId)
          if (response) handleSuccessPut(response, payload.name)
        }
      } catch (error: unknown) {
        const fetchError = error as FetchError
        if (fetchError.status === 409) {
          controlError409(fetchError)
        } else {
          notify.error(
            t('topic.channel.edit.error', {
              nameChannel: values.channel,
            })
          )
        }
      }
    }
  }

  useEffect(() => {
    if (channel && channelId) {
      const i18nData = channel.translations.find(
        (item) => item.language === DEFAULT_LANG
      )
      reset({ channel: i18nData?.name, language: i18nData?.language })
    }
  }, [channel])

  useEffect(() => {
    if (watchLang !== DEFAULT_LANG) {
      setIsTranslating(true)
    } else {
      setIsTranslating(false)
    }
    if (channel && channelId) {
      const i18nData = (channel?.translations || []).find(
        (item) => item.language === watchLang
      )
      reset({
        channel: i18nData?.name || '',
        language: watchLang,
      })
    }
  }, [watchLang])

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          onConfirm: () => {
            if (channel && channelId) {
              const i18nData = channel.translations.find(
                (item) => item.language === DEFAULT_LANG
              )
              reset({ channel: i18nData?.name, language: i18nData?.language })
            }
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  return (
    <>
      {!isLoaded && channelId ? (
        <Loader />
      ) : (
        <div className="FormChannel">
          <FormProvider methods={methods}>
            {channel && channelId && (
              <TranslationCard
                translations={channel.translations}
                lang={watchLang}
                entity={t('topic.channel.entity')}
              />
            )}
            <div className="FormChannel__text">
              <p>
                {channelId
                  ? t('topic.channel.name.title.edit')
                  : t('topic.channel.name.title')}
              </p>
            </div>
            <div className="FormChannel__form">
              <div className="row Channel">
                <div className="col-lg-10">
                  <FormTextInput
                    control={control}
                    name="channel"
                    label={t('topic.channel.modal.name.label')}
                    readOnlyValue={
                      isTranslating ? defaultChannel?.name : undefined
                    }
                    type="text"
                    placeholder={t('topic.channel.modal.name.placeholder')}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <FormActionsLang
              selectName="language"
              selectDisabled={!channelId}
              isLoaded={true}
              methods={methods}
              isSubmitting={isSubmitting}
              onSubmit={onSubmit}
              onCancel={handleCancel}
              onBeforeChange={handleBeforeChangeLang}
            />
          </FormProvider>
        </div>
      )}
    </>
  )
}

export default FormChannel
