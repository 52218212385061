import { Icon, IconName } from '@liveconnect/icons'
import { FC } from 'react'

import './styles.scss'

type ArrowButtonProps = {
  onClick: () => void
  label: string
  icon: IconName
}

const ArrowButton: FC<ArrowButtonProps> = ({ onClick, label, icon }) => {
  return (
    <button className="ArrowButton" onClick={onClick}>
      <p>{label}</p>
      <Icon name={icon} />
    </button>
  )
}

export default ArrowButton
