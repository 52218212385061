import { FC } from 'react'
import classNames from 'classnames'

import './styles.scss'

type HeadingTypes = 'h1' | 'h2' | 'h3' | 'h4'

type HeadingProps = {
  as?: HeadingTypes
  style?: HeadingTypes
  className?: string
  subheading?: string
}

const Heading: FC<HeadingProps> = ({
  as: Tag = 'h1',
  style,
  className,
  children,
  subheading,
}) => {
  const styleClass = style ?? Tag

  return (
    <>
      <Tag
        className={classNames(
          `lc-heading lc-heading--${styleClass}`,
          className
        )}
      >
        {children}
      </Tag>
      {subheading && <p className="lc-subheading">{subheading}</p>}
    </>
  )
}

export default Heading
