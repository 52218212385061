import { DateTimeControl, Tooltip } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { Controller, Control, Path } from 'react-hook-form'

interface FormDateTimeProps<Z> {
  control: Control<Z>
  name: keyof Z
  label: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  floatingFeedback?: boolean
  tooltip?: string | JSX.Element
}

const FormDateTime = <T,>({
  control,
  name,
  label,
  placeholder,
  disabled,
  required,
  floatingFeedback,
  tooltip,
}: FormDateTimeProps<T>) => {
  return (
    <Controller
      control={control}
      name={name as unknown as Path<T>}
      render={({ field, fieldState }) => (
        <DateTimeControl
          label={label}
          name={field.name}
          error={fieldState.error}
          value={new Date((field.value ? field.value : '') as string)}
          onChange={field.onChange}
          onBlur={field.onBlur}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          floatingFeedback={floatingFeedback}
          slotLabel={
            tooltip ? (
              <Tooltip content={tooltip} className="ms-2">
                <Icon name="info_outline" tabIndex={0} />
              </Tooltip>
            ) : undefined
          }
        />
      )}
    />
  )
}

export default FormDateTime
