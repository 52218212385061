import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import ActivitiesListScreen from '../pages/Activities'
import ActivityDetailForm from '../pages/Activities/DetailForm'
import CollaboratorsDetailForm from '../pages/Activities/DetailForm/Collaborators/DetailForm'

const ActivitiesRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ActivitiesListScreen />} />
      <Route path="/create" element={<ActivityDetailForm />} />
      <Route path="/:activityId/edit" element={<ActivityDetailForm />} />
      <Route
        path="/:activityId"
        element={<ActivityDetailForm isDetail={true} />}
      />
      <Route
        path="/:activityId/collaborators/:collaboratorTypeId/create"
        element={<CollaboratorsDetailForm />}
      />
      <Route
        path="/:activityId/collaborators/:collaboratorTypeId/:collaboratorId"
        element={<CollaboratorsDetailForm />}
      />
    </Routes>
  )
}

export default ActivitiesRoutes
