import { FC } from 'react'
import classNames from 'classnames'

import { TabLink } from '../../types'

import './styles.scss'

interface TabsLinkProps {
  data: TabLink
  active: string
  setActive: (tabName: string) => void
}

export const TabsLink: FC<TabsLinkProps> = ({ data, active, setActive }) => {
  const { id, name } = data
  return (
    <button
      className={classNames('tabs-nav__link', {
        'tabs-nav__link--active': active == id,
      })}
      type="button"
      onClick={() => setActive(id)}
    >
      {name}
    </button>
  )
}
