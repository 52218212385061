import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import DirectoriesList from '../pages/Directories/DirectoriesList'
import CreateDirectory from '../pages/Directories/FormDirectory/CreateDirectory'
import EditDirectory from '../pages/Directories/FormDirectory/EditDirectory'
import ManageDirectory from '../pages/Directories/Manage'
import FormElement from '../pages/Directories/FormElement'
import FormMultimedia from '../pages/Directories/FormElement/MultimediaTab/FormMultimedia'

const DirectoriesRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<DirectoriesList />} />
      <Route path="/new" element={<CreateDirectory />} />
      <Route path="/:directoryId" element={<EditDirectory />} />
      <Route path="/:directoryId/manage" element={<ManageDirectory />} />
      <Route path="/:directoryId/elements/create" element={<FormElement />} />
      <Route
        path="/:directoryId/elements/:elementId"
        element={<FormElement />}
      />
      <Route
        path="/:directoryId/elements/:elementId/multimedia/create"
        element={<FormMultimedia />}
      />
      <Route
        path="/:directoryId/elements/:elementId/multimedia/:multimediaId"
        element={<FormMultimedia />}
      />
    </Routes>
  )
}

export default DirectoriesRoutes
