import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { useHtmlToString } from '../../../../../utils/useHtmlToString'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    name: yup
      .string()
      .label(t('activity.collaborators.detailForm.name.label'))
      .required(({ label }) => t('validations.required', { label })),
    description: yup
      .string()
      .label(t('activity.collaborators.detailForm.description.label'))
      .required(({ label }) => t('validations.required', { label }))
      .test(
        'activity.collaborators.detailForm.description.label',
        ({ label }) => t('validations.maxLength', { label, maxLength: 200 }),
        (val) => useHtmlToString(val ?? '').length <= 200
      ),
  })
}
