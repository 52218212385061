import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Icon } from '@liveconnect/icons'

import './styles.scss'

interface FilterButtonProps {
  isActive: boolean
  onClick: () => void
}

export const FilterButton: FC<FilterButtonProps> = ({ isActive, onClick }) => {
  const { t } = useTranslation()
  return (
    <button
      type="button"
      className={classnames('btn btn--filter', {
        'btn--filter-active': isActive,
      })}
      onClick={onClick}
    >
      <Icon name="tune" />
      <span>{t('common.filter')}</span>
    </button>
  )
}
