import { matchPath, useLocation } from 'react-router-dom'
import { ChannelsTabs } from '../core/channels/types'
import { BrandTabs } from '../pages/Brand/types'
import { FormElementTabs } from '../pages/Directories/FormElement'

interface CommunitiesParams {
  tenantId: string
  basePath: string
}

export const tenantsPathPattern = '/t/:tenantId'

function extractParams(pathname: string): CommunitiesParams {
  const match = matchPath({ path: tenantsPathPattern }, pathname) ??
    matchPath({ path: `${tenantsPathPattern}`, end: false }, pathname) ?? {
      params: { tenantId: '', basePath: '' },
    }

  const { params } = match
  const tenantId = params.tenantId || ''
  const basePath = `/t/${tenantId}`

  return {
    tenantId,
    basePath,
  }
}

export function useCustomRouter(): CommunitiesParams {
  const { pathname } = useLocation()
  const communitiesParams = extractParams(pathname)

  return communitiesParams
}

export interface NavigationState {
  brandCurrentTab: BrandTabs
  channelCurrentTab: ChannelsTabs
  elementCurrentTab: FormElementTabs
}
