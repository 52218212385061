// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormChannel__text p{margin-bottom:40px;margin-top:8px}.FormChannel__form{min-height:calc(100vh - 550px)}`, "",{"version":3,"sources":["webpack://./src/app/pages/TopicFamilies/CreateChannels/FormChannel/styles.scss"],"names":[],"mappings":"AAUQ,qBACE,kBAAA,CACA,cAAA,CAIJ,mBACE,8BAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.FormChannel {\n    &__text {\n        p {\n          margin-bottom: 40px;\n          margin-top: 8px;\n        }\n      }\n    \n      &__form {\n        min-height: calc(100vh - 550px);\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormChannel__text": `FormChannel__text`,
	"FormChannel__form": `FormChannel__form`
};
export default ___CSS_LOADER_EXPORT___;
