import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    contributorType: yup
      .string()
      .label(t('activity.collaborators.typeForm.name.label'))
      .required(({ label }) => t('validations.required', { label })),
  })
}
