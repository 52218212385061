import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  FormSelect,
  FormTextInput,
  FormProvider,
  Tooltip,
  UploadInput,
  MimeType,
} from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import { Main } from '../../../components/Main'
import { Loader } from '../../../components/Loader'
import { buildValidationSchema } from './validations'
import { TopicFamilyForm } from '../../../core/topicFamilies/types'
import useTopicFamilies from '../../../core/topicFamilies/useTopicFamilies'
import useNotifications from '../../../utils/notifications/useNotifications'
import FormActions from '../../../components/forms/FormActions'
import HeadLineWithoutButton from '../../../components/Headline/WithoutButton'
import useLocalizations from '../../../core/localizations/useLocalizations'
import { DEFAULT_LANG } from '../../../i18n/config'
import { useCustomRouter } from '../../../utils/extractParams'
import { FetchError } from '../../../utils/fetch/types'
import { useBlockRouteChangeWithDialog } from '../../../utils/routing/useBlockRouteChange'
import useUploadInput from '../../../utils/uploadInput/useUploadInput'

import './styles.scss'

const CreateTopic: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const notify = useNotifications()
  const { localizations } = useLocalizations()
  const { basePath } = useCustomRouter()
  const { createTopicFamily, uploadImage } = useTopicFamilies()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })

  const methods = useForm<TopicFamilyForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      language:
        (localizations || []).find((item) => item.isDefault)?.isoCode ||
        DEFAULT_LANG,
    },
  })
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { isValid, isDirty },
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const handleCancel = () => {
    navigate(`${basePath}/sectors`)
  }

  const onSubmit = async (values: TopicFamilyForm) => {
    setIsSubmitting(true)
    try {
      if (values?.imageUrl?.includes('blob:')) {
        const imageUrl = await uploadImage(values.imageUrl as string)
        values = { ...values, imageUrl }
      }
      const response = await createTopicFamily(values)
      if (response) {
        navigate(`${basePath}/sectors/${response.id}`)
        notify.success(t('topic.topic.modal.succeessFeedback'))
        setIsSubmitting(false)
      } else setIsSubmitting(false)
    } catch (error: unknown) {
      setIsSubmitting(false)
      const fetchError = error as FetchError
      if (fetchError.status === 409) {
        notify.warning(t('topic.channel.modal.formError'))
        setError('name', {
          type: 'custom',
          message: t(`validations.ENTITY_CONFLICT`, {
            label: t(`topic.topic.modal.name`).toLowerCase(),
          }),
        })
      } else {
        notify.error(t('topic.topic.modal.unknownError'))
      }
    }
  }

  const renderLanguageSelector = () => {
    return (
      <>
        <FormSelect
          control={control}
          name="language"
          label=""
          options={localizations.map((item) => ({
            label: t(`localizations.${item.isoCode}`),
            value: item.isoCode,
          }))}
          disabled={true}
          noOptionsMessage={t('select.empty')}
          menuPlacement="top"
          isClearable={false}
        />
        <Tooltip content={t('brand.changeLanguage.tooltip')} className="ms-2">
          <Icon name="info_outline" tabIndex={0} />
        </Tooltip>
      </>
    )
  }

  return (
    <Main ariaLabelledby="list-topics-title">
      <HeadLineWithoutButton
        title={t('topic.topic.modal.title')}
        leyend={t('common.required')}
      />
      <FormProvider methods={methods}>
        <div className="CreateTopic__form">
          <div className="row">
            <div className="col-12">
              <FormTextInput
                control={control}
                name="name"
                label={t('topic.topic.modal.name')}
                placeholder={t('topic.topic.modal.placeholder')}
                type="text"
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 upload__content">
              <UploadInput
                control={control}
                title={t('topic.topic.modal.image')}
                name="imageUrl"
                accept={accept}
                onRemoveFile={() => setValue('imageUrl', '')}
                selectFilesError={(error) =>
                  setError('imageUrl', {
                    message: error.message,
                  })
                }
                labels={labels}
              />
            </div>
          </div>
        </div>
        <FormActions languageSelector={renderLanguageSelector()}>
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-outline-primary"
          >
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {isSubmitting ? <Loader /> : t('common.save')}
          </button>
        </FormActions>
      </FormProvider>
    </Main>
  )
}

export default CreateTopic
