import { FC } from 'react'
import classnames from 'classnames'

import './index.scss'

type LoaderProps = {
  className?: string
}
export const Loader: FC<LoaderProps> = ({ className }) => {
  return (
    <div
      className={classnames(
        'Loader d-flex h-100 w-100 justify-content-center align-items-center',
        className
      )}
    >
      <div className="spinner-border text-danger" role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  )
}
