import { useTranslation } from 'react-i18next'
import useNotifications from '../notifications/useNotifications'

const useCopy = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const handleCopy = (text: string, entity: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() =>
        notify.success(
          t('topic.channel.copy.success', {
            entity: entity,
          })
        )
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((error) => notify.error(t('common.unknownError')))
  }

  return {
    handleCopy,
  }
}

export default useCopy
