import { FC, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { TabsComponent } from '@liveconnect/communities-ui'
import useChannels from '../../../core/channels/useChannels'
import { NavigationState } from '../../../utils/extractParams'
import useBrandsInfo from '../../../core/brandSpace/useBrandSpace'
import { Main } from '../../../components/Main'
import useTopicFamilies from '../../../core/topicFamilies/useTopicFamilies'
import { DEFAULT_LANG } from '../../../i18n/config'
import { TabComponent } from '../../../components/Tabs/types'
import HeadLineWithoutButton from '../../../components/Headline/WithoutButton'
import { ChannelsTabs } from '../../../core/channels/types'
import { TopicFamilyDetail } from '../../../core/topicFamilies/types'
import FormChannel from './FormChannel'
import { LinkedThemes } from './LinkedThemes'
import FormBrand from './FormBrand'

import './styles.scss'

const CreateChannels: FC = () => {
  const { sectorId, channelId } = useParams()
  const { t } = useTranslation()
  const { state } = useLocation()
  const { getTopicFamily } = useTopicFamilies()
  const { channel, fetchChannelDetail } = useChannels()
  const { fetchBrandsChannels, isLoaded } = useBrandsInfo()
  const [sector, setSector] = useState<TopicFamilyDetail>()
  const [tabs, setTabs] = useState<TabComponent[]>([])

  const initSector = async () => {
    if (sectorId) {
      const response = await getTopicFamily(sectorId)
      setSector(response)
    }
  }

  const getActiveTab = (): string | undefined => {
    if (state) {
      const channelCurrentTab = (state as NavigationState).channelCurrentTab
      return channelCurrentTab
    }
  }

  const disabledTab = useMemo(() => {
    if (channel && channel.themes && channel.themes.length === 0) {
      return true
    }
    return false
  }, [channel])

  useEffect(() => {
    initSector()
  }, [sectorId])

  useEffect(() => {
    fetchBrandsChannels()
  }, [])

  useEffect(() => {
    if (sectorId && channelId && channelId !== channel?.id) {
      channelId && fetchChannelDetail(channelId, sectorId)
    }
  }, [channelId])

  useEffect(() => {
    setTabs([
      {
        id: ChannelsTabs.CHANNEL_INFO,
        label: t('topic.channel.entity'),
        component: <FormChannel key={channelId} channelId={channelId} />,
        disabled: !isLoaded,
      },
      {
        id: ChannelsTabs.THEMES,
        label: t('brand.edit.brandTopics'),
        component: <LinkedThemes />,
        disabled: !channelId,
      },
      {
        id: ChannelsTabs.BRANDSPACES,
        label: t('navMenu.brand'),
        component: <FormBrand />,
        disabled: disabledTab || !channelId ? true : false,
      },
    ])
  }, [isLoaded, channelId, disabledTab])

  const sectorTranslation = sector?.translations.find(
    (t) => t.language === DEFAULT_LANG
  )

  const tranformText = () => {
    let text = ''
    channelId
      ? (text = t('topic.channel.modal.title.edit'))
      : (text = t('topic.channel.modal.title'))
    text = `${text} ${sectorTranslation?.name}`
    return text
  }

  return (
    <Main ariaLabelledby="list-topics-title" className="CreateChannels">
      <HeadLineWithoutButton
        title={tranformText()}
        leyend={t('common.required')}
        text={
          channelId
            ? t('topic.channel.subtitle.edit')
            : t('topic.channel.subtitle')
        }
      />
      <TabsComponent tabs={tabs} getActiveTab={getActiveTab} />
    </Main>
  )
}

export default CreateChannels
