import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import {
  DeleteCommentPayload,
  PostListPayload,
  PostsState,
  UpdateCommentPayload,
  DisablePublisherPayload,
  SetPostCommentsPayload,
  PostDetail,
  PostMetrics,
} from './types'

const initialState: PostsState = {
  detail: null,
  list: [],
  metrics: null,
  page: 1,
  pageSize: 5,
  hasNextPage: false,
  isLoaded: false,
}

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setDetail: (state, action: PayloadAction<PostDetail>) => {
      state.detail = action.payload
    },
    setMetrics: (state, action: PayloadAction<PostMetrics>) => {
      state.metrics = action.payload
    },
    setList: (state, action: PayloadAction<PostListPayload>) => {
      state.list = action.payload.list.map((item) => ({
        ...item,
        comments: [],
      }))
      state.page = action.payload.page
      state.pageSize = action.payload.pageSize
      state.hasNextPage = action.payload.hasNextPage
    },
    setPostComments: (state, action: PayloadAction<SetPostCommentsPayload>) => {
      const postIndex = state.list.findIndex(
        (post) => post.id === action.payload.postId
      )

      state.list[postIndex].comments = action.payload.list
    },
    addPostComment: (state, action: PayloadAction<UpdateCommentPayload>) => {
      const postIndex = state.list.findIndex(
        (post) => post.id === action.payload.postId
      )

      if (typeof action.payload.comment === 'undefined') return

      state.list[postIndex].comments.unshift(action.payload.comment)
      state.list[postIndex].totalComments += 1
    },
    deletePostComment: (state, action: PayloadAction<DeleteCommentPayload>) => {
      const postIndex = state.list.findIndex(
        (post) => post.id === action.payload.postId
      )
      const commentIndex = state.list[postIndex].comments.findIndex(
        (comment) => comment.id === action.payload.commentId
      )
      state.list[postIndex].comments.splice(commentIndex, 1)
      state.list[postIndex].totalComments -= 1
    },
    updatePostComment: (state, action: PayloadAction<UpdateCommentPayload>) => {
      const postIndex = state.list.findIndex(
        (post) => post.id === action.payload.postId
      )

      if (typeof action.payload.comment === 'undefined') return

      const commentIndex = state.list[postIndex].comments.findIndex(
        (comment) =>
          action.payload.comment && comment.id === action.payload.comment.id
      )
      state.list[postIndex].comments[commentIndex] = action.payload.comment
    },
    disablePublisher: (
      state,
      action: PayloadAction<DisablePublisherPayload>
    ) => {
      for (let i = 0; i < state.list.length; i++) {
        const post = state.list[i]
        if (post.publisher.id === action.payload.publisherId)
          state.list[i].publisher.isEnabled = false
        for (let j = 0; j < post.comments.length; j++) {
          const comment = post.comments[j]
          if (comment.publisher.id === action.payload.publisherId)
            state.list[i].comments[j].publisher.isEnabled = false
        }
      }
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setDetail,
  setMetrics,
  setList,
  setPostComments,
  addPostComment,
  deletePostComment,
  updatePostComment,
  disablePublisher,
} = postsSlice.actions

export const selectPosts = (state: RootState) => state.posts

export default postsSlice.reducer
