import { FC, useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'

import { Pagination, Loader } from '@liveconnect/components'
import { ContentTable } from '@liveconnect/communities-ui'

import useUi from '../../../../core/ui/useUi'
import useActivities from '../../../../core/activities/useActivities'

import {
  Attendee,
  AttendeesListParams,
} from '../../../../core/activities/types'
import useNotifications from '../../../../utils/notifications/useNotifications'

import './styles.scss'

interface ActivityFormAttendeesProps {
  isDetail?: boolean
}

const ActivityFormAttendees: FC<ActivityFormAttendeesProps> = ({
  isDetail,
}) => {
  const { t } = useTranslation()
  const { showConfirmation } = useUi()
  const { getAttendees, deleteAttendee, attendees } = useActivities()
  const { activityId } = useParams()
  const notify = useNotifications()
  const [loading, setLoading] = useState<boolean>(false)

  const handleDelete = (attendee: Attendee) => {
    showConfirmation({
      title: t('common.warning'),
      subtitle: t('activity.form.attendees.modal.subtitle'),
      text: t('activity.form.attendees.modal.text'),
      confirmText: t('common.yes'),
      cancelText: t('common.no'),
      iconName: 'report_problem',
      onConfirm: () => {
        confirmDelete(attendee)
      },
    })
  }

  const confirmDelete = async (attendee: Attendee) => {
    try {
      if (activityId) await deleteAttendee(activityId, attendee.id)
      notify.success(t('activity.form.attendees.delete.toast.ok'))
    } catch (error) {
      notify.error(t('activity.form.attendees.delete.toast.ko'))
    }
  }

  const actions = (row: Attendee) => {
    if (isDetail) return []
    return [
      {
        onClick: () => handleDelete(row),
        label: t('common.delete'),
        icon: 'delete_outline',
      },
    ]
  }

  const columns: ColumnDef<Attendee>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('activity.form.attendees.data.name'),
      },
      {
        accessorKey: 'surname',
        header: t('activity.form.attendees.data.surname'),
      },
      {
        accessorKey: 'email',
        header: t('activity.form.attendees.data.email'),
      },
    ],
    []
  )

  const getData = async (
    params: AttendeesListParams = { page: 1, page_size: 12 }
  ) => {
    if (activityId) {
    }
    setLoading(true)
    try {
      activityId && (await getAttendees(activityId, params))
    } catch (e) {
      notify.error(t('activity.form.attendees.load.error'))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [activityId])

  return (
    <div className="AFA">
      {(!attendees || loading) && <Loader />}

      {!isDetail && (
        <p className="mb-5">{t('activity.form.attendees.data.subtitle')}</p>
      )}

      <div className="AFA__section">
        <ContentTable
          title={t('activity.form.attendees.data.title')}
          columns={columns}
          data={attendees?.items ?? []}
          emptyText={t('activities.noAttendees')}
          actions={actions}
        />

        {attendees && (
          <Pagination
            currentPage={attendees.page - 1}
            totalPages={attendees.availablePages}
            totalItems={attendees.availableItems}
            pageSize={attendees.pageSize}
            singlePageText={(count) => t('common.pager.singlePage', { count })}
            multiplePageText={(start, end, total) =>
              t('common.pager.multiplePage', { start, end, total })
            }
            onChange={(pageIndex) =>
              getData({ page: pageIndex + 1, page_size: 12 })
            }
          />
        )}
      </div>
    </div>
  )
}

export default ActivityFormAttendees
