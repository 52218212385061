import { FC, useEffect, useMemo, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  SelectControl,
  Loader,
  Avatar,
  MultiSelectTreeControl,
} from '@liveconnect/components'
import { isNullOrEmpty } from '@liveconnect/communities-ui'
import { Icon } from '@liveconnect/icons'
import { FilterButton } from '../../components/Buttons/FilterButton'

import { Main } from '../../components/Main'
import { PostContainer } from './Post'
import usePosts from '../../core/posts/usePosts'
import {
  PublishersResponse,
  SelectType,
  PublisherTypeEnum,
  Publisher,
  PostParams,
  EditPostFnParams,
  PostStateEnum,
} from '../../core/posts/types'
import EditPost from './EditPost'
import { WallFilters, WallFiltersParams } from './Filters'
import { Channel } from '../../core/channels/types'
import usePermissions from '../../core/permissions/usePermissions'

import './styles.scss'
import { useCustomRouter } from '../../utils/extractParams'

interface EditingPostInterface {
  channels: Channel[]
  publisher: Publisher
  title: string
  message: string
  image: string
  id: string
  isHighlighted: boolean
  selectedThemes: string[]
  video: string
  publishDateTime: string | null
  state: PostStateEnum
  timezone: string
}

const WallScreen: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const { verifyModeratorWall } = usePermissions()
  const {
    postList,
    hasNextPostsPage,
    fetchPosts,
    fetchNextPagePosts,
    getPublishers,
    isLoaded,
  } = usePosts()
  const firstPostRequestGoesWithChannels = useRef<boolean>(true)
  const [selectedPublisherId, setSelectedPublisherId] = useState<string>('')
  const [selectedPublisher, setSelectedPublisher] =
    useState<PublishersResponse>({
      id: '',
      name: '',
      type: '',
      shortDescription: '',
      channels: [],
      profileImageUrl: '',
    })
  const [selectedChannels, setSelectedChannels] = useState<string[]>([])
  const [selectedFiltersChannelsIds, setSelectedFiltersChannelsIds] = useState<
    string[] | null
  >(null)
  const [publishers, setPublishers] = useState<PublishersResponse[]>([])
  const [valuesPublishers, setValuesPublishers] = useState<SelectType[]>([])
  const [valuesChannels, setValuesChannels] = useState<SelectType[]>([])
  const [isIfemaPublish, setisIfemaPublish] = useState<boolean>(false)
  const [imageBrand, setImageBrand] = useState<string>('')
  const [editingPost, setEditingPost] = useState<EditingPostInterface | null>(
    null
  )
  const [filtersState, setFiltersState] = useState<WallFiltersParams | null>()
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false)

  const parsePublishers = (publis: PublishersResponse[]) => {
    return publis?.map((publisher: PublishersResponse) => ({
      value: publisher.id,
      label: publisher.name,
    }))
  }

  const placeholderChannels = useMemo(() => {
    if (selectedChannels.length === valuesChannels.length) {
      return t('post.header.select.channels.all')
    }
    if (selectedChannels.length > 0) {
      return t('post.header.select.channels', {
        count: selectedChannels.length,
      })
    }
    return t('post.header.select.channels.empty')
  }, [selectedChannels, valuesChannels])

  const fetchPublishers = async () => {
    const _publishers = await getPublishers()
    setPublishers(_publishers)
    const transformValues = parsePublishers(_publishers)
    transformValues && setValuesPublishers(transformValues)
    const ifemaPublish = _publishers.find(
      (publish) => publish.type === PublisherTypeEnum.TENANT
    )
    if (ifemaPublish) {
      setisIfemaPublish(true)
      setSelectedPublisherId(ifemaPublish.id)
      const transformChannels = ifemaPublish?.channels.map(
        (channel) => channel.id
      )
      transformChannels && setSelectedChannels(transformChannels)
    } else {
      setisIfemaPublish(false)
      setSelectedPublisherId(_publishers[0].id)
    }
  }

  const parseChannels = () => {
    const _publisher = publishers.find(
      (publish) => publish.id === selectedPublisherId
    )
    _publisher && setSelectedPublisher(_publisher)
    _publisher && setImageBrand(_publisher.profileImageUrl)
    const parsChannels = _publisher?.channels.map((channel) => ({
      value: channel.id,
      label: channel.name ? channel.name : '',
      title: channel.name ? channel.name : '',
    }))
    if (parsChannels) {
      setValuesChannels(parsChannels)
      if (selectedFiltersChannelsIds) {
        setSelectedChannels(selectedFiltersChannelsIds)
      } else {
        setSelectedChannels(
          _publisher?.channels.map((channel) => channel.id) ?? []
        )
      }
      setSelectedFiltersChannelsIds(null)
    }
  }

  const fetchParams = useMemo(() => {
    const ifemaPublish = publishers.find(
      (publish) => publish.type === PublisherTypeEnum.TENANT
    )
    const publisher_external_id = selectedPublisherId
    const publisher_type =
      selectedPublisherId === ifemaPublish?.id ||
      selectedPublisherId === undefined
        ? PublisherTypeEnum.TENANT
        : PublisherTypeEnum.BRAND
    const params: PostParams = {
      publisher_external_id,
      publisher_type,
      is_pending_post: false,
      showsponsored: filtersState?.showsponsored,
      fromdate: filtersState?.fromdate
        ? new Date(filtersState.fromdate).toISOString()
        : undefined,
      todate: filtersState?.todate
        ? new Date(filtersState.todate).toISOString()
        : undefined,
    }
    if (selectedChannels.length > 0) {
      params.channels_ids = selectedChannels
    }
    /* params.state = PostStateEnum.PUBLISHED */
    return params
  }, [selectedChannels, filtersState])

  const onChangeFilters = async (filters: WallFiltersParams) => {
    const updateChannels = filters.publisher_external_id === selectedPublisherId
    setSelectedFiltersChannelsIds(filters.channels_ids)
    setSelectedPublisherId(filters.publisher_external_id)
    setFiltersState(filters)
    if (updateChannels) {
      setSelectedChannels(filters.channels_ids)
    }
  }

  const editPost = ({
    publisher,
    channels,
    title,
    message,
    themes,
    image,
    isHighlighted,
    video,
    publishDateTime,
    state,
    timezone,
    id,
  }: EditPostFnParams) => {
    setEditingPost({
      channels,
      publisher,
      title,
      message,
      image,
      id,
      isHighlighted,
      selectedThemes: themes.map((theme) => theme.id),
      video,
      publishDateTime,
      state,
      timezone,
    })
  }

  useEffect(() => {
    !selectedPublisherId &&
      selectedChannels.length > 0 &&
      setSelectedChannels([])
    const ifemaPublish = publishers.find(
      (publish) => publish.type === PublisherTypeEnum.TENANT
    )
    selectedPublisherId === ifemaPublish?.id ||
    selectedPublisherId === undefined
      ? setisIfemaPublish(true)
      : setisIfemaPublish(false)
    parseChannels()
  }, [selectedPublisherId])

  useEffect(() => {
    if (isNullOrEmpty(selectedPublisherId)) return
    if (
      firstPostRequestGoesWithChannels.current &&
      selectedChannels.length === 0
    )
      return
    firstPostRequestGoesWithChannels.current = false
    fetchPosts(fetchParams)
  }, [fetchParams])

  useEffect(() => {
    fetchPublishers()
  }, [])

  useEffect(() => {
    if (!verifyModeratorWall()) {
      navigate(`${basePath}/access-denied`)
    }
  }, [])

  return (
    <Main ariaLabelledby="home" className="Home__content">
      <WallFilters
        isActive={filtersVisible}
        onClose={() => setFiltersVisible(false)}
        onChange={onChangeFilters}
        publishers={publishers}
        selectedPublisher={selectedPublisherId}
        selectedChannels={selectedChannels}
      />
      <div className="row Home__header">
        <div className="title">
          <h2>{t('post.header.title')}</h2>
          <p>{t('common.required')}</p>
        </div>
        <div className="subtitle">
          <p>{t('post.header.subtitle')}</p>
          <button
            className="btn btn-link"
            onClick={() => navigate('shedules-posts')}
          >
            {t('post.link.posts.schedules')}
            <Icon name="chevron_right" />
          </button>
        </div>
        <div className="selectContent row align-items-end">
          <div className="col-lg">
            <SelectControl
              name="publisher"
              label={t('post.header.select.publisher')}
              placeholder={t('post.header.select.publisher')}
              options={valuesPublishers}
              onChange={setSelectedPublisherId}
              value={selectedPublisherId}
              required={true}
              noOptionsMessage={t('select.empty')}
              isClearable={false}
            />
          </div>
          <div className="col-lg">
            <MultiSelectTreeControl
              name="channel"
              value={selectedChannels}
              onChange={setSelectedChannels}
              label={t('post.header.select.channels.label')}
              options={valuesChannels}
              placeholder={t('post.header.select.channels.empty')}
              placeholderInfo={() => placeholderChannels}
              disabled={!selectedPublisherId || selectedPublisherId === ''}
              required={true}
            />
          </div>
          <div className="col-lg-auto mb-4">
            <FilterButton
              isActive={
                filtersState?.fromdate ||
                filtersState?.todate ||
                filtersState?.showsponsored
                  ? true
                  : false
              }
              onClick={() => {
                setFiltersVisible(!filtersVisible)
              }}
            />
          </div>
        </div>
      </div>
      {!isLoaded ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-7">
            <button
              className={classNames('Home__create-post', {
                'Home__disabled-button':
                  !selectedPublisherId || !selectedChannels.length,
              })}
              onClick={() =>
                navigate('./post/create', {
                  state: { selectedPublisher: selectedPublisher },
                })
              }
              disabled={!selectedPublisherId || !selectedChannels.length}
            >
              <Avatar
                name={
                  valuesPublishers.find((p) => p.value === selectedPublisherId)
                    ?.label ?? ''
                }
                surname={''}
                imageUrl={imageBrand}
                size="md"
              />
              <div className="Home__create-post__fakeInput">
                {t('post.create_text')}
              </div>
            </button>
            <InfiniteScroll
              dataLength={postList.length}
              next={() => fetchNextPagePosts(fetchParams)}
              hasMore={hasNextPostsPage}
              loader={<Loader />}
              scrollableTarget="layout__scrollable"
            >
              {postList && (
                <div>
                  {postList.map((post) => (
                    <PostContainer
                      key={post.id}
                      {...post}
                      selectedPublisher={selectedPublisher}
                      publisherType={
                        isIfemaPublish
                          ? PublisherTypeEnum.TENANT
                          : PublisherTypeEnum.BRAND
                      }
                      openEdit={editPost}
                      fetchPosts={() => fetchPosts(fetchParams)}
                    />
                  ))}
                </div>
              )}
            </InfiniteScroll>
          </div>
          <div className="col-lg-3"></div>
        </div>
      )}
      {editingPost && (
        <EditPost
          onClose={() => {
            setEditingPost(null)
          }}
          onCreate={() => {
            fetchPosts(fetchParams)
          }}
          selectedPublisher={selectedPublisherId}
          isIfemaPublish={isIfemaPublish}
          {...editingPost}
        />
      )}
    </Main>
  )
}

export default WallScreen
