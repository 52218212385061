import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import './index.scss'
import Heading from '../Heading'

interface MainProps {
  ariaLabelledby: string
  className?: string
  title?: string
  subtitle?: string
  isVisibleRequiredFieldsLeyend?: boolean
  breadcrumbs?: string[]
  rightColumn?: ReactNode
}

export const Main: FC<MainProps> = ({
  ariaLabelledby,
  className,
  children,
  title,
  subtitle,
  isVisibleRequiredFieldsLeyend = false,
  breadcrumbs,
  rightColumn,
}) => {
  const { t } = useTranslation()
  return (
    <main
      aria-labelledby={ariaLabelledby}
      className={classNames('container-fluid lc-main', className)}
    >
      {breadcrumbs && breadcrumbs.length > 0 && (
        <p className="lc-main__breadcrumbs">{breadcrumbs.join(' / ')}</p>
      )}
      {title && (
        <div className="row mb-4">
          <div className="col">
            <Heading>{title}</Heading>
            {subtitle && <p>{subtitle}</p>}
          </div>
          <div className="col-auto">
            {isVisibleRequiredFieldsLeyend && (
              <span className="lc-main__required">{t('common.required')}</span>
            )}
            {rightColumn}
          </div>
        </div>
      )}
      {children}
    </main>
  )
}
