import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import { Badge, BadgeMembersList, BadgesList, BadgesState } from './types'

const initialState: BadgesState = {
  detail: null,
  members: {
    page: 1,
    pageSize: 20,
    items: [],
    availableItems: 0,
    availablePages: 0,
  },
  list: {
    page: 1,
    pageSize: 4,
    items: [],
    availableItems: 0,
    availablePages: 0,
  },
  isLoaded: false,
}

export const badgesSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setBadgesList: (state, action: PayloadAction<BadgesList>) => {
      state.list = action.payload
    },
    setBadgeDetail: (state, action: PayloadAction<Badge | null>) => {
      state.detail = action.payload
    },
    setMembersList: (state, action: PayloadAction<BadgeMembersList>) => {
      state.members = action.payload
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setBadgesList,
  setBadgeDetail,
  setMembersList,
} = badgesSlice.actions

export const selectBadge = (state: RootState) => state.badges

export default badgesSlice.reducer
