import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  FormTextInput,
  FormTextarea,
  Loader,
  MimeType,
  UploadInput,
} from '@liveconnect/components'

import { ActivityCollaboratorsForm } from '../../../../../core/activities/types'
import { useCustomRouter } from '../../../../../utils/extractParams'
import useUploadInput from '../../../../../utils/uploadInput/useUploadInput'
import useNotifications from '../../../../../utils/notifications/useNotifications'

import { useActivities } from '../../../../../core'

import BackButton from '../../../../../components/Buttons/BackButton'
import { Main } from '../../../../../components/Main'
import CustomForm from '../../../../../components/CustomForm'
import TranslationCard from '../../../../../components/TranslationCard'

import { buildValidationSchema } from './validations'
import { DEFAULT_LANG } from '../../../../../i18n/config'
import { Translation } from '../../../../../core/shared/types'
import { uploadFile } from '../../../../../utils/fetch/uploadFile'

const CollaboratorsDetailForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notify = useNotifications()
  const { activityId, collaboratorId, collaboratorTypeId } = useParams()
  const { basePath } = useCustomRouter()
  const {
    isLoaded,
    createCollaborator,
    editCollaborator,
    translateCollaborator,
    uploadImageActivity,
    fetchCollaborator,
    collaborator,
  } = useActivities()
  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })

  const defaultValues = {
    language: DEFAULT_LANG,
    activityId,
    contributorInfoId: collaboratorTypeId,
  }

  const methods = useForm<ActivityCollaboratorsForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues,
  })

  const { control, watch, setValue, setError, reset } = methods

  const formValues = watch()

  const isTranslating = useMemo(() => {
    return formValues.language !== DEFAULT_LANG
  }, [formValues.language])

  const onSubmit = async (values: ActivityCollaboratorsForm) => {
    const _values = { ...values }
    let action = 'create'
    if (collaboratorId) action = 'edit'
    if (isTranslating) action = 'translate'
    try {
      _values.image = await uploadFile(_values.image, uploadImageActivity)

      if (!collaboratorId) {
        const response = await createCollaborator(_values)
        navigate(
          `${basePath}/activities/${activityId}/collaborators/${collaboratorTypeId}/${response}`
        )
      } else {
        if (isTranslating) {
          await translateCollaborator(collaboratorId, _values)
        } else {
          await editCollaborator(collaboratorId, _values)
        }
        reset(_values)
        navigate(`${basePath}/activities/${activityId}/edit`, {
          state: { activeTabParam: 'collaborators' },
        })
      }
      notify.success(t(`activity.collaborators.detail.feedback.${action}.ok`))
    } catch (error) {
      notify.error(t(`activity.collaborators.detail.feedback.${action}.ko`))
    }
  }

  useEffect(() => {
    if (collaboratorId && activityId)
      fetchCollaborator(collaboratorId, activityId)
  }, [collaboratorId, activityId])

  useEffect(() => {
    if (collaborator && collaboratorId) {
      const translation = collaborator.translations.find(
        (t) => t.language === formValues.language
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, translations, ...restCollaborator } = collaborator
      reset({
        ...formValues,
        ...restCollaborator,
        description: translation?.description ?? '',
      })
    } else {
      reset({ ...defaultValues })
    }
  }, [collaborator, formValues.language])

  if (!isLoaded) return <Loader />

  return (
    <Main
      ariaLabelledby="activity-collaborators-form"
      title={
        collaboratorId
          ? t('activity.collaborators.detail.title.edit')
          : t('activity.collaborators.detail.title.create')
      }
      subtitle={
        !collaboratorId ? t('activity.collaborators.detail.subtitle') : ''
      }
      isVisibleRequiredFieldsLeyend
      rightColumn={
        <BackButton
          label={t('activity.collaborators.detail.goBack')}
          onClick={() =>
            navigate(`${basePath}/activities/${activityId}/edit`, {
              state: { activeTabParam: 'collaborators' },
            })
          }
        />
      }
    >
      <CustomForm
        methods={methods}
        onSubmit={onSubmit}
        onCancel={() =>
          navigate(`${basePath}/activities/${activityId}/edit`, {
            state: { activeTabParam: 'collaborators' },
          })
        }
        isSelectDisabled={!collaboratorId}
      >
        {collaboratorId && collaborator && (
          <TranslationCard
            translations={collaborator.translations as Translation[]}
            lang={formValues.language}
            entity={t('activity.collaborators.data')}
          />
        )}
        {!isTranslating && (
          <div className="row">
            <div className="col-9">
              <div className="row">
                <div className="col-6">
                  <FormTextInput
                    label={t('activity.collaborators.detailForm.name.label')}
                    placeholder={t(
                      'activity.collaborators.detailForm.name.placeholder'
                    )}
                    control={control}
                    name="name"
                    required
                    data-testid="activity-collaborators-detail-name"
                  />
                </div>
                <div className="col-6">
                  <FormTextInput
                    label={t('activity.collaborators.detailForm.company.label')}
                    placeholder={t(
                      'activity.collaborators.detailForm.company.placeholder'
                    )}
                    control={control}
                    name="company"
                    data-testid="activity-collaborators-detail-company"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <FormTextarea
          label={t('activity.collaborators.detailForm.description.label')}
          placeholder={t(
            'activity.collaborators.detailForm.description.placeholder'
          )}
          control={control}
          name="description"
          rows={5}
          required
          data-testid="activity-collaborators-detail-description"
          helperText={t('validations.leyendField.max', { number: 200 })}
        />
        {!isTranslating && (
          <UploadInput
            control={control}
            title={t('activity.collaborators.detailForm.imageUrl.label')}
            name="image"
            accept={accept}
            onRemoveFile={() => setValue('image', '')}
            selectFilesError={(error) =>
              setError('image', {
                message: error.message,
              })
            }
            required
            rules={{
              image: {
                maxFileSize: 1,
                maxWidth: 128,
                maxHeight: 128,
              },
            }}
            data-testid="activity-collaborators-detail-imageUrl"
            labels={labels}
          />
        )}
      </CustomForm>
    </Main>
  )
}

export default CollaboratorsDetailForm
