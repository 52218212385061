import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup
    .object()
    .shape({
      channel: yup
        .string()
        .label(t('topic.channel.modal.name.label'))
        .required(({ label }) => t('validations.required', { label }))
        .max(40, ({ label }) =>
          t('validations.maxLength', { label, maxLength: 40 })
        ),
    })
    .required()
}
