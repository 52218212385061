import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormMultiSelect,
  FormSelect,
  FormTextInput,
  FormProvider,
  UploadInput,
  MimeType,
} from '@liveconnect/components'

import { Main } from '../../../../components/Main'
import { FormActionsLang } from '../../../../components/forms/FormActionsLang'
import BackButton from '../../../../components/Buttons/BackButton'

import useUi from '../../../../core/ui/useUi'
import useLocalizations from '../../../../core/localizations/useLocalizations'
import {
  BrandDirectory,
  ChannelSectorDirectory,
  DirectoryForm,
  PayloadDirectory,
  SectorDirectory,
  TypeDirectory,
} from '../../../../core/directories/types'
import useDirectories from '../../../../core/directories/useDirectories'
import usePermissions from '../../../../core/permissions/usePermissions'

import useNotifications from '../../../../utils/notifications/useNotifications'
import { useCustomRouter } from '../../../../utils/extractParams'
import { useBlockRouteChangeWithDialog } from '../../../../utils/routing/useBlockRouteChange'
import useUploadInput from '../../../../utils/uploadInput/useUploadInput'

import { DEFAULT_LANG } from '../../../../i18n/config'
import { buildValidationSchema } from './validations'

import './styles.scss'

const CreateDirectory: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { localizations } = useLocalizations()
  const { basePath } = useCustomRouter()
  const {
    getSectorsDirectory,
    addDirectory,
    uploadImageDirectory,
    getBrandsDirectory,
  } = useDirectories()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [topics, setTopics] = useState<SectorDirectory[]>([])
  const [brands, setBrands] = useState<BrandDirectory[]>([])
  const {
    verifyModeratorDirectories,
    verifySomeChannelPermissionsDirectories,
    verifySomeBrandPermissionsDirectories,
  } = usePermissions()
  const { labels, accept } = useUploadInput({
    allowedExtensions: [MimeType.JPEG, MimeType.PNG],
  })

  const methods = useForm<DirectoryForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      language:
        (localizations || []).find((item) => item.isDefault)?.isoCode ||
        DEFAULT_LANG,
    },
  })
  const {
    control,
    formState,
    watch,
    setError,
    setValue,
    formState: { isDirty, dirtyFields },
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const watchType = watch('type')
  const watchSector = watch('sectorId')

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  const codeOptions = useMemo(() => {
    const options = brands.map((code: BrandDirectory) => {
      return {
        value: code.id,
        label: code.name,
      }
    })
    return options
  }, [brands])

  const sectorsOptions = useMemo(() => {
    const options = topics.map((topic: SectorDirectory) => {
      return {
        value: topic.id,
        label: topic.name,
      }
    })
    return options
  }, [topics])

  const channelsOptions = useMemo(() => {
    if (watchSector && topics) {
      const channelsFilter = topics.filter(
        (topic: SectorDirectory) => topic.id === watchSector
      )
      const options = channelsFilter[0].channels.map(
        (channel: ChannelSectorDirectory) => {
          return {
            value: channel.id,
            label: channel.name,
          }
        }
      )
      return options
    }
  }, [watchSector])

  const directoriesOptions = () => {
    const options = []
    if (verifySomeBrandPermissionsDirectories()) {
      options.unshift({
        value: TypeDirectory.BrandSpace,
        label: t('navMenu.brand'),
      })
    }
    if (verifySomeChannelPermissionsDirectories()) {
      options.unshift({
        value: TypeDirectory.Channel,
        label: t('topic.channels'),
      })
    }

    if (verifyModeratorDirectories()) {
      options.unshift({
        value: TypeDirectory.Main,
        label: t('directories.type.menu'),
      })
    }
    return options
  }

  const handleCancel = () => {
    navigate(`${basePath}/directories`)
  }

  const transformData = (val: DirectoryForm) => {
    let data
    switch (val.type) {
      case TypeDirectory.Main:
        data = {
          name: val.name,
          description: val.description,
          type: val.type,
          mainDirectoryType: { imageUrl: val.imageUrl },
          language: val.language,
        }
        return data
      case TypeDirectory.Channel:
        data = {
          name: val.name,
          description: val.description,
          type: val.type,
          channelDirectoryType: {
            sectorId: val.sectorId,
            channels: val.channels,
          },
          language: val.language,
        }
        return data
      case TypeDirectory.BrandSpace:
        data = {
          name: val.name,
          description: val.description,
          type: val.type,
          brandSpaceDirectoryType: { brandSpaceId: val.code },
          language: val.language,
        }
        return data
      default:
        data = {
          name: val.name,
          description: val.description,
          mainDirectoryType: { imageUrl: val.imageUrl },
          type: val.type,
          language: val.language,
        }
        return data
    }
  }

  const onSubmit = async (values: DirectoryForm) => {
    setIsSubmitting(true)
    try {
      const dataForm: PayloadDirectory = transformData(values)
      if (
        watchType === TypeDirectory.Main &&
        dataForm.mainDirectoryType &&
        dataForm.mainDirectoryType.imageUrl
      ) {
        const image: string = dataForm.mainDirectoryType.imageUrl
        if (image.includes('blob:')) {
          const responseImage = await uploadImageDirectory(image)
          responseImage &&
            dataForm.mainDirectoryType &&
            (dataForm.mainDirectoryType.imageUrl = responseImage)
        }
      }
      const response = await addDirectory(dataForm)
      if (response) {
        setIsSubmitting(false)
        navigate(`${basePath}/directories/${response}`)
        notify.success(
          t('directories.form.create.toast.ok', { name: values.name })
        )
      }
    } catch (error: unknown) {
      setIsSubmitting(false)
      notify.error(t('directories.form.create.toast.ko', { name: values.name }))
    }
  }

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const fetchSectors = async () => {
    const sectors = await getSectorsDirectory()
    sectors && setTopics(sectors)
  }
  const fetchDirectoryBrands = async () => {
    const brandsDirectory = await getBrandsDirectory()
    brandsDirectory && setBrands(brandsDirectory)
  }

  useEffect(() => {
    if (watchType === TypeDirectory.Channel) {
      fetchSectors()
    }
    if (watchType === TypeDirectory.BrandSpace) {
      fetchDirectoryBrands()
    }
  }, [watchType])

  return (
    <Main ariaLabelledby="create-directory" className="CreateDirectory">
      <div className="d-flex justify-content-between">
        <h1 id="create-directory-title" className="h3">
          {t('directories.create')}
        </h1>
        <BackButton
          label={t('directories.form.back')}
          onClick={() => navigate('../')}
        />
      </div>
      <div className="d-flex justify-content-between CreateDirectory__text">
        <div>
          <p id="directory-form-text" className="h3">
            {t('directories.form.text')}
          </p>
          <p className="leyend" id="directory-form-leyend">
            {t('directories.form.leyend')}
          </p>
        </div>
        <div className="d-flex flex-row-reverse modal-required-legend">
          <div className="bd-highlight">{t('common.required')}</div>
        </div>
      </div>
      <FormProvider methods={methods}>
        <div className="CreateDirectory__form">
          <div className="row">
            <div className="col-8 contentInput">
              <FormSelect
                control={control}
                name="type"
                label={t('directories.form.type')}
                placeholder={t('directories.form.type.placeholder')}
                options={directoriesOptions()}
                required={true}
              />
            </div>
            <div className="col-10 contentInput">
              <FormTextInput
                control={control}
                name="name"
                label={t('directories.form.name')}
                placeholder={t('directories.form.name.placeholder')}
                type="text"
                required={true}
              />
            </div>
            <div className="col-10 contentInput withMax">
              <FormTextInput
                control={control}
                name="description"
                label={t('directories.form.description')}
                placeholder={t('directories.form.description.placeholder')}
                type="text"
                required={true}
              />
              <span className="max">
                {t('directories.form.description.max')}
              </span>
            </div>
          </div>
          {watchType && (
            <div className="row CreateDirectory__form__info">
              <div className="col-12">
                <p className="h3 info">{t('directories.form.info')}</p>
              </div>
              {watchType === TypeDirectory.Main && (
                <div className="col-12">
                  <UploadInput
                    control={control}
                    title={t('topic.topic.modal.image')}
                    name="imageUrl"
                    accept={accept}
                    onRemoveFile={() => setValue('imageUrl', '')}
                    selectFilesError={(error) =>
                      setError('imageUrl', {
                        message: error.message,
                      })
                    }
                    required
                    rules={{
                      image: {
                        maxWidth: 64,
                        maxHeight: 64,
                        maxFileSize: 50,
                      },
                    }}
                    labels={labels}
                  />
                </div>
              )}
              {watchType === TypeDirectory.Channel && (
                <>
                  <div className="col-8 contentInput">
                    <FormSelect
                      control={control}
                      name="sectorId"
                      label={t('navMenu.topics')}
                      placeholder={t('directories.form.sector.placeholder')}
                      options={sectorsOptions}
                      required={true}
                    />
                  </div>
                  <div className="col-8 contentInput">
                    <FormMultiSelect
                      control={control}
                      name="channels"
                      label={t('activities.filters.fields.channels.label')}
                      placeholder={t(
                        'activities.filters.fields.channels.placeholder'
                      )}
                      options={channelsOptions || []}
                      required={true}
                      disabled={!watchSector}
                    />
                  </div>
                </>
              )}
              {watchType === TypeDirectory.BrandSpace && (
                <div className="col-8">
                  <FormSelect
                    control={control}
                    name="code"
                    label={t('brand.edit.eventCode')}
                    placeholder={t('brand.edit.eventCode.placeholder')}
                    options={codeOptions}
                    required={true}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <FormActionsLang
          selectName="language"
          selectDisabled={true}
          isLoaded={true}
          methods={methods}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          onCancel={handleCancel}
          onBeforeChange={handleBeforeChangeLang}
        />
      </FormProvider>
    </Main>
  )
}

export default CreateDirectory
