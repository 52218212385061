import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Loader } from '@liveconnect/communities-ui'
import { ToggleControl, Pagination } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import EmptyState from '../../../../components/EmptyState'
import SearchTable from '../../../../components/Table/SearchTable'
import TranslationChips from '../../../../components/TranslationsChips'
import { MultimediaSection } from '../../../../core/multimedia/types'
import useMultimedia from '../../../../core/multimedia/useMultimedia'
import useUi from '../../../../core/ui/useUi'
import useNotifications from '../../../../utils/notifications/useNotifications'

import './styles.scss'

const idNull = '00000000-0000-0000-0000-000000000000'

const MultimediaTab: FC = () => {
  const { t } = useTranslation()
  const { directoryId, elementId } = useParams()
  const { showConfirmation } = useUi()
  const notify = useNotifications()

  const {
    fetchMultimediaSection,
    fetchMultimediaList,
    clearMultimediaList,
    changeVisibilityMultimedia,
    deleteMultimedia,
    multimediaList,
    section,
    isLoaded,
  } = useMultimedia()

  const handleConfirmPublish = async () => {
    try {
      if (section && elementId) {
        const params = {
          sectionId: section.multimediaSectionId,
          visible: !section.isVisible,
          elementId: elementId,
        }
        await changeVisibilityMultimedia(params)
        await fetchMultimediaSection(elementId)
      }
    } catch (e) {
      notify.error(t('common.unknownError'))
    }
  }

  const confirmDeleteMultimedia = async (idItem: string) => {
    try {
      elementId && (await deleteMultimedia(idItem, elementId))
      notify.success(
        t('directories.formElement.multimedia.list.table.delete.ok')
      )
      try {
        if (section && elementId) {
          await fetchMultimediaList({
            page: 1,
            page_size: 20,
            multimedia_section_id: section.multimediaSectionId,
            elementId: elementId,
          })
        }
      } catch (error) {
        notify.error(t('common.unknownError'))
      }
    } catch (error) {
      notify.error(t('directories.formElement.multimedia.list.table.delete.ko'))
    }
  }

  const handleConfirmDelete = (idItem: string) => {
    showConfirmation({
      title: t('directories.formElement.multimedia.list.table.delete.title'),
      subtitle: t(
        'directories.formElement.multimedia.list.table.delete.subtitle'
      ),
      text: t('directories.formElement.multimedia.list.table.delete.text'),
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: async () => {
        confirmDeleteMultimedia(idItem)
      },
    })
  }

  const searchMultimedia = async (param: string) => {
    if (section && section.multimediaSectionId && elementId) {
      try {
        await fetchMultimediaList({
          page: 1,
          page_size: 20,
          multimedia_section_id: section.multimediaSectionId,
          search: param,
          elementId: elementId,
        })
      } catch (error) {
        notify.error(t('common.unknownError'))
      }
    }
  }

  const confirmDisabled = () => {
    if (
      section &&
      section.multimediaSectionId &&
      section.multimediaSectionId !== idNull &&
      multimediaList &&
      multimediaList.items.length > 0
    ) {
      return false
    }
    return true
  }

  const getDataSectionMultimedia = async () => {
    try {
      if (elementId) {
        const response: MultimediaSection | undefined =
          await fetchMultimediaSection(elementId)
        if (response && response.multimediaSectionId) {
          try {
            await getDataTable(response.multimediaSectionId)
          } catch (e) {
            notify.error(t('common.unknownError'))
            await clearMultimediaList()
          }
        }
      }
    } catch (e) {
      notify.error(t('common.unknownError'))
    }
  }
  const getDataTable = async (idSectionMultimedia: string) => {
    if (elementId) {
      await fetchMultimediaList({
        page: 1,
        page_size: 20,
        multimedia_section_id: idSectionMultimedia,
        elementId: elementId,
      })
    }
  }

  useEffect(() => {
    getDataSectionMultimedia()
  }, [elementId])

  return (
    <>
      {!section ? (
        <Loader />
      ) : (
        <div className="MultimediaTab">
          <div className="col-12">
            <ToggleControl
              id={`isPublished-${section.multimediaSectionId}`}
              className="MultimediaTab__toogle"
              label={t('directories.formElement.multimediaSection.toogle')}
              name="isPublished"
              value={section.isVisible}
              onChange={handleConfirmPublish}
              disabled={confirmDisabled()}
              data-testid="toogle-multimediaTab"
            />
          </div>
          <div className="col-12 MultimediaTab__title">
            <div className="header">
              <h3 data-testid="title-multimediaTab">
                {t('directories.formElement.multimediaSection.title')}
              </h3>
            </div>

            <p>{t('directories.formElement.multimediaSection.text')}</p>
          </div>
          <div
            className="col-12 MultimediaTab__table"
            data-testid="table-multimediaTab"
          >
            <div>
              <SearchTable
                title={t(
                  'directories.formElement.multimedia.list.table.search.title'
                )}
                titleLink={t(
                  'directories.formElement.multimediaSection.title.link'
                )}
                placeHolderText={t('common.search')}
                handleSearch={(s: string) => searchMultimedia(s)}
                urlLink={`../${directoryId}/elements/${elementId}/multimedia/create`}
                data-testid="search-multimediaTab"
              />
              <div
                className="header row"
                data-testid="table-header-multimediaTab"
              >
                <div className="col cell">
                  <p>
                    {t('directories.formElement.multimedia.list.table.title')}
                  </p>
                </div>
                <div className="col-5 cell">
                  <p>{t('sections.list.table.columns.website')}</p>
                </div>
                <div className="col-2 cell">
                  <p>{t('sections.validations.language')}</p>
                </div>
                <div className="col-1 cell">{''}</div>
              </div>
              {isLoaded && multimediaList && (
                <div>
                  {multimediaList.items.length > 0 ? (
                    <>
                      {multimediaList.items.map((multimedia) => (
                        <div
                          className="body row"
                          key={`${multimedia.id}`}
                          data-testid={`table-${multimedia.id}-bodyRow-multimediaTab`}
                        >
                          <div className="col cell">{multimedia.name}</div>
                          <div className="col-5 cell">
                            {multimedia.multimediaUrl ? (
                              <a
                                href={multimedia.multimediaUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {multimedia.multimediaUrl}
                              </a>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="col-2 cell">
                            <TranslationChips
                              translations={multimedia.translations}
                              isArrayOfStrings
                            />
                          </div>
                          <div className="col-1 cell actions">
                            <Link to={`./multimedia/${multimedia.id}`}>
                              <Icon name="edit" />
                            </Link>
                            <button
                              type="button"
                              onClick={() => handleConfirmDelete(multimedia.id)}
                            >
                              <Icon name="delete_outline" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="row empty">
                      <EmptyState
                        text={t('directories.elements.empty')}
                        data-testid="empty-multimediaTab"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {multimediaList && (
            <Pagination
              currentPage={multimediaList.page - 1}
              totalPages={multimediaList.availablePages}
              totalItems={multimediaList.availableItems}
              pageSize={multimediaList.pageSize}
              singlePageText={(count) =>
                t('common.pager.singlePage', { count })
              }
              multiplePageText={(start, end, total) =>
                t('common.pager.multiplePage', { start, end, total })
              }
              onChange={(pageIndex) => {
                if (
                  elementId &&
                  section &&
                  section.multimediaSectionId &&
                  section.multimediaSectionId !== idNull
                ) {
                  fetchMultimediaList({
                    page: pageIndex + 1,
                    page_size: 20,
                    multimedia_section_id: section.multimediaSectionId,
                    elementId: elementId,
                  })
                }
              }}
              data-testid="pagination-multimediaTab"
            />
          )}
        </div>
      )}
    </>
  )
}

export default MultimediaTab
