import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'

import { ContentTable, SearchInput } from '@liveconnect/communities-ui'
import { Icon } from '@liveconnect/icons'
import { Pagination, Loader } from '@liveconnect/components'

import { Main } from '../../../../components/Main'
import { BadgeMembersListParams } from '../../../../core/badges/types'
import useBadges from '../../../../core/badges/useBadges'
import useUi from '../../../../core/ui/useUi'
import { useCustomRouter } from '../../../../utils/extractParams'
import useNotifications from '../../../../utils/notifications/useNotifications'
import { BrandTabs } from '../../types'
import ModalAssign from './ModalAssign'

import './styles.scss'

interface MemberParsed {
  id: string
  name: string
  surname: string
  email: string
}

const ManageBadge = () => {
  const { t } = useTranslation()
  const { showConfirmation } = useUi()
  const notify = useNotifications()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { badgeId, brandId } = useParams()
  const { basePath } = useCustomRouter()
  const {
    fetchMembers,
    members,
    deleteMember,
    isLoaded,
    fetchBadgeDetail,
    badgeDetail,
  } = useBadges()
  const navigate = useNavigate()
  const location = useLocation()
  const [params, setParams] = useState<BadgeMembersListParams>({
    SearchText: '',
    Page: 1,
    page_size: 20,
  })

  const badgeName = useMemo(() => {
    if (location.state && (location.state as { badgeName: string }).badgeName) {
      return (location.state as { badgeName: string }).badgeName
    }
    return ''
  }, [location])

  const membersParsed = useMemo(() => {
    if (!members.items) return []
    return members.items.map((member) => ({
      id: member.memberId,
      name: member.member.name,
      surname: member.member.surname,
      email: member.member.email,
    }))
  }, [members])

  const actions = (row: MemberParsed) => {
    return [
      {
        onClick: () => handleDelete(row),
        label: t('common.delete'),
        icon: 'delete_outline',
      },
    ]
  }

  const columns: ColumnDef<MemberParsed>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('badge.manage.members.name'),
      },
      {
        accessorKey: 'surname',
        header: t('badge.manage.members.surname'),
      },
      {
        accessorKey: 'email',
        header: t('badge.manage.members.email'),
      },
    ],
    []
  )

  const handleDelete = (member: MemberParsed) => {
    showConfirmation({
      title: t('common.warning'),
      subtitle: t('badge.manage.deleteModal.subtitle', {
        badgeName,
      }),
      confirmText: t('common.withdraw'),
      cancelText: t('common.no'),
      iconName: 'report_problem',
      onConfirm: () => {
        confirmDelete(member)
      },
    })
  }

  const confirmDelete = async (member: MemberParsed) => {
    try {
      if (!badgeId) return
      await deleteMember(badgeId, member.id)
      fetchMembers(badgeId, params)
      notify.success(t('badge.manage.deleteToast.ok'))
    } catch (error) {
      notify.error(t('badge.manage.deleteToast.ko'))
    }
  }

  const handleGoBack = () => {
    navigate(`${basePath}/brands/${brandId}/edit`, {
      state: { brandCurrentTab: BrandTabs.BADGES },
    })
  }

  const handleReloadGrid = (updatedParams?: BadgeMembersListParams) => {
    if (updatedParams) setParams(updatedParams)
  }

  const handleChangeStateModal = () => {
    setIsModalVisible(!isModalVisible)
  }

  useEffect(() => {
    if (badgeId) {
      fetchMembers(badgeId, params)
      if (!location.state) fetchBadgeDetail(badgeId)
    }
  }, [badgeId, params, location])

  return (
    <Main ariaLabelledby="manage-badge" className="manage-badge">
      <div className="row mb-4">
        <div className="col">
          <h1 className="page-title">
            {t('badge.manage.title')}{' '}
            {badgeDetail?.translations[0].name ?? badgeName}
          </h1>
          <p className="page-subtitle">{t('badge.manage.subtitle')}</p>
        </div>
        <div className="col-auto d-flex flex-column align-items-end">
          <button className="btn btn-arrow mb-5" onClick={handleGoBack}>
            <Icon name="chevron_left" />
            {t('badge.manage.backToBadges')}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleChangeStateModal}
          >
            {t('badge.manage.assign')}
          </button>
        </div>
      </div>

      {!isLoaded ? (
        <Loader className="mt-4" />
      ) : (
        <>
          <ContentTable
            title={t('badge.manage.members')}
            columns={columns}
            data={membersParsed}
            emptyText={t('badge.manage.noMembers')}
            headerChild={
              <SearchInput
                onSearch={(s: string) => {
                  handleReloadGrid({
                    ...params,
                    SearchText: s,
                  })
                }}
                defaultValue={params.SearchText}
                placeholder={t('badge.manage.searchMembers')}
              />
            }
            actions={actions}
          />
          <Pagination
            currentPage={members.page - 1}
            totalPages={members.availablePages}
            totalItems={members.availableItems}
            pageSize={members.pageSize}
            singlePageText={(count) => t('common.pager.singlePage', { count })}
            multiplePageText={(start, end, total) =>
              t('common.pager.multiplePage', { start, end, total })
            }
            onChange={(pageIndex) =>
              handleReloadGrid({
                ...params,
                Page: pageIndex + 1,
              })
            }
          />
        </>
      )}

      <ModalAssign
        isModalVisible={isModalVisible}
        handleChangeStateModal={handleChangeStateModal}
        badgeName={badgeName}
        onAssign={() => fetchMembers(badgeId!, params)}
      />
    </Main>
  )
}

export default ManageBadge
