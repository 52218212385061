import { ToggleControl } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  DragDropContext,
  Droppable,
  DraggableProvided,
  DropResult,
  DroppableProvided,
  Draggable,
} from 'react-beautiful-dnd'

import { Sponsor } from '../../../../core/brandSpace/types'
import useUi from '../../../../core/ui/useUi'
import SubsectionComponent from './Subsection'
import TranslationChips from '../../../../components/TranslationsChips'
import { Section } from '../../../../core/sections/types'
import useSections from '../../../../core/sections/useSections'
import { DEFAULT_LANG } from '../../../../i18n/config'
import useNotifications from '../../../../utils/notifications/useNotifications'
import { getTranslationFromSection } from '../../EditSections/utils'
import { SponsorHeader, SponsorRow } from './SponsorRow'

import './styles.scss'

interface SectionComponentProps {
  brandId: string
  section: Section
  provided: DraggableProvided
}

const SectionComponent: FC<SectionComponentProps> = ({
  brandId,
  section,
  provided,
}) => {
  const { t } = useTranslation()
  const {
    updateIsFeatured,
    deleteSponsor,
    updateOrderSponsor,
    updateSubsectionOrder,
  } = useSections()
  const notify = useNotifications()
  const navigate = useNavigate()
  const { showConfirmation } = useUi()
  const defTranslation = getTranslationFromSection(section, DEFAULT_LANG)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSectionCollapsed, setIsSectionCollapsed] = useState<boolean>(false)

  const handleUpdateIsHighlighted = async () => {
    setIsSubmitting(true)
    try {
      await updateIsFeatured({
        brandId,
        sectionId: section.id,
        isHighlighted: !section.isFeatured,
      })
      notify.success(t('sections.list.actions.highlight.feedback.ok'))
    } catch (error) {
      notify.error(t('sections.list.actions.highlight.feedback.ko'))
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleAddSponsor = (section: Section) => {
    navigate(`sections/${section.id}/sponsors`)
  }

  const handleReorderSubsections = async (result: DropResult) => {
    if (!result.destination || !brandId) return
    const subsection = section.subSections?.find(
      (s) => s.id === result.draggableId
    )
    if (!subsection) return

    try {
      await updateSubsectionOrder({
        brandId,
        sectionId: section.id,
        subsectionId: subsection.id,
        index: result.destination.index,
      })
      notify.success(t('brand.sponsors.reorder.feedback.ok'))
    } catch (e) {
      notify.error(t('brand.sponsors.reorder.feedback.ko'))
    }
  }

  const handleReorderSponsors = async (result: DropResult) => {
    if (!result.destination || !brandId) return

    try {
      await updateOrderSponsor({
        sponsorId: result.draggableId,
        index: result.destination.index,
        brandId: brandId,
      })
      notify.success(t('brand.sponsors.reorder.feedback.ok'))
    } catch (e) {
      notify.error(t('brand.sponsors.reorder.feedback.ko'))
    }
  }

  const handleEditSponsor = (sectionId: string, sponsor: Sponsor) => {
    navigate(`sections/${sectionId}/sponsors/${sponsor.id}`)
  }

  const handleDeleteSponsor = (sponsor: Sponsor) => {
    showConfirmation({
      title: t('sponsors.delete.modal.title'),
      subtitle: t('sponsors.delete.modal.subtitle'),
      text: t('sponsors.delete.modal.text'),
      cancelText: t('common.no'),
      confirmText: t('common.yes'),
      iconName: 'report_problem',
      onConfirm: async () => {
        try {
          await deleteSponsor(sponsor.id)
          notify.success(t('brand.sponsors.delete.feedback.ok'))
        } catch (error) {
          notify.error(t('brand.sponsors.delete.feedback.ko'))
        }
      },
    })
  }

  return (
    <div
      className="Section"
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <div className="Section__header">
        <div className="Section__header__flex-start">
          <div className="Section__header__dd">
            <div className="section-wrapper" {...provided.dragHandleProps}>
              <Icon name="drag_and_drop" />
            </div>
          </div>
          <div className="Section__header__title-and-languages me-3">
            <div className="Section__header__title-and-languages__title">
              {defTranslation?.name ?? ''}:
            </div>
          </div>
          <TranslationChips translations={section.translations} showDefault />
        </div>
        <div className="Section__header__flex-end">
          <div className="Section__header__highlighted">
            <ToggleControl
              id={`isFeatured-${section.id}`}
              label={t('sections.list.highlight')}
              name="isFeatured"
              value={section.isFeatured}
              onChange={handleUpdateIsHighlighted}
              disabled={isSubmitting}
            />
          </div>
          <div className="Section__header__collapse">
            <button onClick={() => setIsSectionCollapsed(!isSectionCollapsed)}>
              {!isSectionCollapsed && <Icon name="keyboard_arrow_up" />}
              {isSectionCollapsed && <Icon name="keyboard_arrow_down" />}
            </button>
          </div>
        </div>
      </div>
      {!isSectionCollapsed && (
        <div className="Section__body">
          <div className="Section__body__sponsors">
            {section.sponsors && section.sponsors.length > 0 && (
              <div className="SponsorList">
                <SponsorHeader />
                <DragDropContext onDragEnd={handleReorderSponsors}>
                  <Droppable droppableId={`${section.id}.sponsors`}>
                    {(dropProvided: DroppableProvided) => (
                      <div
                        className="SponsorList__droppable"
                        {...dropProvided.droppableProps}
                        ref={dropProvided.innerRef}
                      >
                        {section.sponsors &&
                          section.sponsors.map(
                            (sponsor: Sponsor, sponsorIndex: number) => (
                              <Draggable
                                key={sponsor.id}
                                draggableId={sponsor.id}
                                index={sponsorIndex}
                              >
                                {(dragProvided: DraggableProvided) => (
                                  <SponsorRow
                                    key={sponsor.id}
                                    sponsor={sponsor}
                                    handleEdit={(row) =>
                                      handleEditSponsor(section.id, row)
                                    }
                                    handleDelete={handleDeleteSponsor}
                                    provided={dragProvided}
                                  />
                                )}
                              </Draggable>
                            )
                          )}
                        {dropProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
            <div className="Section__body__sponsors__add">
              <button onClick={() => handleAddSponsor(section)}>
                <Icon name="add" />
                {t('common.add')}
              </button>
            </div>
          </div>
          <DragDropContext onDragEnd={handleReorderSubsections}>
            <Droppable droppableId={`${section.id}.subsections`}>
              {(dropProvided: DroppableProvided) => (
                <div
                  className="Section__body__droppable"
                  {...dropProvided.droppableProps}
                  ref={dropProvided.innerRef}
                >
                  {section.subSections &&
                    section.subSections.map((subsection, index) => (
                      <Draggable
                        key={subsection.id}
                        draggableId={subsection.id}
                        index={index}
                      >
                        {(dragProvided: DraggableProvided) => (
                          <SubsectionComponent
                            subsection={subsection}
                            key={subsection.id}
                            provided={dragProvided}
                            handleEditSponsor={(row) =>
                              handleEditSponsor(subsection.id, row)
                            }
                            handleDeleteSponsor={handleDeleteSponsor}
                            handleReorderSponsors={handleReorderSponsors}
                          />
                        )}
                      </Draggable>
                    ))}
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  )
}

export default SectionComponent
