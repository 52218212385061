// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DirectoriesList{display:flex;flex-direction:column}.DirectoriesList__header{display:flex;justify-content:space-between}.DirectoriesList__text{margin-bottom:32px}`, "",{"version":3,"sources":["webpack://./src/app/pages/Directories/DirectoriesList/styles.scss"],"names":[],"mappings":"AAQA,iBACI,YAAA,CACA,qBAAA,CAEA,yBACI,YAAA,CACA,6BAAA,CAEJ,uBACI,kBAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.DirectoriesList {\n    display: flex;\n    flex-direction: column;\n\n    &__header {\n        display: flex;\n        justify-content: space-between;\n    }\n    &__text {\n        margin-bottom: 32px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DirectoriesList": `DirectoriesList`,
	"DirectoriesList__header": `DirectoriesList__header`,
	"DirectoriesList__text": `DirectoriesList__text`
};
export default ___CSS_LOADER_EXPORT___;
