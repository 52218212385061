import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap'

import { Main } from '../../../components/Main'
import ActivityConfig from './Config'
import ActivityAttendees from './Attendees'
import ActivityFormDetail from './Detail'
import CommentsActivity from './Comments'
import CollaboratorsActivity from './Collaborators'

import './styles.scss'

interface ActivityDetailFormScreenProps {
  isDetail?: boolean
}
interface dataTab {
  activeTabParam: string
}

const ActivityDetailFormScreen: FC<ActivityDetailFormScreenProps> = ({
  isDetail = false,
}) => {
  const { t } = useTranslation()
  const { activityId } = useParams()
  const [title, seTitle] = useState<string>('')
  const { state } = useLocation()
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState<string>('config')

  const setActivityTitle = (title: string) => {
    seTitle(title)
  }

  const changeState = (param: string | any) => {
    setCurrentTab(param)
    navigate('./', {
      state: { activeTabParam: param },
    })
  }

  useEffect(() => {
    if (state && (state as dataTab)) {
      return setCurrentTab((state as dataTab).activeTabParam)
    }
    return setCurrentTab('config')
  }, [state])

  return (
    <Main ariaLabelledby="ActivityForm" className="ActivityForm">
      <div className="ActivityForm__header">
        <h3 className="ActivityForm__header__title">
          {isDetail && title}
          {!isDetail &&
            (activityId
              ? t('activityForm.edit.title')
              : t('activityForm.create.title'))}
        </h3>
      </div>
      <Tabs
        id="justify-tab-example"
        className="mb-5"
        mountOnEnter
        unmountOnExit
        transition={false}
        activeKey={currentTab}
        onSelect={(key) => changeState(key)}
      >
        <Tab eventKey={'config'} title={t('activityForm.tabs.config')}>
          {isDetail ? (
            <ActivityFormDetail setActivityTitle={setActivityTitle} />
          ) : (
            <ActivityConfig />
          )}
        </Tab>
        <Tab
          eventKey={'assistants'}
          title={t('activityForm.tabs.assistants')}
          disabled={!activityId}
        >
          <ActivityAttendees isDetail={isDetail} />
        </Tab>
        <Tab
          eventKey={'collaborators'}
          title={t('activityForm.tabs.collaborators')}
          disabled={!activityId}
        >
          <CollaboratorsActivity />
        </Tab>
        <Tab
          eventKey={'comments'}
          title={t('activityForm.tabs.comments')}
          disabled={!activityId}
        >
          <CommentsActivity isDetail={isDetail} />
        </Tab>
      </Tabs>
    </Main>
  )
}

export default ActivityDetailFormScreen
