import { ReactNode, useMemo, useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormProvider } from '@liveconnect/components'

import { useUi } from '../../core'

import { FormActionsLang } from '../forms/FormActionsLang'

import { useBlockRouteChangeWithDialog } from '../../utils/routing/useBlockRouteChange'

interface CustomFormProps<Z extends FieldValues> {
  methods: UseFormReturn<Z>
  children: ReactNode
  onSubmit: (values: Z) => void
  onCancel: () => void
  isSelectDisabled: boolean
  isLoaded?: boolean
  hideSelector?: boolean
}

const CustomForm = <T extends FieldValues>({
  children,
  methods,
  onSubmit,
  onCancel,
  isSelectDisabled,
  hideSelector = false,
  isLoaded = true,
}: CustomFormProps<T>) => {
  const { showConfirmation } = useUi()
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const {
    formState,
    formState: { dirtyFields },
  } = methods

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  useBlockRouteChangeWithDialog(isDirtyWithoutLanguage && !isSubmitting)

  const handleBeforeChangeLang = (): Promise<boolean> => {
    return new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          iconName: 'report_problem',
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })
  }

  const handleSubmit = async (values: T) => {
    setIsSubmitting(true)
    await onSubmit(values)
    setIsSubmitting(false)
  }

  return (
    <FormProvider methods={methods}>
      {children}
      <FormActionsLang
        hideSelector={hideSelector}
        selectDisabled={isSelectDisabled}
        isLoaded={isLoaded}
        methods={methods}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        onBeforeChange={handleBeforeChangeLang}
      />
    </FormProvider>
  )
}

export default CustomForm
