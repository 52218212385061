import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import {
  ElementsState,
  ElementsListResponse,
  ElementInfoTabDetail,
  ElementDragListItem,
  ElementInformativeDetail,
} from './types'

const initialState: ElementsState = {
  detail: {
    infoElement: null,
    infoSection: null,
  },
  listNoHigh: null,
  listHigh: null,
  directoryName: null,
  isLoaded: false,
}

export const elementsSlice = createSlice({
  name: 'elements',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setElementsListNoHigh: (
      state,
      action: PayloadAction<ElementsListResponse>
    ) => {
      state.listNoHigh = action.payload
    },
    setElementsListHigh: (
      state,
      action: PayloadAction<ElementDragListItem[]>
    ) => {
      state.listHigh = action.payload
    },
    setElementInfoTab: (state, action: PayloadAction<ElementInfoTabDetail>) => {
      state.detail.infoElement = action.payload
    },
    setElementInformativeSectionVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      if (state.detail.infoElement)
        state.detail.infoElement.informativeSectionVisible = action.payload
    },
    setElementMultimediaSectionVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      if (state.detail.infoElement)
        state.detail.infoElement.multimediaSectionVisible = action.payload
    },
    setElementInformativeTabBool: (state, action: PayloadAction<boolean>) => {
      if (state.detail.infoElement)
        state.detail.infoElement.hasInformativeSection = action.payload
    },
    setDirectoryName: (state, action: PayloadAction<string>) => {
      state.directoryName = action.payload
    },
    setElementInformativeTab: (
      state,
      action: PayloadAction<ElementInformativeDetail | null>
    ) => {
      state.detail.infoSection = action.payload
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setElementsListNoHigh,
  setElementsListHigh,
  setElementInfoTab,
  setDirectoryName,
  setElementInformativeTab,
  setElementMultimediaSectionVisible,
  setElementInformativeSectionVisible,
  setElementInformativeTabBool,
} = elementsSlice.actions

export const selectBadge = (state: RootState) => state.badges

export default elementsSlice.reducer
