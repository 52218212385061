import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { InternalOptions } from 'yup/lib/types'
import { ThemeChannel } from '../../../../../core/channels/types'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    themes: yup
      .array()
      .of(
        yup.object({
          translations: yup
            .array()
            .of(
              yup.object({
                name: yup
                  .string()
                  .label(t('topic.theme.modal.name.label'))
                  .required(({ label }) => t('validations.required', { label }))
                  .test(
                    'unique-theme-names',
                    t('validations.duplicateField', {
                      label: t('topic.theme.modal.name.label').toLowerCase(),
                    }),
                    function (
                      value: string | undefined,
                      context: InternalOptions
                    ) {
                      if (!value) return true
                      const currentValues: ThemeChannel[] =
                        context.from?.[2].value.themes
                      const entries = currentValues.filter((theme) =>
                        theme.translations.some(
                          (translation) =>
                            translation.name.toLocaleLowerCase() ===
                            value.toLocaleLowerCase()
                        )
                      ).length
                      return entries === 1
                    }
                  )
                  .nullable(),
              })
            )
            .nullable()
            .notRequired(),
        })
      )
      .label(t('brand.edit.brandTopics'))
      .min(1, ({ label }) => t('validations.required', { label }))
      .required(({ label }) => t('validations.required', { label })),
  })
}
