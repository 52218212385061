import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import { Section, SectionsState, SetIsFeaturedPayload } from './types'

const initialState: SectionsState = {
  list: [],
  isLoaded: false,
}

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<Section[]>) => {
      state.list = action.payload
    },
    setIsFeatured: (state, action: PayloadAction<SetIsFeaturedPayload>) => {
      const sectionIndex = state.list.findIndex(
        (section) => section.id === action.payload.sectionId
      )
      state.list[sectionIndex].isFeatured = action.payload.isFeatured
    },
  },
})

export const { showLoader, hideLoader, setList, setIsFeatured } =
  sectionsSlice.actions

export const selectSections = (state: RootState) => state.sections

export default sectionsSlice.reducer
