import { FC, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import i18next from 'i18next'

import { Loader } from '@liveconnect/components'

import { Main } from '../components/Main'
import logo from '../../assets/images/logoIfemaBlack.svg'
import denied from '../../assets/images/acceso_denegado.svg'
import useCorporate from '../core/configuration/corporate/useCorporate'
import { DEFAULT_LANG } from '../i18n/config'

import './styles.scss'

export const AccessDenied: FC = () => {
  const { t } = useTranslation()
  const { isLoaded, fetchAccessDenyPageInfo, accessDenyInfo } = useCorporate()
  const { tenantId } = useParams()

  const buttonInfo = useMemo(() => {
    if (!accessDenyInfo) return null
    return (
      accessDenyInfo.find(
        (info) =>
          info.language.split('-').at(0) === i18next.language.split('-').at(0)
      ) ??
      accessDenyInfo.find(
        (info) =>
          info.language.split('-').at(0) === DEFAULT_LANG.split('-').at(0)
      )
    )
  }, [accessDenyInfo])

  useEffect(() => {
    if (tenantId) fetchAccessDenyPageInfo(tenantId)
  }, [tenantId])

  if (!isLoaded)
    return (
      <div className="m-5">
        <Loader />
      </div>
    )

  return (
    <div className="pageDennied">
      <Main ariaLabelledby="access-denied-title" className="accessDenied">
        <div className="row accessDenied__content align-items-center">
          <div className="col-12 col-lg-6">
            <div className="accessDenied__content_image">
              <img src={denied} alt="Acceso denegado" className="access" />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <img src={logo} alt="logo" className="accessDenied__content_logo" />
            <div className="col-12 col-lg-9 accessDenied__content_texts">
              <h1>{t('accessDenied.title')}</h1>
              <p>
                <Trans i18nKey="accessDenied.link">
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <a
                    href="https://www.ifema.es/contacto"
                    className=""
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </Trans>
              </p>
            </div>
            <div className="col-12 accessDenied__content_actions">
              {buttonInfo && (
                <a
                  href={buttonInfo.url}
                  className="btn btn-primary my-4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {buttonInfo.text}
                </a>
              )}
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
