import { FC, useEffect, useMemo, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useParams } from 'react-router-dom'

import {
  FormSelect,
  FormProvider,
  Loader,
  Tooltip,
} from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import EditSectionComponent from './EditSection'
import { DEFAULT_LANG } from '../../../i18n/config'
import { FormSection, FormSubsection, SectionsForm } from './types'
import useUi from '../../../core/ui/useUi'
import { getTranslationFromSection } from './utils'
import { buildValidationSchema } from './validations'
import useSections from '../../../core/sections/useSections'
import FormActions from '../../../components/forms/FormActions'
import useLocalizations from '../../../core/localizations/useLocalizations'
import useNotifications from '../../../utils/notifications/useNotifications'
import {
  Section,
  Subsection,
  CreateSectionsSectionModel,
  CreateSubsectionModel,
  CreateSectionsModel,
} from '../../../core/sections/types'
import { BrandTabs } from '../types'
import { Translation } from '../../../core/shared/types'
import { useCustomRouter } from '../../../utils/extractParams'
import { useBlockRouteChangeWithDialog } from '../../../utils/routing/useBlockRouteChange'
import TranslationCard from '../../../components/TranslationCard'

import './styles.scss'

interface SectionScreenProps {
  isNew: boolean
}

const SectionsScreen: FC<SectionScreenProps> = ({ isNew }) => {
  const { t } = useTranslation()
  const { fetchSections, createUpdateSections, sections, isLoaded } =
    useSections()
  const { localizations } = useLocalizations()
  const { showConfirmation } = useUi()
  const { brandId } = useParams()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const notify = useNotifications()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const methods = useForm<SectionsForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      language:
        (localizations || []).find((item) => item.isDefault)?.isoCode ||
        DEFAULT_LANG,
      sections: [{ name: '' }],
    },
  })
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState,
    formState: { isValid, isDirty, touchedFields, dirtyFields },
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const { fields, append, remove } = useFieldArray<SectionsForm>({
    control,
    name: 'sections',
    keyName: 'internalId',
  })

  const watchLang = watch('language')
  const watchSections = watch('sections')

  const isDirtyWithoutLanguage = useMemo(() => {
    const _dirtyFields = { ...dirtyFields }
    delete _dirtyFields.language
    return Object.keys(_dirtyFields).length > 0
  }, [formState])

  const hasPendingTranslations = () => {
    if (watchSections.length === 0) return false
    let isLastLanguage = false
    for (const section of sections) {
      const _translations = section.translations.map((item) => item.language)
      const alreadyTranslated = _translations.find(
        (language) => language === watchLang
      )
      if (!alreadyTranslated) {
        _translations.push(watchLang)
      }
      const sameLength = localizations.length === _translations.length
      if (!sameLength) return true
      if (!alreadyTranslated) isLastLanguage = true
    }
    return !isLastLanguage && touchedFields.sections?.length
  }

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirtyWithoutLanguage) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          iconName: 'report_problem',
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const onCancel = () => {
    navigate(`${basePath}/brands/${brandId}/edit`, {
      state: { brandCurrentTab: BrandTabs.SPONSORS },
    })
  }

  const showNotifications = () => {
    if (isNew) notify.success(t('sections.create.feedback.ok'))
    else if (watchLang === DEFAULT_LANG)
      notify.success(t('sections.update.feedback.ok'))
    else notify.success(t('sections.update.translations.feedback.ok'))
  }

  const mapSection = (section: Section): FormSection => {
    const translation = getTranslationFromSection(section, watchLang)

    const mappedSection: FormSection = {
      id: section.id,
      name: translation?.name ?? '',
    }

    if (section.subSections) {
      mappedSection.subsections = section.subSections.map(
        (subsection: Subsection) => {
          const translation = getTranslationFromSection(subsection, watchLang)
          return {
            id: subsection.id,
            name: translation?.name ?? '',
          }
        }
      )
    }
    return mappedSection
  }

  const updateSections = async (params: CreateSectionsModel) => {
    try {
      await createUpdateSections(params)
      navigate(`${basePath}/brands/${brandId}/edit`, {
        state: { brandCurrentTab: BrandTabs.SPONSORS },
      })
      showNotifications()
      setIsSubmitting(false)
    } catch (e: unknown) {
      setIsSubmitting(false)
      if (isNew) notify.error(t('sections.create.feedback.ko'))
      else notify.error(t('sections.update.feedback.ko'))
      if (e instanceof Error) throw e
    }
  }

  const withOutSectionsSubmit = () => {
    setIsSubmitting(true)
    const params: CreateSectionsModel = {
      brandId: brandId || '',
      language: watchLang,
      sections: [],
    }
    updateSections(params)
  }

  const onSubmit = (sections: SectionsForm) => {
    setIsSubmitting(true)
    const params: CreateSectionsModel = {
      brandId: brandId || '',
      language: watchLang,
      sections: [],
    }
    for (const sectionForm of sections.sections) {
      const model: CreateSectionsSectionModel = {
        id: sectionForm.id,
        name: sectionForm.name.trim(),
        index: sections.sections.indexOf(sectionForm),
      }

      if (sectionForm.subsections) {
        model.subsections = sectionForm.subsections.map(
          (subsection: FormSubsection, index: number) => {
            const subModel: CreateSubsectionModel = {
              id: subsection.id,
              name: subsection.name.trim(),
              index,
            }
            return subModel
          }
        )
      }
      params.sections.push(model)
    }

    updateSections(params)
  }

  const renderLanguageSelector = () => {
    return (
      <>
        <FormSelect
          control={control}
          name="language"
          label=""
          options={localizations.map((item) => ({
            label: t(`localizations.${item.isoCode}`),
            value: item.isoCode,
          }))}
          disabled={isNew}
          noOptionsMessage={t('select.empty')}
          menuPlacement="top"
          isClearable={false}
          onBeforeChange={handleBeforeChangeLang}
        />
        <Tooltip content={t('brand.changeLanguage.tooltip')} className="ms-2">
          <Icon name="info_outline" tabIndex={0} />
        </Tooltip>
      </>
    )
  }

  const deleteSection = (sectionIndex: number) => {
    remove(sectionIndex)
  }

  const translationsArray = useMemo(() => {
    const newArray: Translation[][] = []
    sections.map((section) => {
      newArray.push(section.translations)
    })
    return newArray
  }, [sections])

  useEffect(() => {
    if (isNew) return
    const mappedSections = sections.map(mapSection)
    if (mappedSections.length > 0) setValue('sections', mappedSections)
  }, [sections, watchLang])

  useEffect(() => {
    if (!isNew && isLoaded && (!sections || sections.length === 0))
      navigate('../')
  }, [isLoaded])

  useEffect(() => {
    brandId && fetchSections(brandId)
  }, [])

  return (
    <section className="Sections">
      <h2 className="Sections__header">
        {t(`sections.title.${isNew ? 'new' : 'edit'}`)}
        <span className="required">{t('common.required')}</span>
      </h2>
      {!isNew && (
        <TranslationCard
          translations={translationsArray}
          lang={watchLang}
          entity={t('brand.sponsors.entity')}
        />
      )}
      <div className="Sections__list">
        <FormProvider methods={methods}>
          {fields.map((item: FormSection, index: number) => {
            return (
              <EditSectionComponent
                key={item.internalId}
                control={control}
                sectionIndex={index}
                section={sections.find((s) => s.id === item.id)}
                isNew={isNew}
                currentLang={watchLang}
                onClick={deleteSection}
                watchSections={watchSections}
              />
            )
          })}
          {isNew && (
            <button
              className="add-section"
              onClick={() => append({ name: '' })}
            >
              <Icon name="add" />
              {t('sections.new.add-section')}
            </button>
          )}
        </FormProvider>
      </div>
      <FormActions languageSelector={renderLanguageSelector()}>
        <button
          className="btn btn-outline-primary"
          disabled={isSubmitting}
          onClick={onCancel}
        >
          {t('common.cancel')}
        </button>
        <button
          className="btn btn-primary"
          disabled={(!isValid && isNew) || isSubmitting}
          onClick={
            watchSections.length === 0 && !isNew
              ? () => withOutSectionsSubmit()
              : handleSubmit(onSubmit)
          }
        >
          {isSubmitting ? <Loader /> : t('common.save')}
        </button>
      </FormActions>
    </section>
  )
}

export default SectionsScreen
