import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import {
  TopicFamiliesState,
  TopicFamilyDetail,
  TopicFamilyListItem,
} from './types'

const initialState: TopicFamiliesState = {
  detail: null,
  list: [],
  isLoaded: false,
}

export const topicFamiliesSlice = createSlice({
  name: 'topicFamilies',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<TopicFamilyListItem[]>) => {
      state.list = action.payload
    },
    setDetail: (state, action: PayloadAction<TopicFamilyDetail>) => {
      state.detail = action.payload
    },
    addTopics: (state, action: PayloadAction<TopicFamilyListItem[]>) => {
      state.list = [...action.payload, ...state.list]
    },
    updateTopic: (state, action: PayloadAction<TopicFamilyDetail>) => {
      const topicIndex = state.list.findIndex(
        (item) => item.id === action.payload.id
      )
      const updatedTopic = {
        ...action.payload,
        translations: action.payload.translations.map(
          (translation) => translation.language
        ),
      }
      state.list[topicIndex] = updatedTopic
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setList,
  setDetail,
  addTopics,
  updateTopic,
} = topicFamiliesSlice.actions

export const selectTopicFamilies = (state: RootState) => state.topicFamilies

export default topicFamiliesSlice.reducer
