import { FC, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import usePermissions from '../core/permissions/usePermissions'

import Sectors from '../pages/TopicFamilies'
import CreateChannels from '../pages/TopicFamilies/CreateChannels'
import { FormLinkedThemes } from '../pages/TopicFamilies/CreateChannels/LinkedThemes/FormLinkedThemes'
import CreateTopic from '../pages/TopicFamilies/CreateTopics'
import EditTopic from '../pages/TopicFamilies/EditTopic'
import { useCustomRouter } from '../utils/extractParams'

const SectorsRouter: FC = () => {
  const { verifyEditorSectorsChannelsThemes } = usePermissions()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()

  const initPermissionsUser = () => {
    if (!verifyEditorSectorsChannelsThemes()) {
      navigate(`${basePath}/access-denied`)
    }
  }

  useEffect(() => {
    initPermissionsUser()
  }, [])
  return (
    <Routes>
      <Route path="/" element={<Sectors />} />
      <Route path="/new" element={<CreateTopic />} />
      <Route path="/:sectorId" element={<EditTopic />} />
      <Route path="/:sectorId/channels/new" element={<CreateChannels />} />
      <Route
        path="/:sectorId/channels/:channelId"
        element={<CreateChannels />}
      />
      <Route
        path="/:sectorId/channels/:channelId/themes/create"
        element={<FormLinkedThemes isNew />}
      />
      <Route
        path="/:sectorId/channels/:channelId/themes"
        element={<FormLinkedThemes />}
      />
    </Routes>
  )
}

export default SectorsRouter
