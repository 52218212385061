import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  ConfigurationOnboardingDetailResponse,
  ConfigurationOnboardingState,
} from './types'

const initialState: ConfigurationOnboardingState = {
  isLoaded: false,
  detail: null,
}

export const configurationOnboardingSlice = createSlice({
  name: 'configurationOnboarding',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setDetail: (
      state,
      action: PayloadAction<ConfigurationOnboardingDetailResponse>
    ) => {
      state.detail = action.payload
    },
  },
})

export const { setDetail, showLoader, hideLoader } =
  configurationOnboardingSlice.actions

export default configurationOnboardingSlice.reducer
