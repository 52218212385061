import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { TypeDirectory } from '../../../../core/directories/types'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    type: yup
      .string()
      .label(t('directories.form.type'))
      .required(({ label }) => t('validations.required', { label })),
    name: yup
      .string()
      .label(t('topic.topic.modal.name'))
      .max(20, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 20 })
      )
      .required(({ label }) => t('validations.required', { label })),
    description: yup
      .string()
      .label(t('topic.topic.modal.name'))
      .max(80, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 80 })
      )
      .required(({ label }) => t('validations.required', { label })),
    imageUrl: yup
      .string()
      .when('type', {
        is: (type: TypeDirectory) => type == TypeDirectory.Main,
        then: yup
          .string()
          .label(t('topic.topic.modal.image'))
          .required(({ label }) => t('validations.required', { label })),
      })
      .nullable()
      .notRequired(),
    sectorId: yup
      .string()
      .when('type', {
        is: (type: TypeDirectory) => type == TypeDirectory.Channel,
        then: yup
          .string()
          .label(t('navMenu.topics'))
          .required(({ label }) => t('validations.required', { label })),
      })
      .nullable()
      .notRequired(),
    channels: yup
      .array()
      .when('type', {
        is: (type: TypeDirectory) => type == TypeDirectory.Channel,
        then: yup
          .array()
          .min(1)
          .label(t('activities.filters.fields.channels.label'))
          .required(({ label }) => t('validations.required', { label })),
      })
      .nullable()
      .notRequired(),
    code: yup
      .string()
      .when('type', {
        is: (type: TypeDirectory) => type == TypeDirectory.BrandSpace,
        then: yup
          .string()
          .label(t('brand.edit.eventCode'))
          .required(({ label }) => t('validations.required', { label })),
      })
      .nullable()
      .notRequired(),
  })
}
