import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseTopicFamiliesResult,
  UpdateIsPublishedParams,
  ChannelPublishResponse,
  TopicFamilyListItem,
  TopicFamilyDetail,
  ChannelTopicFamily,
} from './types'
import { setList, setDetail, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useTopicFamilies = (): UseTopicFamiliesResult => {
  const dispatch = useAppDispatch()
  const { detail, list, isLoaded } = useAppSelector(
    (state) => state.topicFamilies
  )
  const { get, post, put, patch, del } = useFetch()

  const endpoint = 'topic-families'
  const channelsEndpoint = 'channels'

  const getTopicFamilies = async (): Promise<
    TopicFamilyListItem[] | undefined
  > => await get({ endpoint })

  const fetchTopicFamilies = async () => {
    dispatch(showLoader())
    const response: TopicFamilyListItem[] | undefined = await getTopicFamilies()
    if (response) {
      dispatch(setList(response))
    }
    dispatch(hideLoader())
  }

  const bulkAddTopicFamilies = async (topics: TopicFamilyListItem[]) => {
    await post({
      endpoint: `${endpoint}/batch`,
      body: topics,
    })
    fetchTopicFamilies()
  }

  const getTopicFamily = async (id: string) => {
    const response: TopicFamilyDetail | undefined = await get({
      endpoint: `${endpoint}/${id}`,
    })
    return response
  }

  const fetchTopicFamily = async (id: string) => {
    dispatch(showLoader())
    const response: TopicFamilyDetail | undefined = await getTopicFamily(id)
    if (response) {
      dispatch(setDetail(response))
    }
    dispatch(hideLoader())
  }

  const createTopicFamily = async (body: Partial<TopicFamilyDetail>) => {
    const response: TopicFamilyDetail | undefined = await post({
      endpoint,
      body,
    })
    return response
  }

  const editTopicFamily = async ({ id, ...body }: TopicFamilyDetail) => {
    const response: TopicFamilyDetail | undefined = await put({
      endpoint,
      id,
      body,
    })
    if (response) {
      dispatch(setDetail(response))
    }
    return response
  }

  const deleteTopicFamily = async (id: string) => {
    await del({ endpoint, id })
  }

  interface ImageResult {
    uri: string
  }

  const uploadImage = async (image: string) => {
    const form = new FormData()
    const blob = await fetch(image).then((r) => r.blob())
    form.set('imageFile', blob)
    dispatch(showLoader())
    try {
      const result: ImageResult | undefined = await post({
        endpoint: `${endpoint}/image`,
        body: form,
        isMultipart: true,
      })
      return result?.uri ?? ''
    } finally {
      dispatch(hideLoader())
    }
  }

  const updateIsPublished = async (
    params: UpdateIsPublishedParams
  ): Promise<void> => {
    const result: TopicFamilyDetail | undefined = await put({
      endpoint: endpoint,
      id: params.id,
      body: params,
    })
    if (result)
      dispatch(
        setList(
          list.map((sector) => {
            if (sector.id === params.id)
              return { ...sector, isPublished: params.isPublished }
            return sector
          })
        )
      )
  }

  const publishChannel = async (id: string, isPublished: boolean) => {
    const response: ChannelPublishResponse | undefined = await patch({
      endpoint: channelsEndpoint,
      id: `${id}/publish`,
      body: { isPublished },
    })
    if (response) {
      dispatch(
        setList(
          list.map((sector) => {
            if (sector.id === response.sectorId) {
              return {
                ...sector,
                isPublished: response.sectorPublishValue,
                channels: sector.channels.map((channel) => {
                  if (channel.id === response.channelId)
                    return {
                      ...channel,
                      isPublished: response.channelPublishValue,
                    }
                  return channel
                }),
              }
            }
            return sector
          })
        )
      )
    }
  }

  const sortTopicFamilies = async (topicFamilies: TopicFamilyListItem[]) => {
    const oldSectors = [...list]
    try {
      dispatch(setList(topicFamilies))
      await put({
        endpoint: `${endpoint}/reorder`,
        body: {
          sectors: topicFamilies.map(({ id }, index) => ({ id, index })),
        },
      })
    } catch {
      dispatch(setList(oldSectors))
    }
  }

  const sortChannels = async (
    sectorId: string,
    channels: ChannelTopicFamily[]
  ) => {
    const currentSector = list.find((s) => s.id === sectorId)
    if (currentSector) {
      const oldSectors = [...list]
      const newSectors = list.map((sector) => {
        if (sector.id === sectorId) {
          return {
            ...sector,
            channels,
          }
        }
        return sector
      })
      try {
        dispatch(setList(newSectors))
        await put({
          endpoint: `${channelsEndpoint}/reorder`,
          body: {
            channels: channels.map(({ id }, index) => ({ id, index })),
          },
        })
      } catch {
        dispatch(setList(oldSectors))
      }
    }
  }

  return {
    topicFamily: detail,
    topicFamilyList: list,
    isLoaded,
    getTopicFamilies,
    getTopicFamily,
    fetchTopicFamilies,
    fetchTopicFamily,
    bulkAddTopicFamilies,
    createTopicFamily,
    editTopicFamily,
    deleteTopicFamily,
    uploadImage,
    updateIsPublished,
    publishChannel,
    sortTopicFamilies,
    sortChannels,
  }
}

export default useTopicFamilies
