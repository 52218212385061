import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Header, LogoImage } from '@liveconnect/components'

import useAuth from '../../core/auth/useAuth'
import useUi from '../../core/ui/useUi'
import useTenantConfig from '../../core/tenantConfig/useTenantConfig'
import { ApplicationLogo } from './application-logo'
import useMe from '../../core/me/useMe'

import './styles.scss'

const AppHeader: FC = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { me } = useMe()
  const userTenant = useMemo(() => user?.profile.tenant_id ?? '', [user])
  const { toggleShowPanel, isPanelVisible } = useUi()
  const { tenantConfig } = useTenantConfig()

  return (
    <Header className="Header">
      <Header.Left>
        <Header.Logo>
          <Link to={`/t/${userTenant}`} className="navbar-brand">
            <ApplicationLogo />
          </Link>
        </Header.Logo>
      </Header.Left>

      <Header.Right>
        {tenantConfig?.tenantLogoUrl && (
          <Header.Logo>
            <LogoImage
              src={tenantConfig.tenantLogoUrl}
              size="sm"
              alt="ifemaLogo"
              className="header__tenant-logo"
            />
          </Header.Logo>
        )}

        <Header.Menu>
          <Header.AvatarButton
            onClick={toggleShowPanel}
            active={isPanelVisible}
            avatar={{
              name: me?.name ?? '',
              surname: me?.surname ?? '',
              imageUrl: me?.imageUrl ?? '',
              alt: t('header.avatar'),
            }}
          />
        </Header.Menu>
      </Header.Right>
    </Header>
  )
}

export default AppHeader
