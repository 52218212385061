import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { Me, IsoCode } from './types'
import { setDetail } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useMe = () => {
  const dispatch = useAppDispatch()
  const { detail } = useAppSelector((state) => state.me)
  const { get, patch } = useFetch()

  const endpoint = 'members'

  const fetchDetail = async () => {
    const response: Me | undefined = await get({ endpoint: `${endpoint}/me` })
    response && dispatch(setDetail(response))
  }

  const changeLocalization = async (isoCode: IsoCode) => {
    const response: { isoCode: IsoCode } | undefined = await patch({
      endpoint: `${endpoint}/localization`,
      body: { isoCode },
    })
    response && dispatch(setDetail({ ...detail!, isoCode: response.isoCode }))
  }

  return {
    me: detail,
    fetchMe: fetchDetail,
    changeLocalization,
  }
}

export default useMe
